import { EditOutlined, HomeOutlined, ShopOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { TSettingsModal } from '..';

interface IProps {
  showModal: (modal: TSettingsModal) => void;
  isSettings: boolean;
}

const SettingsSider: React.FC<IProps> = ({ showModal, isSettings }) => {
  return (
    <>
      <Menu mode="inline">
        <Menu.Item
          icon={<EditOutlined />}
          onClick={() => showModal('companyData')}
          disabled={!isSettings}
        >
          Dane firmy
        </Menu.Item>
        <Menu.Item
          icon={<HomeOutlined />}
          onClick={() => showModal('fvAddress')}
          disabled={!isSettings}
        >
          Adres firmy
        </Menu.Item>
        <Menu.Item
          icon={<ShopOutlined />}
          onClick={() => showModal('localAddress')}
          disabled={!isSettings}
        >
          Adres lokalu
        </Menu.Item>
      </Menu>
    </>
  );
};

export default SettingsSider;
