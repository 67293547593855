import { IdType } from 'services/types';
import { SupplierT } from './Suppliers';

export const SUPPLIERS_LOAD_SUCCESS = 'SUPPLIERS_LOAD_SUCCESS';
export const SUPPLIERS_LOAD_ERROR = 'SUPPLIERS_LOAD_ERROR';
export const SUPPLIERS_SET_SUPPLIER = 'SUPPLIERS_SET_SUPPLIER';
export const SUPPLIERS_ADD_TO_LIST = 'SUPPLIERS_ADD_TO_LIST';
export const SUPPLIERS_REMOVE_FROM_LIST = 'SUPPLIERS_REMOVE_FROM_LIST';
export const SUPPLIERS_UPDATE_IN_LIST = 'SUPPLIERS_UPDATE_IN_LIST';

export interface SuppliersLoadSuccessT {
  type: typeof SUPPLIERS_LOAD_SUCCESS;
  suppliers: SupplierT[];
}
export interface SuppliersLoadErrorT {
  type: typeof SUPPLIERS_LOAD_ERROR;
}
export interface SuppliersSetSupplierT {
  type: typeof SUPPLIERS_SET_SUPPLIER;
  supplier: SupplierT | null;
}
export interface SuppliersAddToListT {
  type: typeof SUPPLIERS_ADD_TO_LIST;
  supplier: SupplierT;
}
export interface SuppliersRemoveFromListT {
  type: typeof SUPPLIERS_REMOVE_FROM_LIST;
  id: IdType;
}
export interface SuppliersUpdateInListT {
  type: typeof SUPPLIERS_UPDATE_IN_LIST;
  supplier: SupplierT;
}

export type SuppliersActionsT =
  | SuppliersAddToListT
  | SuppliersRemoveFromListT
  | SuppliersUpdateInListT
  | SuppliersLoadSuccessT
  | SuppliersLoadErrorT
  | SuppliersSetSupplierT;
