import { Descriptions } from 'antd';
import React from 'react';
import { ContactT } from 'services/store/types/settings/Settings';

interface PropsT {
  contact: ContactT;
}

const CompanyData: React.FC<PropsT> = ({ contact }) => {
  if (contact)
    return (
      <Descriptions title="Dane firmy" size="small" column={1}>
        <Descriptions.Item label="Nazwa">
          <strong> {contact.name}</strong>
        </Descriptions.Item>
        <Descriptions.Item label="Imię i nazwisko">
          <strong>
            {contact.firstname} {contact.surname}
          </strong>
        </Descriptions.Item>
        <Descriptions.Item label="NIP">
          <strong> {contact.nip}</strong>
        </Descriptions.Item>
        <Descriptions.Item label="REGON">
          <strong> {contact.regon}</strong>
        </Descriptions.Item>
        <Descriptions.Item label="E-mail">
          <strong> {contact.email}</strong>
        </Descriptions.Item>
        <Descriptions.Item label="Nr telefonu">
          <strong> {contact.phone}</strong>
        </Descriptions.Item>
        <Descriptions.Item label="www">
          <strong> {contact.webpage}</strong>
        </Descriptions.Item>
        <Descriptions.Item label="Nazwa banku ">
          <strong> {contact.bankName}</strong>
        </Descriptions.Item>
        <Descriptions.Item label="Nr konta">
          <strong> {contact.bankAccount}</strong>
        </Descriptions.Item>
      </Descriptions>
    );
  return null;
};

export default CompanyData;
