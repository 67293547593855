/* eslint-disable @typescript-eslint/default-param-last */
import moment from 'moment';
import {
  NewProjectActionsT,
  NEWPROJECT_SET_USER,
  NEWPROJECT_SET_CUSTOMER,
  NEWPROJECT_CLEAR_CUSTOMER,
  NEWPROJECT_SET_FIELD,
  NEWPROJECT_CLEAR_PROJECT,
} from '../types/newProject/actions';
import { NewProjectT } from '../types/newProject/NewProject';

const initialState: NewProjectT = {
  name: '',
  price: undefined,
  User: undefined,
  UserId: undefined,
  Customer: undefined,
  CustomerId: undefined,
  montageAddress: undefined,
  projectYear: moment(),
  comments: '',
  images: [],
};

const newProjectReducer = (
  state = initialState,
  action: NewProjectActionsT
): NewProjectT => {
  switch (action.type) {
    case NEWPROJECT_SET_USER: {
      return {
        ...state,
        User: action.user,
        UserId: action.user.id,
      };
    }
    case NEWPROJECT_SET_CUSTOMER: {
      return {
        ...state,
        Customer: action.customer,
        CustomerId: action.customer.id,
        montageAddress: undefined,
      };
    }
    case NEWPROJECT_CLEAR_CUSTOMER: {
      return {
        ...state,
        CustomerId: undefined,
        Customer: undefined,
        montageAddress: undefined,
      };
    }
    case NEWPROJECT_SET_FIELD: {
      return { ...state, [action.field]: action.value };
    }
    case NEWPROJECT_CLEAR_PROJECT: {
      return {
        ...state,
        name: '',
        price: undefined,
        User: action.user,
        UserId: action.user.id,
        Customer: undefined,
        CustomerId: undefined,
        montageAddress: undefined,
        comments: '',
      };
    }
    default:
      return state;
  }
};

export { newProjectReducer };
