import { Button, message, Typography, Col, Row } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useModal } from 'services/hooks/useModal';
import {
  pricingAddPattern,
  pricingRemovePattern,
} from 'services/store/actions/pricing';
import { PatternT, VeneerT } from 'services/store/types/catalog/Catalog';
import {
  PricingPatternT,
  PricingPatternTypeT,
  PricingT,
} from 'services/store/types/pricing/Pricing';
import { IdType } from 'services/types';
import AddFrontPattern from '../AddFrontPattern';
import { checkIsPricingPatternsAreUserdInElements } from '../utils';
import PricingPatternsList from './List';

const initModal = {
  frontsPatterns: false,
  boardsPatterns: false,
};

interface PropsT {
  fronts: PatternT[];
  boards: PatternT[];
  veneers: VeneerT[];
  pricing: PricingT;
}

const PricingPatterns: React.FC<PropsT> = ({
  fronts,
  boards,
  veneers,
  pricing,
}) => {
  const dispatch = useDispatch();
  const { modal, showModal, closeModal } = useModal(initModal);

  const handleAddToList = (item: PricingPatternT) => {
    dispatch(pricingAddPattern(item));
  };
  const handleRemoveFromList = (
    pattern: PricingPatternT,
    type: PricingPatternTypeT
  ) => {
    //   check is pattern is used in some segment
    const isPatternUsed = checkIsPricingPatternsAreUserdInElements(
      pricing,
      pattern
    );
    if (isPatternUsed)
      return message.error('Wzór jest używany w jednym z wybranych korpusów');

    return dispatch(pricingRemovePattern(pattern, type));
  };

  return (
    <>
      <Row>
        <Col xs={12} md={6}>
          <Typography.Title level={4}>Korpus</Typography.Title>
          <PricingPatternsList
            patterns={pricing.PricingPatterns.filter(
              (pattern: PricingPatternT) => pattern.type === 'body'
            )}
            actions={{ remove: handleRemoveFromList }}
            editable={pricing.listEditable}
          />

          <Button
            type="primary"
            disabled={pricing.listEditable}
            onClick={() => showModal('boardsPatterns')}
          >
            Dodaj wzór płyty
          </Button>
        </Col>

        <Col xs={12} md={6}>
          <Typography.Title level={4}>Fronty</Typography.Title>
          <PricingPatternsList
            patterns={pricing.PricingPatterns.filter(
              (pattern: PricingPatternT) => pattern.type === 'front'
            )}
            actions={{ remove: handleRemoveFromList }}
            editable={pricing.listEditable}
          />

          <Button
            type="primary"
            disabled={pricing.listEditable}
            onClick={() => showModal('frontsPatterns')}
          >
            Dodaj wzór frontu
          </Button>
        </Col>
      </Row>

      {/* MODALS */}
      {/* NOTE Add front pattern */}
      {(modal.frontsPatterns || modal.boardsPatterns) && (
        <AddFrontPattern
          fronts={fronts}
          boards={boards}
          veneers={veneers}
          closeModal={closeModal}
          visible={modal.frontsPatterns || modal.boardsPatterns}
          handleAddPattern={handleAddToList}
          patternType={modal.frontsPatterns ? 'front' : 'body'}
        />
      )}
    </>
  );
};

export default PricingPatterns;
