import { UserAddOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { TCustomersListModal } from '..';

interface IProps {
  showModal: (modal: TCustomersListModal) => void;
}

const CustomersListSider: React.FC<IProps> = ({ showModal }) => {
  return (
    <>
      <Menu mode="inline">
        <Menu.Item
          icon={<UserAddOutlined style={{ color: 'green' }} />}
          onClick={() => showModal('newCustomer')}
        >
          Dodaj klienta
        </Menu.Item>
      </Menu>
    </>
  );
};

export default CustomersListSider;
