import { UserT } from './Auth';

export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';
export const AUTH_USER_LOAD_SUCCESS = 'AUTH_USER_LOAD_SUCCESS';
export const AUTH_LOGOUT_USER = 'AUTH_LOGOUT_USER';

export interface UserLoginSuccessT {
  type: typeof AUTH_LOGIN_SUCCESS;
  token: string;
}
export interface UserLoginErrorT {
  type: typeof AUTH_LOGIN_ERROR;
}

export interface UserLoadSuccessT {
  type: typeof AUTH_USER_LOAD_SUCCESS;
  user: UserT;
  permission: string;
}

export interface UserLogoutT {
  type: typeof AUTH_LOGOUT_USER;
}

export type AuthActionsT =
  | UserLoadSuccessT
  | UserLoginSuccessT
  | UserLoginErrorT
  | UserLogoutT;
