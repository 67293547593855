import { UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, message, Modal, Select, Tooltip } from 'antd';
import Upload, { RcFile } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import useUser from 'hooks/useUser';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addProjectFile } from 'services/api/files/post';
import { getAllProjectDataById } from 'services/store/actions/projects';
import { setSpinner } from 'services/store/actions/view';
import { FileT } from 'services/store/types/projects/Projects';
import { perms, projectFiles } from 'services/utils/const';
import { isProjectIncludeFile } from 'services/utils/project';
import styled from 'styled-components';

const { Option } = Select;

const StyledInput = styled(Input)`
  margin-bottom: 10px;
`;

export interface IAddFileProps {
  filesInProject: FileT[];
  visible: boolean;
  closeModal: () => void;
  refetchFiles: () => void;
  projectId: string;
}

const AddFile: React.FC<IAddFileProps> = ({
  visible,
  closeModal,
  projectId,
  refetchFiles,
  filesInProject,
}) => {
  const dispatch = useDispatch();
  const { hasPermission, getPermission, isAdmin } = useUser();
  const [files, setFiles] = useState<RcFile[]>([]);
  const [name, setName] = useState<undefined | string>(undefined);
  const [description, setDescription] = useState('');
  const [protect, setProtect] = useState(false);
  const [isCustomName, setIsCustomName] = useState(false);

  useEffect(() => {
    if (hasPermission([perms.CARPENTER, perms.EXTERNAL_COMPANY]))
      handleCustomName(true);
  }, []);

  const handleFile = (f: RcFile, fList: RcFile[]) => {
    setFiles([...files, ...fList]);
    return false;
  };
  const handleRemove = (f: UploadFile) => {
    const filesList = files.filter((file: RcFile) => file.uid !== f.uid);
    setFiles(filesList);
  };

  const handleCustomName = (v: boolean) => {
    setName(undefined);
    setIsCustomName(v);
  };

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    await addProjectFile(
      projectId,
      files,
      { name, description, type: 'file', protect },
      (p) => {
        message.success('Dodano plik do projektu');
        refetchFiles();
        dispatch(setSpinner(false));
        closeModal();
      },
      () => {
        dispatch(setSpinner(false));
        message.error('Błąd!');
      }
    );
  };

  return (
    <Modal
      title="Dodaj plik"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      onOk={handleSubmit}
      okButtonProps={{ disabled: !files.length || !name }}
    >
      <div>
        <Select
          disabled={isCustomName}
          placeholder="Nazwa*"
          size="large"
          style={{ width: '100%', marginBottom: 10 }}
          value={!isCustomName ? name : undefined}
          onChange={(value) => setName(value)}
          allowClear={true}
        >
          {filesInProject &&
            projectFiles
              .filter(
                (item: any) => isAdmin() || hasPermission([item.permToCreate])
              )
              .map((item: any) => {
                return (
                  <Option
                    key={item.name}
                    value={item.name}
                    disabled={isProjectIncludeFile(filesInProject, item.name)}
                  >
                    {item.name}
                  </Option>
                );
              })}
        </Select>
        <div>
          <Checkbox
            checked={isCustomName}
            onChange={(e) => handleCustomName(e.target.checked)}
            style={{ marginBottom: 10 }}
            disabled={getPermission() === perms.CARPENTER}
          >
            Niestandardowa nazwa
          </Checkbox>
        </div>
        <StyledInput
          disabled={!isCustomName}
          value={isCustomName ? name : ''}
          placeholder="Nazwa*"
          size="large"
          onChange={(e) => setName(e.target.value)}
        />
        <StyledInput
          value={description}
          placeholder="Opis..."
          onChange={(e) => setDescription(e.target.value)}
        />
        <div>
          <Tooltip title="Widoczny dla administratorów, projektantów i osoby dodającej">
            <Checkbox
              checked={protect}
              onChange={(e) => setProtect(e.target.checked)}
              style={{ marginBottom: 10 }}
            >
              Chroniony
            </Checkbox>
          </Tooltip>
        </div>
        <Upload
          fileList={files}
          beforeUpload={handleFile}
          onRemove={handleRemove}
          multiple
        >
          <Button size="large" icon={<UploadOutlined />}>
            Dodaj plik
          </Button>
        </Upload>
      </div>
    </Modal>
  );
};

export default AddFile;
