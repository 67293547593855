import moment, { Moment } from 'moment';

/**
 * DISPLAY
 */
export const initZero = (number: number) => {
  if (number < 10) return `0${number}`;
  return number;
};

export const dateToString = (value: Date | undefined) => {
  if (value) {
    const date = new Date(value);
    return `${initZero(date.getDate())}.${initZero(
      date.getMonth() + 1
    )}.${date.getFullYear()}r.`;
  }
  return '-';
};

export const rangeDateToString = (start: Date, end: Date) => {
  const s = new Date(start);
  const e = new Date(end);

  if (s.getFullYear() === e.getFullYear()) {
    if (s.getMonth() === e.getMonth()) {
      return `${initZero(s.getDate())} - ${initZero(e.getDate())}.${initZero(
        e.getMonth() + 1
      )}.${e.getFullYear()}r.`;
    }
    return `${initZero(s.getDate())}.${initZero(s.getMonth() + 1)} - ${initZero(
      e.getDate()
    )}.${initZero(e.getMonth() + 1)}.${e.getFullYear()}r.`;
  }
  return `${initZero(s.getDate())}.${initZero(
    s.getMonth() + 1
  )}.${s.getFullYear()}r. - ${initZero(e.getDate())}.${initZero(
    e.getMonth() + 1
  )}.${e.getFullYear()}r.`;
};

/**
 * COMPARE
 */
export const isDateEqual = (d1: Date, d2: Date) => {
  const date1 = new Date(d1);
  const date2 = new Date(d2);

  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const isDateBetween = (date: Date, start: Date, end: Date) => {
  const result = moment(date).isBetween(
    moment(start).hour(0),
    moment(end).hour(23)
  );
  return result;
};

export const isMonthEqual = (d1: Moment, d2: Moment) =>
  d1.month() === d2.month() && d1.year() === d2.year();

export const daysUntilToday = (dateFrom: Moment) =>
  moment().diff(dateFrom, 'days', true);
export const daysFromToday = (dateTo: Moment) =>
  dateTo.diff(moment(), 'days', true);

export const weeksFromDate = (dateTo: Moment, dateFrom?: Moment) => {
  const from = dateFrom || moment();
  return dateTo.diff(from, 'weeks');
};

export const getDatesBetween = (start: Moment, end: Moment) => {
  const result: Moment[] = [start.clone()];
  if (start && end) {
    let currentDate = start.clone();
    while (currentDate.isBefore(end)) {
      currentDate.add(1, 'day');
      result.push(currentDate.clone());
    }
  }
  return result;
};
