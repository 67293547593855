import { Dispatch } from 'redux';
import setAuthToken from 'services/utils/setAuthToken';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import Axios from 'axios';
import { Moment } from 'moment';
import {
  TermsLoadSuccessT,
  TERMS_LOAD_SUCCESS,
  TermsLoadErrorT,
  TERMS_LOAD_ERROR,
  TermsSetDateT,
  TERMS_SET_DATE,
  TermsSetFilterT,
  TERMS_SET_FILTER,
} from '../types/terms/actions';
import { TermT } from '../types/terms/Terms';
import { setSpinner } from './view';

export const termsLoadSuccess = (terms: TermT[]): TermsLoadSuccessT => ({
  type: TERMS_LOAD_SUCCESS,
  terms,
});
export const termsLoadError = (): TermsLoadErrorT => ({
  type: TERMS_LOAD_ERROR,
});
export const termsSetDate = (date: Moment): TermsSetDateT => {
  return {
    type: TERMS_SET_DATE,
    date,
  };
};
export const termsSetFilter = (
  key: 'customerId' | 'type',
  value: string | number | undefined
): TermsSetFilterT => ({
  type: TERMS_SET_FILTER,
  key,
  value,
});

/**--------------------
 * THUNK
 ----------------------*/
export const getTermsByYear = (
  year: number,
  onEnd?: () => void,
  onError?: () => void
) => async (dispatch: Dispatch) => {
  setAuthToken();
  try {
    const res: any = await Axios.get(`/api/schedule/year/${year}`);

    dispatch(termsLoadSuccess(res.data.terms));
    if (onEnd) onEnd();
  } catch (error) {
    dispatch(termsLoadError());
    axiosErrorHandle(error, onError);
  }
  dispatch(setSpinner(false));
};
