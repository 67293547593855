import { Input, InputNumber, Table, Typography } from 'antd';
import Img from 'components/Image';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  NewOrderAccessoryT,
  NewOrderT,
} from 'services/store/types/orders/Orders';

const columns = [
  {
    title: 'Lp.',
    align: 'center' as const,
    render: (t: string, r: any, i: number) => i + 1,
  },
  {
    title: 'Zdjęcie',
    dataIndex: 'img',
    align: 'center' as const,
    render: (t: string, record: any, i: number) => (
      <Img
        previewPath={record.imgPath}
        path={record.miniaturePath}
        isExist={record.Accessory}
      />
    ),
  },
  {
    title: 'Nazwa',
    dataIndex: 'name',
    align: 'center' as const,
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
    align: 'center' as const,
  },
  {
    title: 'Cena',
    dataIndex: 'price',
    align: 'center' as const,
  },
  {
    title: 'Ilość',
    dataIndex: 'qty',
    align: 'center' as const,
    render: (t: number, record: any, i: number) => (
      <InputNumber
        value={t}
        onChange={(value) => record.handleQty(value, record.index, i)}
        min={1}
      />
    ),
  },
];

interface PropsT {
  order: NewOrderT;
  handleField: (
    e: ChangeEvent<HTMLInputElement>,
    index: number,
    key: 'name' | 'description'
  ) => void;
  handleQty: (
    value: number | string | undefined | null,
    index: number,
    accessoryIndex: number
  ) => void;
  index: number;
}

const NewOrder: React.FC<PropsT> = ({
  order,
  handleField,
  index,
  handleQty,
}) => {
  const [list, setList] = useState<any>([]);

  useEffect(() => {
    const newList = order.OrderAccessories.map(
      (accessory: NewOrderAccessoryT) => ({
        ...accessory,
        ...accessory.Accessory,
        key: accessory.AccessoryId,
        handleQty,
        index,
      })
    );
    setList(newList);
  }, [order]);

  // FIXME  poprawić te handlery do obsługi. W tym momencie są zdublowane w scenes/projectorders/comp/newOrders i w comp/orders/newOrderModal

  return (
    <div>
      <Typography.Title level={3}>{order.Supplier.name}</Typography.Title>
      <div>
        <Input
          value={order.name}
          onChange={(e) => handleField(e, index, 'name')}
          placeholder="Nazwa"
          style={{ maxWidth: 300, marginBottom: 3, marginRight: 3 }}
        />
        <Input
          value={order.description}
          onChange={(e) => handleField(e, index, 'description')}
          placeholder="Opis..."
          style={{ maxWidth: 400, marginBottom: 3 }}
        />
      </div>
      <Table
        columns={columns}
        dataSource={list}
        pagination={false}
        size="small"
      />
    </div>
  );
};

export default NewOrder;
