import { Descriptions } from 'antd';
import React from 'react';
import { OtherSettingsT } from 'services/store/types/settings/Settings';

interface PropsT {
  settings: OtherSettingsT;
}

const OtherSettings: React.FC<PropsT> = ({ settings }) => {
  return (
    <div>
      <Descriptions title="Inne ustawienia">
        <Descriptions.Item label="Id użytkownika BLOW">
          <strong>{settings.blowUserId}</strong>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default OtherSettings;
