import React from 'react';
import PageTemplate from 'templates/PageTemplate';
import { PageHeader } from 'antd';
import { getPersonalName } from 'services/utils/users';
import PermissionTag from 'components/Users/PermissionTag';
import PersonalData from 'components/Users/PersonalData';
import { StyledH1 } from 'components/Header';
import useUser from 'hooks/useUser';
import SiderTemplate from 'templates/SiderTemplate/SiderTemplate';
import ContentTemplate from 'templates/ContentTemplate/ContentTemplate';
import { useModal } from 'services/hooks/useModal';
import UserSettingsSider from './sider/UserSettingsSider';
import ChangePassword from './components/modals/ChangePassword';
import ChangeData from 'components/Users/EditDataModal/ChangeData';
import DeleteUser from '../components/DeleteUser';

const initModal = {
  dataChange: false,
  userRemove: false,
  passwordChange: false,
};
export type TUserSettingsModal = keyof typeof initModal;

const UserSettingsPage = () => {
  const { user } = useUser();
  const { modal, showModal, closeModal } = useModal(initModal);

  if (user)
    return (
      <PageTemplate>
        <SiderTemplate>
          <UserSettingsSider showModal={showModal} />
        </SiderTemplate>
        <ContentTemplate>
          <PageHeader
            title={<StyledH1>{getPersonalName(user)}</StyledH1>}
            subTitle={<PermissionTag permission={user.permission} />}
          >
            <PersonalData title="Dane użytkownika" user={user} column={1} />
          </PageHeader>
        </ContentTemplate>

        {/* MODALS */}
        {modal.passwordChange && user && (
          <ChangePassword
            visible={modal.passwordChange}
            closeModal={closeModal}
            user={user}
          />
        )}
        {modal.dataChange && user && (
          <ChangeData
            visible={modal.dataChange}
            closeModal={closeModal}
            user={user}
          />
        )}
        {modal.userRemove && user && (
          <DeleteUser
            visible={modal.userRemove}
            closeModal={closeModal}
            user={user}
          />
        )}
      </PageTemplate>
    );
  return null;
};

export default UserSettingsPage;
