import axios, { AxiosResponse } from 'axios';
import FileSaver from 'file-saver';
import { ProjectT } from 'services/store/types/projects/Projects';
import { FileTypesT } from 'services/types';
import setAuthToken from 'services/utils/setAuthToken';
import { getPersonalName } from 'services/utils/users';

export interface IFileData {
  fileType: FileTypesT;
  body?: any;
  name: string;
  ext: 'pdf' | 'docx';
}

export const filesApi = {
  //NOTE GET
  getProjectDocument: async (
    project: ProjectT,
    fileData: IFileData
  ): Promise<any> => {
    setAuthToken();
    const res = await axios({
      url: `/api/files/project/document/${project.id}`,
      method: 'POST',
      responseType: 'arraybuffer',
      data: { ...fileData, name: undefined },
    });
    return FileSaver.saveAs(
      new Blob([res.data], { type: `application/${fileData.ext}` }),
      `${fileData.name}_${getPersonalName(project.Customer)}.${fileData.ext}`
    );
  },
};
