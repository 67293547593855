import React, { useEffect, useState } from 'react';
import {
  ProjectStatusT,
  ProjectT,
} from 'services/store/types/projects/Projects';
import { Select, Modal, message } from 'antd';
import { projectStates } from 'services/utils/const';
import { capitalizeFirstLetter } from 'services/utils/string';
import { useMutation } from '@tanstack/react-query';
import { projectsApi } from 'services/api/projects.api';
import useUser from 'hooks/useUser';

const { Option } = Select;

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  refetchProject: () => Promise<any>;
  project: ProjectT;
}

const ChangeStatus: React.FC<PropsT> = ({
  visible,
  closeModal,
  project,
  refetchProject,
}) => {
  const { isAdmin, isDesigner, isTechnologist, isCarpenter } = useUser();
  const [status, setStatus] = useState<ProjectStatusT | undefined>(undefined);

  useEffect(() => {
    if (isDesigner()) return setStatus('technolog');
    if (isTechnologist()) return setStatus('produkcja');
    if (isCarpenter()) return setStatus('montaż');
    return undefined;
  }, []);

  const handleStatus = (value: ProjectStatusT) => setStatus(value);

  const updateStatus = useMutation(
    async () => status && projectsApi.updateStatus(project.id, status),
    {
      onSuccess() {
        message.success('Status zaktualizowany');
        refetchProject();
        closeModal();
      },
      onError(err: any) {
        console.log(err?.response);
        message.error('Bład');
      },
    }
  );

  return (
    <Modal
      title="Zmiana statusu"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      onOk={() => updateStatus.mutate()}
      okButtonProps={{ disabled: !status, loading: updateStatus.isLoading }}
    >
      <Select
        size="large"
        placeholder="Status"
        value={status}
        onChange={handleStatus}
        style={{ width: '100%' }}
        disabled={!isAdmin()}
      >
        {projectStates.map((item: string) =>
          item === 'zakończone' ? null : (
            <Option key={item} value={item}>
              {capitalizeFirstLetter(item)}
            </Option>
          )
        )}
      </Select>
    </Modal>
  );
};

export default ChangeStatus;
