import Axios from 'axios';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import { LoginValues } from 'services/types';

export const authUser = async (
  credentials: LoginValues,
  onEnd: (token: string) => void,
  onErr: (message: string) => void
) => {
  try {
    const res = await Axios.post('/api/auth/', credentials);
    const { data } = res;

    onEnd(data.token);
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};
