import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

export const StyledH1 = styled.h1`
  font-size: 38px;
  color: ${({ theme }) => theme.primary};
  letter-spacing: 5px;
  cursor: ${({ clickable }: { clickable?: boolean }) =>
    clickable ? 'pointer' : 'default'};
  ${() => {
    if (isMobile) return 'font-size: 28px; letter-spacing: 0px';
    return null;
  }}
`;
export const StyledH2 = styled.h2`
  color: ${({ theme }) => theme.secondary};
  font-size: 28px;
  letter-spacing: 3px;
  ${() => {
    if (isMobile) return 'font-size: 22px; letter-spacing: 0px';
    return null;
  }}
`;
export const StyledH3 = styled.h3`
  color: ${({ theme }) => theme.tertiary};
  font-size: 22px;
  letter-spacing: 2px;
  ${() => {
    if (isMobile) return 'font-size: 18px; letter-spacing:0 ';
    return null;
  }}
`;
export const StyledH4 = styled.h4`
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: bold;
  ${() => {
    if (isMobile) return 'font-size: 16px; letter-spacing:0 ';
    return null;
  }}
`;
