import React, { useEffect, useState } from 'react';
import PageTemplate from 'templates/PageTemplate';
import {
  Badge,
  Col,
  Divider,
  Empty,
  message,
  PageHeader,
  Popover,
  Row,
} from 'antd';
import { StyledH1 } from 'components/Header';
import { useDispatch } from 'react-redux';
import { setProjectCollapse } from 'services/store/actions/view';
import { updateFilesInProject } from 'services/store/actions/projects';
import { useParams, useHistory } from 'react-router';
import { getPersonalName } from 'services/utils/users';
import IconDivider from 'components/IconDivider';
import {
  UserOutlined,
  HistoryOutlined,
  FileTextOutlined,
  FolderOpenOutlined,
  IdcardOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { perms } from 'services/utils/const';
import { checkProjectErrors } from 'components/Projects/CustomersWithProjectsList/utils';
import { ProjectErrorT } from 'components/Projects/CustomersWithProjectsList/utils/types';
import ProjectData from './components/ProjectData';
import ProjectInfo from './components/ProjectInfo';
import CustomerData from './components/CustomerData';
import ProjectHistory from './components/ProjectHistory';
import { useMutation, useQueries } from '@tanstack/react-query';
import { projectsApi } from 'services/api/projects.api';
import { AxiosResponse } from 'axios';
import {
  CommentT,
  FileT,
  HistoryItemT,
  ProjectT,
} from 'services/store/types/projects/Projects';
import Comments from './components/Comments';
import Images from './components/Images';
import Files from './components/Files';
import usePermission from 'hooks/useUser';
import SiderTemplate from 'templates/SiderTemplate/SiderTemplate';
import ContentTemplate from 'templates/ContentTemplate/ContentTemplate';
import ProjectSider from './sider/ProjectSider';
import { useModal } from 'services/hooks/useModal';
import AddAddress from 'components/Address/AddressModal/AddAddress';
import DeleteProject from './components/Modals/DeleteProject';
import Payments from './components/Modals/Payments';
import GetFiles from './components/Modals/GetFiles';
import SetMeasureWeek from './components/Modals/SetMeasureWeek';
import SetMeasureDate from './components/Modals/SetMeasureDay';
import SetMontageWeek from './components/Modals/SetMontageWeek';
import SetMontageDay from './components/Modals/SetMontageDay';
import ChangeDesigner from './components/Modals/ChangeDesigner';
import ChangeExternalCompany from './components/Modals/ChangeExternalCompany';
import ChangePrice from './components/Modals/ChangePrice';
import ChangeName from './components/Modals/ChangeName';
import ChangeProjectNumber from './components/Modals/ChangeNumber';
import ChangeProjectOrdinalNumber from './components/Modals/ChangeOrdinalNumber';
import ChangeProjectDate from './components/Modals/ChangeCreationDate';
import ChangeStatus from './components/Modals/ChangeStatus';
import EndProduction from './components/Modals/EndProduction';
import EndMontage from './components/Modals/EndMontage';
import ChangeEquipment from './components/Modals/ChangeEquipment';
import { AddressT } from 'services/store/types/settings/Settings';
import { PricingFileTypesT } from 'services/types';
import FileSaver from 'file-saver';
import Spinner from 'components/Spinner';
import FinishProject from './components/Modals/FinishProject';
import ChangeCustomerModal from './components/Modals/ChangeCustomer.modal';

const initModal = {
  changeOrdinalNumber: false,
  measureWeek: false,
  measureDate: false,
  montageWeek: false,
  montageDate: false,
  changeCustomer: false,
  changeDesigner: false,
  changePrice: false,
  changeStatus: false,
  equipment: false,
  files: false,
  payments: false,
  deleteProject: false,
  montageAddress: false,
  endProduction: false,
  endMontage: false,
  changeNumber: false,
  changeCreationDate: false,
  changeExternalCompany: false,
  changeName: false,
  finishProject: false,
};

export type TProjectModals = keyof typeof initModal;

interface PropsT {}

const ProjectPage: React.FC<PropsT> = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: { id: string } = useParams();
  const [errors, setErrors] = useState<ProjectErrorT[]>([]);
  const { isAdmin, hasPermission, user } = usePermission();

  const { modal, showModal, closeModal } = useModal(initModal);

  const [project, comments, files, equipment, projectHistory] = useQueries({
    queries: [
      {
        queryKey: ['get-project', params.id],
        queryFn: () => projectsApi.getProject(params.id),
        onSuccess: (data: AxiosResponse<{ project: ProjectT }>) => {},
        onError: () => {
          message.error('Błąd!');
        },
        enabled: !!user,
        staleTime: 0,
      },
      {
        queryKey: ['get-project-comments', params.id],
        queryFn: () => projectsApi.getProjectComments(params.id),
        onSuccess(data: AxiosResponse<{ comments: CommentT[] }>) {},
        onError: () => {
          message.error('Błąd!');
        },
        enabled: !!user,
      },
      {
        queryKey: ['get-project-files', params.id],
        queryFn: () => projectsApi.getProjectFiles(params.id),
        onSuccess(data: AxiosResponse<{ files: FileT[] }>) {
          if (data?.data?.files) {
            dispatch(updateFilesInProject(data.data.files));
          }
        },
        onError: () => {
          message.error('Błąd!');
        },
        enabled: !!user,
      },
      {
        queryKey: ['get-project-equipment', params.id],
        queryFn: () => projectsApi.getProjectEquipment(params.id),
        onError: () => {
          message.error('Błąd!');
        },
        enabled: !!user,
      },
      {
        queryKey: ['get-project-history', params.id],
        queryFn: () => projectsApi.getProjectHistory(params.id),
        onSuccess(data: AxiosResponse<{ history: HistoryItemT[] }>) {
          if (data?.data?.history) {
          }
        },
        onError: () => {
          message.error('Błąd!');
        },
        enabled: !!isAdmin(),
      },
    ],
  });

  const updateMontageAddress = useMutation(
    async (montageAddress: AddressT) =>
      projectsApi.updateMontageAddress(params.id, montageAddress),
    {
      onSuccess() {
        message.success('Adres zaktualizowany');
        project.refetch();
        closeModal();
      },
      onError(err: any) {
        console.log(err?.response);
        message.error('Bład');
      },
    }
  );
  const getPricingFile = useMutation(
    async (type: PricingFileTypesT) =>
      projectsApi.getProjectPricingFiles(params.id, type),
    {
      onSuccess(res: any, type: PricingFileTypesT) {
        const fileName = {
          elements: 'Lista_elementów_konstrukcyjnych',
          fronts: 'Lista_frontów',
          accessories: 'Lista_ackesoriów',
          orders: 'Zamówienia',
        };
        if (type === 'orders') {
          FileSaver.saveAs(
            new Blob([res.data], { type: 'application/zip' }),
            'zamowienia.zip'
          );
        } else {
          FileSaver.saveAs(
            new Blob([res.data], { type: 'application/xlsx' }),
            `${fileName[type]}.xlsx`
          );
        }
        closeModal();
      },
      onError(err: any) {
        console.log(err?.response);
        message.error('Bład');
      },
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!!project.data && !!files.data) {
      if (user) {
        const err = checkProjectErrors(
          project.data.data.project,
          user.permission,
          files.data.data.files
        );
        setErrors(err.errors);
      }
    }
    return () => {
      dispatch(setProjectCollapse([]));
    };
  }, [project.data, files.data]);

  return (
    <PageTemplate>
      {(project.isLoading ||
        files.isLoading ||
        comments.isLoading ||
        updateMontageAddress.isLoading) && <Spinner />}
      <SiderTemplate>
        {/* TODO REFACTOR PROJECT SIDERS */}
        <ProjectSider
          project={project.data?.data.project}
          files={files.data?.data.files}
          showModal={showModal}
          getPicingFile={getPricingFile.mutate}
        />
      </SiderTemplate>
      <ContentTemplate>
        {project.data ? (
          <>
            <PageHeader
              title={
                <StyledH1
                  onClick={() =>
                    hasPermission([perms.ADMIN, perms.DESIGNER])
                      ? history.push(
                          `/customers/${project.data?.data.project.CustomerId}`
                        )
                      : null
                  }
                  clickable
                >
                  {project.data.data.project.Customer.nip && (
                    <IdcardOutlined style={{ color: 'black' }} />
                  )}{' '}
                  {project.data.data.project.Customer
                    ? getPersonalName(project.data.data.project.Customer)
                    : '-'}
                  {project.data.data.project.Customer.CustomerBlackList && (
                    <Popover
                      title="Czarna lista"
                      content={
                        project.data.data.project.Customer.CustomerBlackList
                          .description
                      }
                    >
                      <ExclamationCircleOutlined style={{ color: 'red' }} />
                    </Popover>
                  )}
                </StyledH1>
              }
              subTitle={`/${project.data.data.project.name}/ `}
            >
              {errors.length ? (
                <div style={{ textAlign: 'left' }}>
                  {errors.map((item: ProjectErrorT) => {
                    return (
                      <div
                        key={item.text}
                        style={{
                          color: item.type === 'warn' ? 'orange' : 'red',
                        }}
                      >
                        <Badge
                          color={item.type === 'warn' ? 'orange' : 'red'}
                        />
                        {item.text}
                      </div>
                    );
                  })}
                  <Divider />
                </div>
              ) : null}
              {/* -------------------------PROJECT INFO------------------------- */}
              <ProjectInfo project={project.data.data.project} />
            </PageHeader>

            {/* -------------------------CUSTOMER DATA-------------------------- */}
            <IconDivider title="Dane klienta" icon={<UserOutlined />} />
            <CustomerData customer={project.data.data.project.Customer} />

            {/* -------------------------PROJECT----------------------- */}
            <IconDivider title="Projekt" icon={<FileTextOutlined />} />
            <ProjectData
              project={project.data.data.project}
              equipment={equipment.data?.data.equipment}
            />

            {/* -------------------------OTHER--------------------------- */}
            <IconDivider title="Inne" icon={<FolderOpenOutlined />} />
            <Row>
              <Col xs={12} md={8}>
                <Comments
                  comments={comments.data?.data.comments}
                  projectId={params.id}
                  refetchComments={comments.refetch}
                  isLoading={comments.isLoading}
                />
              </Col>
              <Col xs={12} md={8}>
                <Images
                  files={files.data?.data.files}
                  projectId={params.id}
                  refetchFiles={files.refetch}
                />
              </Col>
              <Col xs={12} md={8}>
                <Files
                  projectFiles={files.data?.data.files}
                  projectId={params.id}
                  refetchFiles={files.refetch}
                />
              </Col>
            </Row>

            {/* -------------------------PROJECT HISTORY------------------------ */}
            {isAdmin() && (
              <>
                <IconDivider title="Historia" icon={<HistoryOutlined />} />
                <ProjectHistory history={projectHistory.data?.data.history} />
              </>
            )}
          </>
        ) : (
          <div>
            <Empty />
          </div>
        )}
      </ContentTemplate>

      {/* MODALS */}
      {project.data && (
        <>
          {modal.changeStatus && (
            <ChangeStatus
              closeModal={closeModal}
              visible={modal.changeStatus}
              project={project.data.data.project}
              refetchProject={project.refetch}
            />
          )}
          {modal.changePrice && (
            <ChangePrice
              closeModal={closeModal}
              visible={modal.changePrice}
              project={project.data.data.project}
              refetchProject={project.refetch}
            />
          )}
          {modal.changeName && (
            <ChangeName
              closeModal={closeModal}
              visible={modal.changeName}
              project={project.data.data.project}
              refetchProject={project.refetch}
            />
          )}
          {modal.changeNumber && (
            <ChangeProjectNumber
              closeModal={closeModal}
              visible={modal.changeNumber}
              project={project.data.data.project}
              refetchProject={project.refetch}
            />
          )}
          {modal.changeOrdinalNumber && (
            <ChangeProjectOrdinalNumber
              closeModal={closeModal}
              visible={modal.changeOrdinalNumber}
              project={project.data.data.project}
              refetchProject={project.refetch}
            />
          )}
          {modal.changeCreationDate && (
            <ChangeProjectDate
              closeModal={closeModal}
              visible={modal.changeCreationDate}
              project={project.data.data.project}
              refetchProject={project.refetch}
            />
          )}
          {modal.changeCustomer && (
            <ChangeCustomerModal
              closeModal={closeModal}
              visible={modal.changeCustomer}
              CustomerId={project.data.data.project.CustomerId}
              projectId={project.data.data.project.id}
              refetchProject={project.refetch}
            />
          )}
          {modal.changeDesigner && (
            <ChangeDesigner
              closeModal={closeModal}
              visible={modal.changeDesigner}
              UserId={project.data.data.project.UserId}
              projectId={project.data.data.project.id}
              refetchProject={project.refetch}
            />
          )}
          {modal.changeExternalCompany && (
            <ChangeExternalCompany
              closeModal={closeModal}
              visible={modal.changeExternalCompany}
              externalCompanyId={project.data.data.project.externalCompanyId}
              projectId={project.data.data.project.id}
              project={project.data.data.project}
              refetchProject={project.refetch}
            />
          )}
          {modal.payments && (
            <Payments
              visible={modal.payments}
              closeModal={closeModal}
              project={project.data.data.project}
              refetchProject={project.refetch}
            />
          )}
          {files.data && modal.files && (
            <GetFiles
              visible={modal.files}
              closeModal={closeModal}
              project={project.data.data.project}
              projectFiles={files.data.data.files}
              refetchProject={project.refetch}
              refetchFiles={files.refetch}
            />
          )}
          {modal.equipment && (
            <ChangeEquipment
              closeModal={closeModal}
              visible={modal.equipment}
              project={project.data.data.project}
              refetchEquipment={equipment.refetch}
            />
          )}
          {/*  */}
          {modal.montageAddress && (
            <AddAddress
              visible={modal.montageAddress}
              closeModal={closeModal}
              values={project.data?.data.project.montageAddress}
              handleSubmit={updateMontageAddress.mutateAsync}
              customer={project.data?.data.project.Customer}
            />
          )}
          {modal.measureWeek && (
            <SetMeasureWeek
              closeModal={closeModal}
              visible={modal.measureWeek}
              project={project.data.data.project}
            />
          )}
          {modal.measureDate && (
            <SetMeasureDate
              project={project.data.data.project}
              closeModal={closeModal}
              visible={modal.measureDate}
            />
          )}
          {modal.montageWeek && (
            <SetMontageWeek
              closeModal={closeModal}
              visible={modal.montageWeek}
              project={project.data.data.project}
            />
          )}
          {modal.montageDate && (
            <SetMontageDay
              closeModal={closeModal}
              visible={modal.montageDate}
              project={project.data.data.project}
            />
          )}
          {modal.endProduction && (
            <EndProduction
              closeModal={closeModal}
              visible={modal.endProduction}
              project={project.data.data.project}
            />
          )}
          {modal.endMontage && (
            <EndMontage
              closeModal={closeModal}
              visible={modal.endMontage}
              project={project.data.data.project}
            />
          )}

          {modal.deleteProject && (
            <DeleteProject
              visible={modal.deleteProject}
              closeModal={closeModal}
              project={project.data.data.project}
            />
          )}
          {modal.finishProject && (
            <FinishProject
              project={project.data.data.project}
              closeModal={closeModal}
              visible={modal.finishProject}
              refetchProject={project.refetch}
            />
          )}
        </>
      )}
    </PageTemplate>
  );
};

export default ProjectPage;
