import React, { useEffect } from 'react';
import PageTemplate from 'templates/PageTemplate';
import { PageHeader } from 'antd';
import { StyledH1 } from 'components/Header';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateT } from 'services/store';
import { getSettings } from 'services/store/actions/settings';
import { setSpinner } from 'services/store/actions/view';
import { getCabinets } from 'services/store/actions/cabinets';
import CabinetsList from 'components/Base/Cabinets/CabinetsList';

const CabinetsListPage = () => {
  const dispatch = useDispatch();

  const cabinets = useSelector((state: AppStateT) => state.cabinets.cabinets);
  const cabinetTypes = useSelector(
    (state: AppStateT) => state.settings.cabinetTypes
  );

  useEffect(() => {
    if (!cabinetTypes.length) dispatch(getSettings());
    if (!cabinets.length) dispatch(setSpinner(true));
    dispatch(getCabinets());
    dispatch(getSettings());
  }, []);

  return (
    <PageTemplate>
      <PageHeader title={<StyledH1>Korpusy</StyledH1>} />
      <CabinetsList cabinets={cabinets} />
    </PageTemplate>
  );
};

export default CabinetsListPage;
