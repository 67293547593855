import { Empty } from 'antd';
import CabinetSegments from 'components/Pricing/Segments';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getProjectPricingByProjectId } from 'services/store/actions/projects';
import { setSpinner } from 'services/store/actions/view';
import { ProjectT } from 'services/store/types/projects/Projects';

interface PropsT {
  project: ProjectT;
}

const ProjectPricingCabinets: React.FC<PropsT> = ({ project }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!project.Pricing) {
      dispatch(setSpinner(true));
      dispatch(getProjectPricingByProjectId(project.id));
    }
  }, []);

  if (project.Pricing)
    return (
      <div>
        <CabinetSegments pricing={project.Pricing} previewOnly />
      </div>
    );
  return <Empty />;
};

export default ProjectPricingCabinets;
