import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{
  justify: any;
  direction: any;
  isWrapped: boolean;
}>`
  display: flex;
  justify-content: ${({ justify }) => justify || 'space-between'};
  flex-direction: ${({ direction }) => direction || 'row'};
  flex-wrap: ${({ isWrapped }) => (isWrapped ? 'wrap' : 'no-wrap')};
`;

const ButtonWrapper = styled.div<{ justify: any; direction: any }>`
  display: flex;
  justify-content: ${({ justify }) => justify || 'space-between'};
  flex-direction: ${({ direction }) => direction || 'row'};
  button {
    margin: 0 5px;
  }
`;

interface PropsT {
  justify?:
    | 'flex-start'
    | 'space-between'
    | 'flex-end'
    | 'space-around'
    | 'center';
  direction?: string;
  style?: any;
  wrap?: boolean;
}
export const FlexRow: React.FC<PropsT> = ({
  children,
  justify,
  direction,
  style = {},
  wrap = false,
}) => {
  return (
    <Wrapper
      justify={justify}
      direction={direction}
      style={style}
      isWrapped={wrap}
    >
      {children}
    </Wrapper>
  );
};

export const ButtonRow: React.FC<PropsT> = ({
  children,
  justify,
  direction,
}) => {
  return (
    <ButtonWrapper justify={justify} direction={direction}>
      {children}
    </ButtonWrapper>
  );
};
