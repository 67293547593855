import Axios from 'axios';
import { IdType } from 'services/types';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import setAuthToken from 'services/utils/setAuthToken';

export const removePattern = async (
  id: IdType,
  onEnd: () => void,
  onErr: () => void
) => {
  setAuthToken();
  try {
    await Axios.delete(`/api/catalog/pattern/${id}`);

    onEnd();
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};

export const removeTable = async (
  id: IdType,
  onEnd: () => void,
  onErr: () => void
) => {
  setAuthToken();
  try {
    await Axios.delete(`/api/catalog/table/${id}`);

    onEnd();
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};

export const removeVeneer = async (
  id: IdType,
  onEnd: () => void,
  onErr: () => void
) => {
  setAuthToken();
  try {
    await Axios.delete(`/api/catalog/veneer/${id}`);

    onEnd();
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};
