import Axios from 'axios';
import { ProjectT } from 'services/store/types/projects/Projects';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';

export const removeFile = async (
  fileId: string,
  onEnd: (project: ProjectT) => void,
  onError: () => void
) => {
  try {
    const res: any = await Axios.delete(`/api/files/${fileId}`);

    return onEnd(res.data.project);
  } catch (err) {
    return axiosErrorHandle(err, onError);
  }
};
