import * as yup from 'yup';

export const schema = yup.object({
  name: yup.string().required('Nazwa jest wymagana'),
  symbol: yup.string().required('Symbol jest wymagany'),
  SupplierId: yup.number().required('Dystrybutor jest wymagany'),
});

export const tableSchema = yup.object({
  name: yup.string().required('Nazwa jest wymagana'),
  symbol: yup.string().required('Symbol jest wymagany'),
  width: yup
    .number()
    .typeError('Uzupełnij')
    .moreThan(0, 'Uzupełnij')
    .required(),
  depth: yup
    .number()
    .typeError('Uzupełnij')
    .moreThan(0, 'Uzupełnij')
    .required(),
  thickness: yup
    .number()
    .typeError('Uzupełnij')
    .moreThan(0, 'Uzupełnij')
    .required(),
  price: yup
    .number()
    .typeError('Uzupełnij')
    .moreThan(0, 'Uzupełnij')
    .required(),
  SupplierId: yup.number().required('Dystrybutor jest wymagany'),
});
