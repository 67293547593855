/* eslint-disable import/prefer-default-export */
export const theme = {
  primary: '#1768ab',
  secondary: '#06bbe0',
  tertiary: '#6d8797',
  orderTable: {
    headerRight: '#1f7aea',
    right: '#b8d2fe',
    headerLeft: '#3cd1ec',
    left: '#cbedfb',
    focus: '#e8f8ff',
  },
};
