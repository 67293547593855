import { CabinetT } from './Cabinets';

export const CABINETS_LOAD_SUCCESS = 'CABINETS_LOAD_SUCCESS';
export const CABINETS_LOAD_ERROR = 'CABINETS_LOAD_ERROR';
export const CABINETS_ADD_TO_LIST = 'CABINETS_ADD_TO_LIST';
export const CABINETS_REMOVE_FROM_LIST = 'CABINETS_REMOVE_FROM_LIST';
export const CABINETS_UPDATE_IN_LIST = 'CABINETS_UPDATE_IN_LIST';
export const CABINETS_SET_CABINET = 'CABINETS_SET_CABINET';

export interface CabinetsLoadSuccessT {
  type: typeof CABINETS_LOAD_SUCCESS;
  cabinets: CabinetT[];
}
export interface CabinetsLoadErrorT {
  type: typeof CABINETS_LOAD_ERROR;
}
export interface SetCabinetT {
  type: typeof CABINETS_SET_CABINET;
  cabinet: CabinetT | null;
}

export interface CabinetsAddToListT {
  type: typeof CABINETS_ADD_TO_LIST;
  cabinet: CabinetT;
}
export interface CabinetsRemoveFromListT {
  type: typeof CABINETS_REMOVE_FROM_LIST;
  id: number | string;
}
export interface CabinetsUpdateInListT {
  type: typeof CABINETS_UPDATE_IN_LIST;
  cabinet: CabinetT;
}

export type CabinetsActionsT =
  | SetCabinetT
  | CabinetsAddToListT
  | CabinetsRemoveFromListT
  | CabinetsUpdateInListT
  | CabinetsLoadSuccessT
  | CabinetsLoadErrorT;
