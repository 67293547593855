import { Input, message, Modal } from 'antd';
import withPermission from 'hoc/withPermission';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { removeUser } from 'services/api/users/remove';
import { logoutUser } from 'services/store/actions/auth';
import { setSpinner } from 'services/store/actions/view';
import { PermissionsT, UserT } from 'services/store/types/auth/Auth';
import { perms } from 'services/utils/const';

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  user: UserT;
  permission: PermissionsT;
}
const DeleteUser: React.FC<PropsT> = ({
  visible,
  closeModal,
  user,
  permission,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState('');

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    await removeUser(
      user.id,
      () => {
        dispatch(setSpinner(false));
        if (permission !== perms.ADMIN) return dispatch(logoutUser());
        message.warning('Pracownik usunięty');
        history.push('/users');
        return null;
      },
      () => {
        dispatch(setSpinner(false));
        message.error('Błąd');
      }
    );
  };
  return (
    <Modal
      title="Usuwanie użytkownika"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      onOk={handleSubmit}
      okButtonProps={{
        danger: true,
        disabled: email !== user.email || user.permission === perms.ADMIN,
      }}
    >
      <div style={{ textAlign: 'center' }}>
        Aby usunąć dane użytkownika wpisz adres e-mail przypisany do konta.
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail"
        />
      </div>
    </Modal>
  );
};

export default withPermission(DeleteUser);
