import React from 'react';
import withPermission from 'hoc/withPermission';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'services/store/actions/auth';
import { perms } from 'services/utils/const';
import AdminNav from './AdminNav';
import DesignerNav from './DesignerNav';
import TechnologistNav from './TechnologistNav';
import CarpenterNav from './CarpenterNav';
import InstallerNav from './InstallerNav';
import OfficeNav from './OfficeNav';
import ExternalCompanyNav from './ExternalCompanyNav';

interface PropsT {
  permission: string;
}

const Navigation: React.FC<PropsT> = ({ permission }) => {
  const dispatch = useDispatch();
  const handleLogOut = () => {
    dispatch(logoutUser());
    return window.location.reload();
  };

  if (permission === perms.ADMIN)
    return <AdminNav handleLogOut={handleLogOut} />;
  if (permission === perms.OFFICE)
    return <OfficeNav handleLogOut={handleLogOut} />;
  if (permission === perms.DESIGNER)
    return <DesignerNav handleLogOut={handleLogOut} />;
  if (permission === perms.TECHNOLOGIST)
    return <TechnologistNav handleLogOut={handleLogOut} />;
  if (permission === perms.EXTERNAL_COMPANY)
    return <ExternalCompanyNav handleLogOut={handleLogOut} />;
  if (permission === perms.CARPENTER)
    return <CarpenterNav handleLogOut={handleLogOut} />;
  if (permission === perms.INSTALLER)
    return <InstallerNav handleLogOut={handleLogOut} />;
  return null;
};

export default withPermission(Navigation);
