import { CustomerItemT, TermT } from 'services/store/types/terms/Terms';
import { getPersonalName } from 'services/utils/users';

export const getCustomersFromTerms = (terms: TermT[]) => {
  const customers = terms.reduce((acc: CustomerItemT[], term: TermT) => {
    const project =
      term.measureTerm || term.montageTerm || term.receptionTerm || null;

    if (project) {
      const { Customer } = project;
      const isExist = acc.find(
        (item: CustomerItemT) => item.id === Customer.id
      );

      if (!isExist)
        return acc.concat({ id: Customer.id, name: getPersonalName(Customer) });
      return acc;
    }

    return acc;
  }, []);

  return customers.sort((a: CustomerItemT, b: CustomerItemT) =>
    a.name.localeCompare(b.name)
  );
};
