import axios, { AxiosResponse } from 'axios';
import { TermT, WeekT } from 'services/store/types/terms/Terms';
import setAuthToken from 'services/utils/setAuthToken';

export const termsApi = {
  getTermsByYear: async (
    year: number
  ): Promise<AxiosResponse<{ terms: TermT[]; weeks: WeekT[] }>> => {
    setAuthToken();
    return axios.get(`/api/schedule/year/${year}`);
  },
};
