import { Empty, Typography, Col, Row } from 'antd';
import CabinetSegments from 'components/Pricing/Segments';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ElementsList from 'components/Pricing/Elements/List';
import { getProjectPricingByProjectId } from 'services/store/actions/projects';
import { setSpinner } from 'services/store/actions/view';
import { ProjectT } from 'services/store/types/projects/Projects';

interface PropsT {
  project: ProjectT;
}

const ProjectPricingElements: React.FC<PropsT> = ({ project }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!project.Pricing) {
      dispatch(setSpinner(true));
      dispatch(getProjectPricingByProjectId(project.id));
    }
  }, []);

  if (project.Pricing)
    return (
      <div>
        <Row>
          <Col xs={12} md={6}>
            <Typography.Title level={4}>Konstrukcyjne</Typography.Title>
            <ElementsList
              type="body"
              elements={project.Pricing.PricingElements}
            />
          </Col>
          <Col xs={12} md={6}>
            <Typography.Title level={4}>Fronty</Typography.Title>
            <ElementsList
              type="front"
              elements={project.Pricing.PricingElements}
            />
          </Col>
        </Row>
      </div>
    );
  return <Empty />;
};

export default ProjectPricingElements;
