import { Dispatch } from 'redux';
import setAuthToken from 'services/utils/setAuthToken';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import Axios from 'axios';
import {
  CatalogAddToListT,
  CatalogLoadErrorT,
  CatalogLoadSuccessT,
  CatalogRemoveFromListT,
  CatalogUpdateInListT,
  CATALOG_ADD_TO_LIST,
  CATALOG_LOAD_ERROR,
  CATALOG_LOAD_SUCCESS,
  CATALOG_REMOVE_FROM_LIST,
  CATALOG_UPDATE_IN_LIST,
  CATALOG_LOAD_MANY_SUCCESS,
  CatalogLoadManySuccessT,
  CatalogLoadManyErrorT,
  CATALOG_LOAD_MANY_ERROR,
} from '../types/catalog/actions';
import { setSpinner } from './view';
import {
  PatternTypeT,
  PatternT,
  TableT,
  VeneerT,
} from '../types/catalog/Catalog';

export const catalogLoadSuccess = (
  listName: PatternTypeT,
  list: PatternT[] | TableT[] | VeneerT[]
): CatalogLoadSuccessT => ({
  type: CATALOG_LOAD_SUCCESS,
  listName,
  list,
});
export const catalogLoadManySuccess = (lists: {
  fronts?: PatternT[];
  boards?: PatternT[];
  tables?: TableT[];
  veneers?: VeneerT[];
}): CatalogLoadManySuccessT => ({
  type: CATALOG_LOAD_MANY_SUCCESS,
  lists,
});

export const catalogLoadError = (
  listName: PatternTypeT
): CatalogLoadErrorT => ({
  type: CATALOG_LOAD_ERROR,
  listName,
});
export const catalogLoadManyError = (): CatalogLoadManyErrorT => ({
  type: CATALOG_LOAD_MANY_ERROR,
});

export const catalogAddToList = (
  listName: PatternTypeT,
  item: PatternT | TableT | VeneerT
): CatalogAddToListT => ({
  type: CATALOG_ADD_TO_LIST,
  listName,
  item,
});

export const catalogUpdateInList = (
  listName: PatternTypeT,
  item: PatternT | TableT | VeneerT
): CatalogUpdateInListT => ({
  type: CATALOG_UPDATE_IN_LIST,
  listName,
  item,
});

export const catalogRemoveFromList = (
  listName: PatternTypeT,
  id: string | number
): CatalogRemoveFromListT => ({
  type: CATALOG_REMOVE_FROM_LIST,
  listName,
  id,
});

/**--------------------
 * THUNK
 ----------------------*/

export const getCatalogPatterns = (
  type: PatternTypeT,
  onEnd?: () => void,
  onError?: () => void
) => async (dispatch: Dispatch) => {
  setAuthToken();
  try {
    const res = await Axios.get(`/api/catalog/${type}`);

    dispatch(catalogLoadSuccess(type, res.data.patterns));
    if (onEnd) onEnd();
  } catch (error) {
    dispatch(catalogLoadError(type));
    axiosErrorHandle(error, onError);
  }
  dispatch(setSpinner(false));
};

export const getManyCatalogPatterns = (
  types: PatternTypeT[],
  onEnd?: () => void,
  onError?: () => void
) => async (dispatch: Dispatch) => {
  setAuthToken();
  try {
    const res: any = await Axios.post('/api/catalog/many', { types });

    dispatch(catalogLoadManySuccess(res.data.patterns));
    if (onEnd) onEnd();
  } catch (error) {
    dispatch(catalogLoadManyError());
    axiosErrorHandle(error, onError);
  }
  dispatch(setSpinner(false));
};
