import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import locale from 'antd/es/locale/pl_PL';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import App from './App';
import 'antd/dist/antd.css';
import 'styles/antd.css';
import 'moment/locale/pl';
import store from './services/store';

moment.locale('pl');

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={locale}>
      <App />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
);
