import React, { useState } from 'react';
import { Checkbox, Input, message, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { ProjectT } from 'services/store/types/projects/Projects';
import styled from 'styled-components';
import { addProjectComment } from 'services/api/comments/post';
import { setProject } from 'services/store/actions/projects';
import { permsPL, userPermissions } from 'services/utils/const';
import { capitalizeFirstLetter } from 'services/utils/string';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { AppStateT } from 'services/store';

const { TextArea } = Input;

const StyledTextArea = styled(TextArea)`
  margin-bottom: 10px;
`;

export interface IAddCommentProps {
  visible: boolean;
  closeModal: () => void;
  refetchComments: () => void;
  projectId: string;
}

const AddComment: React.FC<IAddCommentProps> = ({
  visible,
  closeModal,
  projectId,
  refetchComments,
}) => {
  const user = useSelector((state: AppStateT) => state.auth.user);
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');
  const [permissions, setPermissions] = useState(
    user ? `_${user.permission}` : ''
  );

  const handleCheckbox = (e: CheckboxChangeEvent) => {
    const { checked, value } = e.target;
    if (checked) return setPermissions(permissions.concat(value));
    return setPermissions(permissions.replace(value, ''));
  };

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    await addProjectComment(
      projectId,
      { comment, permissions },
      (p) => {
        refetchComments();
        setComment('');
        closeModal();
        message.success('Dodano uwagę');
        dispatch(setSpinner(false));
      },
      () => {
        message.error('Błąd!');
        dispatch(setSpinner(false));
      }
    );
  };

  return (
    <Modal
      title="Dodaj uwagę"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      onOk={handleSubmit}
      okButtonProps={{ disabled: !comment || !permissions }}
    >
      <div>
        <StyledTextArea
          value={comment}
          placeholder="Treść uwagi..."
          onChange={(e) => setComment(e.target.value)}
        />
        {userPermissions.map((item: string) => {
          return (
            <div key={item}>
              <Checkbox
                checked={permissions.includes(item)}
                value={`_${item}`}
                onChange={handleCheckbox}
                disabled={user?.permission ? user.permission === item : false}
              >
                {capitalizeFirstLetter(permsPL[item])}
              </Checkbox>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default AddComment;
