import { Moment } from 'moment';
import { OrdersFiltersFieldsT, OrdersListsT, OrderT } from './Orders';

export const ORDERS_LOAD_SUCCESS = 'ORDERS_LOAD_SUCCESS';
export const ORDERS_LOAD_ERROR = 'ORDERS_LOAD_ERROR';
export const ORDERS_SET_ORDER = 'ORDERS_SET_ORDER';
export const ORDERS_SET_FILTER_FIELD = 'ORDERS_SET_FILTER_FIELD';

export interface OrdersSetFilterFieldT {
  type: typeof ORDERS_SET_FILTER_FIELD;
  list: OrdersListsT;
  field: OrdersFiltersFieldsT;
  value: [Moment, Moment];
}
export interface OrdersLoadSuccessT {
  type: typeof ORDERS_LOAD_SUCCESS;
  list: OrdersListsT;
  orders: OrderT[];
}
export interface OrdersLoadErrorT {
  type: typeof ORDERS_LOAD_ERROR;
  list: OrdersListsT;
}
export interface OrdersSetOrderT {
  type: typeof ORDERS_SET_ORDER;
  order: OrderT | null;
}

export type OrdersActionsT =
  | OrdersSetFilterFieldT
  | OrdersLoadSuccessT
  | OrdersLoadErrorT
  | OrdersSetOrderT;
