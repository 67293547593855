import React, { useState, useEffect } from 'react';
import { Image, Popconfirm, Table, Typography } from 'antd';
import imgPlaceholder from 'assets/images/placeholder.png';
import { DeleteOutlined } from '@ant-design/icons';
import { domain } from 'services/utils/const';
import { PaintT, PatternTypeT } from 'services/store/types/catalog/Catalog';
import {
  PricingPatternT,
  PricingPatternTypeT,
} from 'services/store/types/pricing/Pricing';
import { IdType } from 'services/types';
import Img from 'components/Image';
import { cutString } from 'services/utils/string';

const columns: any[] = [
  {
    title: 'Lp.',
    align: 'center' as const,
    render: (t: string, r: any, i: number) => i + 1,
  },
  {
    title: (
      <div>
        <div>Zdjęcie</div>
        <div>
          <small>Płyt./Obrz.</small>
        </div>
      </div>
    ),
    dataIndex: 'img',
    align: 'center' as const,
    render: (v: any, r: PricingPatternT) => {
      return (
        <>
          <Img
            isExist={r.Pattern}
            path={r.Pattern?.miniaturePath}
            previewPath={r.Pattern?.imgPath}
          />
          {r.Veneer && (
            <Img
              isExist={r.Veneer}
              path={r.Veneer?.miniaturePath}
              previewPath={r.Veneer?.imgPath}
            />
          )}
        </>
      );
    },
  },
  {
    title: <div>Lakier</div>,
    dataIndex: 'Paint',
    align: 'center' as const,
    render: (paint: PaintT) => {
      if (paint)
        return (
          <div
            style={{
              margin: '0 auto',
              width: 100,
              height: 40,
              backgroundColor: paint.hex,
              position: 'relative',
            }}
          >
            <div
              style={{
                position: 'absolute',
                bottom: 3,
                left: 3,
                backgroundColor: 'rgba(255,255,255,0.4)',
                fontSize: 10,
              }}
            >
              {paint.name} - {paint.type}
            </div>
          </div>
        );
      return '-';
    },
  },
  {
    title: (
      <div>
        <div>Nazwa</div>
        <div>
          <small>Płyt./Obrz.</small>
        </div>
      </div>
    ),
    dataIndex: 'name',
    align: 'center' as const,
    defaultSortOrder: 'ascend' as const,
    render: (v: any, r: PricingPatternT) => {
      return `${cutString(r.Pattern?.name, 20)} / ${cutString(
        r.Veneer?.name,
        20
      )}`;
    },
  },
  {
    title: (
      <div>
        <div>Symbol</div>
        <div>
          <small>Płyt./Obrz.</small>
        </div>
      </div>
    ),
    dataIndex: 'symbol',
    align: 'center' as const,
    render: (v: any, r: PricingPatternT) => {
      return `${cutString(r.Pattern?.symbol, 20)} / ${cutString(
        r.Veneer?.symbol,
        20
      )}`;
    },
  },
  {
    title: 'Akcje',
    dataIndex: 'actions',
    align: 'center' as const,
  },
];

interface PropsT {
  patterns: PricingPatternT[];
  actions?: {
    remove?: (id: PricingPatternT, type: PricingPatternTypeT) => void;
  };
  editable?: boolean;
  rowClick?: (p: PricingPatternT) => void;
}

const PatternsList: React.FC<PropsT> = ({
  patterns,
  actions = undefined,
  editable,
  rowClick,
  ...props
}) => {
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    const newList = patterns.map((pattern: PricingPatternT, i: number) => {
      if (pattern.Pattern)
        return {
          ...pattern,
          key: `${pattern.PatternId} ${pattern.VeneerId} ${pattern.PaintId}`,
          patternObj: pattern,
          actions: actions ? (
            <div>
              {actions.remove && (
                <Popconfirm
                  title="Usuń element"
                  onConfirm={() =>
                    actions.remove &&
                    pattern &&
                    actions.remove(pattern, pattern.type)
                  }
                  disabled={editable}
                >
                  <DeleteOutlined
                    title="Usuń"
                    style={
                      editable
                        ? { color: 'gray', margin: 5, cursor: 'default' }
                        : { color: 'red', cursor: 'pointer', margin: 5 }
                    }
                  />
                </Popconfirm>
              )}
            </div>
          ) : (
            <Typography.Text disabled>brak</Typography.Text>
          ),
        };
      return null;
    });

    setList(newList);
  }, [patterns]);

  return (
    <div>
      <Table
        columns={columns}
        pagination={false}
        dataSource={list}
        size="small"
        onRow={(r: any) => {
          return {
            onClick: () => (rowClick ? rowClick(r.patternObj) : null),
          };
        }}
        {...props}
      />
    </div>
  );
};

export default PatternsList;
