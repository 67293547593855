import axios, { AxiosResponse } from 'axios';
import { OrderT } from 'services/store/types/orders/Orders';
import { IdType } from 'services/types';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import setAuthToken from 'services/utils/setAuthToken';

export const getOrderById = async (
  id: IdType,
  onEnd: (orders: OrderT) => void,
  onError?: () => void
) => {
  setAuthToken();
  try {
    const res: AxiosResponse = await axios.get(`/api/orders/data/${id}`);

    const { order } = res.data;

    onEnd(order);
  } catch (error) {
    axiosErrorHandle(error, onError);
  }
};
