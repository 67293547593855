import Axios from 'axios';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import setAuthToken from 'services/utils/setAuthToken';
import { CustomerT } from 'services/store/types/customers/Customers';

export const addCustomerToBlackList = async (
  customerId: string | number,
  body: { pesel: string; description: string },
  onEnd: (customer: CustomerT) => void,
  onErr: () => void
) => {
  setAuthToken();
  try {
    const res: any = await Axios.post(`/api/blacklist/${customerId}`, {
      ...body,
    });

    onEnd(res.data.customer);
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};
