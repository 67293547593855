import Axios from 'axios';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import setAuthToken from 'services/utils/setAuthToken';

export const removeUser = async (
  id: string,
  onEnd: () => void,
  onErr: () => void
) => {
  setAuthToken();
  try {
    await Axios.delete(`/api/users/${id}`);

    onEnd();
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};
