import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateT } from 'services/store';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { Layout } from 'antd';
import MainTemplate from 'templates/MainTemplate';
import LoginPage from 'scenes/Sign/Login';
import Spinner from 'components/Spinner';
import styled from 'styled-components';
import logo from 'assets/images/logo.svg';
import Navigation from 'components/Navigation';
import HomePage from 'scenes/Home';
import UsersListPage from 'scenes/Users/List';
// import UsersListSider from 'siders/Users/List';
import UserProfilePage from 'scenes/Users/Profile';
// import UserProfileSider from 'siders/Users/Profile';
import CustomersListPage from 'scenes/Customers/List';
// import CustomersListSider from 'siders/Customers/List';
import SettingsPage from 'scenes/Settings';
// import SettingsSider from 'siders/Settings';
import AccessoriesPage from 'scenes/Accessories';
import SuppliersPage from 'scenes/Suppliers/List';
// import SuppliersSider from 'siders/Suppliers';
// import AccessoriesSider from 'siders/Accessories';
// import HomeSider from 'siders/Home';
// import NewProjectSider from 'siders/NewProject';
import NewProjectPage from 'scenes/NewProject';
import CustomerProfilePage from 'scenes/Customers/Profile';
import PrivateRoute from 'hoc/PrivateRoute';
import { perms } from 'services/utils/const';
// import CustomerProfileSider from 'siders/Customers/Profile';
import ProjectPage from 'scenes/Project';
import { isMobile } from 'react-device-detect';
import SchedulePage from 'scenes/Schedule';
import UserSettingsPage from 'scenes/Users/UserSettings';
// import UserSettingsSider from 'siders/Users/UserSettings';
import { setSider } from 'services/store/actions/view';
// import CabinetsListSider from 'siders/Cabinets/List';
import CabinetsListPage from 'scenes/Cabinets/List';
import NewCabinetPage from 'scenes/NewCabinet';
import CabinetPage from 'scenes/Cabinets/Profile';
// import CabinetSider from 'siders/Cabinets/Profile';
import ProjectPricingPage from 'scenes/ProjectPricing';
// import ProjectPricingSider from 'siders/ProjectPricing';
import CatalogPaintsPage from 'scenes/Catalog/Paints';
import CatalogBoardsPage from 'scenes/Catalog/Boards';
// import CatalogBoardsSider from 'siders/Catalog/Boards';
import CatalogFrontsPage from 'scenes/Catalog/Fronts';
// import CatalogFrontsSider from 'siders/Catalog/Fronts';
import CatalogTablesPage from 'scenes/Catalog/Tables';
// import CatalogTablesSider from 'siders/Catalog/Tables';
// import ProjectOrdersSider from 'siders/ProjectOrders';
import NewOrdersPage from 'scenes/ProjectOrders';
import OrdersPage from 'scenes/Orders/List';
import SupplierPage from 'scenes/Suppliers/Page';
// import OrdersListSider from 'siders/Orders/List';
import OrderPickUpPage from 'scenes/Orders/PickUp';
import CatalogVeneersPage from 'scenes/Catalog/Veneers';
// import CatalogVeneersSider from 'siders/Catalog/Veneers';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ProjectPickTermPage from 'scenes/ProjectSchedule/ProjectPickTermPage';

const { Header, Content, Sider, Footer } = Layout;

const Logo = styled.img`
  width: 120px;
  height: 30px;
  margin: 18px 28px 16px 0;
  float: left;
  cursor: pointer;
`;

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state: AppStateT) => state.auth.user);
  const isAuth = useSelector((state: AppStateT) => state.auth.isAuth);
  const isSpinner = useSelector((state: AppStateT) => state.view.isSpinner);
  const isSider = useSelector((state: AppStateT) => state.view.isSider);

  const queryClient = new QueryClient();

  useEffect(() => {
    const sider = localStorage.getItem('isSider');
    if (sider) dispatch(setSider(JSON.parse(sider)));
  }, []);

  const handleToggleSider = () => dispatch(setSider(!isSider));
  return (
    <QueryClientProvider client={queryClient}>
      {isSpinner && <Spinner />}
      <Router>
        <MainTemplate>
          <Layout style={{ minHeight: '100vh' }}>
            <Header>
              <Link to="/">
                <Logo src={logo} />
              </Link>
              <Navigation />
            </Header>
            {/* <Layout> */}
            {/* <Sider
                style={
                  !isMobile
                    ? {
                        overflow: 'auto',
                        height: '100vh',
                        position: 'sticky',
                        top: 0,
                        left: 0,
                      }
                    : {}
                }
                width={200}
                theme="light"
                collapsedWidth={isMobile ? 0 : 80}
                collapsible
                collapsed={isSider}
                onCollapse={handleToggleSider}
              >
                <Switch>
                  <Route exact path="/" component={HomeSider} />
                  <PrivateRoute
                    permissions={[perms.ADMIN, perms.DESIGNER, perms.OFFICE]}
                    exact
                    path="/customers"
                    component={CustomersListSider}
                  />
                  <PrivateRoute
                    permissions={[perms.ADMIN, perms.DESIGNER, perms.OFFICE]}
                    path="/customers/:id"
                    component={CustomerProfileSider}
                  />
                  <PrivateRoute
                    permissions={[perms.ADMIN]}
                    exact
                    path="/users"
                    component={UsersListSider}
                  />
                  <PrivateRoute
                    permissions={[perms.ADMIN]}
                    path="/users/:id"
                    component={UserProfileSider}
                  />
                  <PrivateRoute
                    permissions={[perms.ADMIN]}
                    path="/settings"
                    component={SettingsSider}
                  />
                  <Route exact path="/suppliers" component={SuppliersSider} />
                  <Route path="/accessories" component={AccessoriesSider} />
                  <Route exact path="/cabinets" component={CabinetsListSider} />
                  <Route path="/cabinets/:id" component={CabinetSider} />
                  <PrivateRoute
                    permissions={[perms.ADMIN, perms.DESIGNER, perms.OFFICE]}
                    path="/newproject"
                    component={NewProjectSider}
                  />
                  <PrivateRoute
                    permissions={[perms.ADMIN, perms.DESIGNER, perms.OFFICE]}
                    path="/pricing/:id"
                    component={ProjectPricingSider}
                  />
                  <Route path="/account" component={UserSettingsSider} />
                  <Route path="/orders" component={OrdersListSider} />
                  <Route path="/projects/:id" exact component={ProjectSider} />
                  <Route
                    path="/projects/orders/:id"
                    exact
                    component={ProjectOrdersSider}
                  />
                  <Route
                    path="/catalog/boards"
                    component={CatalogBoardsSider}
                  />
                  <Route
                    path="/catalog/veneers"
                    component={CatalogVeneersSider}
                  />
                  <Route
                    path="/catalog/tables"
                    component={CatalogTablesSider}
                  />
                  <Route
                    path="/catalog/fronts"
                    component={CatalogFrontsSider}
                  />
                </Switch>
                <div style={{ height: 100 }} />
              </Sider> */}
            {/* <Layout>
                <Content
                  style={{ margin: 10, padding: 10, backgroundColor: 'white' }}
                > */}
            <Switch>
              <Route path="/login" component={LoginPage} />
              <Route exact path="/" component={HomePage} />
              <PrivateRoute
                permissions={[perms.ADMIN, perms.DESIGNER, perms.OFFICE]}
                exact
                path="/customers"
                component={CustomersListPage}
              />
              <PrivateRoute
                permissions={[perms.ADMIN, perms.DESIGNER, perms.OFFICE]}
                path="/customers/:id"
                component={CustomerProfilePage}
              />
              <PrivateRoute
                permissions={[perms.ADMIN, perms.DESIGNER, perms.OFFICE]}
                exact
                path="/users"
                component={UsersListPage}
              />
              <PrivateRoute
                permissions={[perms.ADMIN, perms.DESIGNER, perms.OFFICE]}
                path="/users/:id"
                component={UserProfilePage}
              />
              <Route path="/settings" component={SettingsPage} />
              <Route exact path="/suppliers" component={SuppliersPage} />
              <Route exact path="/suppliers/:id" component={SupplierPage} />
              <Route exact path="/accessories" component={AccessoriesPage} />
              <Route exact path="/cabinets" component={CabinetsListPage} />
              <Route path="/cabinets/:id" component={CabinetPage} />
              <Route exact path="/newcabinet/" component={NewCabinetPage} />
              <PrivateRoute
                permissions={[perms.ADMIN, perms.DESIGNER, perms.OFFICE]}
                path="/newproject"
                component={NewProjectPage}
              />
              <PrivateRoute
                permissions={[perms.ADMIN, perms.TECHNOLOGIST, perms.CARPENTER]}
                path="/projects/orders/:id"
                component={NewOrdersPage}
              />
              <PrivateRoute
                permissions={[perms.ADMIN, perms.DESIGNER, perms.OFFICE]}
                path="/pricing/:id"
                component={ProjectPricingPage}
              />
              <Route path="/projects/:id" exact component={ProjectPage} />
              <Route
                path="/projects/schedule/:type/:id"
                exact
                component={ProjectPickTermPage}
              />
              <Route path="/schedule" component={SchedulePage} />
              <Route path="/account" component={UserSettingsPage} />
              <PrivateRoute
                exact
                permissions={[perms.ADMIN, perms.CARPENTER, perms.TECHNOLOGIST]}
                path="/orders"
                component={OrdersPage}
              />
              <PrivateRoute
                permissions={[perms.ADMIN, perms.CARPENTER, perms.TECHNOLOGIST]}
                path="/orders/:id/pickup"
                component={OrderPickUpPage}
              />
              <Route path="/catalog/paints" component={CatalogPaintsPage} />
              <Route path="/catalog/boards" component={CatalogBoardsPage} />
              <Route path="/catalog/veneers" component={CatalogVeneersPage} />
              <Route path="/catalog/tables" component={CatalogTablesPage} />
              <Route path="/catalog/fronts" component={CatalogFrontsPage} />
            </Switch>
            {/* </Content> */}
            {/* </Layout> */}
            {/* </Layout> */}
            <Footer style={{ textAlign: 'center', padding: '3px 0 10px 0' }}>
              <small>
                <div>
                  <a href="https://www.emmi.pl">EmmiCRM 1.0.0</a>
                </div>
              </small>
            </Footer>
          </Layout>
        </MainTemplate>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
