/* eslint-disable @typescript-eslint/default-param-last */
import { ViewT } from '../types/view/View';
import {
  ViewActionsT,
  VIEW_SET_SPINNER,
  VIEW_SET_HOME_KEY,
  VIEW_SET_SIDER,
  VIEW_SET_COLLAPSE,
} from '../types/view/actions';

const initialState: ViewT = {
  isSpinner: false,
  homeKey: 'currentProjects',
  ordersKey: 'active',
  isSider: true,
  projectCollapse: [],
};

const viewReducer = (state = initialState, action: ViewActionsT): ViewT => {
  switch (action.type) {
    case VIEW_SET_SIDER: {
      localStorage.setItem('isSider', JSON.stringify(action.isSider));
      return { ...state, isSider: action.isSider };
    }
    case VIEW_SET_SPINNER: {
      return { ...state, isSpinner: action.isSpinner };
    }
    case VIEW_SET_HOME_KEY: {
      return { ...state, [action.page]: action.key };
    }
    case VIEW_SET_COLLAPSE: {
      return {
        ...state,
        projectCollapse: action.key,
      };
    }
    default:
      return state;
  }
};

export { viewReducer };
