import { useMutation, useQueries } from '@tanstack/react-query';
import { Alert, Button, message, PageHeader, Space } from 'antd';
import { AxiosResponse } from 'axios';
import { StyledH1 } from 'components/Header';
import Spinner from 'components/Spinner';
import { isString } from 'lodash';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { projectsApi } from 'services/api/projects.api';
import { termsApi } from 'services/api/terms.api';
import { AppStateT } from 'services/store';
import { ProjectT } from 'services/store/types/projects/Projects';
import { TermT, WeekT } from 'services/store/types/terms/Terms';
import { perms } from 'services/utils/const';
import {
  getDatesBetween,
  rangeDateToString,
  weeksFromDate,
} from 'services/utils/date';
import ContentTemplate from 'templates/ContentTemplate/ContentTemplate';
import PageTemplate from 'templates/PageTemplate';
import PickTermCalendarView from './components/PickTermCalendarView';

const ProjectPickTermPage = () => {
  const params: { id: string; type: 'montage' | 'measure' } = useParams();
  const [termType] = useState(params.type === 'measure' ? 'pomiar' : 'montaż');
  const [date, setDate] = useState(moment());
  const history = useHistory();
  const user = useSelector((state: AppStateT) => state.auth.user);

  const [pickedWeek, setPickedWeek] = useState<Moment | undefined>(undefined);

  const [startDate, setStartDate] = useState<Moment | undefined>(undefined);
  const [endDate, setEndDate] = useState<Moment | undefined>(undefined);

  const [terms, project] = useQueries({
    queries: [
      {
        queryKey: ['get-schedule', date],
        queryFn: () => termsApi.getTermsByYear(moment(date).year()),
        onSuccess(res: any) {},
        onError(err: any) {
          message.error('Błąd!');
        },
      },
      {
        queryKey: ['get-project', params.id],
        queryFn: () => projectsApi.getProject(params.id),
        onSuccess(res: any) {
          console.log(res);
          //   if (params.type === 'measure' && res?.data?.project?.measureTerm) {
          //     const measureTerm = res.data.project.measureTerm;
          //     console.log(measureTerm);
          //     setStartDate(moment(measureTerm.startDate));
          //     setEndDate(moment(measureTerm.endDate));
          //   }
        },
        onError(err: any) {
          message.error('Błąd!');
        },
      },
    ],
  });

  const { mutate: updateMeasureTerm, isLoading: isMeasureTermUpdating } =
    useMutation(
      async () => {
        if (startDate && endDate)
          return projectsApi.updateMeasureTerm(params.id, startDate, endDate);
      },
      {
        onSuccess() {
          message.success('Zaktualizowano datę pomiaru');
          history.push(`/projects/${params.id}`);
        },
        onError(err: any) {
          if (isString(err)) return message.error(err);
          return message.error('Błąd');
        },
      }
    );
  const { mutate: updateMontageTerm, isLoading: isMontageTermUpdating } =
    useMutation(
      async () => {
        if (startDate && endDate)
          return projectsApi.updateMontageTerm(params.id, startDate, endDate);
      },
      {
        onSuccess() {
          message.success('Zaktualizowano datę montażu');
          history.push(`/projects/${params.id}`);
        },
        onError(err: any) {
          if (isString(err)) return message.error(err);
          return message.error('Błąd');
        },
      }
    );

  const canPickDate = (d: Moment) => {
    if ([0, 6].includes(d.day())) return false;
    if (d.isBefore(moment())) return false;
    if (startDate && !endDate && d.isBefore(startDate)) return false;

    const reservedTerms = terms.data?.data.terms.filter((term: TermT) => {
      const projectId =
        term.measureTerm?.id || term.montageTerm?.id || term.receptionTerm?.id;
      return projectId?.toString() !== params.id;
    });

    // data jest zajęta
    const isTermReserved = reservedTerms?.some((term: TermT) => {
      const termStart = moment(term.startDate);
      const termEnd = moment(term.endDate);
      if (
        term.type === termType &&
        (d.isSame(termStart, 'date') ||
          d.isSame(termEnd, 'date') ||
          d.isBetween(termStart.startOf('day'), termEnd.endOf('day')))
      ) {
        return true;
      }
      return false;
    });
    if (isTermReserved) return false;

    // zakres zawiera inne terminy
    if (startDate && !endDate) {
      const datesInRange = getDatesBetween(startDate, d);
      const isRangeReserved = datesInRange.some((dateInRange) => {
        const isInReservedTerms = reservedTerms?.some((term: TermT) => {
          const termStart = moment(term.startDate);
          const termEnd = moment(term.endDate);
          if (
            term.type === termType &&
            (dateInRange.isSame(termStart, 'date') ||
              dateInRange.isSame(termEnd, 'date') ||
              dateInRange.isBetween(
                termStart.startOf('day'),
                termEnd.endOf('day')
              ))
          ) {
            return true;
          }
          return false;
        });
        return isInReservedTerms;
      });
      if (isRangeReserved) return false;
    }
    return true;
  };

  const handlePickDate = (d: Moment) => {
    if (!canPickDate(d)) {
      return message.error('Nie możesz wybrać tej daty');
    }
    if (startDate && !endDate) {
      setEndDate(d);
    } else {
      setStartDate(d);
      setEndDate(undefined);
    }
  };

  const resetDates = () => {
    setStartDate(undefined);
    setEndDate(undefined);
  };

  const handleDate = (d: Moment) => {
    setDate(d);
    resetDates();
  };
  return (
    <PageTemplate>
      <ContentTemplate>
        {(terms.isLoading ||
          project.isLoading ||
          isMeasureTermUpdating ||
          isMontageTermUpdating) && <Spinner />}
        <PageHeader
          extra={[
            <Button key={0} onClick={resetDates}>
              Reset
            </Button>,
            <Button
              key={1}
              disabled={!startDate || !endDate}
              type="primary"
              onClick={() =>
                params.type === 'montage'
                  ? updateMontageTerm()
                  : updateMeasureTerm()
              }
            >
              Zatwierdź
            </Button>,
          ]}
          title={
            <StyledH1>
              Rezerwacja terminu{' '}
              {params.type === 'montage' ? 'montażu' : 'pomiaru'}
            </StyledH1>
          }
          subTitle={
            project.data?.data
              ? `Projekt nr.: ${project.data.data.project.number || '-'}`
              : '-'
          }
        />
        <Space direction="vertical">
          {params.type === 'montage' && project.data?.data.project.measureWeek && (
            <Alert
              type="warning"
              message={`Tydzień pomiaru ustalono na:
              ${moment(
                project.data?.data.project.measureWeek.date
              ).isoWeek()} tydz.
              roku.`}
            />
          )}
          {params.type === 'measure' &&
            project.data?.data.project.montageWeek &&
            weeksFromDate(moment(project.data.data.project.montageWeek.date)) <
              7 && (
              <Alert
                type="warning"
                message={`Montaż za 
              ${weeksFromDate(
                moment(project.data.data.project.montageWeek.date)
              )} 
              tyg.`}
              />
            )}
          {!startDate && <Alert message={`Wybierz datę startu`} type="error" />}
          {startDate && !endDate && (
            <Alert message={`Wybierz datę końcową`} type="error" />
          )}
          {startDate && endDate && (
            <Alert
              message={`Wybrałeś ${startDate.week()}${
                endDate && endDate.week() !== startDate.week()
                  ? ` - ${endDate.week()}`
                  : ''
              } tydzień roku`}
              type="success"
            />
          )}
        </Space>
        <PickTermCalendarView
          terms={terms.data?.data.terms}
          weeks={terms.data?.data.weeks}
          pickDate={handlePickDate}
          startDate={startDate}
          endDate={endDate}
          date={date}
          handleDate={handleDate}
          termType={params.type === 'montage' ? 'montaż' : 'pomiar'}
        />
      </ContentTemplate>
    </PageTemplate>
  );
};

export default ProjectPickTermPage;
