/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Typography } from 'antd';
import { Input, Form } from 'formik-antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Formik } from 'formik';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateT } from 'services/store';
import { StyledH1 } from 'components/Header';
import { LoginValues } from 'services/types';
import { setSpinner } from 'services/store/actions/view';
import { authUser } from 'services/api/auth/get';
import {
  loginSuccess,
  loadUserData,
  loginError,
} from 'services/store/actions/auth';
import { schema } from './utils/validate';

const { Link } = Typography;

const Wrapper = styled.div`
  height: 100%;
  padding: 100px 30px;
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;
const LinkWrapper = styled.div`
  text-align: right;
  margin-top: 10px;
`;

const LoginPage = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state: AppStateT) => state.auth.isAuth);
  const { token } = localStorage;
  if (token && isAuth) return <Redirect to="/" />;

  const handleSubmit = async (values: LoginValues, actions: any) => {
    dispatch(setSpinner(true));
    await authUser(
      values,
      (authToken) => {
        dispatch(loginSuccess(authToken));
        dispatch(loadUserData(() => {}));
      },
      () => {
        dispatch(loginError());
        actions.setErrors({
          email: 'Nieprawidłowe dane logowania',
          password: 'Nieprawidłowe dane logowania',
        });
      }
    );
    dispatch(setSpinner(false));
  };
  return (
    <Wrapper>
      <div>
        <StyledH1>Panel logowania</StyledH1>
        <Formik
          validationSchema={schema}
          // ONSUBMIT REQUEST
          onSubmit={handleSubmit}
          initialValues={{
            email: '',
            password: '',
          }}
          render={(props) => (
            <Form
              noValidate
              className="form-container"
              onFinish={props.handleSubmit}
            >
              <Form.Item name="email">
                <Input
                  name="email"
                  placeholder="E-mail"
                  type="email"
                  autoCapitalize="off"
                  required
                  prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                />
              </Form.Item>
              <Form.Item name="password">
                <Input.Password
                  name="password"
                  placeholder="Hasło"
                  required
                  autoCapitalize="off"
                  prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                />
              </Form.Item>

              <StyledButton htmlType="submit" type="primary" size="large">
                Zaloguj
              </StyledButton>
              <LinkWrapper>
                <Link href="/passwordremind">Nie pamiętam hasła</Link>
              </LinkWrapper>
            </Form>
          )}
        />
      </div>
    </Wrapper>
  );
};

export default LoginPage;
