import React from 'react';
import { Tag } from 'antd';
import { perms, permsPL } from 'services/utils/const';

interface PropsT {
  permission: string;
}

const PermissionTag: React.FC<PropsT> = ({ permission }) => {
  let color = 'green';
  if (permission === perms.ADMIN) color = 'green';
  else if (permission === perms.CARPENTER) color = 'geekblue';
  else if (permission === perms.DESIGNER) color = 'purple';
  else if (permission === perms.TECHNOLOGIST) color = 'orange';
  else if (permission === perms.EXTERNAL_COMPANY) color = 'blue';
  else if (permission === perms.INSTALLER) color = 'cyan';
  else if (permission === perms.OFFICE) color = 'magenta';
  return <Tag color={color}>{permsPL[permission].toUpperCase()}</Tag>;
};

export default PermissionTag;
