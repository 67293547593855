import { Dispatch } from 'redux';
import setAuthToken from 'services/utils/setAuthToken';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import Axios from 'axios';
import {
  SettingsT,
  CabinetTypeT,
  SettingsListsT,
} from '../types/settings/Settings';
import {
  SettingsLoadSuccessT,
  SETTINGS_LOAD_SUCCESS,
  SettingsLoadErrorT,
  SETTINGS_LOAD_ERROR,
  SETTINGS_ADD_TO_LIST,
  SettingsAddToListT,
  SettingsUpdateInListT,
  SETTINGS_UPDATE_IN_LIST,
  SETTINGS_REMOVE_FROM_LIST,
  SettingsRemoveFromListT,
} from '../types/settings/actions';
import { setSpinner } from './view';

export const settingsLoadSuccess = (
  settings: SettingsT
): SettingsLoadSuccessT => ({
  type: SETTINGS_LOAD_SUCCESS,
  settings,
});

export const settingsLoadError = (): SettingsLoadErrorT => ({
  type: SETTINGS_LOAD_ERROR,
});

export const addToSettingsList = (
  item: CabinetTypeT,
  field: SettingsListsT
): SettingsAddToListT => ({
  type: SETTINGS_ADD_TO_LIST,
  field,
  item,
});
export const updateInSettingsList = (
  item: CabinetTypeT,
  field: SettingsListsT
): SettingsUpdateInListT => ({
  type: SETTINGS_UPDATE_IN_LIST,
  field,
  item,
});
export const removeFromSettingsList = (
  id: string,
  field: SettingsListsT
): SettingsRemoveFromListT => ({
  type: SETTINGS_REMOVE_FROM_LIST,
  field,
  id,
});

/**--------------------
 * THUNK
 ----------------------*/
export const getSettings = (onEnd?: () => void, onError?: () => void) => async (
  dispatch: Dispatch
) => {
  setAuthToken();
  try {
    const res = await Axios.get('/api/settings/');

    dispatch(settingsLoadSuccess(res.data.settings));
    if (onEnd) onEnd();
  } catch (error) {
    dispatch(settingsLoadError());
    axiosErrorHandle(error, onError);
  }
  dispatch(setSpinner(false));
};
