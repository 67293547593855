import React, { useState } from 'react';
import { Button } from 'antd';
import CabinerElementsList from 'components/Base/Cabinets/CabinetElementsList';
import { CabinetElementT } from 'services/store/types/cabinets/Cabinets';
import { initCabinetElement } from 'services/utils/const/initData';
import CabinetElementModal from '../../../../components/Base/Cabinets/CabinetElementModal';

interface PropsT {
  elements: CabinetElementT[];
  push: (values: CabinetElementT) => void;
  remove: (index: number) => void;
  replace: (index: number, values: CabinetElementT) => void;
}
interface ElementPropsT {
  index: number;
  data: CabinetElementT;
}

const CabinetElementsFormList: React.FC<PropsT> = ({
  elements,
  push,
  remove,
  replace,
}) => {
  const [element, setElement] = useState<ElementPropsT | null>(null);

  const handleElement = (data: CabinetElementT) => {
    if (element) {
      if (element.index > -1) replace(element.index, data);
      else push(data);
    }
    return setElement(null);
  };

  return (
    <div>
      <CabinerElementsList
        elements={elements}
        actions={{
          remove,
          update: (index, data) => setElement({ index, data }),
        }}
      />
      <div style={{ marginTop: 20 }}>
        <Button
          type="primary"
          onClick={() => setElement({ index: -1, data: initCabinetElement })}
        >
          Dodaj element
        </Button>
      </div>

      {/* MODAL */}
      {/* NOTE Add/Edit element */}
      {element && (
        <CabinetElementModal
          closeModal={() => setElement(null)}
          visible={!!element}
          handleSubmit={handleElement}
          initValues={element.data}
        />
      )}
    </div>
  );
};

export default CabinetElementsFormList;
