import React, { useState } from 'react';
import PageTemplate from 'templates/PageTemplate';
import { PageHeader, message, Popover, Empty, Row, Col } from 'antd';
import { getPersonalName } from 'services/utils/users';
import { useParams } from 'react-router';
import AddressData from 'components/Address/Display';
import PersonalData from 'components/Users/PersonalData';
import { ExclamationCircleOutlined, FileOutlined } from '@ant-design/icons';
import IconDivider from 'components/IconDivider';
import ProjectsList from 'components/Projects/ProjectsList';
import { StyledH1 } from 'components/Header';
import { isAddressValid } from 'services/utils';
import { ProjectT } from 'services/store/types/projects/Projects';
import { useModal } from 'services/hooks/useModal';
import { useMutation, useQuery } from '@tanstack/react-query';
import { customersApi } from 'services/api/customers.api';
import useUser from 'hooks/useUser';
import Spinner from 'components/Spinner';
import ContentTemplate from 'templates/ContentTemplate/ContentTemplate';
import SiderTemplate from 'templates/SiderTemplate/SiderTemplate';
import CustomerProfileSider from './sider/CustomerProfileSider';
import AddAddress from 'components/Address/AddressModal/AddAddress';
import AddToBlackList from './components/modals/AddToBlackList';
import DeleteCustomer from './components/modals/DeleteCustomer';
import EditCustomer from './components/modals/EditData';
import { AddressT } from 'services/store/types/settings/Settings';

const initModal = {
  editCustomer: false,
  customerRemove: false,
  blackList: false,
  editAddress: false,
};

export type TCustomerProfileModal = keyof typeof initModal;

// FIXME pobierać klienta i projekty oddzielnie

const CustomerProfilePage = () => {
  const params: { id: string } = useParams();
  const { modal, showModal, closeModal } = useModal(initModal);
  const { user, isOffice, isAdmin } = useUser();

  const [projects, setProjects] = useState<ProjectT[]>([]);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['get-customer', params.id],
    queryFn: () => customersApi.getCustomerById(params.id),
    onSuccess(res) {
      const customer = res.data.customer;
      if (user && customer && customer.Projects) {
        let newProjects = customer.Projects;

        if (!isAdmin() && !isOffice())
          newProjects = customer.Projects.filter(
            (project: ProjectT) => project.UserId === user.id
          );

        setProjects(newProjects);
      }
    },
    onError() {
      message.error('Błąd');
    },
  });

  const updateAddress = useMutation(
    async (address: AddressT) =>
      customersApi.updateCustomer(params.id, { CustomerAddress: address }),
    {
      onSuccess() {
        refetch();
        closeModal();
        message.success('Adres zaktualizowany');
      },
      onError() {
        message.error('Błąd');
      },
    }
  );

  if (data?.data) {
    const customer = data.data.customer;
    return (
      <PageTemplate>
        <SiderTemplate>
          <CustomerProfileSider
            showModal={showModal}
            customer={data.data.customer}
          />
        </SiderTemplate>
        <ContentTemplate>
          {isLoading && <Spinner />}
          <PageHeader
            title={
              <StyledH1>
                {getPersonalName(customer)}{' '}
                {customer.CustomerBlackList && (
                  <Popover
                    title="Czarna lista"
                    content={customer.CustomerBlackList.description}
                  >
                    <ExclamationCircleOutlined style={{ color: 'red' }} />
                  </Popover>
                )}
              </StyledH1>
            }
          />
          {(!customer.CustomerAddress ||
            !isAddressValid(customer.CustomerAddress)) && (
            <strong style={{ color: 'red ' }}>Dodaj adres zamieszkania</strong>
          )}
          <Row>
            <Col xs={12} md={3}>
              <PersonalData
                title="Dane klienta"
                customer={customer}
                loading={!customer}
                column={1}
              />
            </Col>
            <Col xs={12} md={3}>
              <AddressData
                address={customer.CustomerAddress}
                loading={!customer.CustomerAddress}
                column={1}
              />
            </Col>
          </Row>
          <IconDivider
            title="Projekty"
            icon={<FileOutlined style={{ color: 'lightskyblue' }} />}
          />
          <ProjectsList projects={projects} />
        </ContentTemplate>

        {/* MODALS */}
        {modal.editAddress && (
          <AddAddress
            visible={modal.editAddress}
            closeModal={closeModal}
            handleSubmit={updateAddress.mutate}
            title="Adres zamieszkania"
            values={customer.CustomerAddress}
          />
        )}
        {modal.blackList && (
          <AddToBlackList
            visible={modal.blackList}
            customer={customer}
            closeModal={closeModal}
            refetchCustomer={refetch}
          />
        )}
        {modal.customerRemove && (
          <DeleteCustomer
            visible={modal.customerRemove}
            customer={customer}
            closeModal={closeModal}
          />
        )}
        {modal.editCustomer && (
          <EditCustomer
            closeModal={closeModal}
            visible={modal.editCustomer}
            customer={customer}
            refetchCustomer={refetch}
          />
        )}
      </PageTemplate>
    );
  }
  return <Empty />;
};

export default CustomerProfilePage;
