import { PermissionsT } from 'services/store/types/auth/Auth';

export const domain = 'https://emmi-crm.pl/';
// export const domain = 'http://localhost:5000/';

// PERMS
export const userPermissions = [
  'admin',
  'designer',
  'technologist',
  'externalCompany',
  'carpenter',
  'installer',
  'office',
];
export const perms = {
  ADMIN: 'admin' as PermissionsT,
  DESIGNER: 'designer' as PermissionsT,
  CARPENTER: 'carpenter' as PermissionsT,
  TECHNOLOGIST: 'technologist' as PermissionsT,
  EXTERNAL_COMPANY: 'externalCompany' as PermissionsT,
  INSTALLER: 'installer' as PermissionsT,
  OFFICE: 'office' as PermissionsT,
};
export const permsPL: any = {
  admin: 'administrator',
  designer: 'projektant',
  carpenter: 'stolarz',
  technologist: 'technolog',
  externalCompany: 'firma zewnętrzna',
  installer: 'montażysta',
  office: 'biuro',
};

// STATES
export const orderStates = {
  CREATED: 'utworzone',
  CANCEL: 'anulowane',
  SENDED: 'wysłane',
  PART_PICKED: 'odebrane częściowo',
  ENDED: 'zakończone',
};
export const states = {
  PENDING: 'oczekuje',
  PROJECT: 'projekt',
  TECH: 'technolog',
  EXTERNAL_COMPANY: 'firma zewnętrzna',
  PRODUCTION: 'produkcja',
  MONTAGE: 'montaż',
  RECLAMATION: 'reklamacja',
  CANCEL: 'anulowany',
  ENDED: 'zakończone',
};
export const projectStates = [
  'oczekuje',
  'projekt',
  'technolog',
  'firma zewnętrzna',
  'produkcja',
  'montaż',
  'reklamacja',
  'zakończone',
  'anulowany',
];
export const projectFiles = [
  { permToCreate: '_admin_designer', name: 'U - Skan umowy' },
  { permToCreate: '_admin_designer', name: 'Z1 - Projekt i specyfikacja' },
  { permToCreate: '_admin_designer', name: 'Z2 - Skan wykazu sprzętu' },
  { permToCreate: '_admin_designer', name: 'Z3 - Skan warunków montażu' },
  { permToCreate: '_admin_designer', name: 'Z4 - Skan gwarancji' },
  { permToCreate: '_admin_designer', name: 'Z5 - Skan RODO' },
  {
    permToCreate: '_admin_designer',
    name: 'Z6 - Skan zgody na otrzymywanie faktur',
  },
  { permToCreate: '_admin_designer', name: 'AU - Skan aneksu do umowy' },
  { permToCreate: '_admin_designer', name: 'UD - Skan umowy długoterminowej' },
  { permToCreate: '_admin_technologist', name: 'Technolog - rysunki' },
  {
    permToCreate: '_admin_designer_technologist_installer',
    name: 'Skan protokołu odbioru',
  },
];
