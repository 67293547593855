import { AddressT } from 'services/store/types/settings/Settings';

export const capitalizeFirstLetter = (s: string) =>
  s.charAt(0).toUpperCase() + s.slice(1);

export const formatPhoneNumber = (n: string | number) => {
  if (!n) return '';
  const number = n.toString();
  if (number.length === 9)
    return `${number.substr(0, 3)} ${number.substr(3, 3)} ${number.substr(
      6,
      3
    )}`;
  return n;
};
export const formatAddress = (address: AddressT) => {
  return `${address.city}, ${address.street ? address.street : ''}, ${
    address.apartment ? `m${address.apartment}` : ''
  }`;
};

export const getExtension = (filename: string) => {
  const arr = filename.split('.');
  return arr[arr.length - 1];
};

export const cutString = (
  string: string | null | undefined,
  maxLength: number
) => {
  if (string) {
    if (string.length > maxLength) return `${string.slice(0, maxLength)}...`;
    return string;
  }
  return '-';
};
