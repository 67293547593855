import { DeleteOutlined } from '@ant-design/icons';
import {
  Empty,
  Image,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
} from 'antd';
import ElementDimension from 'components/Base/Cabinets/ElementDimension';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CabinetElementTypeT } from 'services/store/types/cabinets/Cabinets';
import {
  PricingElementT,
  PricingPatternT,
} from 'services/store/types/pricing/Pricing';
import { pricingElementsQty } from 'services/store/actions/pricing';
import { domain } from 'services/utils/const';
import styled from 'styled-components';
import Img from 'components/Image';

const columns: any[] = [
  {
    title: 'Lp.',
    align: 'center' as const,
    render: (t: string, r: any, i: number) => i + 1,
  },
  {
    title: 'Wzór',
    dataIndex: 'pattern',
    align: 'center' as const,
  },
  {
    title: 'Wysokosć',
    dataIndex: 'height',
    align: 'center' as const,
    render: (t: number, r: any) => (
      <ElementDimension edge={r.h1} edge2={r.h2}>
        {t}
      </ElementDimension>
    ),
  },
  {
    title: 'Szerokość',
    dataIndex: 'width',
    align: 'center' as const,
    render: (t: number, r: any) => (
      <ElementDimension edge={r.h1} edge2={r.h2}>
        {t}
      </ElementDimension>
    ),
  },
  {
    title: 'Grubość',
    dataIndex: 'thickness',
    align: 'center' as const,
  },
  {
    title: 'Lakier',
    dataIndex: 'paint',
    align: 'center' as const,
  },
  {
    title: 'Segment/Korpus',
    dataIndex: 'order',
    align: 'center' as const,
  },
  {
    title: 'Ilość',
    dataIndex: 'qty',
    align: 'center' as const,
  },
  {
    title: 'Akcje',
    dataIndex: 'actions',
    align: 'center' as const,
  },
];

const Wrapper = styled.div`
  max-height: 500px;
  overflow-y: auto;
`;

interface PropsT {
  elements: PricingElementT[];
  type: CabinetElementTypeT;
  actions?: {
    remove?: (index: number) => void;
  };
  editable?: boolean;
}

const ElementsList: React.FC<PropsT> = ({
  elements,
  type,
  actions,
  editable,
}) => {
  const dispatch = useDispatch();
  const [list, setList] = useState<any>([]);

  useEffect(() => {
    if (elements.length) {
      const newList = elements
        .map((element: PricingElementT, i: number) => {
          return {
            key: `${element.ElementId}${element.order}${
              element.qty
            }${Math.random()}`,
            ...element.Element,
            qty: (
              <InputNumber
                value={element.qty}
                onChange={(value) => {
                  if (typeof value === 'number') {
                    handleQty(i, value);
                  }
                }}
                min={1}
                disabled={!editable}
                style={{ width: 100 }}
              />
            ),
            paint: <PaintPreview pattern={element.PricingPattern} />,
            pattern: !element.Element.isHdf ? (
              <>
                <Img
                  path={element.PricingPattern?.Pattern?.miniaturePath}
                  previewPath={element.PricingPattern?.Pattern?.imgPath}
                  isExist={element.PricingPattern?.Pattern}
                />
                {element.PricingPattern?.Veneer && (
                  <Img
                    path={element.PricingPattern?.Veneer?.miniaturePath}
                    previewPath={element.PricingPattern?.Veneer?.imgPath}
                    isExist={element.PricingPattern?.Veneer}
                  />
                )}
              </>
            ) : (
              'HDF'
            ),
            order: (
              <Typography.Text disabled>
                Kolejnosć: {element.segmentNumber}/{element.order}
              </Typography.Text>
            ),
            actions: actions ? (
              <div>
                {actions.remove && (
                  <Popconfirm
                    title="Usuń element"
                    onConfirm={() => actions.remove && actions.remove(i)}
                    disabled={!editable}
                  >
                    <DeleteOutlined
                      title="Usuń"
                      style={
                        editable
                          ? { color: 'red', cursor: 'pointer', margin: 5 }
                          : { color: 'gray', margin: 5, cursor: 'default' }
                      }
                    />
                  </Popconfirm>
                )}
              </div>
            ) : (
              <Typography.Text disabled>brak</Typography.Text>
            ),
          };
        })
        .filter((element: any, i: number) => element.type === type);
      setList(newList);
    }
  }, [elements, editable]);

  const handleQty = (index: number, value: number) =>
    dispatch(pricingElementsQty(index, value));

  if (list.length)
    return (
      <Wrapper>
        <Table
          columns={columns}
          dataSource={list}
          size={'small' as const}
          pagination={false}
        />
      </Wrapper>
    );
  return <Empty />;
};

export default ElementsList;

const PaintPreview = ({
  pattern = undefined,
}: {
  pattern: PricingPatternT | undefined;
}) => {
  if (pattern)
    return (
      <div
        style={{
          padding: 4,
          backgroundColor: pattern.Paint?.hex || 'white',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <div
          style={{
            backgroundColor: 'rgba(255,255,255,0.4)',
            fontSize: 10,
          }}
        >
          {pattern.Paint
            ? `${pattern.Paint.name}-${pattern.Paint.type}`
            : 'Laminat'}
        </div>
      </div>
    );
  return null;
};
