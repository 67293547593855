import React from 'react';
import { DatePicker, PageHeader, Tabs } from 'antd';
import { CaretRightOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { AppStateT } from 'services/store';
import { setTabKey } from 'services/store/actions/view';
import { StyledH1 } from 'components/Header';
import { setFiltersField } from 'services/store/actions/projects';
import ActiveProjects from '../components/ActiveProjects';
import EndedProjects from '../components/EndedProjects';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const DesignerHomePage = () => {
  const dispatch = useDispatch();
  const key = useSelector((state: AppStateT) => state.view.homeKey);
  const dates = useSelector(
    (state: AppStateT) => state.projects.filters.ended.dates
  );

  const handleDates = (values: any) => {
    dispatch(setFiltersField('ended', 'dates', values));
  };
  const handleKey = (k: string) => dispatch(setTabKey('homeKey', k));
  return (
    <div>
      <PageHeader
        title={
          <StyledH1>
            {key === 'currentProjects'
              ? 'Aktywne projekty'
              : 'Zakończone projekty'}
          </StyledH1>
        }
        extra={
          key === 'finishedProjects' && [
            <RangePicker
              key={1}
              value={dates}
              onChange={handleDates}
              allowClear={false}
            />,
          ]
        }
      />
      <Tabs defaultActiveKey={key} onChange={handleKey}>
        <TabPane
          tab={
            <>
              <CaretRightOutlined />
              Aktywne
            </>
          }
          key="currentProjects"
        >
          {key === 'currentProjects' && <ActiveProjects />}
        </TabPane>
        <TabPane
          tab={
            <>
              <CheckCircleOutlined /> Zakończone
            </>
          }
          key="finishedProjects"
        >
          {key === 'finishedProjects' && <EndedProjects />}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default DesignerHomePage;
