import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import {
  PricingPatternT,
  PricingPatternTypeT,
} from 'services/store/types/pricing/Pricing';
import PatternsList from 'scenes/ProjectPricing/components/PricingPatterns/List';

interface PropsT {
  type: 'front' | 'body';
  closeModal: () => void;
  visible: boolean;
  patterns: PricingPatternT[];
  pickPattern: (p: PricingPatternT) => void;
}

const PickPatternToCabinet: React.FC<PropsT> = ({
  type,
  closeModal,
  visible,
  patterns,
  pickPattern,
}) => {
  const [patternsList, setPatternsList] = useState<PricingPatternT[]>([]);

  useEffect(() => {
    const newList = patterns.filter((p: PricingPatternT) => p.type === type);
    setPatternsList(newList);
  }, []);

  return (
    <Modal
      title={`Wybierz wzór ${type === 'front' ? 'frontu' : 'korpusu'}`}
      destroyOnClose={true}
      visible={visible}
      width={800}
      onCancel={closeModal}
    >
      <PatternsList
        patterns={patternsList}
        rowClick={(p) => {
          pickPattern(p);
          closeModal();
        }}
      />
    </Modal>
  );
};

export default PickPatternToCabinet;
