import { Typography, Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  pricingSetElements,
  pricingRemoveElement,
} from 'services/store/actions/pricing';
import { PricingT } from 'services/store/types/pricing/Pricing';
import { getElementsFromSegments, optymizeElementsList } from '../utils';
import ElementsList from '../../../../components/Pricing/Elements/List';

interface PropsT {
  pricing: PricingT;
}

const Elements: React.FC<PropsT> = ({ pricing }) => {
  const { Segments, PricingElements } = pricing;
  const dispatch = useDispatch();

  useEffect(() => {
    const newElements = getElementsFromSegments(Segments);
    const optymized = optymizeElementsList(newElements);

    // TODO Get boards surface/ veneers length/ painted fronts surface

    dispatch(pricingSetElements(optymized));
  }, [Segments]);

  const handleRemove = (index: number) => dispatch(pricingRemoveElement(index));

  return (
    <div>
      <Row>
        <Col xs={12} md={6}>
          <Typography.Title level={4}>Elementy konstrukcyjne</Typography.Title>
          <ElementsList
            elements={PricingElements}
            type="body"
            actions={{ remove: handleRemove }}
            editable={pricing.listEditable}
          />
        </Col>
        <Col xs={12} md={6}>
          <Typography.Title level={4}>Fronty</Typography.Title>
          <ElementsList
            elements={PricingElements}
            type="front"
            actions={{ remove: handleRemove }}
            editable={pricing.listEditable}
          />
        </Col>
      </Row>
      okleina
    </div>
  );
};

export default Elements;
