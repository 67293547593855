import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Modal, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateFile } from 'services/api/files/update';
import { setProject } from 'services/store/actions/projects';
import { setSpinner } from 'services/store/actions/view';
import { FileT } from 'services/store/types/projects/Projects';

interface PropsT {
  closeModal: () => void;
  visible: boolean;
  fileData?: FileT;
  refetchFiles: () => Promise<any>;
}

const UpdateFile: React.FC<PropsT> = ({
  closeModal,
  visible,
  fileData,
  refetchFiles,
}) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState<RcFile[]>([]);

  const handleFile = (f: RcFile, fList: RcFile[]) => {
    setFiles([...files, ...fList]);
    return false;
  };
  const handleRemove = (f: UploadFile) => {
    const filesList = files.filter((file: RcFile) => file.uid !== f.uid);
    setFiles(filesList);
  };

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    if (fileData && files.length)
      await updateFile(
        fileData.id,
        files,
        (project) => {
          refetchFiles();
          message.success(`Dodano ${fileData.name}`);
          closeModal();
        },
        () => {
          message.error('Błąd!');
        }
      );
    dispatch(setSpinner(false));
  };

  return (
    <Modal
      title={`Dodaj ${fileData ? fileData.name : 'plik'}`}
      visible={visible}
      onCancel={closeModal}
      destroyOnClose={true}
      onOk={handleSubmit}
      okButtonProps={{ disabled: !files.length }}
    >
      <div>
        <Upload
          fileList={files}
          beforeUpload={handleFile}
          onRemove={handleRemove}
          multiple
        >
          <Button size="large" icon={<UploadOutlined />}>
            Dodaj plik
          </Button>
        </Upload>
      </div>
    </Modal>
  );
};

export default UpdateFile;
