import Axios from 'axios';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import { UserT } from 'services/store/types/auth/Auth';
import setAuthToken from 'services/utils/setAuthToken';

export const createNewUser = async (
  values: CreateUserValuesT,
  onEnd: (user: UserT) => void,
  onErr: (msg: string) => void
) => {
  setAuthToken();
  try {
    const res: any = await Axios.post('/api/users/', { ...values });

    onEnd(res.data.user);
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};

export interface CreateUserValuesT {
  email: string;
  firstname: string;
  surname: string;
  password: string;
  password2: string;
  permission: string;
}
