import React, { useState } from 'react';
import { DatePicker, message, Modal } from 'antd';
import { ProjectT } from 'services/store/types/projects/Projects';
import moment, { Moment } from 'moment';
import { useMutation } from '@tanstack/react-query';
import { projectsApi } from 'services/api/projects.api';

interface PropsT {
  project: ProjectT;
  closeModal: () => void;
  refetchProject: () => Promise<any>;
  visible: boolean;
}

const ChangeProjectDate: React.FC<PropsT> = ({
  project,
  closeModal,
  visible,
  refetchProject,
}) => {
  const [date, setDate] = useState<Moment | null>(
    project.startAt ? moment(project.startAt) : null
  );

  const updateCreationDate = useMutation(
    async () => date && projectsApi.updateCreationDate(project.id, date),
    {
      onSuccess() {
        message.success('Data zaktualizowana');
        refetchProject();
        closeModal();
      },
      onError(err: any) {
        console.log(err?.response);
        message.error('Bład');
      },
    }
  );

  return (
    <Modal
      title="Zmiana daty utworzenia projektu"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      onOk={() => updateCreationDate.mutate()}
      okButtonProps={{ disabled: !date, loading: updateCreationDate.isLoading }}
    >
      <DatePicker
        placeholder="Wybierz dzień"
        style={{ width: '100%' }}
        size="large"
        picker="date"
        value={date}
        onChange={(value) => setDate(value)}
      />
    </Modal>
  );
};

export default ChangeProjectDate;
