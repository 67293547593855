import {
  NewOrderAccessoryT,
  NewOrderT,
} from 'services/store/types/orders/Orders';
import { PricingAccessoryT } from 'services/store/types/pricing/Pricing';
import { IdType } from 'services/types';

export const createOrdersFromAccessories = (
  accessories: PricingAccessoryT[],
  ProjectId?: IdType
): NewOrderT[] => {
  let orders: NewOrderT[] = [];

  for (let i = 0; i < accessories.length; i++) {
    const accessory = accessories[i];
    if (accessory.Accessory) {
      const order = createNewOrderObj(accessory, ProjectId);
      if (order) {
        const isExist = orders.find(
          (o: NewOrderT) => o.SupplierId === order.SupplierId
        );

        if (isExist) {
          orders = orders.map((o: NewOrderT) => {
            if (o.SupplierId === order.SupplierId) {
              const newOrder = {
                ...o,
                ProjectId,
                OrderAccessories: [
                  ...o.OrderAccessories,
                  ...order.OrderAccessories,
                ],
              };
              return newOrder;
            }
            return o;
          });
        } else {
          orders.push(order);
        }
      }
    }
  }

  orders = orders.map((order: NewOrderT) => {
    const price = order.OrderAccessories.reduce(
      (p: number, accessory: NewOrderAccessoryT) => {
        const accessoryPrice =
          (accessory.Accessory?.price || 0) * accessory.qty;
        const newPrice = p + accessoryPrice;

        return newPrice;
      },
      0
    );

    const newOrder = { ...order, price };
    return newOrder;
  });

  return orders;
};

const createNewOrderObj = (
  accessory: PricingAccessoryT,
  ProjectId?: IdType
) => {
  if (accessory.Accessory)
    return {
      name: '',
      description: '',
      price: 0,
      Supplier: accessory.Accessory.Supplier,
      SupplierId: accessory.Accessory.SupplierId,
      ProjectId,
      status: 'utworzone' as const,
      OrderAccessories: [
        {
          Accessory: accessory.Accessory,
          AccessoryId: accessory.AccessoryId,
          qty: accessory.qty,
        },
      ],
    };
  return undefined;
};

export const getOrdersPriceAndExpectedPickUpDate = (
  orders: NewOrderT[]
): NewOrderT[] => {
  const newOrders = orders.map((order: NewOrderT) => {
    const orderPrice = getOrderPrice(order.OrderAccessories);

    return { ...order, price: orderPrice };
  });

  return newOrders;
};

const getOrderPrice = (accessories: NewOrderAccessoryT[]) => {
  return accessories.reduce((price: number, accessory: NewOrderAccessoryT) => {
    const accessoryPrice = (accessory.Accessory?.price || 0) * accessory.qty;
    const newPrice = price + accessoryPrice;
    return newPrice;
  }, 0);
};
