import { Dispatch } from 'redux';
import setAuthToken from 'services/utils/setAuthToken';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import Axios from 'axios';
import { CustomerT } from '../types/customers/Customers';
import {
  CUSTOMERS_LOAD_SUCCESS,
  CUSTOMERS_LOAD_ERROR,
  CUSTOMERS_SET_CUSTOMER,
  CUSTOMERS_ADD_CUSTOMER_TO_LIST,
  CustomersLoadSuccessT,
  CustomersLoadErrorT,
  SetCustomerT,
  AddCustomerToListT,
} from '../types/customers/actions';
import { setSpinner } from './view';

export const customersLoadSuccess = (
  customers: CustomerT[] | []
): CustomersLoadSuccessT => ({
  type: CUSTOMERS_LOAD_SUCCESS,
  customers,
});
export const customersLoadError = (): CustomersLoadErrorT => ({
  type: CUSTOMERS_LOAD_ERROR,
});
export const setCustomer = (customer: CustomerT | null): SetCustomerT => ({
  type: CUSTOMERS_SET_CUSTOMER,
  customer,
});
export const addCustomerToList = (customer: CustomerT): AddCustomerToListT => ({
  type: CUSTOMERS_ADD_CUSTOMER_TO_LIST,
  customer,
});

/**--------------------
 * THUNK
 ----------------------*/

export const getAllCustomers = (
  onEnd?: () => void,
  onError?: () => void
) => async (dispatch: Dispatch) => {
  setAuthToken();
  try {
    const res = await Axios.get('/api/customers/');
    const { customers } = res.data;

    dispatch(customersLoadSuccess(customers));
    if (onEnd) onEnd();
  } catch (error) {
    dispatch(customersLoadError());
    axiosErrorHandle(error, onError);
  }
  dispatch(setSpinner(false));
};

export const getAllCustomerDataById = (
  id: string,
  onEnd?: () => void,
  onError?: () => void
) => async (dispatch: Dispatch) => {
  setAuthToken();
  try {
    const res = await Axios.get(`/api/customers/alldata/${id}`);
    const { customer } = res.data;

    dispatch(setCustomer(customer));
    if (onEnd) onEnd();
  } catch (error) {
    dispatch(setCustomer(null));
    axiosErrorHandle(error, onError);
  }
  dispatch(setSpinner(false));
};
