import React from 'react';
import { Modal, Button } from 'antd';
import { Input, Form } from 'formik-antd';
import { Formik } from 'formik';
import { AddressT } from 'services/store/types/settings/Settings';
import { CustomerT } from 'services/store/types/customers/Customers';
import { ButtonRow } from 'templates/FlexRow';
import { schema } from './utils/validate';

const initValues = {
  city: '',
  postcode: '',
  street: '',
  apartment: '',
  description: '',
};

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  handleSubmit: (address: AddressT) => Promise<any> | void;
  values?: AddressT | null;
  title?: string;
  customer?: CustomerT;
}

const AddAddress: React.FC<PropsT> = ({
  values,
  visible,
  closeModal,
  handleSubmit,
  title,
  customer,
}) => {
  const handleAddress = async (address: AddressT) => {
    await handleSubmit(address);

    closeModal();
  };

  return (
    <Modal
      title={title || 'Adres montażu'}
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Anuluj
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          form="AddMontageAddress"
        >
          Zatwierdź
        </Button>,
      ]}
    >
      <Formik
        validationSchema={schema}
        onSubmit={handleAddress}
        initialValues={values || initValues}
      >
        {(props) => {
          return (
            <Form
              noValidate
              className="form-container"
              id="AddMontageAddress"
              onFinish={props.handleSubmit}
            >
              <Form.Item name="city">
                <Input name="city" placeholder="Miejscowość" required />
              </Form.Item>
              <Form.Item name="postcode">
                <Input name="postcode" placeholder="Kod pocztowy" required />
              </Form.Item>
              <Form.Item name="street">
                <Input name="street" placeholder="Ulica" required />
              </Form.Item>
              <Form.Item name="apartment">
                <Input name="apartment" placeholder="Nr domu/lokalu" required />
              </Form.Item>
              <Form.Item name="description">
                <Input.TextArea
                  name="description"
                  placeholder="Opis..."
                  required
                />
              </Form.Item>
              {customer && (
                <ButtonRow justify="flex-end">
                  <Button
                    size="small"
                    type="primary"
                    disabled={!customer.CustomerAddress}
                    onClick={() =>
                      customer.CustomerAddress
                        ? props.setValues(customer.CustomerAddress)
                        : null
                    }
                  >
                    {customer.CustomerAddress
                      ? 'Ustaw adres zamieszkania klienta'
                      : 'Brak adresu zamieszkania klienta'}
                  </Button>
                </ButtonRow>
              )}
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddAddress;
