import * as yup from 'yup';

export const schema = yup.object({
  PatternId: yup.number().required('Wzór jest wymagany'),
  desing: yup.string(),
  Paint: yup.object().when('design', {
    is: 'lakier',
    then: yup.object().shape({
      name: yup.string().required('Uzupełnij'),
      hex: yup.string().required('Uzupełnij'),
      type: yup.string().required('Uzupełnij'),
      palette: yup.string().required('Uzupełnij'),
    }),
  }),
});
