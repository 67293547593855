import React, { useEffect, useState } from 'react';
import { Modal, DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { ProjectT } from 'services/store/types/projects/Projects';
import {
  projectTermsTypes,
  updateProjectTermsById,
} from 'services/store/actions/projects';
import withPermission from 'hoc/withPermission';
import { PermissionsT } from 'services/store/types/auth/Auth';
import { perms } from 'services/utils/const';
import { weeksFromDate } from 'services/utils/date';

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  project: ProjectT;
  permission: PermissionsT;
}

const SetMontageWeek: React.FC<PropsT> = ({
  visible,
  closeModal,
  project,
  permission,
}) => {
  const dispatch = useDispatch();
  const [week, setWeek] = useState<Moment | null>(null);

  useEffect(() => {
    let initWeek = null;
    if (project.montageWeek) initWeek = moment(project.montageWeek.date);
    else if (permission === perms.DESIGNER) {
      if (project.measureWeek)
        initWeek = moment(project.measureWeek.date).add(6, 'weeks');
      else initWeek = moment().add(6, 'week');
    }
    setWeek(initWeek);
  }, []);

  const handleChange = (date: Moment | null) => setWeek(date);

  const disabledDate = (currDate: Moment) => {
    if (permission === perms.DESIGNER) {
      if (project.measureWeek) {
        const diff = currDate.diff(moment(project.measureWeek.date), 'week');
        return diff < 5;
      }
      const diff = currDate.diff(moment(), 'week');
      return diff < 6;
    }
    return currDate < moment();
  };

  const onSubmit = async () => {
    if (week) {
      dispatch(setSpinner(true));
      dispatch(
        updateProjectTermsById(
          projectTermsTypes.MONTAGE_WEEK,
          project.id,
          { montageWeek: week },
          'Ustalono tydzień montażu',
          () => closeModal()
        )
      );
    }
  };

  return (
    <Modal
      title={`Tydzień montażu (${
        week ? `${week.isoWeek()}, ${week.isoWeek() + 1}` : '-'
      })`}
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      onOk={onSubmit}
      okButtonProps={{ disabled: !week }}
    >
      {project.measureWeek && (
        <div style={{ textAlign: 'center', marginBottom: 10 }}>
          Tydzień pomiaru ustalono na:{' '}
          <strong>{moment(project.measureWeek.date).isoWeek()}</strong> tydz.
          roku.
        </div>
      )}
      {week && weeksFromDate(week) < 7 && (
        <span style={{ color: 'red' }}>
          {!project.measureWeek && 'Brak terminu pomiaru. '}Montaż za{' '}
          {weeksFromDate(week)} tyg.
        </span>
      )}
      <DatePicker
        placeholder="Wybierz tydzień"
        style={{ width: '100%' }}
        size="large"
        picker="week"
        value={week || null}
        onChange={handleChange}
        disabledDate={disabledDate}
      />
    </Modal>
  );
};

export default withPermission(SetMontageWeek);
