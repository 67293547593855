import React from 'react';
import { Menu } from 'antd';
import {
  LockOutlined,
  UserDeleteOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import useUser from 'hooks/useUser';
import { TUserProfileModal } from '..';

interface IProps {
  showModal: (key: TUserProfileModal) => void;
}

const UserProfileSider: React.FC<IProps> = ({ showModal }) => {
  const { user } = useUser();

  return (
    <>
      <Menu mode="inline">
        <Menu.Item
          onClick={() => showModal('dataChange')}
          icon={<UserSwitchOutlined />}
          disabled={!user}
        >
          Edytuj dane
        </Menu.Item>
        <Menu.Item
          onClick={() => showModal('permissionChange')}
          icon={<UserSwitchOutlined />}
          disabled={!user}
        >
          Zmień uprawnienia
        </Menu.Item>{' '}
        <Menu.Item
          onClick={() => showModal('passwordChange')}
          icon={<LockOutlined />}
          disabled={!user}
        >
          Zmień hasło
        </Menu.Item>
        <Menu.Item
          onClick={() => showModal('userRemove')}
          icon={<UserDeleteOutlined />}
          disabled={!user}
          danger
        >
          Usuń użytkownika
        </Menu.Item>
      </Menu>
    </>
  );
};

export default UserProfileSider;
