import Axios from 'axios';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import { CustomerT } from 'services/store/types/customers/Customers';
import setAuthToken from 'services/utils/setAuthToken';

export const updateCustomerData = async (
  customer: CustomerT,
  onEnd: (user: CustomerT) => void,
  onErr: (msg: string) => void
) => {
  setAuthToken();
  try {
    console.log(customer);

    const res: any = await Axios.put(`/api/customers/${customer.id}`, {
      ...customer,
    });

    onEnd(res.data.customer);
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};
