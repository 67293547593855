import { CheckCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { IProjectSiderProps } from './ProjectSider';

interface IProps extends IProjectSiderProps {}

const ExternalCompanySider: React.FC<IProps> = ({ showModal }) => {
  return (
    <>
      <Menu mode="inline">
        <Menu.Item
          icon={<CheckCircleOutlined />}
          onClick={() => showModal('finishProject')}
        >
          Zakończ projekt
        </Menu.Item>
        <hr />
        <Menu.Item
          icon={<DownloadOutlined />}
          onClick={() => showModal('files')}
        >
          Pobierz dokumenty
        </Menu.Item>
      </Menu>
    </>
  );
};

export default ExternalCompanySider;
