import React, { useState, useEffect } from 'react';
import { Modal, message } from 'antd';
import ExternalCompanySelect from 'components/Users/ExternalCompanySelect';
import { useMutation, useQuery } from '@tanstack/react-query';
import { usersApi } from 'services/api/users.api';
import { projectsApi } from 'services/api/projects.api';
import { ProjectT } from 'services/store/types/projects/Projects';
import { generateProjectOrdinalNumber } from 'services/utils/project';
import moment from 'moment';

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  externalCompanyId?: string;
  projectId: string;
  project: ProjectT;
  refetchProject: () => Promise<any>;
}

const ChangeExternalCompany: React.FC<PropsT> = ({
  visible,
  closeModal,
  externalCompanyId,
  projectId,
  project,
  refetchProject,
}) => {
  const [externalCompany, setExternalCompany] = useState(externalCompanyId);
  const [companyName, setCompanyName] = useState<string>();
  const handleSelect = (id: string) => {
    setExternalCompany(id);
  };

  const getUsers = useQuery({
    queryKey: ['get-users'],
    queryFn: () => usersApi.getAllUsers(),
    onError() {
      message.error('Błąd');
    },
    retry: false,
  });

  useEffect(() => {
    if (getUsers.data?.data.users && externalCompany) {
      const newCompanyName =
        getUsers.data.data.users.find((user) => user.id === externalCompany)
          ?.company || '';
      setCompanyName(newCompanyName);
    } else {
      setCompanyName('');
    }
  }, [getUsers.data, externalCompany]);

  const externalCompanyNextOrdinalNumber = useQuery({
    queryKey: ['get-external-company-next-ordinal-number', externalCompany],
    queryFn: () => projectsApi.getNextOrdinalNumber(externalCompany),
    onError() {
      message.error('Błąd');
    },
    retry: false,
    enabled: !!externalCompany,
  });

  const updateExternalCompany = useMutation(
    async () => projectsApi.updateExternalCompany(projectId, externalCompany),
    {
      onSuccess() {
        message.success('Firma zewnętrzna zaktualizowana');
      },
      onError(err: any) {
        console.log(err?.response);
        message.error('Bład');
      },
    }
  );
  const updateOrdinalNumber = useMutation(
    async () =>
      project &&
      externalCompanyNextOrdinalNumber.data?.data.nextOrdinalNumber &&
      projectsApi.updateOrdinalNumber(
        project.id,
        externalCompanyNextOrdinalNumber.data.data.nextOrdinalNumber,
        project.projectYear
      ),
    {
      onSuccess() {
        message.success('Numer porządkowy zaktualizowany');
      },
      onError(err: any) {
        console.log(err?.response);
        message.error('Bład');
      },
    }
  );

  const handleSubmit = async () => {
    await updateExternalCompany.mutateAsync();
    await updateOrdinalNumber.mutateAsync();
    await refetchProject();
    closeModal();
  };

  console.log(externalCompanyNextOrdinalNumber.data?.data);
  return (
    <Modal
      title="Zmień firmę zewnętrzną"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      okButtonProps={{
        loading:
          updateExternalCompany.isLoading || updateOrdinalNumber.isLoading,
      }}
      onOk={handleSubmit}
    >
      <div>
        <small>
          Aktualny numer porządkowy: {generateProjectOrdinalNumber(project)}
        </small>
      </div>
      <div>
        {companyName && externalCompanyNextOrdinalNumber && (
          <div>
            <small>
              Nowy numer porządkowy: {companyName.slice(0, 3)}/
              {externalCompanyNextOrdinalNumber.data?.data.nextOrdinalNumber}/
              {project.projectYear}
            </small>
          </div>
        )}
      </div>
      <ExternalCompanySelect
        users={getUsers.data?.data.users || []}
        externalCompanyId={externalCompany}
        disabled={!getUsers.data}
        loading={getUsers.isLoading}
        handleUser={handleSelect}
        style={{ width: '100%' }}
      />
    </Modal>
  );
};

export default ChangeExternalCompany;
