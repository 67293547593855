import { UserT } from '../auth/Auth';

export const USERS_ADD_USER_TO_LIST = 'USERS_ADD_USER_TO_LIST';
export const USERS_LOAD_SUCCESS = 'USERS_LOAD_SUCCESS';
export const USERS_LOAD_ERROR = 'USERS_LOAD_ERROR';
export const USERS_SET_USER = 'USERS_SET_USER';

export interface UsersLoadSuccessT {
  type: typeof USERS_LOAD_SUCCESS;
  users: UserT[] | [];
}
export interface UsersLoadErrorT {
  type: typeof USERS_LOAD_ERROR;
}
export interface SetUserT {
  type: typeof USERS_SET_USER;
  user: UserT | null;
}
export interface AddUserToListT {
  type: typeof USERS_ADD_USER_TO_LIST;
  user: UserT;
}

export type UsersActionsT =
  | UsersLoadSuccessT
  | UsersLoadErrorT
  | SetUserT
  | AddUserToListT;
