import * as yup from 'yup';

export const schema = yup.object({
  name: yup.string().required('Nazwa jest wymagana'),
  email: yup
    .string()
    .email('Format adresu email jest nieprawidłowy')
    .required('Email jest wymagany'),
  phone: yup.string().required('Nr telefonu jest wymagany'),
});
