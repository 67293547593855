import React, { useState } from 'react';
import { ProjectT } from 'services/store/types/projects/Projects';
import { InputNumber, message, Modal } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { projectsApi } from 'services/api/projects.api';

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  project: ProjectT;
  refetchProject: () => Promise<any>;
}

const ChangePrice: React.FC<PropsT> = ({
  visible,
  closeModal,
  project,
  refetchProject,
}) => {
  const [price, setPrice] = useState<number | undefined>(project.price);

  const handlePrice = (value: number | string | undefined | null) =>
    setPrice(typeof value === 'number' ? value : 0);

  const updatePrice = useMutation(
    async () => price && projectsApi.updatePrice(project.id, price),
    {
      onSuccess() {
        message.success('Cena zaktualizowana');
        refetchProject();
        closeModal();
      },
      onError(err: any) {
        console.log(err?.response);
        message.error('Bład');
      },
    }
  );
  return (
    <Modal
      title="Zmiana ceny"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      onOk={() => updatePrice.mutate()}
      okButtonProps={{ disabled: !price, loading: updatePrice.isLoading }}
    >
      <InputNumber
        size="large"
        placeholder="Cena"
        value={price}
        onChange={handlePrice}
        style={{ width: '100%' }}
      />
    </Modal>
  );
};

export default ChangePrice;
