import React from 'react';
import PageTemplate from 'templates/PageTemplate';
import { message, PageHeader } from 'antd';
import { StyledH1 } from 'components/Header';
import List from './components/List';
import SiderTemplate from 'templates/SiderTemplate/SiderTemplate';
import ContentTemplate from 'templates/ContentTemplate/ContentTemplate';
import { useModal } from 'services/hooks/useModal';
import { useQuery } from '@tanstack/react-query';
import { usersApi } from 'services/api/users.api';
import UsersListSider from './sider/UsersListSider';
import AddUserModal from './components/modals/AddUser.modal';

const initModal = {
  newUser: false,
};
export type TUsersListModal = keyof typeof initModal;

const UsersListPage = () => {
  const { modal, showModal, closeModal } = useModal(initModal);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['get-all-users'],
    queryFn: () => usersApi.getAllUsers(),
    onError() {
      message.error('Błąd');
    },
  });

  return (
    <PageTemplate>
      <SiderTemplate>
        <UsersListSider showModal={showModal} />
      </SiderTemplate>
      <ContentTemplate>
        <PageHeader title={<StyledH1>Użytkownicy</StyledH1>} />
        <List users={data?.data.users || []} loading={isLoading} />
      </ContentTemplate>

      {/* MODALS */}
      <AddUserModal
        closeModal={closeModal}
        visible={modal.newUser}
        refetchUsers={refetch}
      />
    </PageTemplate>
  );
};

export default UsersListPage;
