import { NewCabinetT } from 'services/utils/const/initData';
import * as yup from 'yup';

export const cabinetSchema = yup.object({
  symbol: yup.string().required('Uzupełnij'),
  CabinetTypeId: yup.string().required('Uzupełnij'),
  height: yup
    .number()
    .typeError('Podaj wartość')
    .moreThan(0, 'Uzupełnij')
    .required(),
  width: yup
    .number()
    .typeError('Podaj wartość')
    .moreThan(0, 'Uzupełnij')
    .required(),
  depth: yup
    .number()
    .typeError('Podaj wartość')
    .moreThan(0, 'Uzupełnij')
    .required(),
  price: yup
    .number()
    .typeError('Podaj wartość')
    .moreThan(0, 'Uzupełnij')
    .required(),
  CabinetAccessories: yup.array().of(
    yup.object().shape({
      defaultQty: yup.number().moreThan(0, 'Uzupełnij').required(),
    })
  ),
});

export const validateNewCabinet = (values: NewCabinetT) => {
  const errors = [];
  if (!values.CabinetElements.length)
    errors.push('Brak elementów konstrukcyjnych');

  return errors;
};
