import React, { useState } from 'react';
import { PageHeader, Tabs } from 'antd';
import { StyledH1 } from 'components/Header';
import PageTemplate from 'templates/PageTemplate';
import RAL from 'assets/data/ColorsRal.json';
import NCS from 'assets/data/ColorsNcs.json';
import ICA from 'assets/data/ColorsIca.json';
import { PaintT } from 'services/store/types/catalog/Catalog';
import { FlexRow } from 'templates/FlexRow';
import './utils/Card.css';

const CatalogPaintsPage = () => {
  const [key, setKey] = useState<string>('ral');

  const handleKey = (k: string) => setKey(k);
  return (
    <PageTemplate>
      <PageHeader title={<StyledH1>Lakiery</StyledH1>} />
      <Tabs defaultActiveKey={key} onChange={handleKey} size="large">
        <Tabs.TabPane key="ral" tab="RAL">
          {key === 'ral' && <ColorsCards colors={RAL} />}
        </Tabs.TabPane>
        <Tabs.TabPane key="ncs" tab="NCS">
          {key === 'ncs' && <ColorsCards colors={NCS} />}
        </Tabs.TabPane>
        <Tabs.TabPane key="ica" tab="ICA">
          {key === 'ica' && <ColorsCards colors={ICA} />}
        </Tabs.TabPane>
      </Tabs>
    </PageTemplate>
  );
};

export default CatalogPaintsPage;

interface ColorsCardsT {
  colors: PaintT[];
}
const ColorsCards: React.FC<ColorsCardsT> = ({ colors }) => {
  return (
    <FlexRow justify="space-between" wrap>
      {colors &&
        colors.map((color: PaintT) => (
          <ColorCard key={color.name} color={color} />
        ))}
    </FlexRow>
  );
};

const ColorCard = ({ color }: { color: PaintT }) => {
  return (
    <div
      className="colorCard"
      color={color.hex}
      style={{ backgroundColor: color.hex }}
    >
      <div className="cardCaption">{color.name}</div>
    </div>
  );
};
