import React from 'react';
import { Typography } from 'antd';
import { EquipmentT } from 'services/store/types/projects/Projects';

const { Title } = Typography;

interface IProjectEquipmentProps {
  projectId: string;
  equipment?: EquipmentT[];
}

const Equipment: React.FC<IProjectEquipmentProps> = ({
  projectId,
  equipment,
}) => {
  return (
    <div>
      <Title level={4}>Lista sprzętu</Title>
      <ul>
        {equipment?.map((item: EquipmentT) => {
          return (
            <li key={item.id}>
              <strong>{item.name} </strong>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Equipment;
