import { PermissionsT } from 'services/store/types/auth/Auth';
import { FileT, ProjectT } from 'services/store/types/projects/Projects';
import { perms } from 'services/utils/const';
import moment from 'moment';

export const isStatusChangeAvailable = (
  perm: PermissionsT,
  project: ProjectT,
  files: FileT[] | undefined
) => {
  const errors = [];
  if (perm === perms.DESIGNER) {
    if (project.status === 'projekt') {
      if (!project.measureWeek) errors.push('Wyznacz tydzień pomiaru');
      if (!project.montageWeek) errors.push('Wyznacz tydzień montażu');
      if (files && files.length < 3) errors.push('Pobierz dokumenty');
      else {
        if (files)
          files.forEach((file: FileT) => {
            if (file.name.includes('Skan umowy') && !file.path)
              errors.push('Dodaj skan umowy');
            else if (file.name.includes('Z1') && !file.path)
              errors.push('Dodaj plik projektu');
            else if (file.name.includes('Z2') && !file.path)
              errors.push('Dodaj skan wykazu sprzętu');
            else if (file.name.includes('Z3') && !file.path)
              errors.push('Dodaj skan warunków montażu');
            else if (file.name.includes('Z4') && !file.path)
              errors.push('Dodaj skan RODO');
            else if (file.name.includes('Z5') && !file.path)
              errors.push('Dodaj skan zgody na otrzymywanie faktur');
          });
      }
    }
  } else if (perm === perms.TECHNOLOGIST) {
    if (project.status === 'technolog') {
      const isDrawings =
        files && files.find((file: FileT) => file.name.includes('Technolog'));
      if (!project.measureTerm) errors.push('Wyznacz datę pomiaru');
      if (project.measureTerm)
        if (
          moment().hour(0) < moment(project.measureTerm.startDate).hour(0) &&
          !isDrawings
        ) {
          errors.push('Pomiar się nie odbył');
        }

      if (!project.montageTerm) errors.push('Wyznacz datę montażu');

      if (!isDrawings) errors.push('Dodaj plik z rysunkami');
      else
        files &&
          files.forEach((file: FileT) => {
            if (file.name.includes('Technolog') && !file.path)
              errors.push('Dodaj plik z rysunkami');
          });

      const isMeasureImg =
        files &&
        files.find(
          (file: FileT) => file.type === 'img' && file.imgType === 'measure'
        );
      if (!isMeasureImg) errors.push('Dodaj zdjęcia z pomiaru');
    }
  } else if (perm === perms.INSTALLER) {
    const isPickUpReport =
      files && files.find((file: FileT) => file.name.includes('protokołu'));
    if (!isPickUpReport) errors.push('Dodaj skan protokołu odbioru');

    const isMontageImg =
      files &&
      files.find(
        (file: FileT) => file.type === 'img' && file.imgType === 'montage'
      );
    if (!isMontageImg) errors.push('Dodaj zdjęcia z montażu');
  }

  return errors;
};

export const filesDisabledErrors = (project: ProjectT) => {
  const { montageAddress, Customer, price, montageWeek } = project;

  const errors: string[] = [];
  if (!montageWeek || !montageWeek.date) errors.push('Wyznacz tydzień montażu');
  if (!montageAddress) errors.push('Dodaj adres montażu');
  else if (!montageAddress.city || !montageAddress.street)
    errors.push('Nieprawidłowy adres montażu');

  if (!price) errors.push('Brak ceny');

  //   if (!Customer.pesel && !Customer.nip)
  //     errors.push('Uzupełnij dane klienta (PESEL/NIP)');
  if (
    !Customer.CustomerAddress ||
    !Customer.CustomerAddress.city ||
    !Customer.CustomerAddress.street
  )
    errors.push('Uzupełnij dane klienta (adres zamieszkania)');

  return errors;
};
