import { Col, Row } from 'antd';
import React from 'react';
import { HistoryItemT, ProjectT } from 'services/store/types/projects/Projects';
import HistoryList from './HistoryList';

interface PropsT {
  history: HistoryItemT[] | undefined;
}

const ProjectHistory: React.FC<PropsT> = ({ history }) => {
  return (
    <Row>
      <Col md={6} sm={12}>
        <HistoryList
          title="Historia zmian"
          list={
            history?.filter((item: HistoryItemT) => item.type === 'updates') ||
            []
          }
        />
      </Col>
      <Col md={6} sm={12}>
        <HistoryList
          title="Historia produkcji"
          list={
            history?.filter(
              (item: HistoryItemT) => item.type === 'production'
            ) || []
          }
        />
      </Col>
    </Row>
  );
};

export default ProjectHistory;
