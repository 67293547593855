/* eslint-disable import/no-anonymous-default-export */
import { message } from 'antd';
import { cutString } from './string';

/* eslint-disable no-console */
export default (error: any, onError?: (msg?: any) => void) => {
  if (error.response) {
    console.error('RESPONSE STATUS: ', error.response.status);
    console.error('RESPONSE DATA: ', error.response.data);
    console.error('RESPONSE HEADERS: ', error.response.headers);
    if (error.response.data.message)
      message.error(cutString(error.response.data.message, 100));
    if (error.response.data.error)
      if (onError) return onError(error.response.data.error);
    if (error.response.data.message)
      if (onError) return onError(error.response.data.message);

    if (error.response.status === 401) return window.location.reload();
  }
  if (error.request) {
    console.error('REQUEST: ', error, error.request);
  } else {
    console.error('ERROR: ', error, error.message);
  }
  if (onError) return onError();
  return null;
};
