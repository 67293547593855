import React from 'react';
import permission from 'context/PermissionContext';

const withPermission = (Component: React.FC<any>) => {
  return function contextComponent(props: any) {
    return (
      <permission.Consumer>
        {(context) => <Component {...props} permission={context} />}
      </permission.Consumer>
    );
  };
};

export default withPermission;
