import React from 'react';
import { Typography, Timeline } from 'antd';
import { HistoryItemT } from 'services/store/types/projects/Projects';
import { dateToString } from 'services/utils/date';

const { Title } = Typography;

interface PropsT {
  title: string;
  list: HistoryItemT[];
}

const HistoryList: React.FC<PropsT> = ({ title, list }) => {
  return (
    <div>
      <Title level={4}>{title}</Title>
      <Timeline>
        {list.map((item: HistoryItemT) => {
          return (
            <Timeline.Item color={item.color} key={item.id}>
              <strong>{dateToString(item.createdAt)} - </strong>
              <span>{item.description} </span>
              <small>
                {item.User
                  ? `${item.User.firstname} ${item.User.surname}`
                  : 'BRAK'}
              </small>
            </Timeline.Item>
          );
        })}
      </Timeline>
    </div>
  );
};

export default HistoryList;
