import * as yup from 'yup';

export const elementSchema = yup.object({
  width: yup
    .number()
    .typeError('Uzupełnij')
    .moreThan(0, 'Uzupełnij')
    .required(),
  height: yup
    .number()
    .typeError('Uzupełnij')
    .moreThan(0, 'Uzupełnij')
    .required(),
  thickness: yup
    .number()
    .typeError('Uzupełnij')
    .moreThan(0, 'Uzupełnij')
    .required(),
  defaultQty: yup
    .number()
    .typeError('Uzupełnij')
    .moreThan(0, 'Uzupełnij')
    .required(),
});
