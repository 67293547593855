/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Empty, Modal, PageHeader, Space, Statistic } from 'antd';
import PageTemplate from 'templates/PageTemplate';
import { StyledH1 } from 'components/Header';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateT } from 'services/store';
import { setSpinner } from 'services/store/actions/view';
import { getPricingData } from 'services/store/actions/projects';
import IconDivider from 'components/IconDivider';
import {
  ApiOutlined,
  BorderOutlined,
  BuildOutlined,
  CheckOutlined,
  DatabaseOutlined,
  ExclamationCircleOutlined,
  FileOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import withPermission from 'hoc/withPermission';
import { PermissionsT } from 'services/store/types/auth/Auth';
import {
  pricingSetAccessories,
  pricingSetElements,
  pricingEditLists,
  pricingSetPrice,
  pricingClearData,
} from 'services/store/actions/pricing';
import { FlexRow } from 'templates/FlexRow';
import { useModal } from 'services/hooks/useModal';
import CabinetSegments from 'components/Pricing/Segments';
import { PatternTypeT } from 'services/store/types/catalog/Catalog';
import { getManyCatalogPatterns } from 'services/store/actions/catalog';
import PricingProjectInfo from './components/PricingProjectInfo';
import PricingPatterns from './components/PricingPatterns';
import PricingElements from './components/Elements';
import PricingAccessories from './components/Accessories';
import {
  getAccessoriesPrice,
  getCabinetsPrice,
  optymizeAccessoriesList,
  optymizeElementsList,
} from './components/utils';
import { validatePricing } from './utils/validate';
import CreatePricingModal from './components/CreatePricingModal';

interface PropsT {
  permission: PermissionsT;
}

const ProjectPricingPage: React.FC<PropsT> = ({ permission }) => {
  const params: { id: string } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const { modal, showModal, closeModal } = useModal({ createPricing: false });

  const pricing = useSelector((state: AppStateT) => state.pricing);

  const [price, setPrice] = useState<{ cabinets: number; accessories: number }>(
    {
      cabinets: 0,
      accessories: 0,
    }
  );

  const frontsPatterns = useSelector(
    (state: AppStateT) => state.catalog.fronts
  );
  const boardsPatterns = useSelector(
    (state: AppStateT) => state.catalog.boards
  );
  const veneersPatterns = useSelector(
    (state: AppStateT) => state.catalog.veneers
  );

  useEffect(() => {
    const types: PatternTypeT[] = [];
    if (!frontsPatterns.length) types.push('fronts');
    if (!boardsPatterns.length) types.push('boards');
    if (!veneersPatterns.length) types.push('veneers');

    if (pricing.ProjectId !== params.id) {
      dispatch(setSpinner(true));
      dispatch(getPricingData(params.id, types));
    } else if (types.length) {
      dispatch(setSpinner(true));
      dispatch(getManyCatalogPatterns(types));
    }
    return () => {
      dispatch(pricingClearData());
    };
  }, []);

  useEffect(() => {
    const cabinets = getCabinetsPrice(pricing.Segments);
    const accessories = getAccessoriesPrice(pricing.PricingAccessories);
    setPrice({ ...price, cabinets, accessories });
    dispatch(pricingSetPrice(cabinets + accessories));
  }, [pricing.Segments, pricing.PricingAccessories]);

  const handleClearData = () => dispatch(pricingClearData());

  const handleEditLists = () => {
    Modal.confirm({
      title: 'Czy chcesz kontynuować',
      icon: <ExclamationCircleOutlined />,
      content:
        'Po wykonaniu tego kroku, edycja wzorów, segmentów, i korpusów będzie zablokowana.',
      onOk() {
        dispatch(pricingEditLists(true));
      },
      onCancel() {},
    });
  };

  const optymizationList = (type: 'accessories' | 'elements') => {
    if (type === 'elements') {
      const elements = optymizeElementsList(pricing.PricingElements);
      dispatch(pricingSetElements(elements));
    } else if (type === 'accessories') {
      const accessories = optymizeAccessoriesList(pricing.PricingAccessories);
      dispatch(pricingSetAccessories(accessories));
    }
  };

  const handleCreatePricing = async () => {
    const errors = validatePricing(pricing);
    if (errors.length) {
      return Modal.error({
        maskClosable: true,
        title: 'Błąd',
        content: (
          <ul>
            {errors.map((err: string) => {
              return <li key={err}>{err}</li>;
            })}
          </ul>
        ),
      });
    }
    return showModal('createPricing');
  };

  if (pricing.Project)
    return (
      <PageTemplate>
        <PageHeader
          title={
            <StyledH1
              onClick={() => history.push(`/projects/${params.id}`)}
              clickable
            >
              Wycena projektu nr: {pricing.Project.number || '-'}
            </StyledH1>
          }
          subTitle={pricing.Project.name ? `/${pricing.Project.name}/` : ''}
          extra={[
            <Button type="primary" danger onClick={handleClearData} key={1}>
              Wyczyść dane
            </Button>,
          ]}
        />

        <IconDivider icon={<FileOutlined />} title="Informacje o projekcie" />
        <PricingProjectInfo project={pricing.Project} />

        <IconDivider icon={<BuildOutlined />} title="Wzory płyt/frontów" />
        <PricingPatterns
          fronts={frontsPatterns}
          boards={boardsPatterns}
          veneers={veneersPatterns}
          pricing={pricing}
        />

        <IconDivider icon={<DatabaseOutlined />} title="Lista korpusów" />
        <CabinetSegments pricing={pricing} />

        <IconDivider icon={<ApiOutlined />} title="Lista akcesoriów" />
        <FlexRow justify="flex-end" style={{ marginBottom: 5 }}>
          <Space>
            <Button type="primary" onClick={handleEditLists}>
              Edytuj listy
            </Button>
          </Space>
        </FlexRow>
        <PricingAccessories pricing={pricing} />

        <IconDivider icon={<BorderOutlined />} title="Lista elementów" />
        <FlexRow justify="flex-end" style={{ marginBottom: 5 }}>
          <Space>
            <Button type="primary" onClick={handleEditLists}>
              Edytuj listy
            </Button>
          </Space>
        </FlexRow>
        <PricingElements pricing={pricing} />

        <IconDivider
          icon={<CheckOutlined />}
          title="Zakończ"
          orientation="right"
        />
        <FlexRow justify="space-between">
          <Space size="large">
            <Statistic
              title="Cena korpusów z frontami"
              value={price.cabinets}
              suffix="zł"
            />
            <Statistic
              title="Cena acesoriów"
              value={price.accessories}
              suffix="zł"
            />
            <Statistic
              title="Cena projektu"
              value={pricing.price}
              suffix="zł"
            />
          </Space>
          <Button type="primary" size="large" onClick={handleCreatePricing}>
            <SaveOutlined /> Zakończ
          </Button>
        </FlexRow>
        {/* MODALS */}
        {modal.createPricing && (
          <CreatePricingModal
            pricing={pricing}
            closeModal={closeModal}
            visible={modal.createPricing}
          />
        )}
      </PageTemplate>
    );
  return <Empty />;
};

export default withPermission(ProjectPricingPage);
