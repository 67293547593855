import React, { useEffect } from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateT } from 'services/store';
import { setSpinner } from 'services/store/actions/view';
import { getCatalogPatterns } from 'services/store/actions/catalog';
import TablesList from 'components/Catalog/Tables/List';
import { TableT } from 'services/store/types/catalog/Catalog';
import { pricingSetSegmentTable } from 'services/store/actions/pricing';
import { IdType } from 'services/types';

interface PropsT {
  closeModal: () => void;
  visible: boolean;
  segmentIndex: IdType;
}

const AddTableToSegment: React.FC<PropsT> = ({
  closeModal,
  visible,
  segmentIndex,
}) => {
  const dispatch = useDispatch();
  const tables = useSelector((state: AppStateT) => state.catalog.tables);

  useEffect(() => {
    if (!tables.length) {
      dispatch(setSpinner(true));
      dispatch(getCatalogPatterns('tables'));
    }
  }, []);

  const handlePickTable = (table: TableT) => {
    dispatch(pricingSetSegmentTable(segmentIndex, table));
    closeModal();
  };

  return (
    <Modal
      title="Wybierz blat"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      width={900}
    >
      <TablesList tables={tables} actions={{ pick: handlePickTable }} />
    </Modal>
  );
};

export default AddTableToSegment;
