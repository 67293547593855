import React from 'react';
import { PageHeader } from 'antd';
import { StyledH1 } from 'components/Header';
import ActiveProjects from '../components/ActiveProjects';

const CarpenterHomePage = () => {
  return (
    <div>
      <PageHeader title={<StyledH1>Aktywne projekty</StyledH1>} />
      <ActiveProjects />
    </div>
  );
};

export default CarpenterHomePage;
