import React from 'react';
import { UserT } from 'services/store/types/auth/Auth';
import { Descriptions, Skeleton, message } from 'antd';
import withPermission from 'hoc/withPermission';
import { perms } from 'services/utils/const';
import { useDispatch } from 'react-redux';
import { setNewProjectUser } from 'services/store/actions/newProject';
import DesignersSelect from 'components/Users/DesignersSelect';

interface PropsT {
  user: UserT | null;
  users: UserT[];
  permission: string;
  UserId: string;
}

const DesignerData: React.FC<PropsT> = ({
  users,
  user,
  UserId,
  permission,
}) => {
  const dispatch = useDispatch();

  const handleUser = (id: string) => {
    const userData = users.find((item) => item.id === id);
    if (userData) {
      dispatch(setNewProjectUser(userData));
      message.success('Zmieniłeś projektanta');
    }
  };

  if (user)
    return (
      <div>
        {permission === perms.ADMIN && (
          <DesignersSelect
            users={users}
            UserId={UserId}
            handleUser={handleUser}
          />
        )}
        <Descriptions size="small" column={1}>
          <Descriptions.Item label="Imię">
            <strong>{user.firstname}</strong>
          </Descriptions.Item>
          <Descriptions.Item label="Nazwisko">
            <strong>{user.surname}</strong>
          </Descriptions.Item>
          <Descriptions.Item label="E-mail">
            <strong>{user.email}</strong>
          </Descriptions.Item>
          <Descriptions.Item label="Telefon">
            <strong>{user.phone || '-'}</strong>
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  return <Skeleton paragraph={{ rows: 1 }} active={true} />;
};

export default withPermission(DesignerData);
