import { UserT } from '../types/auth/Auth';
import {
  NEWPROJECT_SET_USER,
  SetNewProjectUserT,
  SetNewProjectCustomerT,
  NEWPROJECT_SET_CUSTOMER,
  NEWPROJECT_CLEAR_CUSTOMER,
  ClearCustomerInNewProjectT,
  SetNewProjectFieldT,
  NEWPROJECT_SET_FIELD,
  ClearNewProjectT,
  NEWPROJECT_CLEAR_PROJECT,
} from '../types/newProject/actions';
import { CustomerT } from '../types/customers/Customers';

export const setNewProjectUser = (user: UserT): SetNewProjectUserT => ({
  type: NEWPROJECT_SET_USER,
  user,
});
export const setNewProjectCustomer = (
  customer: CustomerT
): SetNewProjectCustomerT => ({
  type: NEWPROJECT_SET_CUSTOMER,
  customer,
});
export const clearNewProjectCustomer = (): ClearCustomerInNewProjectT => ({
  type: NEWPROJECT_CLEAR_CUSTOMER,
});
export const setNewProjectField = (
  field: string,
  value: any
): SetNewProjectFieldT => ({
  type: NEWPROJECT_SET_FIELD,
  field,
  value,
});
export const clearNewProject = (user: UserT): ClearNewProjectT => ({
  type: NEWPROJECT_CLEAR_PROJECT,
  user,
});
