import React from 'react';
import { ProjectT } from 'services/store/types/projects/Projects';
import { Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import {
  updateProjectById,
  projectUpdateTypes,
} from 'services/store/actions/projects';
import { perms } from 'services/utils/const';
import withPermission from 'hoc/withPermission';
import { PermissionsT } from 'services/store/types/auth/Auth';
import styled from 'styled-components';

const TextWrapper = styled.div`
  text-align: center;
  font-size: 20px;
`;

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  project: ProjectT;
  permission: PermissionsT;
}

const EndMontage: React.FC<PropsT> = ({
  visible,
  closeModal,
  project,
  permission,
}) => {
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    dispatch(
      updateProjectById(
        projectUpdateTypes.MONTAGE_END,
        project.id,
        {},
        'Montaż zakończony',
        () => closeModal()
      )
    );
  };
  return (
    <Modal
      title="Zmiana statusu"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      onOk={handleSubmit}
    >
      <TextWrapper>
        {permission === perms.ADMIN && 'Zakończ projekt'}
        {permission === perms.INSTALLER && 'Zgłoś zakończenie montażu'}
      </TextWrapper>
    </Modal>
  );
};

export default withPermission(EndMontage);
