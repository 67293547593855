import { IdType } from 'services/types';
import { PatternT, PatternTypeT, TableT } from '../types/catalog/Catalog';
import {
  PricingAddSegmentCabinetT,
  PricingAddPatternT,
  PricingRemovePatternT,
  PricingRemoveSegmentCabinetT,
  PricingSetPriceT,
  PricingSetProjectT,
  PricingSetSegmentCabinetsT,
  PricingUpdatePatternT,
  PRICING_ADD_SEGMENT_CABINET,
  PRICING_REMOVE_SEGMENT_CABINET,
  PRICING_SET_PRICE,
  PRICING_SET_PROJECT,
  PRICING_SET_ORDER_SEGMENT_CABINETS,
  PRICING_ADD_PATTERN,
  PRICING_REMOVE_PATTERN,
  PRICING_UPDATE_PATTERN,
  PricingAddSegmentT,
  PRICING_ADD_SEGMENT,
  PricingRemoveSegmentT,
  PRICING_REMOVE_SEGMENT,
  PricingUpdateSegmentCabinetT,
  PRICING_UPDATE_SEGMENT_CABINET,
  PricingAddElementsT,
  PRICING_ADD_ELEMENTS,
  PricingRemoveElementT,
  PRICING_REMOVE_ELEMENTS,
  PricingAddAccessoriessT,
  PRICING_ADD_ACCESSORIES,
  PricingRemoveAccessoryT,
  PRICING_REMOVE_ACCESSORY,
  PricingEditSegmentNameT,
  PRICING_EDIT_SEGMENT_NAME,
  PRICING_EDIT_LISTS,
  PricingEditListsT,
  PricingElementsQtyT,
  PRICING_ELEMENTS_QTY,
  PRICING_ACCESSORIES_QTY,
  PricingAccessoriessQtyT,
  PricingSetSegmentTableT,
  PRICING_SET_SEGMENT_TABLE,
  PricingClearDataT,
  PRICING_CLEAR_DATA,
  PricingSetPricingToEditT,
  PRICING_SET_PRICING_TO_EDIT,
} from '../types/pricing/actions';
import {
  PricingAccessoryT,
  PricingElementT,
  PricingPatternT,
  PricingPatternTypeT,
  PricingT,
  SegmentCabinetT,
  SegmentT,
} from '../types/pricing/Pricing';
import { ProjectT } from '../types/projects/Projects';

export const pricingSetPricing = (
  pricing: PricingT
): PricingSetPricingToEditT => ({
  type: PRICING_SET_PRICING_TO_EDIT,
  pricing,
});
export const pricingClearData = (): PricingClearDataT => ({
  type: PRICING_CLEAR_DATA,
});
export const pricingSetPrice = (price: number): PricingSetPriceT => ({
  type: PRICING_SET_PRICE,
  price,
});
export const pricingSetProject = (Project: ProjectT): PricingSetProjectT => ({
  type: PRICING_SET_PROJECT,
  Project,
});
export const pricingEditSegmentName = (
  segmentIndex: number,
  name: string
): PricingEditSegmentNameT => ({
  type: PRICING_EDIT_SEGMENT_NAME,
  segmentIndex,
  name,
});
export const pricingEditLists = (edit: boolean): PricingEditListsT => ({
  type: PRICING_EDIT_LISTS,
  edit,
});
export const pricingSetSegmentTable = (
  segmentIndex: IdType,
  table: TableT | undefined
): PricingSetSegmentTableT => ({
  type: PRICING_SET_SEGMENT_TABLE,
  segmentIndex,
  table,
});

// PATTERN---------------------------
export const pricingAddPattern = (
  item: PricingPatternT
): PricingAddPatternT => ({
  type: PRICING_ADD_PATTERN,
  item,
});
export const pricingRemovePattern = (
  pattern: PricingPatternT,
  patternType: PricingPatternTypeT
): PricingRemovePatternT => ({
  type: PRICING_REMOVE_PATTERN,
  pattern,
  patternType,
});
export const pricingUpdatePattern = (
  item: PatternT
): PricingUpdatePatternT => ({
  type: PRICING_UPDATE_PATTERN,
  item,
});

// ELEMENTS---------------
export const pricingSetElements = (
  elements: PricingElementT[]
): PricingAddElementsT => ({
  type: PRICING_ADD_ELEMENTS,
  elements,
});
export const pricingRemoveElement = (index: number): PricingRemoveElementT => ({
  type: PRICING_REMOVE_ELEMENTS,
  index,
});
export const pricingElementsQty = (
  index: number,
  value: number
): PricingElementsQtyT => ({
  type: PRICING_ELEMENTS_QTY,
  index,
  value,
});

// ACCESSORIES---------------
export const pricingSetAccessories = (
  accessories: PricingAccessoryT[]
): PricingAddAccessoriessT => ({
  type: PRICING_ADD_ACCESSORIES,
  accessories,
});
export const pricingRemoveAccessory = (
  index: number
): PricingRemoveAccessoryT => ({
  type: PRICING_REMOVE_ACCESSORY,
  index,
});
export const pricingAccessoriesQty = (
  index: number,
  value: number
): PricingAccessoriessQtyT => ({
  type: PRICING_ACCESSORIES_QTY,
  index,
  value,
});

// SEGMENT----------------
export const pricingAddSegment = (segment: SegmentT): PricingAddSegmentT => ({
  type: PRICING_ADD_SEGMENT,
  segment,
});
export const pricingRemoveSegment = (index: number): PricingRemoveSegmentT => ({
  type: PRICING_REMOVE_SEGMENT,
  index,
});

// SEGMENT CABINETS--------------------
export const pricingAddSegmentCabinet = (
  index: number,
  cabinet: SegmentCabinetT
): PricingAddSegmentCabinetT => ({
  type: PRICING_ADD_SEGMENT_CABINET,
  index,
  cabinet,
});
export const pricingRemoveSegmentCabinet = (
  segmentIndex: number,
  cabinetIndex: number
): PricingRemoveSegmentCabinetT => ({
  type: PRICING_REMOVE_SEGMENT_CABINET,
  segmentIndex,
  cabinetIndex,
});
export const pricingUpdateSegmentCabinet = (
  segmentIndex: number,
  cabinetIndex: number,
  cabinet: SegmentCabinetT
): PricingUpdateSegmentCabinetT => ({
  type: PRICING_UPDATE_SEGMENT_CABINET,
  segmentIndex,
  cabinetIndex,
  cabinet,
});

// DRAG AND DROP ORDER
export const pricingSetSegmentCabinetsOrder = (
  segmentIndex: number,
  cabinets: SegmentCabinetT[]
): PricingSetSegmentCabinetsT => ({
  type: PRICING_SET_ORDER_SEGMENT_CABINETS,
  segmentIndex,
  cabinets,
});
