import React from 'react';
import { Menu } from 'antd';
import {
  UserSwitchOutlined,
  UserDeleteOutlined,
  LockOutlined,
} from '@ant-design/icons';
import { TUserSettingsModal } from '..';
import useUser from 'hooks/useUser';

interface IProps {
  showModal: (modal: TUserSettingsModal) => void;
}

const UserSettingsSider: React.FC<IProps> = ({ showModal }) => {
  const { user, isAdmin } = useUser();

  return (
    <>
      <Menu mode="inline">
        <Menu.Item
          onClick={() => showModal('dataChange')}
          icon={<UserSwitchOutlined />}
          disabled={!user}
        >
          Edytuj dane
        </Menu.Item>
        <Menu.Item
          onClick={() => showModal('passwordChange')}
          icon={<LockOutlined />}
          disabled={!user}
        >
          Zmień hasło
        </Menu.Item>
        <Menu.Item
          onClick={() => showModal('userRemove')}
          icon={<UserDeleteOutlined />}
          disabled={!user || isAdmin()}
          danger
        >
          Usuń konto
        </Menu.Item>
      </Menu>
    </>
  );
};

export default UserSettingsSider;
