import { message, PageHeader } from 'antd';
import PatternsList from 'components/Catalog/Patterns/List';
import VeneersList from 'components/Catalog/Veneers/List';
import { StyledH1 } from 'components/Header';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NewPatternT, NewVeneerT } from 'services/api/catalog/post';
import { removePattern, removeVeneer } from 'services/api/catalog/remove';
import { updatePattern, updateVeneer } from 'services/api/catalog/update';
import { AppStateT } from 'services/store';
import {
  catalogRemoveFromList,
  catalogUpdateInList,
  getCatalogPatterns,
} from 'services/store/actions/catalog';
import { setSpinner } from 'services/store/actions/view';
import { PatternT, VeneerT } from 'services/store/types/catalog/Catalog';
import PatternModal from 'siders/Catalog/components/PatternModal';
import VeneerModal from 'siders/Catalog/components/VeneerModal';
import PageTemplate from 'templates/PageTemplate';

const CatalogVeneersPage = () => {
  const dispatch = useDispatch();
  const veneers = useSelector((state: AppStateT) => state.catalog.veneers);

  const [toUpdate, setToUpdate] = useState<VeneerT | null>(null);

  useEffect(() => {
    if (!veneers.length) {
      dispatch(setSpinner(true));
      dispatch(getCatalogPatterns('veneers'));
    }
  }, []);

  const handleRemove = async (veneer: VeneerT) => {
    if (veneer.id) {
      const { id } = veneer;
      dispatch(setSpinner(true));
      await removeVeneer(
        id,
        () => {
          dispatch(setSpinner(false));
          dispatch(catalogRemoveFromList('veneers', id));
          message.success('Wzór usunięty');
        },
        () => {
          dispatch(setSpinner(false));
        }
      );
    }
  };
  const handleUpdate = async (veneer: NewVeneerT) => {
    if (veneer.id) {
      dispatch(setSpinner(true));
      await updateVeneer(
        veneer,
        (updatedVeneer) => {
          dispatch(setSpinner(false));
          dispatch(catalogUpdateInList('veneers', updatedVeneer));
          message.success('Obrzeże  zaktualizowane');
          setToUpdate(null);
        },
        () => {
          dispatch(setSpinner(false));
        }
      );
    }
  };

  return (
    <PageTemplate>
      <PageHeader title={<StyledH1>Obrzeża</StyledH1>} />
      <VeneersList
        veneers={veneers}
        actions={{
          update: (veneer: VeneerT) => setToUpdate(veneer),
          remove: handleRemove,
        }}
      />

      {/* MODALS */}
      {toUpdate && (
        <VeneerModal
          title="Edycja obrzeża"
          initData={toUpdate}
          closeModal={() => setToUpdate(null)}
          visible={!!toUpdate}
          customHandleSubmit={handleUpdate}
        />
      )}
    </PageTemplate>
  );
};

export default CatalogVeneersPage;
