import React from 'react';
import { Empty, Image, Popconfirm, Typography } from 'antd';
import {
  PricingPatternT,
  SegmentCabinetT,
} from 'services/store/types/pricing/Pricing';
import { domain } from 'services/utils/const';
import { cutString } from 'services/utils/string';
import styled from 'styled-components';
import { FlexRow } from 'templates/FlexRow';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Draggable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { pricingRemoveSegmentCabinet } from 'services/store/actions/pricing';
import { useModal } from 'services/hooks/useModal';
import imgPlaceholder from 'assets/images/placeholder.png';
import { getFrontPrice } from 'scenes/ProjectPricing/components/utils';
import Img from 'components/Image';
import SegmentCabinetModal from './SegmentCabinetModal';

const Wrapper = styled.div`
  min-width: 250px !important;
  border-radius: 3px;
  border: 1px solid #e9e9e9;
  margin: 5px;
  padding: 5px;
`;

const initModal = {
  editCabinet: false,
};

interface PropsT {
  cabinet: SegmentCabinetT;
  cabinetIndex: number;
  segmentIndex: number;
  disabled?: boolean;
  previewOnly: boolean;
}

const SegmentCabinet: React.FC<PropsT> = ({
  cabinet,
  cabinetIndex,
  segmentIndex,
  disabled,
  previewOnly,
}) => {
  const { modal, showModal, closeModal } = useModal(initModal);
  const dispatch = useDispatch();
  const { Cabinet, FrontsConfig, BodyPattern, FrontPattern } = cabinet;

  const handleRemoveCabinet = () =>
    dispatch(pricingRemoveSegmentCabinet(segmentIndex, cabinetIndex));

  if (Cabinet && BodyPattern)
    return (
      <>
        <Draggable
          draggableId={`${cabinetIndex}`}
          index={cabinetIndex}
          isDragDisabled={previewOnly}
        >
          {(provided: any, snapshot: any) => {
            return (
              <Wrapper
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                isDragging={snapshot.isDragging}
              >
                {/* KORPUS */}
                <div>
                  <FlexRow justify="space-between">
                    <Typography.Title level={5} style={{ marginBottom: 0 }}>
                      Korpus
                    </Typography.Title>
                    <div>
                      <Typography.Text code>{Cabinet.price}zł</Typography.Text>
                    </div>
                  </FlexRow>
                  <FlexRow justify="flex-start">
                    <Img
                      path={Cabinet.miniaturePath}
                      previewPath={Cabinet.imgPath}
                      isExist={Cabinet}
                    />

                    <div>
                      <div>
                        <Typography.Text>{Cabinet.symbol}</Typography.Text>
                      </div>
                      <div>
                        <Typography.Text
                          disabled
                          title={Cabinet.CabinetType.name}
                        >
                          {cutString(Cabinet.CabinetType.name, 30)}
                        </Typography.Text>
                      </div>
                    </div>
                  </FlexRow>
                  <div>
                    <Typography.Text disabled>Wys: </Typography.Text>
                    {Cabinet.height}
                    <Typography.Text disabled>/Szer: </Typography.Text>
                    {Cabinet.width}
                    <Typography.Text disabled>/Głęb: </Typography.Text>
                    {Cabinet.depth}
                  </div>
                  <hr style={{ margin: 1 }} />
                </div>
                <div>
                  {/* PŁYTA KORPUSU */}
                  <Typography.Title level={5} style={{ marginBottom: 0 }}>
                    Płyta
                  </Typography.Title>

                  <FlexRow justify="flex-start">
                    <Img
                      path={BodyPattern.Pattern?.miniaturePath}
                      previewPath={BodyPattern.Pattern?.imgPath}
                      isExist={BodyPattern.Pattern}
                    />
                    {BodyPattern.Veneer && (
                      <Img
                        path={BodyPattern.Veneer.miniaturePath}
                        previewPath={BodyPattern.Veneer.imgPath}
                        isExist={BodyPattern.Veneer}
                      />
                    )}

                    <div>
                      <div>
                        <Typography.Text>
                          {BodyPattern.Pattern?.symbol}/{' '}
                          {BodyPattern.Veneer?.symbol || '-'}
                        </Typography.Text>
                      </div>
                      <div>
                        <Typography.Text
                          disabled
                          title={`${BodyPattern.Pattern?.name}/${BodyPattern.Veneer?.name}`}
                        >
                          {cutString(BodyPattern.Pattern?.name, 20)}/
                          {cutString(BodyPattern.Veneer?.name, 20)}
                        </Typography.Text>
                      </div>
                    </div>
                  </FlexRow>
                  <hr style={{ margin: 1 }} />
                </div>
                <div style={{ minHeight: 170 }}>
                  <Typography.Title level={5} style={{ marginBottom: 0 }}>
                    Front
                  </Typography.Title>

                  {FrontPattern && (
                    <>
                      <FlexRow justify="flex-start">
                        <Img
                          path={FrontPattern.Pattern?.miniaturePath}
                          previewPath={FrontPattern.Pattern?.imgPath}
                          isExist={FrontPattern.Pattern}
                        />
                        {FrontPattern.Veneer && (
                          <Img
                            path={FrontPattern.Veneer.miniaturePath}
                            previewPath={FrontPattern.Veneer.imgPath}
                            isExist={FrontPattern.Veneer}
                          />
                        )}
                        <div>
                          <div>
                            <Typography.Text>
                              {FrontPattern.Pattern?.symbol}/{' '}
                              {FrontPattern.Veneer?.symbol || '-'}
                            </Typography.Text>
                          </div>
                          <div>
                            <Typography.Text
                              disabled
                              title={`${FrontPattern.Pattern?.name}/${FrontPattern.Veneer?.name}`}
                            >
                              {cutString(FrontPattern.Pattern?.name, 20)}/{' '}
                              {cutString(FrontPattern.Veneer?.name, 20)}
                            </Typography.Text>
                          </div>
                        </div>
                      </FlexRow>
                      <div>
                        <PaintPreview pattern={FrontPattern} />
                      </div>
                    </>
                  )}

                  {FrontsConfig ? (
                    <div style={{ backgroundColor: '#f5f5f5', padding: 2 }}>
                      <div>
                        <Typography.Text>
                          {FrontsConfig.name || '-'}
                        </Typography.Text>
                      </div>
                      <div>
                        <Typography.Text disabled style={{ fontSize: 12 }}>
                          {cutString(FrontsConfig.description, 30)}
                        </Typography.Text>
                      </div>
                      <FlexRow justify="space-between">
                        <div>
                          {FrontsConfig.Elements.reduce((acc, item: any) => {
                            const newAcc = acc + item.defaultQty;
                            return newAcc;
                          }, 0)}{' '}
                          elem.
                        </div>
                        <Typography.Text code>
                          {getFrontPrice(cabinet)}zł
                        </Typography.Text>
                      </FlexRow>
                    </div>
                  ) : (
                    <Empty />
                  )}
                </div>
                <hr />
                <FlexRow justify="space-between">
                  <Typography.Title level={5} style={{ margin: 0 }}>
                    Cena: {cabinet.price}zł
                  </Typography.Title>
                  {!previewOnly && (
                    <div>
                      <EditOutlined
                        disabled={disabled}
                        onClick={() => !disabled && showModal('editCabinet')}
                        title="Edytuj"
                        style={
                          !disabled
                            ? { color: 'blue', cursor: 'pointer', margin: 5 }
                            : { color: 'gray', margin: 5, cursor: 'default' }
                        }
                      />
                      <Popconfirm
                        disabled={disabled}
                        title="Usuń element"
                        onConfirm={handleRemoveCabinet}
                      >
                        <DeleteOutlined
                          disabled={disabled}
                          title="Usuń"
                          style={
                            !disabled
                              ? { color: 'red', cursor: 'pointer', margin: 5 }
                              : { color: 'gray', margin: 5, cursor: 'default' }
                          }
                        />
                      </Popconfirm>
                    </div>
                  )}
                </FlexRow>
              </Wrapper>
            );
          }}
        </Draggable>

        {/* MDOALS */}
        {/* NOTE Edit Cabinet Modal */}
        {modal.editCabinet && (
          <SegmentCabinetModal
            closeModal={closeModal}
            visible={modal.editCabinet}
            segmentIndex={segmentIndex}
            cabinetIndex={cabinetIndex}
            cabinetOrder={cabinet.order}
            initValues={cabinet}
          />
        )}
      </>
    );
  return <Empty />;
};

export default SegmentCabinet;

const PaintPreview = ({ pattern }: { pattern: PricingPatternT }) => {
  if (pattern)
    return (
      <div
        style={{
          padding: 4,
          backgroundColor: pattern.Paint?.hex || 'white',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <div
          style={{
            backgroundColor: 'rgba(255,255,255,0.4)',
            fontSize: 10,
          }}
        >
          {pattern.Paint
            ? `${pattern.Paint.name}-${pattern.Paint.type}`
            : 'Laminat'}
        </div>
      </div>
    );
  return null;
};
