/* eslint-disable @typescript-eslint/default-param-last */
import moment from 'moment';
import update from 'immutability-helper';
import {
  OrdersActionsT,
  ORDERS_LOAD_ERROR,
  ORDERS_LOAD_SUCCESS,
  ORDERS_SET_FILTER_FIELD,
  ORDERS_SET_ORDER,
} from '../types/orders/actions';
import { OrdersT } from '../types/orders/Orders';

const initialState: OrdersT = {
  active: [],
  ended: [],
  order: null,
  filters: {
    ended: {
      dates: [
        moment().hour(0).minutes(0).subtract(1, 'months'),
        moment().hour(0).minutes(0),
      ],
    },
  },
};

const ordersReducer = (
  state = initialState,
  action: OrdersActionsT
): OrdersT => {
  switch (action.type) {
    case ORDERS_LOAD_SUCCESS: {
      return { ...state, [action.list]: action.orders };
    }
    case ORDERS_LOAD_ERROR: {
      return { ...state, [action.list]: [] };
    }
    case ORDERS_SET_ORDER: {
      return { ...state, order: action.order };
    }
    case ORDERS_SET_FILTER_FIELD: {
      return update(state, {
        filters: {
          [action.list]: {
            [action.field]: {
              $set: action.value,
            },
          },
        },
      });
    }
    default:
      return state;
  }
};

export { ordersReducer };
