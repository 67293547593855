import React from 'react';
import { useDispatch } from 'react-redux';
import { setNewProjectField } from 'services/store/actions/newProject';
import { Typography, Input, Upload, Button, Row, Col } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload';

interface PropsT {
  comments: string;
  images: RcFile[];
}

const ProjectComments: React.FC<PropsT> = ({ comments, images }) => {
  const dispatch = useDispatch();

  const handleComments = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    dispatch(setNewProjectField('comments', e.target.value));
  const handleAddImage = (file: RcFile, fileList: RcFile[]) => {
    dispatch(setNewProjectField('images', images.concat(fileList)));
    return false;
  };
  const handleRemoveImage = (f: any) => {
    const index = images.indexOf(f);

    const newImages = images.slice();
    newImages.splice(index, 1);
    dispatch(setNewProjectField('images', newImages));
    return false;
  };

  return (
    <Row>
      <Col xs={12} md={6}>
        <Typography.Title level={4}>Uwagi</Typography.Title>
        <Input.TextArea
          placeholder="Uwagi dotyczące projektu"
          rows={4}
          value={comments}
          onChange={handleComments}
        />
      </Col>
      <Col xs={12} md={6}>
        <Typography.Title level={4}>Zdjęcia</Typography.Title>
        <Upload
          fileList={images}
          beforeUpload={handleAddImage}
          onRemove={handleRemoveImage}
          accept="image/*"
          multiple={true}
        >
          <Button icon={<UploadOutlined />}>Dodaj zdjęcie</Button>
        </Upload>
      </Col>
    </Row>
  );
};

export default ProjectComments;
