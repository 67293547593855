import { UserT } from '../auth/Auth';
import { CustomerT } from '../customers/Customers';

export const NEWPROJECT_SET_USER = 'NEWPROJECT_SET_USER';
export const NEWPROJECT_SET_CUSTOMER = 'NEWPROJECT_SET_CUSTOMER';
export const NEWPROJECT_SET_FIELD = 'NEWPROJECT_SET_FIELD';
export const NEWPROJECT_CLEAR_CUSTOMER = 'NEWPROJECT_CLEAR_CUSTOMER';
export const NEWPROJECT_CLEAR_PROJECT = 'NEWPROJECT_CLEAR_PROJECT';

export interface SetNewProjectUserT {
  type: typeof NEWPROJECT_SET_USER;
  user: UserT;
}
export interface SetNewProjectCustomerT {
  type: typeof NEWPROJECT_SET_CUSTOMER;
  customer: CustomerT;
}
export interface ClearCustomerInNewProjectT {
  type: typeof NEWPROJECT_CLEAR_CUSTOMER;
}
export interface SetNewProjectFieldT {
  type: typeof NEWPROJECT_SET_FIELD;
  field: string;
  value: any;
}
export interface ClearNewProjectT {
  type: typeof NEWPROJECT_CLEAR_PROJECT;
  user: UserT;
}

export type NewProjectActionsT =
  | ClearNewProjectT
  | SetNewProjectFieldT
  | SetNewProjectUserT
  | SetNewProjectCustomerT
  | ClearCustomerInNewProjectT;
