import React, { useEffect, useState } from 'react';
import { Table, Popconfirm, Typography } from 'antd';
import TextButton from 'components/TextButton';
import { MailOutlined } from '@ant-design/icons';
import { formatPhoneNumber } from 'services/utils/string';
import EmailMessageModal from 'components/Modals/EmailMessage';
import { SupplierT } from 'services/store/types/suppliers/Suppliers';
import { OrderT } from 'services/store/types/orders/Orders';
import Orders from 'scenes/ProjectOrders/components/Orders';
import { orderStates } from 'services/utils/const';

const columns = [
  {
    title: 'Lp.',
    align: 'center' as const,
    render: (t: string, r: any, i: number) => i + 1,
  },
  {
    title: 'Nazwa',
    dataIndex: 'name',
    align: 'center' as const,
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    align: 'center' as const,
    render: (v: string) => <Typography.Text copyable>{v}</Typography.Text>,
  },
  {
    title: 'Telefon',
    dataIndex: 'phone',
    align: 'center' as const,
  },
  {
    title: 'Czas realizacji',
    dataIndex: 'term',
    align: 'center' as const,
  },
  {
    title: 'Aktywnych zamówień',
    align: 'center' as const,
    render: <T extends { Orders: OrderT[] }>(V: any, R: T) =>
      R.Orders
        ? R.Orders.filter(
            (order: OrderT) =>
              order.status === orderStates.SENDED ||
              order.status === orderStates.PART_PICKED ||
              order.status === orderStates.CREATED
          ).length
        : 0,
  },
  {
    title: 'Wyślij wiadomość',
    dataIndex: 'message',
    align: 'center' as const,
  },
  {
    title: 'Akcje',
    dataIndex: 'actions',
    align: 'center' as const,
  },
];

interface PropsT {
  suppliers: SupplierT[];
  handleRedirect: (supplier: SupplierT) => void;
  handleRemove: (supplier: SupplierT) => void;
  handleUpdate: (supplier: SupplierT) => void;
}
const List: React.FC<PropsT> = ({
  suppliers,
  handleRedirect,
  handleRemove,
  handleUpdate,
}) => {
  const [list, setList] = useState<any[]>([]);
  const [emailAddress, setEmailAddress] = useState('');

  useEffect(() => {
    const newList = suppliers.map((supplier: SupplierT, i: number) => ({
      ...supplier,
      phone: formatPhoneNumber(supplier.phone),
      key: i,
      message: (
        <TextButton
          disabled
          text={<MailOutlined />}
          onClick={() => setEmailAddress(supplier.email)}
        />
      ),
      name: (
        <TextButton
          text={supplier.name}
          onClick={() => handleRedirect(supplier)}
        />
      ),
      orders: '-',
      actions: (
        <div>
          <TextButton text="Edytuj" onClick={() => handleUpdate(supplier)} />
          <Popconfirm
            title="Usunięcie dystrybutora spowoduje skasowanie jego zamówień"
            onConfirm={() => handleRemove(supplier)}
          >
            <TextButton
              text="Usuń"
              onClick={() => {}}
              style={{ marginLeft: 10, color: 'red' }}
            />
          </Popconfirm>
        </div>
      ),
    }));

    setList(newList);
  }, [suppliers]);

  return (
    <div>
      <Table columns={columns} dataSource={list} pagination={false} />

      {/* MODALS */}
      <EmailMessageModal
        visible={!!emailAddress}
        email={emailAddress}
        closeModal={() => setEmailAddress('')}
      />
    </div>
  );
};

export default List;
