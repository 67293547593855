import { Menu, Modal } from 'antd';
import React from 'react';
import {
  MergeCellsOutlined,
  ColumnWidthOutlined,
  DownloadOutlined,
  ForwardOutlined,
  CheckCircleOutlined,
  TableOutlined,
  AppstoreOutlined,
  ApiOutlined,
  AuditOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { states } from 'services/utils/const';
import SubMenu from 'antd/lib/menu/SubMenu';
import { IProjectSiderProps } from './ProjectSider';
import { useHistory } from 'react-router';
import { isStatusChangeAvailable } from './utils';

interface IProps extends IProjectSiderProps {}

const TechnologistSider: React.FC<IProps> = ({
  project,
  showModal,
  getPicingFile,
  files,
}) => {
  const history = useHistory();
  return (
    <>
      <Menu mode="inline">
        <Menu.Item
          key="toProd"
          icon={<ForwardOutlined />}
          onClick={() => {
            const errors = project
              ? isStatusChangeAvailable('technologist', project, files)
              : [];
            if (!errors.length) return showModal('changeStatus');
            return Modal.error({
              maskClosable: true,
              title: 'Błąd zmiany statusu projektu',
              content: (
                <ul>
                  {errors.map((err: string) => {
                    return <li key={err}>{err}</li>;
                  })}
                </ul>
              ),
            });
          }}
          disabled={!project || project.status !== 'technolog'}
        >
          Przekaż na produkcję
        </Menu.Item>
        <Menu.Item
          key="endProd"
          icon={<CheckCircleOutlined />}
          onClick={() => showModal('endProduction')}
          disabled={
            !project || project.status !== 'produkcja' || !project.productionEnd
          }
        >
          Zakończ produkcję
        </Menu.Item>
        <Menu.Divider />

        <Menu.Item
          key="setMeasureDay"
          icon={<ColumnWidthOutlined />}
          disabled={
            !project || !project.measureWeek || project.status !== 'technolog'
          }
          onClick={() =>
            project && history.push(`/projects/schedule/measure/${project.id}`)
          }
        >
          Wyznacz dzień pomiaru
        </Menu.Item>

        <Menu.Item
          key="setMontageDay"
          icon={<MergeCellsOutlined />}
          disabled={!project || !project.montageWeek || !project.measureTerm}
          onClick={() =>
            project && history.push(`/projects/schedule/montage/${project.id}`)
          }
        >
          Wyznacz dzień montażu
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="accessoriesOrders"
          icon={<ShoppingCartOutlined />}
          onClick={() =>
            project && history.push(`/projects/orders/${project.id}`)
          }
          disabled={
            !project ||
            !project.isPriced ||
            [states.CANCEL, states.ENDED].includes(project.status)
          }
        >
          Zamówienia na akcesoria
        </Menu.Item>

        <SubMenu
          key="files"
          icon={<TableOutlined />}
          title="Pobierz pliki"
          disabled={!project || !project.isPriced}
        >
          <Menu.Item
            key="elements"
            icon={<AppstoreOutlined />}
            onClick={() => getPicingFile('elements')}
          >
            Lista elementów konstrukcyjnych
          </Menu.Item>
          <Menu.Item
            key="fronts"
            icon={<AppstoreOutlined />}
            onClick={() => getPicingFile('fronts')}
          >
            Lista frontów
          </Menu.Item>
          <Menu.Item
            key="accessories"
            icon={<ApiOutlined />}
            onClick={() => getPicingFile('accessories')}
          >
            Lista akcesoriów
          </Menu.Item>
          <Menu.Item
            key="orders"
            icon={<AuditOutlined />}
            onClick={() => getPicingFile('orders')}
          >
            Zamówienia
          </Menu.Item>
        </SubMenu>
        <Menu.Divider />
        <Menu.Item
          key="documents"
          icon={<DownloadOutlined />}
          onClick={() => showModal('files')}
        >
          Pobierz dokumenty
        </Menu.Item>
      </Menu>
    </>
  );
};

export default TechnologistSider;
