import { AccessoryT } from 'services/store/types/accesories/Accessories';
import { CabinetAccessoryT } from 'services/store/types/cabinets/Cabinets';

interface AccessoryQtyT {
  Accessory: AccessoryT;
  qty: number;
}

export const getAccessoriesPrice = (accessories: AccessoryQtyT[]) => {
  return accessories.reduce((price: number, accessory: AccessoryQtyT) => {
    const accessoryPrice = accessory.Accessory.price * accessory.qty;
    const newPrice = price + accessoryPrice;
    return newPrice;
  }, 0);
};
export const getCabinetAccessoryQty = (accessories: CabinetAccessoryT[]) => {
  return accessories.reduce(
    (acc: AccessoryQtyT[], accessory: CabinetAccessoryT) => {
      if (accessory.Accessory)
        return [
          ...acc,
          {
            Accessory: accessory.Accessory,
            qty: accessory.defaultQty,
          },
        ];
      return acc;
    },
    []
  );
};
