import React, { useState, useEffect } from 'react';
import { Popconfirm, Table, Typography } from 'antd';
import { useHistory } from 'react-router';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { VeneerT } from 'services/store/types/catalog/Catalog';
import Img from 'components/Image';
import { SupplierT } from 'services/store/types/suppliers/Suppliers';
import { PermissionsT } from 'services/store/types/auth/Auth';
import { perms } from 'services/utils/const';
import withPermission from 'hoc/withPermission';

const columns: any[] = [
  {
    title: 'Lp.',
    align: 'center' as const,
    render: (t: string, r: any, i: number) => i + 1,
  },
  {
    title: 'Zdjęcie',
    dataIndex: 'img',
    align: 'center' as const,
    render: (v: any, r: any) => (
      <Img path={r.miniaturePath} previewPath={r.imgPath} isExist={r.imgPath} />
    ),
  },
  {
    title: 'Nazwa',
    dataIndex: 'name',
    align: 'center' as const,
    defaultSortOrder: 'ascend' as const,
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
    align: 'center' as const,
  },
  {
    title: 'Dystrybutor',
    dataIndex: 'Supplier',
    align: 'center' as const,
    defaultSortOrder: 'ascend' as const,
    sorter: (a: SupplierT, b: SupplierT) => a.name.localeCompare(b.name),
    render: (S: SupplierT) => S.name,
  },
  {
    title: 'Szerokość',
    dataIndex: 'width',
    align: 'center' as const,
  },
  {
    title: 'Grubość',
    dataIndex: 'thickness',
    align: 'center' as const,
  },
  {
    title: 'Cena/m',
    dataIndex: 'price',
    align: 'center' as const,
  },
  {
    title: 'Akcje',
    dataIndex: 'actions',
    align: 'center' as const,
  },
];

interface PropsT {
  veneers: VeneerT[];
  actions?: {
    remove?: (veneer: VeneerT) => void;
    update?: (veneer: VeneerT) => void;
  };
  permission: PermissionsT;
}

const VeneersList: React.FC<PropsT> = ({
  veneers,
  permission,
  actions = undefined,
  ...props
}) => {
  const history = useHistory();
  const [list, setList] = useState<any[]>([]);

  const handleRedirect = (path: string) => history.push(path);

  useEffect(() => {
    const newList = veneers.map((veneer: VeneerT, i: number) => {
      return {
        ...veneer,
        key: veneer.id,
        supplierName: veneer.Supplier ? veneer.Supplier.name : '-',
        actions:
          actions && permission === perms.ADMIN ? (
            <div>
              {actions.update && (
                <EditOutlined
                  title="Edytuj"
                  style={{ color: 'gray', cursor: 'pointer', margin: 5 }}
                  onClick={() => actions.update && actions.update(veneer)}
                />
              )}
              {actions.remove && (
                <Popconfirm
                  title="Usuń element"
                  onConfirm={() => actions.remove && actions.remove(veneer)}
                >
                  <DeleteOutlined
                    title="Usuń"
                    style={{ color: 'red', cursor: 'pointer', margin: 5 }}
                  />
                </Popconfirm>
              )}
            </div>
          ) : (
            <Typography.Text disabled>brak</Typography.Text>
          ),
      };
    });

    setList(newList);
  }, [veneers]);

  return (
    <div>
      <Table
        columns={columns}
        pagination={{ position: ['topRight'] }}
        dataSource={list}
        size="small"
        {...props}
      />
    </div>
  );
};

export default withPermission(VeneersList);
