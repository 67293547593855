import React, { useEffect } from 'react';
import PageTemplate from 'templates/PageTemplate';
import {
  Button,
  PageHeader,
  Upload,
  Modal,
  Typography,
  Table,
  Row,
  Col,
} from 'antd';
import { StyledH1, StyledH4 } from 'components/Header';
import { initCabinetData, NewCabinetT } from 'services/utils/const/initData';
import { Input, Form, InputNumber, Select } from 'formik-antd';
import { useDispatch, useSelector } from 'react-redux';
import { getSettings } from 'services/store/actions/settings';
import { AppStateT } from 'services/store';
import { FieldArray, Formik } from 'formik';
import {
  CheckCircleOutlined,
  SaveOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { FlexRow } from 'templates/FlexRow';
import { setSpinner } from 'services/store/actions/view';
import { getAccessories } from 'services/store/actions/accessories';
import {
  CabinetAccessoryT,
  CabinetT,
} from 'services/store/types/cabinets/Cabinets';
import { CabinetTypeT } from 'services/store/types/settings/Settings';
import IconDivider from 'components/IconDivider';
import { createNewCabinet } from 'services/api/cabinets/post';
import { useHistory, useLocation } from 'react-router';
import {
  getAccessoriesPrice,
  getCabinetAccessoryQty,
} from 'services/utils/pricing';
import CabinetElementsFormList from './components/CabinetElementsFormList';
import CabinetAccessoriesFormList from './components/CabinetAccessoriesFormList';
import CabinetFrontsFormList from './components/CabinetFrontsFromList';
import { cabinetSchema, validateNewCabinet } from './utils/validate';

const { Option } = Select;

const NewCabinetPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation<any>();

  const accessories = useSelector(
    (state: AppStateT) => state.accessories.accessories
  );
  const cabinetTypes = useSelector(
    (state: AppStateT) => state.settings.cabinetTypes
  );

  useEffect(() => {
    // Get accessories
    if (!accessories.length) {
      dispatch(setSpinner(true));
      dispatch(getAccessories());
    }
    // Get cabinet types
    dispatch(getSettings());

    history.replace({ ...history.location, state: {} });
  }, []);

  const handleOk = async (values: NewCabinetT) => {
    const errors = validateNewCabinet(values);
    if (errors.length)
      return Modal.error({
        maskClosable: true,
        title: 'Uzupełnij',
        content: (
          <ul>
            {errors.map((err: string) => (
              <li key={err}>{err}</li>
            ))}
          </ul>
        ),
      });

    const accessoriesPrice = getAccessoriesPrice(
      getCabinetAccessoryQty(values.CabinetAccessories)
    );

    const newCabinet = { ...values };
    if (accessoriesPrice) newCabinet.price -= accessoriesPrice;

    dispatch(setSpinner(true));
    await createNewCabinet(
      newCabinet,
      (cabinet) => {
        history.push(`cabinets/${cabinet.id}`);
      },
      () => {}
    );
    dispatch(setSpinner(false));
    return null;
  };

  return (
    <PageTemplate>
      <Formik
        validationSchema={cabinetSchema}
        onSubmit={handleOk}
        initialValues={
          location.state && location.state.cabinetData
            ? { ...location.state.cabinetData }
            : { ...initCabinetData }
        }
      >
        {(props) => {
          return (
            <Form
              noValidate
              className="form-container"
              id="addCabinetForm"
              onFinish={props.handleSubmit}
            >
              <PageHeader
                title={<StyledH1>Nowy korpus</StyledH1>}
                extra={[
                  <Button
                    key={1}
                    danger
                    onClick={() => props.setValues({ ...initCabinetData })}
                  >
                    Wyczyść dane
                  </Button>,
                ]}
              />
              <IconDivider title="Informacje" />
              <FlexRow justify="flex-start">
                <Form.Item name="height" label="Wys." required>
                  <InputNumber name="height" min={0} />
                </Form.Item>

                <Form.Item name="width" label="Szer." required>
                  <InputNumber name="width" min={0} />
                </Form.Item>

                <Form.Item name="depth" label="Głęb." required>
                  <InputNumber name="depth" min={0} />
                </Form.Item>
              </FlexRow>
              <Row>
                <Col md={3}>
                  <Form.Item name="CabinetTypeId" label="Kategoria" required>
                    <Select placeholder="Kategoria" name="CabinetTypeId">
                      {cabinetTypes.map((type: CabinetTypeT) => {
                        return (
                          <Option value={type.id} key={type.id}>
                            {type.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={3}>
                  <Form.Item name="symbol" label="Sybmol" required>
                    <Input name="symbol" placeholder="Symbol" />
                  </Form.Item>
                </Col>
                <Col md={3}>
                  <Form.Item name="name" label="Nazwa">
                    <Input name="name" placeholder="Nazwa" />
                  </Form.Item>
                </Col>
                <Col md={3}>
                  <Form.Item name="description" label="Opis">
                    <Input.TextArea name="description" placeholder="Opis..." />
                  </Form.Item>
                </Col>
              </Row>

              <Upload
                onRemove={() => props.setFieldValue('img', [])}
                beforeUpload={(file: any) => {
                  props.setFieldValue('img', [file]);
                  return false;
                }}
                fileList={[...props.values.img]}
                accept="image/*"
              >
                <Button icon={<UploadOutlined />}>Dodaj zdjęcie</Button>
              </Upload>

              <IconDivider title="Elementy" />
              <Row>
                <Col md={6}>
                  <StyledH4>Elementy konstrukcyjne</StyledH4>
                  <FieldArray name="CabinetElements">
                    {(arrayHelpers) => {
                      return (
                        <div
                          style={{
                            minHeight: 50,
                          }}
                        >
                          <CabinetElementsFormList
                            elements={props.values.CabinetElements}
                            {...arrayHelpers}
                          />
                        </div>
                      );
                    }}
                  </FieldArray>
                </Col>
                <Col md={6}>
                  <StyledH4>Konfiguracje frontów</StyledH4>
                  <FieldArray name="CabinetFronts">
                    {(arrayHelpers) => {
                      return (
                        <div style={{ minHeight: 50, paddingBottom: 30 }}>
                          <CabinetFrontsFormList
                            fronts={props.values.CabinetFronts}
                            {...arrayHelpers}
                          />
                        </div>
                      );
                    }}
                  </FieldArray>
                </Col>
              </Row>

              <IconDivider title="Akcesoria" />
              <CabinetAccessoriesFormList
                accessories={props.values.CabinetAccessories}
                setAccessoriesList={(accessoriesList: CabinetAccessoryT[]) =>
                  props.setFieldValue('CabinetAccessories', accessoriesList)
                }
              />

              <IconDivider
                icon={<CheckCircleOutlined />}
                title="Zakończ"
                orientation="right"
              />
              <FlexRow justify="space-between">
                <div>
                  <CabinetPriceTable cabinet={props.values} />
                </div>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  form="addCabinetForm"
                >
                  <SaveOutlined />
                  Zapisz
                </Button>
              </FlexRow>
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
};

export default NewCabinetPage;

const columns = [
  {
    title: 'Cena akcesoriów',
    dataIndex: 'accessoryPrice',
    align: 'center' as const,
    render: (value: number, record: any) => {
      return (
        <InputNumber
          name=""
          disabled
          value={value}
          style={{ marginBottom: 24 }}
        />
      );
    },
  },
  {
    title: 'Cena korpusu',
    dataIndex: 'cabinetPrice',
    align: 'center' as const,
    render: (value: any, record: any) => {
      const cabinetPrice = record.cabinetPrice - record.accessoryPrice;
      return (
        <InputNumber
          name=""
          disabled
          value={typeof cabinetPrice === 'number' ? cabinetPrice : 0}
          style={{ marginBottom: 24 }}
        />
      );
    },
  },
  {
    title: 'Cena szafki',
    dataIndex: 'price',
    align: 'center' as const,
  },
];

const CabinetPriceTable = ({ cabinet }: { cabinet: NewCabinetT }) => {
  return (
    <div>
      <Typography.Text disabled>Cena bez frontów</Typography.Text>
      <Table
        size="small"
        pagination={false}
        columns={columns}
        dataSource={[
          {
            key: 1,
            accessoryPrice: getAccessoriesPrice(
              getCabinetAccessoryQty(cabinet.CabinetAccessories)
            ),
            cabinetPrice: cabinet.price,
            price: (
              <Form.Item name="price" required>
                <InputNumber name="price" />
              </Form.Item>
            ),
          },
        ]}
      />
    </div>
  );
};
