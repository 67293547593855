import { CabinetFrontConfigT } from 'services/store/types/cabinets/Cabinets';

export const getNextConfigNumber = (frontsConfigs: CabinetFrontConfigT[]) => {
  const numbers = frontsConfigs.map(
    (config: CabinetFrontConfigT) => config.config
  );
  const maxValue = Math.max(...numbers);

  const value = isFinite(maxValue) ? maxValue + 1 : 1;

  return value;
};
