import React, { useEffect, useState } from 'react';
import { UserT } from 'services/store/types/auth/Auth';
import { Table, Typography } from 'antd';
import PermissionTag from 'components/Users/PermissionTag';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { setUser } from 'services/store/actions/users';
import TextButton from 'components/TextButton';
import { getPersonalName } from 'services/utils/users';
import { formatPhoneNumber } from 'services/utils/string';

const { Text } = Typography;

const columns = [
  {
    title: 'Lp.',
    align: 'center' as const,
    render: (t: string, r: any, i: number) => i + 1,
  },
  {
    title: 'Imię i nazwisko',
    dataIndex: 'name',
    align: 'center' as const,
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    align: 'center' as const,
  },
  {
    title: 'Telefon',
    dataIndex: 'phone',
    align: 'center' as const,
  },
  {
    title: 'Uprawnienia',
    dataIndex: 'permission',
    align: 'center' as const,
  },
];

interface PropsT {
  users: UserT[];
  loading: boolean;
}

const List: React.FC<PropsT> = ({ users, loading }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    const newList = users.map((user: UserT, i: number) => ({
      key: i,
      name: (
        <TextButton
          text={getPersonalName(user)}
          onClick={() => handleUserClick(user)}
        />
      ),
      email: <Text copyable>{user.email}</Text>,
      phone: user.phone ? formatPhoneNumber(user.phone) : '-',
      permission: <PermissionTag permission={user.permission} />,
      id: user.id,
    }));

    setList(newList);
  }, [users]);

  const handleUserClick = (user: UserT) => {
    dispatch(setUser(user));
    history.push(`/users/${user.id}`);
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={list}
        pagination={false}
        loading={loading}
      />
    </div>
  );
};

export default List;
