/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from 'react';
import { Modal, List, message, Badge } from 'antd';
import { FileT, ProjectT } from 'services/store/types/projects/Projects';
import TextButton from 'components/TextButton';
import { capitalizeFirstLetter } from 'services/utils/string';
import { useDispatch } from 'react-redux';
import { perms } from 'services/utils/const';
import GetAnnex from './GetAnnex';
import { useMutation } from '@tanstack/react-query';
import { filesApi, IFileData } from 'services/api/files.api';
import useUser from 'hooks/useUser';
import { filesDisabledErrors } from 'scenes/Project/sider/utils';

const files = [
  {
    name: 'Umowa',
    type: 'agreement',
    permissions: [perms.ADMIN, perms.DESIGNER],
  },
  {
    name: 'Wykaz sprzętu',
    type: 'equipment',
    permissions: [perms.ADMIN, perms.DESIGNER],
  },
  {
    name: 'Warunki montażu',
    type: 'montageConditions',
    permissions: [perms.ADMIN, perms.DESIGNER],
  },
  {
    name: 'Gwarancja',
    type: 'guarantee',
    permissions: [perms.ADMIN, perms.DESIGNER],
  },
  { name: 'RODO', type: 'rodo', permissions: [perms.ADMIN, perms.DESIGNER] },
  {
    name: 'Zgoda na otrzymywanie faktur',
    type: 'emailAgree',
    permissions: [perms.ADMIN, perms.DESIGNER],
  },
  {
    name: 'Umowa długoterminowa',
    type: 'longtermAgreement',
    permissions: [perms.ADMIN, perms.DESIGNER],
  },
  {
    name: 'Aneks do umowy',
    type: 'annex',
    permissions: [perms.ADMIN, perms.DESIGNER],
  },
  {
    name: 'Protokół odbioru',
    type: 'acceptanceReport',
    permissions: [
      perms.ADMIN,
      perms.DESIGNER,
      perms.CARPENTER,
      perms.TECHNOLOGIST,
      perms.OFFICE,
      perms.INSTALLER,
      perms.EXTERNAL_COMPANY,
    ],
  },
];

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  project: ProjectT;
  projectFiles: FileT[];
  refetchProject: () => Promise<any>;
  refetchFiles: () => Promise<any>;
}

const GetFiles: React.FC<PropsT> = ({
  visible,
  closeModal,
  project,
  projectFiles,
  refetchProject,
  refetchFiles,
}) => {
  const {
    getPermission,
    isAdmin,
    isTechnologist,
    isCarpenter,
    isExternalCompany,
  } = useUser();
  const [errors, setErrors] = useState<string[]>([]);
  const [isAnnex, setIsAnnex] = useState(false);

  useEffect(() => {
    const err = filesDisabledErrors(project);
    setErrors(err);
  }, []);

  const buttonDisabled = (name: string) => {
    const agreementFile = projectFiles.find((file: FileT) =>
      file.name.includes('Skan umowy')
    );

    if (name === 'agreement' && !project.montageAddress) return true;
    if (!agreementFile && !['agreement', 'longtermAgreement'].includes(name))
      return true;
    // if (name === 'equipment' && !equip.length) return true;
    return false;
  };

  const getFile = useMutation(
    async (fileData: IFileData) =>
      filesApi.getProjectDocument(project, fileData),
    {
      onSuccess() {
        refetchProject();
        refetchFiles();
        setIsAnnex(false);
        // closeModal();
      },
      onError(err: any) {
        console.log(err?.response);
        message.error('Bład');
      },
    }
  );

  return (
    <Modal
      title={`Dokumenty projektu ${project.number || ''}`}
      visible={visible}
      onCancel={closeModal}
      footer={null}
    >
      {errors.length
        ? errors.map((item: string) => {
            return (
              <div key={item} style={{ color: 'red' }}>
                <Badge color="red" />
                {item}
              </div>
            );
          })
        : !isTechnologist &&
          !isExternalCompany &&
          !isCarpenter && (
            <div style={{ color: 'green' }}>
              Pobranie umowy rozpocznie projekt
            </div>
          )}
      <List
        dataSource={files}
        renderItem={(item: any) => {
          const disabled = buttonDisabled(item.type);
          if (getPermission() && item.permissions.includes(getPermission()))
            return (
              <List.Item
                actions={
                  isAdmin()
                    ? [
                        <TextButton
                          key={1}
                          onClick={() =>
                            item.type === 'annex'
                              ? setIsAnnex(true)
                              : getFile.mutate({
                                  name: item.name,
                                  fileType: item.type,
                                  ext: 'pdf',
                                })
                          }
                          text={`${item.type === 'annex' ? 'Aneks' : ' *.pdf'}`}
                          disabled={disabled || !!errors.length}
                        />,
                        item.type !== 'annex' && (
                          <TextButton
                            key={2}
                            onClick={() =>
                              item.type === 'annex'
                                ? setIsAnnex(true)
                                : getFile.mutate({
                                    name: item.name,
                                    fileType: item.type,
                                    ext: 'docx',
                                  })
                            }
                            text={`${
                              item.type === 'annex' ? 'Aneks' : ' *.docx'
                            }`}
                            disabled={disabled || !!errors.length}
                          />
                        ),
                      ]
                    : [
                        <TextButton
                          key={1}
                          onClick={() =>
                            item.type === 'annex'
                              ? setIsAnnex(true)
                              : getFile.mutate({
                                  name: item.name,
                                  fileType: item.type,
                                  ext: 'pdf',
                                })
                          }
                          text={`${item.type === 'annex' ? 'Aneks' : ' *.pdf'}`}
                          disabled={disabled || !!errors.length}
                        />,
                      ]
                }
              >
                <List.Item.Meta title={capitalizeFirstLetter(item.name)} />
              </List.Item>
            );
          return null;
        }}
      />
      {isAnnex && (
        <GetAnnex
          project={project}
          closeModal={() => setIsAnnex(false)}
          getFile={getFile.mutate}
        />
      )}
    </Modal>
  );
};

export default GetFiles;
