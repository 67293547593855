import axios, { AxiosResponse } from 'axios';
import { AddressT, SettingsT } from 'services/store/types/settings/Settings';
import setAuthToken from 'services/utils/setAuthToken';

export const settingsApi = {
  //NOTE GET
  getSettings: (): Promise<AxiosResponse<{ settings: SettingsT }>> => {
    setAuthToken();
    return axios.get(`/api/settings`);
  },

  //NOTE UPDATE
  updateCompanyAddress: (
    address: AddressT
  ): Promise<AxiosResponse<{ settings: SettingsT }>> => {
    setAuthToken();
    return axios.put(`/api/settings/companyaddress/${address.id}`, {
      addressData: address,
    });
  },
};
