import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { CaretDownFilled } from '@ant-design/icons';
import styled from 'styled-components';

const { SubMenu } = Menu;

const StyledMenu = styled(Menu)`
  display: flex;
  justify-content: flex-end;
`;

interface PropsT {
  handleLogOut: () => void;
}

const InstallerNav: React.FC<PropsT> = ({ handleLogOut }) => {
  const location = useLocation();

  return (
    <StyledMenu
      selectedKeys={[location.pathname]}
      theme="dark"
      mode="horizontal"
    >
      <Menu.Item key="/">
        <NavLink to="/">Strona główna</NavLink>
      </Menu.Item>
      <Menu.Item key="/schedule">
        <NavLink to="/schedule">Terminarz</NavLink>
      </Menu.Item>
      <Menu.Item key="/orders" disabled>
        <NavLink to="/orders">Zamówienia</NavLink>
      </Menu.Item>

      <SubMenu
        title={
          <span className="submenu-title-wrapper">
            Konto
            <CaretDownFilled />
          </span>
        }
      >
        <Menu.Item key="/account">
          <NavLink to="/account">Moje dane</NavLink>
        </Menu.Item>
        <Menu.Item onClick={handleLogOut}>Wyloguj</Menu.Item>
      </SubMenu>
    </StyledMenu>
  );
};

export default InstallerNav;
