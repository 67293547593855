import React from 'react';
import PageTemplate from 'templates/PageTemplate';
import { PageHeader, message } from 'antd';
import { useParams, useHistory } from 'react-router';
import { getPersonalName } from 'services/utils/users';
import PermissionTag from 'components/Users/PermissionTag';
import PersonalData from 'components/Users/PersonalData';
import { FileOutlined } from '@ant-design/icons';
import IconDivider from 'components/IconDivider';
import { StyledH1 } from 'components/Header';
import ProjectsList from 'components/Projects/ProjectsList';
import { perms } from 'services/utils/const';
import ContentTemplate from 'templates/ContentTemplate/ContentTemplate';
import SiderTemplate from 'templates/SiderTemplate/SiderTemplate';
import UserProfileSider from './sider/UserProfileSider';
import { useModal } from 'services/hooks/useModal';
import { usersApi } from 'services/api/users.api';
import { useQuery } from '@tanstack/react-query';
import Spinner from 'components/Spinner';
import ChangeData from 'components/Users/EditDataModal/ChangeData';
import DeleteUser from '../components/DeleteUser';
import ChangeUserPassword from './components/modals/ChangeUserPassword.modal';
import ChangePermissionModal from './components/modals/ChangeUserPermission.modal';

const initModal = {
  permissionChange: false,
  userRemove: false,
  dataChange: false,
  passwordChange: false,
};
export type TUserProfileModal = keyof typeof initModal;

interface PropsT {}

// FIXME POBIERAC USERA I PROJEKTY ODDZIELNIE KURWA!
const UserProfilePage: React.FC<PropsT> = ({}) => {
  const history = useHistory();
  const params: { id: string } = useParams();
  const { modal, showModal, closeModal } = useModal(initModal);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['get-user', params.id],
    queryFn: () => usersApi.getUserById(params.id),
    onError() {
      message.error('Błąd');
    },
  });

  if (data?.data)
    return (
      <PageTemplate>
        {isLoading && <Spinner />}
        <SiderTemplate>
          <UserProfileSider showModal={showModal} />
        </SiderTemplate>
        <ContentTemplate>
          <PageHeader
            title={<StyledH1>{getPersonalName(data?.data.user)}</StyledH1>}
            onBack={() => history.push('/users')}
            subTitle={<PermissionTag permission={data?.data.user.permission} />}
          >
            <PersonalData
              title="Dane użytkownika"
              user={data?.data.user}
              column={1}
            />

            {[perms.DESIGNER, perms.ADMIN].includes(
              data?.data.user.permission
            ) && (
              <>
                <IconDivider
                  title="Projekty"
                  icon={<FileOutlined style={{ color: 'lightskyblue' }} />}
                />
                <ProjectsList projects={data?.data.user.Projects} />
              </>
            )}
          </PageHeader>
        </ContentTemplate>

        {/* MODALS */}
        {data.data && (
          <>
            {modal.dataChange && (
              <ChangeData
                visible={modal.dataChange}
                closeModal={closeModal}
                user={data.data.user}
                refetchUser={refetch}
              />
            )}
            {modal.permissionChange && (
              <ChangePermissionModal
                visible={modal.permissionChange}
                closeModal={closeModal}
                user={data.data.user}
                refetchUser={refetch}
              />
            )}
            {modal.passwordChange && (
              <ChangeUserPassword
                visible={modal.passwordChange}
                closeModal={closeModal}
                user={data.data.user}
                refetchUser={refetch}
              />
            )}
            {modal.userRemove && (
              <DeleteUser
                visible={modal.userRemove}
                closeModal={closeModal}
                user={data.data.user}
              />
            )}
          </>
        )}
      </PageTemplate>
    );
  return null;
};

export default UserProfilePage;
