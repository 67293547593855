import React, { useState } from 'react';
import { Button, Divider, message, Modal, Statistic, Typography } from 'antd';
import { PricingT } from 'services/store/types/pricing/Pricing';
import { useDispatch } from 'react-redux';
import {
  pricingClearData,
  pricingSetPrice,
} from 'services/store/actions/pricing';
import { setSpinner } from 'services/store/actions/view';
import { createNewPricing } from 'services/api/projects/post';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { FlexRow } from 'templates/FlexRow';
import { useHistory } from 'react-router';
import { setProject } from 'services/store/actions/projects';
import { optymizeAccessoriesList, optymizeElementsList } from './utils';

interface PropsT {
  pricing: PricingT;
  closeModal: () => void;
  visible: boolean;
}

const CreatePricingModal: React.FC<PropsT> = ({
  pricing,
  closeModal,
  visible,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [changeProjectPrice, setChangeProjectPrice] = useState<boolean>(true);

  const roundPrice = () => {
    const roundedPrice = Math.ceil(pricing.price / 50) * 50;
    dispatch(pricingSetPrice(roundedPrice));
  };

  const handleSubmit = async () => {
    dispatch(setSpinner(true));

    const newPricing: PricingT = {
      ...pricing,
      PricingAccessories: optymizeAccessoriesList(pricing.PricingAccessories),
      PricingElements: optymizeElementsList(pricing.PricingElements),
      veneerLength: 0,
      frontsSurface: 0,
      elementsSurface: 0,
    };
    await createNewPricing(
      { pricing: newPricing, changeProjectPrice },
      (project) => {
        dispatch(setProject(project));
        message.success('Wycena utworzona');
        dispatch(setSpinner(false));
        dispatch(pricingClearData());
        history.push(`/projects/${project.id}`);
      },
      () => {
        dispatch(setSpinner(false));
      }
    );
  };
  return (
    <Modal
      title="Czy chcesz zatwierdzić wycenę"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      okText="Zatwierdź"
      onOk={handleSubmit}
    >
      <Typography.Text disabled style={{ textAlign: 'center' }}>
        Listy akcesoriów i elementów zostaną zoptymalizowane
      </Typography.Text>
      <hr />
      <Statistic
        title="Cena w projekcie"
        value={pricing.Project?.price || 0}
        suffix="zł"
      />
      <div>
        <Statistic title="Wycena" value={pricing.price || 0} suffix="zł" />
        <Button type="primary" size="small" onClick={roundPrice}>
          Zaokrąglij w górę <small>(do 50)</small>
        </Button>
      </div>
      <FlexRow justify="flex-end">
        <Checkbox
          checked={changeProjectPrice}
          onChange={(e: CheckboxChangeEvent) =>
            setChangeProjectPrice(e.target.checked)
          }
        >
          Zmień cenę w projekcie
        </Checkbox>
      </FlexRow>
    </Modal>
  );
};

export default CreatePricingModal;
