import { UploadOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Button, InputNumber, message, Modal, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React, { useState } from 'react';
import { projectsApi } from 'services/api/projects.api';
import { PaymentT, ProjectT } from 'services/store/types/projects/Projects';

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  refetchProject: () => Promise<any>;
  payment: PaymentT;
  project: ProjectT;
}

const EditPayment: React.FC<PropsT> = ({
  visible,
  closeModal,
  payment,
  project,
  refetchProject,
}) => {
  const [value, setValue] = useState(payment.value);
  const [file, setFile] = useState<RcFile[]>([]);

  const handleFile = (f: RcFile) => {
    setFile([f]);
    return false;
  };
  const handleRemove = () => setFile([]);

  const updatePayments = useMutation(
    async () => {
      if (value)
        return projectsApi.updatePayments(
          project.id,
          [{ PaymentId: payment.id, value }],
          file
        );
    },
    {
      onSuccess() {
        message.success('Płatność zaktualizowana');
        refetchProject();
        closeModal();
      },
      onError(err: any) {
        console.log(err?.response);
        message.error('Bład');
      },
    }
  );

  return (
    <Modal
      title="Edycja płatności"
      visible={visible}
      onCancel={closeModal}
      onOk={() => updatePayments.mutate()}
      okButtonProps={{ disabled: !value, loading: updatePayments.isLoading }}
      style={{ top: 40 }}
    >
      <div>
        <strong>{payment.name}: </strong>

        <InputNumber
          value={value}
          onChange={(v: number | string | undefined | null) =>
            setValue(typeof v === 'number' ? v : 0)
          }
        />
        <Upload
          fileList={file}
          beforeUpload={handleFile}
          onRemove={handleRemove}
        >
          <Button style={{ margin: 1 }} icon={<UploadOutlined />}>
            Dodaj plik
          </Button>
        </Upload>
      </div>
    </Modal>
  );
};

export default EditPayment;
