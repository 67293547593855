import Axios from 'axios';
import FileSaver from 'file-saver';
import { PermissionsT } from 'services/store/types/auth/Auth';
import { ProjectT, FileT } from 'services/store/types/projects/Projects';
import { FileTypesT, IdType, PricingFileTypesT } from 'services/types';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import { perms } from 'services/utils/const';
import { getExtension } from 'services/utils/string';
import { getPersonalName } from 'services/utils/users';

export const getProjectDocument = async (
  project: ProjectT,
  fileType: FileTypesT,
  body: any,
  name: string,
  ext: 'pdf' | 'docx',
  onEnd: () => void,
  onError: () => void
) => {
  try {
    const res = await Axios({
      url: `/api/files/project/document/${project.id}`,
      method: 'POST',
      responseType: 'arraybuffer',
      data: { fileType, ext, ...body },
    });
    FileSaver.saveAs(
      new Blob([res.data], { type: `application/${ext}` }),
      `${name}_${getPersonalName(project.Customer)}.${ext}`
    );
    return onEnd();
  } catch (err) {
    return axiosErrorHandle(err, onError);
  }
};

export const getProjectPricingList = async (
  projectId: IdType,
  type: PricingFileTypesT,
  onEnd: () => void,
  onError: () => void
) => {
  const fileName = {
    elements: 'Lista_elementów_konstrukcyjnych',
    fronts: 'Lista_frontów',
    accessories: 'Lista_ackesoriów',
    orders: 'Zamówienia',
  };

  try {
    const res = await Axios({
      url: `/api/files/project/pricing/${projectId}/${type}`,
      method: 'GET',
      responseType: 'arraybuffer',
    });

    if (type === 'orders') {
      FileSaver.saveAs(
        new Blob([res.data], { type: 'application/zip' }),
        'zamowienia.zip'
      );
    } else {
      FileSaver.saveAs(
        new Blob([res.data], { type: 'application/xlsx' }),
        `${fileName[type]}.xlsx`
      );
    }
    return onEnd();
  } catch (err) {
    axiosErrorHandle(err, onError);
    return null;
  }
};

export const getOrderById = async (
  orderId: IdType,
  onEnd: () => void,
  onError: () => void,
  name?: string
) => {
  try {
    const res = await Axios({
      url: `/api/files/order/${orderId}`,
      method: 'GET',
      responseType: 'arraybuffer',
    });
    FileSaver.saveAs(
      new Blob([res.data], { type: 'application/xlsx' }),
      `${name || 'zamowienie'}.xlsx`
    );
    return onEnd();
  } catch (err) {
    axiosErrorHandle(err, onError);
    return null;
  }
};

export const getFileFrompath = async (
  path: string,
  name: string,
  onEnd: () => void,
  onError: () => void
) => {
  try {
    const res = await Axios({
      url: '/api/files/file/path',
      method: 'PUT',
      responseType: 'arraybuffer',
      data: { path },
    });

    const ext = getExtension(path);

    FileSaver.saveAs(new Blob([res.data]), `${name}.${ext}`);
    return onEnd();
  } catch (err) {
    return axiosErrorHandle(err, onError);
  }
};

export const getProjectFile = async (
  file: FileT,
  onEnd: () => void,
  onError: () => void
) => {
  try {
    const res = await Axios({
      url: `/api/files/${file.id}`,
      method: 'GET',
      responseType: 'arraybuffer',
    });

    const ext = getExtension(file.path);

    FileSaver.saveAs(new Blob([res.data]), `${file.name}.${ext}`);
    return onEnd();
  } catch (err) {
    return axiosErrorHandle(err, onError);
  }
};
