import Axios from 'axios';
import { IdType } from 'services/types';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import setAuthToken from 'services/utils/setAuthToken';

export const removeOrder = async (
  orderId: IdType,
  onEnd: () => void,
  onErr: () => void
) => {
  setAuthToken();
  try {
    await Axios.delete(`/api/orders/${orderId}`);

    onEnd();
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};
