import Axios from 'axios';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import setAuthToken from 'services/utils/setAuthToken';
import { ProjectT } from 'services/store/types/projects/Projects';

export const removeProjectComment = async (
  commentId: string,
  onEnd: (project: ProjectT) => void,
  onErr: () => void
) => {
  setAuthToken();
  try {
    const res: any = await Axios.delete(`/api/comments/${commentId}`);

    onEnd(res.data.project);
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};
