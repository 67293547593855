import { DeleteOutlined } from '@ant-design/icons';
import { Empty, InputNumber, Popconfirm, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { pricingAccessoriesQty } from 'services/store/actions/pricing';
import { PricingAccessoryT } from 'services/store/types/pricing/Pricing';
import styled from 'styled-components';
import Img from 'components/Image';

const columns: any[] = [
  {
    title: 'Lp.',
    align: 'center' as const,
    render: (t: string, r: any, i: number) => i + 1,
  },
  {
    title: 'Zdjęcie',
    dataIndex: 'img',
    align: 'center' as const,
  },
  {
    title: 'Nazwa',
    dataIndex: 'name',
    align: 'center' as const,
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
    align: 'center' as const,
  },
  {
    title: 'Dystrybutor',
    dataIndex: 'supplier',
    align: 'center' as const,
  },
  {
    title: 'Cena',
    dataIndex: 'price',
    align: 'center' as const,
  },
  {
    title: 'Korpus',
    dataIndex: 'cabinet',
    align: 'center' as const,
  },
  {
    title: 'Ilosć',
    dataIndex: 'qty',
    align: 'center' as const,
  },
  {
    title: 'Akcje',
    dataIndex: 'actions',
    align: 'center' as const,
  },
];

const Wrapper = styled.div`
  max-height: 500px;
  overflow-y: auto;
`;

interface PropsT {
  accessories: PricingAccessoryT[];
  actions?: {
    remove?: (index: number) => void;
  };
  editable?: boolean;
}

const PricingAccessoriesList: React.FC<PropsT> = ({
  accessories,
  actions,
  editable,
}) => {
  const dispatch = useDispatch();
  const [list, setList] = useState<any>([]);

  useEffect(() => {
    if (accessories.length) {
      const newList = accessories.map(
        (accessory: PricingAccessoryT, i: number) => {
          return {
            key: `${accessory.AccessoryId}${accessory.CabinetId}${i}`,
            ...accessory.Accessory,
            qty: (
              <InputNumber
                value={accessory.qty}
                onChange={(value) => {
                  if (typeof value === 'number') {
                    handleQty(i, value);
                  }
                }}
                min={1}
                disabled={!editable}
                style={{ width: 100 }}
              />
            ),
            supplier: (
              <Typography.Text>
                {accessory.Accessory?.Supplier.name || '-'}
              </Typography.Text>
            ),
            img: (
              <Img
                path={accessory.Accessory?.miniaturePath}
                previewPath={accessory.Accessory?.imgPath}
                isExist={accessory.Accessory}
              />
            ),
            cabinet: accessory.Cabinet ? (
              <div>
                <Typography.Title level={5} style={{ margin: 0 }}>
                  {accessory.Cabinet.symbol}
                </Typography.Title>
                <Typography.Text>{accessory.Cabinet.name}</Typography.Text>
              </div>
            ) : (
              '-'
            ),
            actions: actions ? (
              <div>
                {actions.remove && (
                  <Popconfirm
                    title="Usuń accessory"
                    onConfirm={() => actions.remove && actions.remove(i)}
                    disabled={!editable}
                  >
                    <DeleteOutlined
                      title="Usuń"
                      style={
                        editable
                          ? { color: 'red', cursor: 'pointer', margin: 5 }
                          : { color: 'gray', margin: 5, cursor: 'default' }
                      }
                    />
                  </Popconfirm>
                )}
              </div>
            ) : (
              <Typography.Text disabled>brak</Typography.Text>
            ),
          };
        }
      );

      setList(newList);
    }
  }, [accessories, editable]);

  const handleQty = (index: number, value: number) =>
    dispatch(pricingAccessoriesQty(index, value));

  if (list.length)
    return (
      <Wrapper>
        <Table
          columns={columns}
          dataSource={list}
          size={'small' as const}
          pagination={false}
        />
      </Wrapper>
    );
  return <Empty />;
};

export default PricingAccessoriesList;
