import React, { useState } from 'react';
import { Modal, Button, message } from 'antd';
import { Input, Form } from 'formik-antd';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { UserT } from 'services/store/types/auth/Auth';
import { updateUser } from 'services/api/users/update';
import { userLoadSuccess } from 'services/store/actions/auth';
import { AppStateT } from 'services/store';
import { setUser } from 'services/store/actions/users';
import { userSchema } from '../utils/validate';

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  refetchUser?: () => Promise<any>;
  user: UserT;
}

const ChangeData: React.FC<PropsT> = ({
  visible,
  closeModal,
  user,
  refetchUser,
}) => {
  const authUser = useSelector((state: AppStateT) => state.auth.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (
    values: { email: string; phone: any; firstname: string; surname: string },
    actions: any
  ) => {
    setLoading(true);
    await updateUser(
      user.id,
      { ...values },
      (u) => {
        setLoading(false);
        if (user.id === authUser?.id)
          dispatch(userLoadSuccess(u, u.permission));
        else {
          if (refetchUser) refetchUser();
        }
        message.success('Zaktualizowano dane');
        closeModal();
      },
      () => {
        setLoading(false);
        message.error('Błąd');
      }
    );
  };
  if (user)
    return (
      <Modal
        title="Edycja danych"
        destroyOnClose={true}
        visible={visible}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            Anuluj
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            htmlType="submit"
            form="ChangeDataForm"
          >
            Zatwierdź
          </Button>,
        ]}
      >
        <div>
          <Formik
            validationSchema={userSchema}
            onSubmit={handleSubmit}
            initialValues={{
              email: user.email,
              phone: user.phone,
              firstname: user.firstname,
              surname: user.surname,
            }}
          >
            {(props) => {
              return (
                <Form
                  noValidate
                  className="form-container"
                  id="ChangeDataForm"
                  onFinish={props.handleSubmit}
                >
                  <Form.Item name="firstname">
                    <Input name="firstname" placeholder="Imię" required />
                  </Form.Item>
                  <Form.Item name="surname">
                    <Input name="surname" placeholder="Nazwisko" required />
                  </Form.Item>
                  <Form.Item name="email">
                    <Input
                      name="email"
                      placeholder="E-mail"
                      type="email"
                      required
                    />
                  </Form.Item>
                  <Form.Item name="phone">
                    <Input name="phone" placeholder="Numer telefonu" required />
                  </Form.Item>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    );
  return null;
};

export default ChangeData;
