import React, { useEffect } from 'react';
import { Button, message, Modal, Upload } from 'antd';
import { TableT } from 'services/store/types/catalog/Catalog';
import { initTableData } from 'services/utils/const/initData';
import { Form, Input, InputNumber, Select } from 'formik-antd';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateT } from 'services/store';
import { setSpinner } from 'services/store/actions/view';
import { SupplierT } from 'services/store/types/suppliers/Suppliers';
import { getSupliers } from 'services/store/actions/suppliers';
import { UploadOutlined } from '@ant-design/icons';
import { createNewTable, NewTableT } from 'services/api/catalog/post';
import { catalogAddToList } from 'services/store/actions/catalog';
import { FlexRow } from 'templates/FlexRow';
import { tableSchema } from './utils/valiadte';

interface PropsT {
  title: string;
  closeModal: () => void;
  visible: boolean;
  initData?: TableT;
  customHandleSubmit?: (table: NewTableT) => void;
}

const TableModal: React.FC<PropsT> = ({
  title,
  closeModal,
  visible,
  initData,
  customHandleSubmit,
}) => {
  const dispatch = useDispatch();

  const suppliers = useSelector(
    (state: AppStateT) => state.suppliers.suppliers
  );

  useEffect(() => {
    if (!suppliers.length) {
      dispatch(setSpinner(true));
      dispatch(getSupliers());
    }
  }, []);

  const handleSubmit = async (values: NewTableT) => {
    if (customHandleSubmit) return customHandleSubmit(values);
    dispatch(setSpinner(true));
    await createNewTable(
      values,
      (table) => {
        dispatch(catalogAddToList('tables', table));
        message.success('Dodano nowy blat');
        closeModal();
      },
      () => {}
    );
    dispatch(setSpinner(false));
    return null;
  };

  return (
    <Modal
      title={title}
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      okButtonProps={{
        htmlType: 'submit',
        form: 'pattern-form-id',
      }}
    >
      <Formik
        validationSchema={tableSchema}
        onSubmit={handleSubmit}
        initialValues={
          initData ? { ...initData, img: [] } : { ...initTableData, img: [] }
        }
      >
        {(props) => {
          return (
            <Form
              noValidate
              id="pattern-form-id"
              onFinish={props.handleSubmit}
              labelCol={{ span: 6 }}
            >
              <Form.Item name="symbol" label="Symbol" required>
                <Input name="symbol" placeholder="Symbol" />
              </Form.Item>
              <Form.Item name="name" label="Nazwa" required>
                <Input name="name" placeholder="Nazwa" />
              </Form.Item>
              <Form.Item name="SupplierId" label="Dystrybutor">
                <Select placeholder="Dystrybutor" name="SupplierId">
                  {suppliers &&
                    suppliers.map((supp: SupplierT) => {
                      if (supp.id)
                        return (
                          <Select.Option value={supp.id} key={supp.id}>
                            {supp.name}
                          </Select.Option>
                        );
                      return null;
                    })}
                </Select>
              </Form.Item>
              <Form.Item name="price" label="Cena" required>
                <InputNumber name="price" min={0} />
              </Form.Item>
              <FlexRow justify="space-between">
                <div style={{ textAlign: 'center' }}>
                  <Form.Item
                    name="width"
                    label="Wys."
                    required
                    style={{ marginBottom: 0 }}
                  >
                    <InputNumber name="width" min={0} />
                  </Form.Item>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Form.Item
                    name="depth"
                    label="Głęb."
                    required
                    style={{ marginBottom: 0 }}
                  >
                    <InputNumber name="depth" min={0} />
                  </Form.Item>
                </div>
                <Form.Item
                  name="thickness"
                  label="Gr."
                  required
                  style={{ textAlign: 'center' }}
                >
                  <InputNumber name="thickness" min={0} />
                </Form.Item>
              </FlexRow>
              <Upload
                onRemove={() => props.setFieldValue('img', [])}
                beforeUpload={(file: any) => {
                  props.setFieldValue('img', [file]);
                  return false;
                }}
                fileList={[...props.values.img]}
                multiple={false}
                accept="image/*"
              >
                <Button icon={<UploadOutlined />}>Dodaj zdjęcie</Button>
              </Upload>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default TableModal;
