import React, { useEffect } from 'react';
import { Button, message, Modal, Upload } from 'antd';
import { PatternT, PatternTypeT } from 'services/store/types/catalog/Catalog';
import { initPatternData } from 'services/utils/const/initData';
import { Form, Input, Select } from 'formik-antd';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateT } from 'services/store';
import { setSpinner } from 'services/store/actions/view';
import { SupplierT } from 'services/store/types/suppliers/Suppliers';
import { getSupliers } from 'services/store/actions/suppliers';
import { UploadOutlined } from '@ant-design/icons';
import { createNewPattern, NewPatternT } from 'services/api/catalog/post';
import { catalogAddToList } from 'services/store/actions/catalog';
import { schema } from './utils/valiadte';

interface PropsT {
  title: string;
  type: PatternTypeT;
  closeModal: () => void;
  visible: boolean;
  initData?: PatternT;
  customHandleSubmit?: (table: NewPatternT) => void;
}

const PatternModal: React.FC<PropsT> = ({
  title,
  type,
  closeModal,
  visible,
  initData,
  customHandleSubmit,
}) => {
  const dispatch = useDispatch();

  const suppliers = useSelector(
    (state: AppStateT) => state.suppliers.suppliers
  );

  useEffect(() => {
    if (!suppliers.length) {
      dispatch(setSpinner(true));
      dispatch(getSupliers());
    }
  }, []);

  const handleSubmit = async (values: NewPatternT) => {
    if (customHandleSubmit) return customHandleSubmit(values);
    dispatch(setSpinner(true));
    await createNewPattern(
      values,
      (pattern) => {
        dispatch(catalogAddToList(type, pattern));
        message.success('Dodano nowy wzór');
        closeModal();
      },
      () => {}
    );
    dispatch(setSpinner(false));
    return null;
  };
  return (
    <Modal
      title={title}
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      okButtonProps={{
        htmlType: 'submit',
        form: 'pattern-form-id',
      }}
    >
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={
          initData
            ? { ...initData, img: [] }
            : { ...initPatternData(type), img: [] }
        }
      >
        {(props) => {
          return (
            <Form
              noValidate
              id="pattern-form-id"
              onFinish={props.handleSubmit}
              labelCol={{ span: 5 }}
            >
              <Form.Item name="symbol" label="Symbol" required>
                <Input name="symbol" placeholder="Symbol" />
              </Form.Item>
              <Form.Item name="name" label="Nazwa" required>
                <Input name="name" placeholder="Nazwa" />
              </Form.Item>
              <Form.Item name="SupplierId">
                <Select placeholder="Dystrybutor" name="SupplierId">
                  {suppliers &&
                    suppliers.map((supp: SupplierT) => {
                      if (supp.id)
                        return (
                          <Select.Option value={supp.id} key={supp.id}>
                            {supp.name}
                          </Select.Option>
                        );
                      return null;
                    })}
                </Select>
              </Form.Item>
              <Upload
                onRemove={() => props.setFieldValue('img', [])}
                beforeUpload={(file: any) => {
                  props.setFieldValue('img', [file]);
                  return false;
                }}
                fileList={[...props.values.img]}
                multiple={false}
                accept="image/*"
              >
                <Button icon={<UploadOutlined />}>Dodaj zdjęcie</Button>
              </Upload>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default PatternModal;
