/* eslint-disable react/require-default-props */
import React, { useEffect } from 'react';
import PageTemplate from 'templates/PageTemplate';
import { PageHeader, Button, message, Statistic, Modal } from 'antd';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateT } from 'services/store';
import {
  setNewProjectUser,
  setNewProjectField,
  clearNewProject,
} from 'services/store/actions/newProject';
import { getAllCustomers } from 'services/store/actions/customers';
import styled from 'styled-components';
import { dateToString } from 'services/utils/date';
import {
  UserOutlined,
  FileOutlined,
  HomeOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  FileAddOutlined,
} from '@ant-design/icons';
import { getAllUsers } from 'services/store/actions/users';
import { setSpinner } from 'services/store/actions/view';
import { createNewProjet } from 'services/api/projects/post';
import IconDivider from 'components/IconDivider';
import { StyledH1 } from 'components/Header';
import { ProjectT } from 'services/store/types/projects/Projects';
import withPermission from 'hoc/withPermission';
import { PermissionsT } from 'services/store/types/auth/Auth';
import { perms } from 'services/utils/const';
import DesignerData from './components/DesignerData';
import CustomerData from './components/CustomerData';
import ProjectData from './components/ProjectData';
import ProjectComments from './components/ProjectComments';
import MontageAddress from './components/MontageAddress';
import { validateNewProject } from './utils';
import ContentTemplate from 'templates/ContentTemplate/ContentTemplate';
import SiderTemplate from 'templates/SiderTemplate/SiderTemplate';
import NewProjectSider from './sider/NewProjectSider';
import { useModal } from 'services/hooks/useModal';
import AddCustomer from 'components/Users/AddCustomerModal';
import { useQueries } from '@tanstack/react-query';
import { customersApi } from 'services/api/customers.api';
import { usersApi } from 'services/api/users.api';
import useUser from 'hooks/useUser';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  div {
    margin: 0 10px;
  }
`;

const initModal = {
  newCustomer: false,
};

export type TNewProjectModal = keyof typeof initModal;

interface PropsT {
  permission: PermissionsT;
}

const NewProjectPage: React.FC<PropsT> = ({ permission }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { modal, showModal, closeModal } = useModal(initModal);
  const { user, isAdmin } = useUser();
  const newProject = useSelector((state: AppStateT) => state.newProject);

  useEffect(() => {
    // SET DESIGNER
    if (user && !newProject.User) dispatch(setNewProjectUser(user));
  }, [user, newProject.User]);

  const [customers, users] = useQueries({
    queries: [
      {
        queryKey: ['get-all-customers'],
        queryFn: () => customersApi.getAllCustomers(),
        onError() {
          message.error('Błąd pobierania klientów');
        },
      },
      {
        queryKey: ['get-all-users'],
        queryFn: () => usersApi.getAllUsers(),
        onError() {
          message.error('Błąd pobierania klientów');
        },
        enabled: isAdmin(),
      },
    ],
  });

  const handleClearProject = () => user && dispatch(clearNewProject(user));
  const handleSubmit = async () => {
    const errors = validateNewProject(newProject);
    if (errors.length) return ErrorModal(errors);

    dispatch(setSpinner(true));
    await createNewProjet(
      newProject,
      (project: ProjectT) => {
        message.success('Utworzyłeś nowy projekt');
        dispatch(setSpinner(false));
        user && dispatch(clearNewProject(user));
        history.push(`/projects/${project.id}`);
      },
      () => {
        message.error('Błąd!');
        dispatch(setSpinner(false));
      }
    );
    return null;
  };

  return (
    <PageTemplate>
      <SiderTemplate>
        <NewProjectSider showModal={showModal} />
      </SiderTemplate>
      <ContentTemplate>
        <PageHeader
          title={<StyledH1>Nowy projekt</StyledH1>}
          onBack={() => history.push('/')}
          extra={[
            <Button
              key={1}
              danger
              disabled={!user}
              type="primary"
              onClick={handleClearProject}
            >
              Wyczyść dane
            </Button>,
          ]}
        >
          <Wrapper>
            <Statistic
              title="Data utworzenia"
              value={dateToString(new Date())}
            />
            <Statistic title="Status" value="Nowy projekt" />
          </Wrapper>
        </PageHeader>
        <IconDivider icon={<UserOutlined />} title="Dane projektanta" />
        <DesignerData
          user={newProject.User}
          users={users.data?.data.users || []}
          UserId={newProject.UserId}
        />
        <IconDivider icon={<UserOutlined />} title="Dane klienta" />
        <CustomerData
          customers={customers.data?.data.customers || []}
          customer={newProject.Customer}
          customerId={newProject.CustomerId}
        />
        <IconDivider icon={<FileOutlined />} title="Dane projektu" />
        <ProjectData
          name={newProject.name}
          price={newProject.price}
          projectYear={newProject.projectYear}
        />
        <IconDivider icon={<HomeOutlined />} title="Adres montażu" />
        <MontageAddress
          address={newProject.montageAddress}
          customer={newProject.Customer}
        />
        <IconDivider
          icon={<ExclamationCircleOutlined />}
          title="Informacje od klienta"
        />
        <ProjectComments
          comments={newProject.comments}
          images={newProject.images}
        />
        <IconDivider
          icon={<CheckCircleOutlined />}
          title="Zakończ"
          orientation="right"
        />
        <Wrapper>
          <Button size="large" type="primary" onClick={handleSubmit}>
            <FileAddOutlined /> Utwórz
          </Button>
        </Wrapper>
      </ContentTemplate>

      {/* MODALS */}
      {modal.newCustomer && (
        <AddCustomer
          visible={modal.newCustomer}
          closeModal={closeModal}
          refetchCustomers={customers.refetch}
        />
      )}
    </PageTemplate>
  );
};

export default withPermission(NewProjectPage);

const ErrorModal = (errors: string[]) => {
  return Modal.error({
    title: 'Uzupełnij dane!',
    content: (
      <ul>
        {errors.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    ),
  });
};
