import { AccessoryT } from './Accessories';

export const ACCESSORIES_LOAD_SUCCESS = 'ACCESSORIES_LOAD_SUCCESS';
export const ACCESSORIES_LOAD_ERROR = 'ACCESSORIES_LOAD_ERROR';
export const ACCESSORIES_ADD_TO_LIST = 'ACCESSORIES_ADD_TO_LIST';
export const ACCESSORIES_REMOVE_FROM_LIST = 'ACCESSORIES_REMOVE_FROM_LIST';
export const ACCESSORIES_UPDATE_IN_LIST = 'ACCESSORIES_UPDATE_IN_LIST';

export interface AccessoriesLoadSuccessT {
  type: typeof ACCESSORIES_LOAD_SUCCESS;
  accessories: AccessoryT[];
}
export interface AccessoriesLoadErrorT {
  type: typeof ACCESSORIES_LOAD_ERROR;
}

export interface AccessoriesAddToListT {
  type: typeof ACCESSORIES_ADD_TO_LIST;
  accessory: AccessoryT;
}
export interface AccessoriesRemoveFromListT {
  type: typeof ACCESSORIES_REMOVE_FROM_LIST;
  id: string;
}
export interface AccessoriesUpdateInListT {
  type: typeof ACCESSORIES_UPDATE_IN_LIST;
  accessory: AccessoryT;
}

export type AccessoriesActionsT =
  | AccessoriesAddToListT
  | AccessoriesRemoveFromListT
  | AccessoriesUpdateInListT
  | AccessoriesLoadSuccessT
  | AccessoriesLoadErrorT;
