import React, { useState, useEffect } from 'react';
import { FileT } from 'services/store/types/projects/Projects';
import { Divider, Button, Typography, List, message } from 'antd';
import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { setSpinner } from 'services/store/actions/view';
import { useDispatch, useSelector } from 'react-redux';
import TextButton from 'components/TextButton';
import { capitalizeFirstLetter } from 'services/utils/string';
import { getProjectFile } from 'services/api/files/get';
import { removeFile } from 'services/api/files/remove';
import { setProject } from 'services/store/actions/projects';
import { AppStateT } from 'services/store';
import { perms } from 'services/utils/const';
import UpdateFile from './Modals/UpdateFile';
import AddFile, { IAddFileProps } from './Modals/AddFile';
import useUser from 'hooks/useUser';

const { Title } = Typography;

const FilesWrapper = styled.div`
  height: 300px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`;

const initModals = {
  addFile: false,
};

interface PropsT
  extends Omit<IAddFileProps, 'visible' | 'closeModal' | 'filesInProject'> {
  projectFiles: FileT[] | undefined;
  refetchFiles: () => Promise<any>;
}

// FIXME refactor - add use query

const Files: React.FC<PropsT> = ({ projectFiles, refetchFiles, ...props }) => {
  const user = useSelector((state: AppStateT) => state.auth.user);
  const { isAdmin, hasPermission } = useUser();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(initModals);
  const [files, setFiles] = useState<any[]>([]);

  const [file, setFile] = useState<FileT | null>(null);

  useEffect(() => {
    if (projectFiles && user) {
      const filesList = projectFiles
        .filter((item: FileT) => {
          if (item.type === 'file') {
            if (hasPermission([perms.ADMIN, perms.DESIGNER])) return item;
            if (
              !item.protect ||
              (item.UserId && user && item.UserId === user.id)
            )
              return item;
          }
          return undefined;
        })
        .sort((a: FileT, b: FileT) => a.name.localeCompare(b.name));

      setFiles(filesList);
    }
  }, [projectFiles, user]);

  const handleCloseModal = () => setFile(null);
  const handleUpdateFile = (f: FileT) => setFile(f);

  const handleModal = (name: 'addFile') => setModal({ ...modal, [name]: true });
  const closeModal = () => setModal(initModals);

  const getFile = async (f: FileT) => {
    dispatch(setSpinner(true));
    await getProjectFile(
      f,
      () => {
        dispatch(setSpinner(false));
      },
      () => {
        dispatch(setSpinner(false));
      }
    );
  };

  const handleRemoveFile = async (fileId: string) => {
    dispatch(setSpinner(true));
    await removeFile(
      fileId,
      (p) => {
        refetchFiles();
        message.success('Plik usunięty');
        dispatch(setSpinner(false));
      },
      () => {
        message.error('Błąd!');
        dispatch(setSpinner(false));
      }
    );
  };

  return (
    <div>
      <div>
        <Title level={4}>Pliki</Title>
        <FilesWrapper>
          <List
            dataSource={files}
            renderItem={(item: FileT) => (
              <List.Item
                actions={[
                  isAdmin() && (
                    <TextButton
                      key={0}
                      onClick={() => handleRemoveFile(item.id)}
                      text="Usuń"
                      style={{ color: 'red' }}
                    />
                  ),
                  <TextButton
                    key={1}
                    onClick={() => handleUpdateFile(item)}
                    text="Dodaj"
                    disabled={!!item.path}
                  />,
                  <TextButton
                    key={2}
                    onClick={() => getFile(item)}
                    text="Pobierz"
                    disabled={!item.path}
                  />,
                ]}
              >
                <List.Item.Meta
                  title={
                    <>
                      {!item.path && (
                        <ExclamationCircleOutlined style={{ color: 'red' }} />
                      )}{' '}
                      {capitalizeFirstLetter(item.name)}
                    </>
                  }
                  description={item.description}
                />
              </List.Item>
            )}
          />
        </FilesWrapper>
        <Divider style={{ opacity: 0.2 }} />
        <Button onClick={() => handleModal('addFile')}>
          <UploadOutlined />
          Dodaj plik
        </Button>
      </div>

      {/* MODALS */}
      {file && (
        <UpdateFile
          closeModal={handleCloseModal}
          visible={!!file}
          fileData={file}
          refetchFiles={refetchFiles}
        />
      )}
      {modal.addFile && projectFiles && (
        <AddFile
          {...props}
          closeModal={closeModal}
          visible={modal.addFile}
          filesInProject={projectFiles}
          refetchFiles={refetchFiles}
        />
      )}
    </div>
  );
};

export default Files;
