import { RcFile } from 'antd/lib/upload';
import Axios from 'axios';
import { ProjectT } from 'services/store/types/projects/Projects';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';

export const addProjectFile = async (
  projectId: string,
  files: RcFile[],
  fileData: any,
  onEnd: (project: ProjectT) => void,
  onError: () => void
) => {
  try {
    const body = new FormData();
    files.forEach((file: RcFile) => body.append('files[]', file, file.name));
    body.append('fileData', JSON.stringify(fileData));

    const res: any = await Axios.post(
      `/api/files/project/file/${projectId}`,
      body
    );

    return onEnd(res.data.project);
  } catch (err) {
    return axiosErrorHandle(err, onError);
  }
};
