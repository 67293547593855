import { FileAddOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import useUser from 'hooks/useUser';
import React from 'react';
import { useHistory } from 'react-router';

const HomeSider = () => {
  const history = useHistory();
  const { isAdmin, isOffice, isDesigner } = useUser();

  if (isAdmin() || isOffice() || isDesigner())
    return (
      <>
        <Menu mode="inline">
          <Menu.Item
            icon={<FileAddOutlined />}
            onClick={() => history.push('/newproject')}
          >
            Nowy projekt
          </Menu.Item>
        </Menu>
      </>
    );
  return null;
};

export default HomeSider;
