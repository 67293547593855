import { Button } from 'antd';
import PickAccessoriesModal from 'components/Base/Accessories/PickAccessoriesModal';
import CabinetAccessoriesList from 'components/Base/Cabinets/CabinetAccessoriesList';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppStateT } from 'services/store';
import { AccessoryT } from 'services/store/types/accesories/Accessories';
import { CabinetAccessoryT } from 'services/store/types/cabinets/Cabinets';
import { getAccessoriesIds } from 'services/utils/project';

interface PropsT {
  accessories: CabinetAccessoryT[];
  setAccessoriesList: (accessories: CabinetAccessoryT[]) => void;
}

const CabinetAccessoriesFormList: React.FC<PropsT> = ({
  accessories,
  setAccessoriesList,
}) => {
  const [getAccessories, setGetAccessories] = useState(false);

  const allAccessories = useSelector(
    (state: AppStateT) => state.accessories.accessories
  );

  const handleSetAccessories = (picked: AccessoryT[]) => {
    const list: CabinetAccessoryT[] = picked.map((item: AccessoryT) => {
      const accessory = accessories.find(
        (a: CabinetAccessoryT) => a.AccessoryId === item.id
      );

      if (accessory)
        return {
          ...accessory,
        };
      return {
        Accessory: item,
        AccessoryId: item.id,
        defaultQty: 1,
      };
    });

    setAccessoriesList(list);
  };

  return (
    <div>
      <div>
        <CabinetAccessoriesList accessories={accessories} />
      </div>
      <div style={{ marginTop: 20 }}>
        <Button
          type="primary"
          onClick={() => setGetAccessories(!getAccessories)}
        >
          Wybierz akcesoria
        </Button>
      </div>

      {/* MODAL */}
      {/* NOTE Get accessories for cabinet */}
      {getAccessories && (
        <PickAccessoriesModal
          visible={getAccessories}
          closeModal={() => setGetAccessories(false)}
          handleAddAccessories={handleSetAccessories}
          accessories={allAccessories}
          selectedIds={getAccessoriesIds(accessories)}
        />
      )}
    </div>
  );
};

export default CabinetAccessoriesFormList;
