import { Layout } from 'antd';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateT } from 'services/store';
import { setSider } from 'services/store/actions/view';

const SiderTemplate: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const isSider = useSelector((state: AppStateT) => state.view.isSider);
  const handleToggleSider = () => dispatch(setSider(!isSider));

  return (
    <Layout.Sider
      style={
        !isMobile
          ? {
              overflow: 'auto',
              height: '100vh',
              position: 'sticky',
              top: 0,
              left: 0,
            }
          : {}
      }
      width={200}
      theme="light"
      collapsedWidth={isMobile ? 0 : 80}
      collapsible
      collapsed={isSider}
      onCollapse={handleToggleSider}
    >
      {children}
    </Layout.Sider>
  );
};

export default SiderTemplate;
