import { Dispatch } from 'redux';
import setAuthToken from 'services/utils/setAuthToken';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import Axios from 'axios';
import { IdType } from 'services/types';
import moment, { Moment } from 'moment';
import { SupplierT } from '../types/suppliers/Suppliers';
import {
  SuppliersLoadSuccessT,
  SUPPLIERS_LOAD_SUCCESS,
  SuppliersLoadErrorT,
  SUPPLIERS_LOAD_ERROR,
  SuppliersAddToListT,
  SUPPLIERS_ADD_TO_LIST,
  SuppliersUpdateInListT,
  SUPPLIERS_UPDATE_IN_LIST,
  SuppliersRemoveFromListT,
  SUPPLIERS_REMOVE_FROM_LIST,
  SuppliersSetSupplierT,
  SUPPLIERS_SET_SUPPLIER,
} from '../types/suppliers/actions';
import { setSpinner } from './view';

export const suppliersLoadSuccess = (
  suppliers: SupplierT[]
): SuppliersLoadSuccessT => ({
  type: SUPPLIERS_LOAD_SUCCESS,
  suppliers,
});

export const suppliersLoadError = (): SuppliersLoadErrorT => ({
  type: SUPPLIERS_LOAD_ERROR,
});

export const setSupplier = (
  supplier: SupplierT | null
): SuppliersSetSupplierT => ({
  type: SUPPLIERS_SET_SUPPLIER,
  supplier,
});

export const addToSuppliersList = (
  supplier: SupplierT
): SuppliersAddToListT => ({
  type: SUPPLIERS_ADD_TO_LIST,
  supplier,
});
export const updateSupplierInList = (
  supplier: SupplierT
): SuppliersUpdateInListT => ({
  type: SUPPLIERS_UPDATE_IN_LIST,
  supplier,
});
export const removeSupplierFromList = (
  id: IdType
): SuppliersRemoveFromListT => ({
  type: SUPPLIERS_REMOVE_FROM_LIST,
  id,
});

/**--------------------
 * THUNK
 ----------------------*/
export const getSupliers = (onEnd?: () => void, onError?: () => void) => async (
  dispatch: Dispatch
) => {
  setAuthToken();
  try {
    const res = await Axios.get('/api/suppliers/');

    dispatch(suppliersLoadSuccess(res.data.suppliers));
    if (onEnd) onEnd();
  } catch (error) {
    dispatch(suppliersLoadError());
    axiosErrorHandle(error, onError);
  }
  dispatch(setSpinner(false));
};

export const getSuplierById = (
  id: IdType,
  onEnd?: () => void,
  onError?: () => void
) => async (dispatch: Dispatch) => {
  setAuthToken();
  try {
    const res = await Axios.get(`/api/suppliers/${id}`);

    dispatch(setSupplier(res.data.supplier));
    if (onEnd) onEnd();
  } catch (error) {
    dispatch(setSupplier(null));
    axiosErrorHandle(error, onError);
  }
  dispatch(setSpinner(false));
};

export const getSuplierByIdWithOrders = (
  id: IdType,
  dates: [Moment, Moment],
  onEnd?: () => void,
  onError?: () => void
) => async (dispatch: Dispatch) => {
  setAuthToken();
  try {
    const res = await Axios.post(`/api/suppliers/${id}/orders`, {
      from: dates[0],
      to: moment(dates[1]).add(1, 'day'),
    });

    dispatch(setSupplier(res.data.supplier));
    if (onEnd) onEnd();
  } catch (error) {
    dispatch(setSupplier(null));
    axiosErrorHandle(error, onError);
  }
  dispatch(setSpinner(false));
};
