import axios from 'axios';
import { OrderT } from 'services/store/types/orders/Orders';
import { IdType } from 'services/types';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import setAuthToken from 'services/utils/setAuthToken';

export const pickUpOrder = async (
  orderId: IdType,
  orderData: OrderT,
  onEnd: (order: OrderT) => void,
  onErr: () => void
) => {
  setAuthToken();
  try {
    const res = await axios.put(`/api/orders/${orderId}/pickup`, {
      order: orderData,
    });

    const { order } = res.data;

    onEnd(order);
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};
