import { Divider } from 'antd';
import TextButton from 'components/TextButton';
import withPermission from 'hoc/withPermission';
import React from 'react';
import { PermissionsT } from 'services/store/types/auth/Auth';
import { PaymentT } from 'services/store/types/projects/Projects';
import { perms } from 'services/utils/const';
import { dateToString } from 'services/utils/date';
import { getPersonalName } from 'services/utils/users';
import { FlexRow } from 'templates/FlexRow';

interface PaidItemPropsT {
  payment: PaymentT;
  getFile: (path: string, name: string) => void;
  editPayment: () => void;
  permission: PermissionsT;
}
const PaidItem: React.FC<PaidItemPropsT> = ({
  payment,
  permission,
  getFile,
  editPayment,
}) => {
  return (
    <>
      <FlexRow justify="space-between">
        <div>
          <strong>{payment.name}</strong> <sub>({payment.part * 100}%)</sub> :{' '}
          <span style={{ fontSize: 20 }}>{payment.value}</span>
          zł <strong style={{ color: 'green' }}>Zatwierdzono</strong>
          <div>
            {' '}
            <small>
              ({payment.User ? getPersonalName(payment.User) : '-'},{' '}
              {payment.date && dateToString(payment.date)})
            </small>
          </div>
        </div>
        <div>
          <TextButton
            text="Edytuj"
            disabled={permission !== perms.ADMIN}
            onClick={editPayment}
            style={{ marginRight: 10 }}
          />
          <TextButton
            text="Pobierz"
            disabled={permission !== perms.ADMIN}
            onClick={() =>
              payment.filePath && getFile(payment.filePath, payment.name)
            }
          />
        </div>
      </FlexRow>
      <Divider />
    </>
  );
};

export default withPermission(PaidItem);
