import { Dispatch } from 'redux';
import setAuthToken from 'services/utils/setAuthToken';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import Axios from 'axios';
import { CabinetT } from '../types/cabinets/Cabinets';
import {
  CabinetsLoadSuccessT,
  CABINETS_LOAD_SUCCESS,
  CabinetsLoadErrorT,
  CABINETS_LOAD_ERROR,
  CabinetsAddToListT,
  CABINETS_ADD_TO_LIST,
  CabinetsUpdateInListT,
  CABINETS_UPDATE_IN_LIST,
  CabinetsRemoveFromListT,
  CABINETS_REMOVE_FROM_LIST,
  SetCabinetT,
  CABINETS_SET_CABINET,
} from '../types/cabinets/actions';
import { setSpinner } from './view';

export const cabinetsLoadSuccess = (
  cabinets: CabinetT[]
): CabinetsLoadSuccessT => ({
  type: CABINETS_LOAD_SUCCESS,
  cabinets,
});

export const cabinetsLoadError = (): CabinetsLoadErrorT => ({
  type: CABINETS_LOAD_ERROR,
});

export const setCabinet = (cabinet: CabinetT | null): SetCabinetT => ({
  type: CABINETS_SET_CABINET,
  cabinet,
});

export const addCabinetToList = (cabinet: CabinetT): CabinetsAddToListT => ({
  type: CABINETS_ADD_TO_LIST,
  cabinet,
});
export const updateCabinetInList = (
  cabinet: CabinetT
): CabinetsUpdateInListT => ({
  type: CABINETS_UPDATE_IN_LIST,
  cabinet,
});
export const removeCabinetFromList = (
  id: number | string
): CabinetsRemoveFromListT => ({
  type: CABINETS_REMOVE_FROM_LIST,
  id,
});

/**--------------------
 * THUNK
 ----------------------*/
export const getCabinets =
  (onEnd?: () => void, onError?: () => void) => async (dispatch: Dispatch) => {
    setAuthToken();
    try {
      const res = await Axios.get('/api/cabinets/');

      dispatch(cabinetsLoadSuccess(res.data.cabinets));
      if (onEnd) onEnd();
    } catch (error) {
      dispatch(cabinetsLoadError());
      axiosErrorHandle(error, onError);
    }
    dispatch(setSpinner(false));
  };

export const getCabinetData =
  (id: string, onEnd?: () => void, onError?: () => void) =>
  async (dispatch: Dispatch) => {
    setAuthToken();
    try {
      const res = await Axios.get(`/api/cabinets/${id}`);

      dispatch(setCabinet(res.data.cabinet));
      if (onEnd) onEnd();
    } catch (error) {
      dispatch(cabinetsLoadError());
      axiosErrorHandle(error, onError);
    }
    dispatch(setSpinner(false));
  };
