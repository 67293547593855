import { CustomerT } from './Customers';

export const CUSTOMERS_ADD_CUSTOMER_TO_LIST = 'CUSTOMERS_ADD_CUSTOMER_TO_LIST';
export const CUSTOMERS_LOAD_SUCCESS = 'CUSTOMERS_LOAD_SUCCESS';
export const CUSTOMERS_LOAD_ERROR = 'CUSTOMERS_LOAD_ERROR';
export const CUSTOMERS_SET_CUSTOMER = 'CUSTOMERS_SET_CUSTOMER';

export interface CustomersLoadSuccessT {
  type: typeof CUSTOMERS_LOAD_SUCCESS;
  customers: CustomerT[] | [];
}
export interface CustomersLoadErrorT {
  type: typeof CUSTOMERS_LOAD_ERROR;
}
export interface SetCustomerT {
  type: typeof CUSTOMERS_SET_CUSTOMER;
  customer: CustomerT | null;
}
export interface AddCustomerToListT {
  type: typeof CUSTOMERS_ADD_CUSTOMER_TO_LIST;
  customer: CustomerT;
}

export type CustomersActionsT =
  | CustomersLoadSuccessT
  | CustomersLoadErrorT
  | SetCustomerT
  | AddCustomerToListT;
