import { Button, Empty, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { pricingAddSegment } from 'services/store/actions/pricing';
import { PricingT, SegmentT } from 'services/store/types/pricing/Pricing';
import { initSegment } from 'services/utils/const/initData';
import Segment from './Segment';

interface PropsT {
  pricing: PricingT;
  previewOnly?: boolean;
}

const CabinetSegments: React.FC<PropsT> = ({
  pricing,
  previewOnly = false,
}) => {
  const { Segments } = pricing;
  const dispatch = useDispatch();

  const handleAddSegment = () => {
    dispatch(
      pricingAddSegment({
        ...initSegment(),
        segmentNumber: Segments.length + 1,
      })
    );
  };

  useEffect(() => {
    if (!previewOnly && !Segments.length) handleAddSegment();
  }, []);

  return (
    <div>
      {Segments && Segments.length ? (
        Segments.map((segment: SegmentT, index: number) => (
          <div key={segment.id}>
            <Typography.Title level={3}>Segment {index + 1}</Typography.Title>
            <Segment
              segment={segment}
              index={index}
              disabled={pricing.listEditable}
              previewOnly={previewOnly}
            />
          </div>
        ))
      ) : (
        <Empty />
      )}
      {!previewOnly && (
        <Button
          type="primary"
          onClick={handleAddSegment}
          disabled={pricing.listEditable}
        >
          Dodaj segment
        </Button>
      )}
    </div>
  );
};

export default CabinetSegments;
