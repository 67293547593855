import { Button, Descriptions, message, PageHeader, Col, Row } from 'antd';
import { StyledH1 } from 'components/Header';
import IconDivider from 'components/IconDivider';
import { OrderStatusTag } from 'components/Projects/StatusTag';
import { title } from 'process';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { getOrderById } from 'services/api/orders/get';
import { setSpinner } from 'services/store/actions/view';
import { OrderT } from 'services/store/types/orders/Orders';
import { dateToString } from 'services/utils/date';
import { formatPhoneNumber } from 'services/utils/string';
import PageTemplate from 'templates/PageTemplate';
import update from 'immutability-helper';
import { pickUpOrder } from 'services/api/orders/update';
import { FlexRow } from 'templates/FlexRow';
import TextButton from 'components/TextButton';
import OrderPickUpAccessoriesList from './components/AccessoriesList';

const OrderPickUpPage = () => {
  const dispatch = useDispatch();
  const params: { id: string } = useParams();
  const history = useHistory();

  const [order, setOrder] = useState<OrderT | null>(null);

  useEffect(() => {
    const getData = async () => {
      dispatch(setSpinner(true));
      await getOrderById(
        params.id,
        (ord) => {
          setOrder(ord);
        },
        () => {
          setOrder(null);
        }
      );
      dispatch(setSpinner(false));
    };

    if (!order) {
      getData();
    }
  }, []);

  const handlePickedQty = (
    index: number,
    value: number | string | undefined | null
  ) => {
    if (value && typeof value === 'number') {
      if (value < 0) return;
      if (order) {
        const newOrder = update(order, {
          OrderAccessories: {
            [index]: {
              pickedQty: { $set: value },
            },
          },
        });
        setOrder(newOrder);
      }
    }
  };

  const handleSubmit = async () => {
    if (order) {
      dispatch(setSpinner(true));
      await pickUpOrder(
        params.id,
        order,
        (ord) => {
          setOrder(ord);
          message.success('Odebrano elementy zamówienia');
        },
        () => {}
      );
    }
    dispatch(setSpinner(false));
  };

  return (
    <PageTemplate>
      <PageHeader
        title={<StyledH1>Odbiór zamówienia {order?.Supplier.name}</StyledH1>}
        subTitle={`${order?.name}. ${order?.description}`}
        extra={order && <OrderStatusTag status={order.status} />}
      />
      {order?.Project && (
        <div>
          <span>Zamówienie do projektu nr: </span>
          <strong>
            <TextButton
              text={order.Project.number || 'Brak numeru'}
              onClick={() => history.push(`/projects/${order.ProjectId}`)}
            />
          </strong>
        </div>
      )}
      {order && (
        <>
          <Row>
            <Col xs={12} md={4}>
              <Descriptions column={1} size="small" title="Dystrybutor">
                <Descriptions.Item label="Nazwa">
                  <strong>{order.Supplier.name}</strong>
                </Descriptions.Item>
                <Descriptions.Item label="Nr tel">
                  <strong>{formatPhoneNumber(order.Supplier.phone)}</strong>
                </Descriptions.Item>
                <Descriptions.Item label="E-mail">
                  <strong>{order.Supplier.email}</strong>
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col xs={12} md={4}>
              <Descriptions column={1} size="small" title="Daty">
                <Descriptions.Item label="Data utworzenia">
                  <strong>{dateToString(order.createdAt)}</strong>
                </Descriptions.Item>
                <Descriptions.Item label="Data wysłania">
                  <strong>{dateToString(order.sendedAt)}</strong>
                </Descriptions.Item>
                <Descriptions.Item label="Przewidywana data realizacji">
                  <strong>{dateToString(order.expectedPickUp)}</strong>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>

          <IconDivider title="Akcesoria" />
          <OrderPickUpAccessoriesList
            accessories={order.OrderAccessories}
            handlePickedQty={handlePickedQty}
          />
          <FlexRow justify="flex-end">
            <Button
              size="large"
              onClick={handleSubmit}
              disabled={!order}
              type="primary"
              style={{ marginTop: 10 }}
            >
              Odbierz elementy
            </Button>
          </FlexRow>
        </>
      )}
    </PageTemplate>
  );
};

export default OrderPickUpPage;
