import React from 'react';
import { Descriptions, Skeleton } from 'antd';
import { AddressT } from 'services/store/types/settings/Settings';
import { isAddressValid } from 'services/utils';

interface PropsT {
  address: AddressT | undefined | null;
  loading?: boolean;
  title?: string;
  column?: number;
}
const AddressData: React.FC<PropsT> = ({
  address,
  loading,
  title = 'Adres',
  column = 6,
}) => {
  if (address && isAddressValid(address))
    return (
      <Descriptions title={title} size="small" column={column}>
        <Descriptions.Item label="Miejscowość">
          <strong> {address.city}</strong>
        </Descriptions.Item>
        <Descriptions.Item label="Ulica">
          <strong> {address.street}</strong>
        </Descriptions.Item>
        <Descriptions.Item label="Nr mieszkania">
          <strong> {address.apartment}</strong>
        </Descriptions.Item>
        <Descriptions.Item label="Kod pocztowy">
          <strong> {address.postcode}</strong>
        </Descriptions.Item>
        <Descriptions.Item label="Opis">
          <strong> {address.description}</strong>
        </Descriptions.Item>
      </Descriptions>
    );
  return (
    <Skeleton
      paragraph={{ rows: 5, width: [200, 210, 190, 200, 205] }}
      title={{ width: 100 }}
      active={loading}
    />
  );
};

export default AddressData;
