import { Button } from 'antd';
import PickAccessoriesModal from 'components/Base/Accessories/PickAccessoriesModal';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'services/hooks/useModal';
import { AppStateT } from 'services/store';
import { getAccessories } from 'services/store/actions/accessories';
import {
  pricingSetAccessories,
  pricingRemoveAccessory,
} from 'services/store/actions/pricing';
import { setSpinner } from 'services/store/actions/view';
import { CabinetAccessoryT } from 'services/store/types/cabinets/Cabinets';
import {
  PricingAccessoryT,
  PricingT,
} from 'services/store/types/pricing/Pricing';
import { getAccessoriesIds } from 'services/utils/project';
import { AccessoryT } from 'services/store/types/accesories/Accessories';
import { getAccessoriesFromSegments, optymizeAccessoriesList } from '../utils';
import PricingAccessoriesList from '../../../../components/Pricing/Accessories/List';

interface PropsT {
  pricing: PricingT;
}

const Accessories: React.FC<PropsT> = ({ pricing }) => {
  const { Segments, PricingAccessories } = pricing;
  const dispatch = useDispatch();
  const { modal, showModal, closeModal } = useModal({ addAccessory: false });
  const accessories = useSelector(
    (state: AppStateT) => state.accessories.accessories
  );

  useEffect(() => {
    const newAccessories = getAccessoriesFromSegments(Segments);
    const optymized = optymizeAccessoriesList(newAccessories);
    dispatch(pricingSetAccessories(optymized));
  }, [Segments]);

  const handleRemove = (index: number) =>
    dispatch(pricingRemoveAccessory(index));

  const handleModal = () => {
    if (!accessories.length) {
      dispatch(setSpinner(true));
      dispatch(getAccessories(() => showModal('addAccessory')));
    } else {
      showModal('addAccessory');
    }
  };

  const handleAddAccessories = (accessoriesToAdd: AccessoryT[]) => {
    const list: PricingAccessoryT[] = accessoriesToAdd.map(
      (item: AccessoryT) => {
        const accessory = PricingAccessories.find(
          (a: PricingAccessoryT) => a.AccessoryId === item.id
        );

        if (accessory)
          return {
            ...accessory,
          };
        return {
          Accessory: item,
          AccessoryId: item.id,
          qty: 1,
          isAdditional: true,
        };
      }
    );

    const accToAdd = pricing.PricingAccessories.concat(list);
    dispatch(pricingSetAccessories(accToAdd));
  };

  return (
    <div>
      <PricingAccessoriesList
        accessories={PricingAccessories}
        actions={{ remove: handleRemove }}
        editable={pricing.listEditable}
      />
      <Button type="primary" onClick={handleModal}>
        Dodaj akcesorium
      </Button>

      {/* MODAL */}
      {modal.addAccessory && (
        <PickAccessoriesModal
          closeModal={closeModal}
          visible={modal.addAccessory}
          accessories={accessories}
          handleAddAccessories={handleAddAccessories}
          selectedIds={[]}
        />
      )}
    </div>
  );
};

export default Accessories;
