import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { CaretDownFilled } from '@ant-design/icons';
import styled from 'styled-components';

const { SubMenu } = Menu;

const StyledMenu = styled(Menu)`
  display: flex;
  justify-content: flex-end;
`;

interface PropsT {
  handleLogOut: () => void;
}

const OfficeNav: React.FC<PropsT> = ({ handleLogOut }) => {
  const location = useLocation();

  return (
    <StyledMenu
      selectedKeys={[location.pathname]}
      theme="dark"
      mode="horizontal"
    >
      <Menu.Item key="/">
        <NavLink to="/">Strona główna</NavLink>
      </Menu.Item>
      <Menu.Item key="/customers">
        <NavLink to="/customers">Klienci</NavLink>
      </Menu.Item>
      <Menu.Item key="/schedule">
        <NavLink to="/schedule">Terminarz</NavLink>
      </Menu.Item>
      <SubMenu
        title={
          <span className="submenu-title-wrapper">
            Baza
            <CaretDownFilled />
          </span>
        }
      >
        {/* <Menu.Item key="/suppliers">
          <NavLink to="/suppliers">Dystrybutorzy</NavLink>
        </Menu.Item> */}

        <Menu.Divider />
        <Menu.Item key="/cabinets">
          <NavLink to="/cabinets">Szafki</NavLink>
        </Menu.Item>
        <Menu.Item key="/accessories">
          <NavLink to="/accessories">Akcesoria</NavLink>
        </Menu.Item>

        <Menu.Divider />
        <Menu.Item key="/catalog/boards">
          <NavLink to="/catalog/boards">Płyty</NavLink>
        </Menu.Item>
        <Menu.Item key="/catalog/veneers">
          <NavLink to="/catalog/veneers">Obrzeża</NavLink>
        </Menu.Item>
        <Menu.Item key="/catalog/tables">
          <NavLink to="/catalog/tables">Blaty</NavLink>
        </Menu.Item>
        <Menu.Item key="/catalog/fronts">
          <NavLink to="/catalog/fronts">Fronty</NavLink>
        </Menu.Item>
        <Menu.Item key="/catalog/paints">
          <NavLink to="/catalog/paints">Lakiery</NavLink>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        title={
          <span className="submenu-title-wrapper">
            Konto
            <CaretDownFilled />
          </span>
        }
      >
        <Menu.Item key="/account">
          <NavLink to="/account">Moje dane</NavLink>
        </Menu.Item>
        <Menu.Item onClick={handleLogOut}>Wyloguj</Menu.Item>
      </SubMenu>
    </StyledMenu>
  );
};

export default OfficeNav;
