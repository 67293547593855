import { PermissionsT } from 'services/store/types/auth/Auth';
import { FileT, ProjectT } from 'services/store/types/projects/Projects';
import { perms, states } from 'services/utils/const';
import {
  agreementFileError,
  agreementScanError,
  firstPaymentPartError,
  lateDesignerError,
  measureImagesError,
  measureTermError,
  measureWeekError,
  montageAddressError,
  montageTermError,
  secondPaymentPartError,
  technologistFileError,
  thirdPaymentPartError,
} from './projectWarns';
import { ProjectErrorsT, ProjectErrorsTypesT, ProjectErrorT } from './types';

export const checkIsProjectsHasErrors = (
  projects: ProjectT[],
  permission: PermissionsT,
  files: FileT[]
) => {
  let err: ProjectErrorsTypesT = null;

  for (let i = 0; i < projects.length; i++) {
    if (err === 'danger') break;
    const project = projects[i];

    if (project.status !== 'zakończone') {
      const projectErrorType = checkProjectErrors(project, permission, files);
      if (projectErrorType.type) err = projectErrorType.type;
    }
  }

  return err;
};

export const checkProjectErrors = (
  project: ProjectT,
  permission: PermissionsT,
  files: FileT[]
) => {
  const err: ProjectErrorsT = { errors: [], type: null };

  const addError = (newError: ProjectErrorT | null) => {
    if (newError) {
      if (err.type !== 'danger') err.type = newError.type;
      err.errors.push(newError);
    }
  };

  if (project.status !== 'zakończone')
    if (permission === perms.ADMIN) adminErrors(project, addError, files);
    else if (
      permission === perms.DESIGNER &&
      [states.PENDING, states.PROJECT].includes(project.status)
    )
      designerErrors(project, addError, files);
    else if (permission === perms.TECHNOLOGIST)
      technologistErrors(project, addError, files);
  return err;
};

const adminErrors = (
  project: ProjectT,
  addError: (error: ProjectErrorT | null) => void,
  files?: FileT[]
) => {
  // PŁATNOŚCI
  //    1 część
  if (!files?.length && project?.Files) files = project.Files;
  if (!!files?.length) {
    const agreementFile = files.find((file: FileT) =>
      file.name.includes('Skan umowy')
    );
    if (!agreementFile) {
      const agreementError = agreementFileError(project);

      addError(agreementError);
    } else {
      const firstPaymentError = firstPaymentPartError(project, agreementFile);
      addError(firstPaymentError);
    }

    // Brak plików od technologa
    if ([states.TECH].includes(project.status)) {
      const technologistError = technologistFileError(project, files);
      addError(technologistError);
    }
  }

  //   2 część
  const secondPaymentError = secondPaymentPartError(project);
  addError(secondPaymentError);

  //   3 część
  const thirdPaymentError = thirdPaymentPartError(project);
  addError(thirdPaymentError);

  // Projekt u projektanta w tygodniu pomiaru
  if ([states.PROJECT, states.PENDING].includes(project.status)) {
    const designerError = lateDesignerError(project);
    addError(designerError);
  }

  //   Brak terminu montażu
  const montageError = montageTermError(project);
  addError(montageError);

  //   Brak terminu pomiaru
  const montageWeek = measureWeekError(project);
  addError(montageWeek);

  //   Brak adresu montażu
  const montageAddress = montageAddressError(project);
  addError(montageAddress);
};

const technologistErrors = (
  project: ProjectT,
  addError: (error: ProjectErrorT | null) => void,
  files?: FileT[]
) => {
  if (!files?.length && project?.Files) files = project.Files;
  if ([states.TECH].includes(project.status)) {
    // Brak terminu pomiaru
    const measureError = measureTermError(project);
    addError(measureError);

    if (!!files?.length) {
      // Dzień po pomiarze prosić o zdjęcia
      const measureImages = measureImagesError(project, files);
      addError(measureImages);

      // W ciągu tygodnia od pomiaru prosić o pliki
      const technologistError = technologistFileError(project, files);
      addError(technologistError);
    }
  }

  //   Brak terminu montażu
  const montageError = montageTermError(project);
  addError(montageError);
};

const designerErrors = (
  project: ProjectT,
  addError: (error: ProjectErrorT | null) => void,
  files: FileT[]
) => {
  // Projekt u projektanta w tygodniu pomiaru
  if ([states.PROJECT, states.PENDING].includes(project.status)) {
    const designerError = lateDesignerError(project);
    addError(designerError);
  }

  // 2 dni po pobraniu umowy prosić o skan
  const agreementScan = agreementScanError(files);
  addError(agreementScan);

  //   Brak adresu montażu
  const montageAddress = montageAddressError(project);
  addError(montageAddress);

  //   Brak terminu pomiaru
  const montageWeek = measureWeekError(project);
  addError(montageWeek);
};
