import { Button, message, PageHeader } from 'antd';
import React, { useEffect, useState } from 'react';
import { StyledH1 } from 'components/Header';
import { PricingAccessoryT } from 'services/store/types/pricing/Pricing';
import { NewOrderT } from 'services/store/types/orders/Orders';
import {
  createOrdersFromAccessories,
  getOrdersPriceAndExpectedPickUpDate,
} from 'scenes/ProjectOrders/utils';
import update from 'immutability-helper';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { createProjectOrders } from 'services/store/actions/orders';
import Order from 'components/Orders/NewOrder';
import _ from 'lodash';

interface PropsT {
  accessories: PricingAccessoryT[];
}

const NewOrders: React.FC<PropsT> = ({ accessories }) => {
  const dispatch = useDispatch();
  const params: { id: string } = useParams();
  const [orders, setOrders] = useState<NewOrderT[]>([]);

  useEffect(() => {
    if (accessories.length) {
      const newOrders = createOrdersFromAccessories(accessories, params.id);
      setOrders(newOrders);
    }
  }, []);

  const handleField = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    key: 'name' | 'description'
  ) => {
    const copy = _.cloneDeep(orders);
    const newOrders = update(copy, {
      [index]: { [key]: { $set: e.target.value } },
    });
    setOrders(newOrders);
  };

  const handleQty = (
    value: number | string | undefined | null,
    index: number,
    accessoryIndex: number
  ) => {
    if (value && typeof value === 'number') {
      const copy = _.cloneDeep(orders);
      const newOrders = update(copy, {
        [index]: {
          OrderAccessories: {
            [accessoryIndex]: { qty: { $set: value } },
          },
        },
      });
      setOrders(newOrders);
    }
  };

  const handleCreateOrders = async () => {
    dispatch(setSpinner(true));
    const newOrders = getOrdersPriceAndExpectedPickUpDate(orders);
    dispatch(createProjectOrders(params.id, newOrders));
    message.success('Zamówienia zostały wygenerowane');
  };

  return (
    <div>
      <PageHeader
        title={<StyledH1>Generowanie zamówień</StyledH1>}
        extra={[
          <Button key={1} onClick={handleCreateOrders}>
            Utwórz zamówienia
          </Button>,
          <Button type="primary" key={2} disabled onClick={() => {}}>
            Utwórz i wyślij
          </Button>,
        ]}
      />
      {orders.map((order: NewOrderT, index: number) => (
        <Order
          order={order}
          key={order.SupplierId}
          index={index}
          handleField={handleField}
          handleQty={handleQty}
        />
      ))}
    </div>
  );
};

export default NewOrders;
