import React, { useState, useEffect } from 'react';
import PageTemplate from 'templates/PageTemplate';
import { PageHeader, message } from 'antd';
import SupplierModal from 'components/Base/Suppliers/SupplierModal';
import { useSelector, useDispatch } from 'react-redux';
import { AppStateT } from 'services/store';
import { updateSupplier } from 'services/api/suppliers/update';
import { setSpinner } from 'services/store/actions/view';
import { removeSupplier } from 'services/api/suppliers/remove';
import { useHistory } from 'react-router';
import { SupplierT } from 'services/store/types/suppliers/Suppliers';
import {
  getSupliers,
  updateSupplierInList,
  removeSupplierFromList,
} from 'services/store/actions/suppliers';
import { StyledH1 } from 'components/Header';
import List from './components/List';

const SuppliersPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [toUpdate, setToUpdate] = useState<SupplierT | null>(null);
  const suppliers = useSelector(
    (state: AppStateT) => state.suppliers.suppliers
  );

  useEffect(() => {
    if (!suppliers.length) dispatch(setSpinner(true));
    dispatch(
      getSupliers(
        () => {
          dispatch(setSpinner(false));
        },
        () => {
          dispatch(setSpinner(false));
          message.error('Błąd');
        }
      )
    );
  }, []);

  const handleRedirect = (supplier: SupplierT) => {
    history.push(`/suppliers/${supplier.id}`);
  };
  const handleUpdateSupplier = async (
    supplier: SupplierT,
    setLoading: (x: boolean) => void
  ) => {
    if (toUpdate && toUpdate.id) {
      await updateSupplier(
        toUpdate.id,
        supplier,
        (updatedSupplier) => {
          dispatch(updateSupplierInList(updatedSupplier));
          message.success('Dystrybutor zaktualizowany');
          setLoading(false);
          setToUpdate(null);
        },
        () => {
          setLoading(false);
          message.error('Błąd');
        }
      );
    }
  };
  const handleRemoveSupplier = async (supplier: SupplierT) => {
    if (supplier.id) {
      const { id } = supplier;
      dispatch(setSpinner(true));
      await removeSupplier(
        id,
        () => {
          dispatch(setSpinner(false));
          dispatch(removeSupplierFromList(id));
          message.success('Kategoria usunięta');
        },
        () => {
          dispatch(setSpinner(false));
          message.error('Błąd');
        }
      );
    }
  };

  return (
    <PageTemplate>
      <PageHeader title={<StyledH1>Dystrybutorzy</StyledH1>} />
      <List
        suppliers={suppliers}
        handleRedirect={handleRedirect}
        handleRemove={handleRemoveSupplier}
        handleUpdate={setToUpdate}
      />

      {/* MODALS */}
      {toUpdate && (
        <SupplierModal
          visible={!!toUpdate}
          title="Edycja dystrybutora"
          closeModal={() => setToUpdate(null)}
          values={toUpdate}
          handleSubmit={handleUpdateSupplier}
        />
      )}
    </PageTemplate>
  );
};

export default SuppliersPage;
