import { Button } from 'antd';
import CabinetFrontsConfigsList from 'components/Base/Cabinets/CabinetFrontsConfigsList';
import React, { useState } from 'react';
import { CabinetFrontConfigT } from 'services/store/types/cabinets/Cabinets';
import { initCabinetFrontConfig } from 'services/utils/const/initData';
import CabinetFrontConfigModal from '../../../../components/Base/Cabinets/CabinetFrontConfigModal';
import { getNextConfigNumber } from './utils';

interface PropsT {
  fronts: CabinetFrontConfigT[];
  push: (values: CabinetFrontConfigT) => void;
  remove: (index: number) => void;
  replace: (index: number, values: CabinetFrontConfigT) => void;
}
interface FrontConfigT {
  index: number;
  data: CabinetFrontConfigT;
}

const CabinetFrontsFormList: React.FC<PropsT> = ({
  fronts,
  push,
  remove,
  replace,
}) => {
  const [config, setConfig] = useState<FrontConfigT | null>(null);

  const handleConfig = (data: CabinetFrontConfigT) => {
    if (config) {
      if (config.index > -1) replace(config.index, data);
      else push(data);
    }
    setConfig(null);
  };

  return (
    <div>
      <div>
        <CabinetFrontsConfigsList
          fronts={fronts}
          actions={{
            remove,
            update: (index, data) => setConfig({ index, data }),
          }}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <Button
          type="primary"
          onClick={() =>
            setConfig({
              index: -1,
              data: {
                ...initCabinetFrontConfig,
                config: getNextConfigNumber(fronts),
              },
            })
          }
        >
          Dodaj konfigurację
        </Button>
      </div>

      {/* MODAL */}
      {/* NOTE Add/Edit element */}
      {config && (
        <CabinetFrontConfigModal
          closeModal={() => setConfig(null)}
          visible={!!config}
          handleSubmit={handleConfig}
          initValues={config.data}
        />
      )}
    </div>
  );
};

export default CabinetFrontsFormList;
