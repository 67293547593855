import { Moment } from 'moment';
import { TermT } from './Terms';

export const TERMS_LOAD_SUCCESS = 'TERMS_LOAD_SUCCESS';
export const TERMS_LOAD_ERROR = 'TERMS_LOAD_ERROR';
export const TERMS_SET_DATE = 'TERMS_SET_DATE';
export const TERMS_SET_FILTER = 'TERMS_SET_FILTER';

export interface TermsSetFilterT {
  type: typeof TERMS_SET_FILTER;
  key: 'customerId' | 'type';
  value: string | number | undefined;
}
export interface TermsSetDateT {
  type: typeof TERMS_SET_DATE;
  date: Moment;
}
export interface TermsLoadSuccessT {
  type: typeof TERMS_LOAD_SUCCESS;
  terms: TermT[];
}
export interface TermsLoadErrorT {
  type: typeof TERMS_LOAD_ERROR;
}

export type TermsActionsT =
  | TermsLoadErrorT
  | TermsSetFilterT
  | TermsLoadSuccessT
  | TermsSetDateT;
