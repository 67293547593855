import React, { useState } from 'react';
import PageTemplate from 'templates/PageTemplate';
import { message, PageHeader, Tabs } from 'antd';
import { AlertOutlined, ContactsOutlined } from '@ant-design/icons';
import { StyledH1 } from 'components/Header';
import Contact from './components/Contact';
import OtherSettings from './components/Other';
import ContentTemplate from 'templates/ContentTemplate/ContentTemplate';
import SiderTemplate from 'templates/SiderTemplate/SiderTemplate';
import { useModal } from 'services/hooks/useModal';
import { useMutation, useQuery } from '@tanstack/react-query';
import { settingsApi } from 'services/api/settings.api';
import Spinner from 'components/Spinner';
import SettingsSider from './sider/SettingsSider';
import AddAddress from 'components/Address/AddressModal/AddAddress';
import ChangeCompanyData from './components/modals/ChangeCompanyData';
import { AddressT } from 'services/store/types/settings/Settings';

const { TabPane } = Tabs;

const initModal = {
  fvAddress: false,
  localAddress: false,
  companyData: false,
};
export type TSettingsModal = keyof typeof initModal;

const SettingsPage = () => {
  const [tab, setTab] = useState('contact');
  const { modal, showModal, closeModal } = useModal(initModal);

  const { data, isLoading, isSuccess, refetch } = useQuery({
    queryKey: ['get-settings'],
    queryFn: () => settingsApi.getSettings(),
    onError() {
      message.error('Błąd');
    },
  });

  const updateAddress = useMutation(
    async (address: AddressT) => settingsApi.updateCompanyAddress(address),
    {
      onSuccess() {
        refetch();
        closeModal();
        message.success('Adres zaktualizowany');
      },
      onError() {
        message.error('Błąd');
      },
    }
  );

  return (
    <PageTemplate>
      {isLoading && <Spinner />}
      <SiderTemplate>
        <SettingsSider showModal={showModal} isSettings={isSuccess} />
      </SiderTemplate>
      <ContentTemplate>
        <PageHeader title={<StyledH1>Ustawienia</StyledH1>} />
        <Tabs defaultActiveKey={tab}>
          <TabPane
            tab={
              <span>
                <ContactsOutlined />
                Kontakt
              </span>
            }
            key="contact"
          >
            {data?.data.settings.contact && (
              <Contact contact={data.data.settings.contact} />
            )}
          </TabPane>
          <TabPane
            tab={
              <span>
                <AlertOutlined />
                Inne
              </span>
            }
            key="other"
          >
            {data?.data.settings.other && (
              <OtherSettings settings={data.data.settings.other} />
            )}
          </TabPane>
        </Tabs>
      </ContentTemplate>
      {/* MODALS */}

      {modal.fvAddress && (
        <AddAddress
          closeModal={closeModal}
          values={data?.data.settings.contact?.fvAddress}
          title="Adres firmy"
          visible={modal.fvAddress}
          handleSubmit={updateAddress.mutate}
        />
      )}
      {modal.localAddress && (
        <AddAddress
          closeModal={closeModal}
          values={data?.data.settings.contact?.localAddress}
          title="Adres lokalu"
          visible={modal.localAddress}
          handleSubmit={updateAddress.mutate}
        />
      )}
      {modal.companyData && data?.data.settings.contact && (
        <ChangeCompanyData
          closeModal={closeModal}
          values={data.data.settings.contact}
          visible={modal.companyData}
          refetchSettings={refetch}
        />
      )}
    </PageTemplate>
  );
};

export default SettingsPage;
