import React from 'react';
import { CustomerT } from 'services/store/types/customers/Customers';
import { Select, Skeleton, message, Popover, Row, Col } from 'antd';
import { getPersonalName } from 'services/utils/users';
import { useDispatch } from 'react-redux';
import {
  setNewProjectCustomer,
  clearNewProjectCustomer,
} from 'services/store/actions/newProject';
import { AddressT } from 'services/store/types/settings/Settings';
import AddressData from 'components/Address/Display';
import PersonalData from 'components/Users/PersonalData';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

interface PropsT {
  customers: CustomerT[];
  customer?: CustomerT;
  customerId?: string;
}

const CustomerData: React.FC<PropsT> = ({
  customers,
  customer,
  customerId,
}) => {
  const dispatch = useDispatch();

  const handleCustomer = (id: string) => {
    const customerData = customers.find((item) => item.id === id);
    if (customerData) {
      dispatch(setNewProjectCustomer(customerData));
      message.success('Wybrałeś klienta');
    }
  };

  const handleClearCustomer = () => {
    dispatch(clearNewProjectCustomer());
    message.success('Wyczyściłeś dane klienta');
  };

  return (
    <div>
      <Select
        showSearch
        size="large"
        onClear={handleClearCustomer}
        placeholder="Klient"
        optionFilterProp="children"
        filterOption={(input: any, option: any) =>
          !!(
            option?.children
              ?.toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          )
        }
        allowClear
        value={customerId}
        onChange={handleCustomer}
        style={{ width: 200, marginBottom: 30 }}
      >
        {customers
          .sort((a, b) => `${a.surname}`.localeCompare(b.surname))
          .map((item: CustomerT) => {
            return (
              <Option key={item.id} value={item.id}>
                {getPersonalName(item)}
              </Option>
            );
          })}
      </Select>
      <div>
        {customer ? (
          <Data customer={customer} address={customer.CustomerAddress} />
        ) : (
          <>
            <Skeleton paragraph={{ rows: 4 }} title={{ width: 200 }} />
          </>
        )}
      </div>
    </div>
  );
};

export default CustomerData;

const Data = ({
  customer,
  address,
}: {
  customer: CustomerT;
  address: AddressT | undefined;
}) => (
  <Row>
    <Col xs={12} md={3}>
      <PersonalData
        title={
          <span>
            Dane klienta{' '}
            {customer.CustomerBlackList && (
              <Popover
                title="Czarna lista"
                content={customer.CustomerBlackList.description}
              >
                <ExclamationCircleOutlined style={{ color: 'red' }} />
              </Popover>
            )}
          </span>
        }
        customer={customer}
        loading={!customer}
        column={1}
      />
    </Col>
    <Col xs={12} md={3}>
      <AddressData address={address} column={1} />
    </Col>
  </Row>
);
