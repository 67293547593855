import React, { useState, useEffect } from 'react';
import { ProjectT } from 'services/store/types/projects/Projects';
import { Table } from 'antd';
import TextButton from 'components/TextButton';
import { useHistory } from 'react-router';
import { formatAddress } from 'services/utils/string';
import { dateToString } from 'services/utils/date';
import { getPersonalName } from 'services/utils/users';
import { ProjectStatusTag } from '../StatusTag';

const columns = [
  {
    title: 'Lp',
    align: 'center' as const,
    render: (t: string, r: any, i: number) => i + 1,
  },
  { title: 'Nazwa projektu', dataIndex: 'name', align: 'center' as const },
  { title: 'Adres montażu', dataIndex: 'address', align: 'center' as const },
  {
    title: 'Data utworzenia',
    dataIndex: 'createdAt',
    align: 'center' as const,
  },
  { title: 'Data montażu', dataIndex: 'montageAt', align: 'center' as const },
  { title: 'Projektant', dataIndex: 'designer', align: 'center' as const },
  { title: 'Status', dataIndex: 'status', align: 'center' as const },
  { title: 'Numer', dataIndex: 'number', align: 'center' as const },
];

interface PropsT {
  projects: ProjectT[] | undefined;
}

const ProjectsList: React.FC<PropsT> = ({ projects }) => {
  const history = useHistory();
  const [list, setList] = useState<any>(null);

  useEffect(() => {
    if (projects) {
      const newList = projects.map((project: ProjectT) => {
        return {
          name: (
            <TextButton
              text={project.name}
              onClick={() => history.push(`/projects/${project.id}`)}
            />
          ),
          address: project.montageAddress
            ? formatAddress(project.montageAddress)
            : '-',
          createdAt: dateToString(project.startAt),
          montageAt: project.montageTerm
            ? dateToString(project.montageTerm.startDate)
            : '-',
          designer: (
            <TextButton
              text={project.User ? getPersonalName(project.User) : '-'}
              onClick={() => history.push(`/users/${project.UserId}`)}
            />
          ),
          status: <ProjectStatusTag status={project.status} />,
          number: project.number,
          key: project.id,
        };
      });
      setList(newList);
    }
  }, [projects]);

  return <Table columns={columns} dataSource={list} loading={!list} />;
};

export default ProjectsList;
