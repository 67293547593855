import { useSelector } from 'react-redux';
import { AppStateT } from 'services/store';
import { PermissionsT } from 'services/store/types/auth/Auth';
import { perms } from 'services/utils/const';

const useUser = () => {
  const user = useSelector((state: AppStateT) => state.auth.user);

  const isAdmin = () => {
    if (user?.permission === perms.ADMIN) return true;
    return false;
  };
  const isDesigner = () => {
    if (user?.permission === perms.DESIGNER) return true;
    return false;
  };
  const isTechnologist = () => {
    if (user?.permission === perms.TECHNOLOGIST) return true;
    return false;
  };
  const isExternalCompany = () => {
    if (user?.permission === perms.EXTERNAL_COMPANY) return true;
    return false;
  };
  const isCarpenter = () => {
    if (user?.permission === perms.CARPENTER) return true;
    return false;
  };
  const isInstaller = () => {
    if (user?.permission === perms.INSTALLER) return true;
    return false;
  };
  const isOffice = () => {
    if (user?.permission === perms.OFFICE) return true;
    return false;
  };
  const getPermission = () => {
    if (user) return user.permission;
    return undefined;
  };
  const hasPermission = (permissions: PermissionsT[] | string) => {
    if (!user) return false;
    if (permissions.includes(user.permission)) return true;
    return false;
  };

  return {
    isAdmin,
    isDesigner,
    isTechnologist,
    isExternalCompany,
    isCarpenter,
    isInstaller,
    isOffice,
    getPermission,
    hasPermission,
    user,
  };
};

export default useUser;
