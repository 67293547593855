import React from 'react';
import { Tag } from 'antd';
import { orderStates, states } from 'services/utils/const';

interface PropsT {
  status: string;
}
export const ProjectStatusTag: React.FC<PropsT> = ({ status }) => {
  let color = 'cyan';
  if (status === states.PROJECT) color = 'blue';
  if (status === states.TECH) color = 'purple';
  if (status === states.PRODUCTION) color = 'orange';
  if (status === states.MONTAGE) color = 'gold';
  if (status === states.ENDED) color = 'green';
  if (status === states.CANCEL) color = 'red';
  if (status === states.RECLAMATION) color = 'magenta';
  return <Tag color={color}>{status.toUpperCase()}</Tag>;
};

export const OrderStatusTag: React.FC<PropsT> = ({ status }) => {
  let color = 'cyan';
  if (status === orderStates.CREATED) color = 'gold';
  if (status === orderStates.SENDED) color = 'blue';
  if (status === orderStates.PART_PICKED) color = 'magenta';
  if (status === orderStates.CANCEL) color = 'red';
  if (status === orderStates.ENDED) color = 'green';
  return <Tag color={color}>{status.toUpperCase()}</Tag>;
};
