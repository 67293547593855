import { Moment } from 'moment';
import { OrderT } from '../orders/Orders';
import { PricingT } from '../pricing/Pricing';
import { ProjectT, CustomerProjectsT, FileT } from './Projects';

export const PROJECTS_LOAD_SUCCESS = 'PROJECTS_LOAD_SUCCESS';
export const PROJECTS_CUSTOMERS_LOAD_SUCCESS =
  'PROJECTS_CUSTOMERS_LOAD_SUCCESS';
export const PROJECTS_LOAD_ERROR = 'PROJECTS_LOAD_ERROR';
export const PROJECTS_SET_PROJECT = 'PROJECTS_SET_PROJECT';
export const PROJECTS_SET_PROJECT_PRICING = 'PROJECTS_SET_PROJECT_PRICING';
export const PROJECTS_SET_PROJECT_ORDERS = 'PROJECTS_SET_PROJECT_ORDERS';
export const PROJECTS_ADD_TO_LIST = 'PROJECTS_ADD_TO_LIST';
export const PROJECTS_CLEAR_LIST = 'PROJECTS_CLEAR_LIST';
export const PROJECTS_EXPAND_ROW = 'PROJECTS_EXPAND_ROW';
export const PROJECTS_COLLAPSE_ROW = 'PROJECTS_RECOLLAPSE_ROW';
export const PROJECTS_SET_FILTERS_FIELD = 'PROJECTS_SET_FILTERS_FIELD';
export const UPDATE_FILES_IN_PROJECT = 'UPDATE_FILES_IN_PROJECT';

export interface UpdateFileInProjectT {
  type: typeof UPDATE_FILES_IN_PROJECT;
  files: FileT[];
}
export interface ProjectsLoadSuccessT {
  type: typeof PROJECTS_LOAD_SUCCESS;
  projects: ProjectT[];
}
export interface ProjectCustomersLoadSuccessT {
  type: typeof PROJECTS_CUSTOMERS_LOAD_SUCCESS;
  list: ProjectsListsT;
  customers: CustomerProjectsT[];
}
export interface ProjectsLoadErrorT {
  type: typeof PROJECTS_LOAD_ERROR;
}
export interface ProjectsSetProjectT {
  type: typeof PROJECTS_SET_PROJECT;
  project: ProjectT | null;
}
export interface ProjectsSetProjectPricingT {
  type: typeof PROJECTS_SET_PROJECT_PRICING;
  pricing: PricingT | null;
}
export interface ProjectsSetProjectOrdersT {
  type: typeof PROJECTS_SET_PROJECT_ORDERS;
  orders: OrderT[];
}
export interface ProjectsAddToListT {
  type: typeof PROJECTS_ADD_TO_LIST;
  project: ProjectT;
}
export interface ProjectsClearListT {
  type: typeof PROJECTS_CLEAR_LIST;
  list: ProjectsListsT;
}
export interface ProjectsExpandRowT {
  type: typeof PROJECTS_EXPAND_ROW;
  list: ProjectsListsT;
  key: string;
}
export interface ProjectsCollapseRowT {
  type: typeof PROJECTS_COLLAPSE_ROW;
  list: ProjectsListsT;
  key: string;
}
export interface ProjectSetFiltersFielsT {
  type: typeof PROJECTS_SET_FILTERS_FIELD;
  list: ProjectsListsT;
  field: ProjectFiltersFieldsT;
  value: [Moment, Moment];
}

export type ProjectsActionsT =
  | UpdateFileInProjectT
  | ProjectsSetProjectOrdersT
  | ProjectsSetProjectPricingT
  | ProjectSetFiltersFielsT
  | ProjectsCollapseRowT
  | ProjectsExpandRowT
  | ProjectCustomersLoadSuccessT
  | ProjectsClearListT
  | ProjectsLoadSuccessT
  | ProjectsLoadErrorT
  | ProjectsSetProjectT
  | ProjectsAddToListT;

export type ProjectsListsT = 'active' | 'ended';
export type ProjectFiltersFieldsT = 'dates';
