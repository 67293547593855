import React, { useState, useEffect } from 'react';
import { Image, Table } from 'antd';
import {
  CabinetAccessoryT,
  CabinetT,
} from 'services/store/types/cabinets/Cabinets';
import { cutString } from 'services/utils/string';
import imgPlaceholder from 'assets/images/placeholder.png';
import { useHistory } from 'react-router';
import TextButton from 'components/TextButton';
import { domain } from 'services/utils/const';

const columns: any[] = [
  {
    title: 'Lp.',
    align: 'center' as const,
    render: (t: string, r: any, i: number) => i + 1,
  },
  {
    title: 'Zdjęcie',
    dataIndex: 'img',
    align: 'center' as const,
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
    align: 'center' as const,
  },
  {
    title: (
      <div>
        <div>Nazwa</div>
        <div>
          <small>opis</small>
        </div>
      </div>
    ),
    dataIndex: 'name',
    align: 'center' as const,
    render: (value: string, record: CabinetT) => (
      <div>
        <div>{value || '-'}</div>
        <div>
          <small title={record.description}>
            {cutString(record.description || '-', 25)}
          </small>
        </div>
      </div>
    ),
  },
  {
    title: 'Kategoria',
    dataIndex: 'cabinetTypeName',
    align: 'center' as const,
    defaultSortOrder: 'ascend' as const,
    sorter: (a: any, b: any) =>
      a.cabinetTypeName.localeCompare(b.cabinetTypeName),
  },
  {
    title: (
      <div>
        <div>Wymiary</div>
        <div>
          <small>Wys/Szer/Głęb</small>
        </div>
      </div>
    ),
    dataIndex: 'dimension',
    align: 'center' as const,
  },
  {
    title: (
      <div>
        <div>Cena</div>
        <div>
          <small>korpus</small>
        </div>
      </div>
    ),
    dataIndex: 'price',
    align: 'center' as const,
  },
  {
    title: (
      <div>
        <div>Cena</div>
        <div>
          <small>korpus + akcesoria</small>
        </div>
      </div>
    ),
    dataIndex: 'priceWithAccessories',
    align: 'center' as const,
  },
];

interface PropsT {
  cabinets: CabinetT[];
}

const CabinetsList: React.FC<PropsT> = ({ cabinets }) => {
  const history = useHistory();
  const [list, setList] = useState<any[]>([]);

  const handleRedirect = (path: string) => history.push(path);
  useEffect(() => {
    const newList = cabinets.map((cabinet: CabinetT, i: number) => {
      const accessoriesPrice = cabinet.CabinetAccessories.reduce(
        (price: number, accessory: CabinetAccessoryT) => {
          const accPrice =
            accessory.defaultQty *
            (accessory.Accessory ? accessory.Accessory.price : 0);
          const newPrice = price + accPrice;
          return newPrice;
        },
        0
      );

      return {
        ...cabinet,
        symbol: (
          <TextButton
            text={cabinet.symbol}
            onClick={() => handleRedirect(`/cabinets/${cabinet.id}`)}
          />
        ),
        key: cabinet.id,
        img: (
          <Image
            width={40}
            height={40}
            src={
              cabinet.miniaturePath
                ? `${domain}${cabinet.miniaturePath}`
                : imgPlaceholder
            }
            alt="img"
            style={{ cursor: 'pointer' }}
            preview={{
              src: `${domain}${cabinet.imgPath}`,
            }}
          />
        ),
        cabinetTypeName: cabinet.CabinetType.name,
        dimension: `${cabinet.height}/${cabinet.width}/${cabinet.depth}`,
        priceWithAccessories: cabinet.price + accessoriesPrice,
      };
    });

    setList(newList);
  }, [cabinets]);

  return (
    <div>
      <Table
        columns={columns}
        pagination={{ position: ['topRight'] }}
        dataSource={list}
        size="small"
      />
    </div>
  );
};

export default CabinetsList;
