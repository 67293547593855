import axios, { AxiosResponse } from 'axios';
import { UserT } from 'services/store/types/auth/Auth';
import setAuthToken from 'services/utils/setAuthToken';

export const usersApi = {
  //NOTE GET
  getAllUsers: (): Promise<AxiosResponse<{ users: UserT[] }>> => {
    setAuthToken();
    return axios.get(`/api/users`);
  },
  getUserById: (id: string): Promise<AxiosResponse<{ user: UserT }>> => {
    setAuthToken();
    return axios.get(`/api/users/user/${id}`);
  },
};
