import React, { useEffect, useState } from 'react';
import { Table, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import TextButton from 'components/TextButton';
import { getPersonalName } from 'services/utils/users';
import { CustomerT } from 'services/store/types/customers/Customers';
import { setCustomer } from 'services/store/actions/customers';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { formatAddress, formatPhoneNumber } from 'services/utils/string';
import { AppStateT } from 'services/store';
import { ProjectT } from 'services/store/types/projects/Projects';
import { UserT } from 'services/store/types/auth/Auth';
import { perms } from 'services/utils/const';

const { Text } = Typography;

const columns = [
  {
    title: 'Lp.',
    align: 'center' as const,
    render: (t: string, r: any, i: number) => i + 1,
  },
  {
    title: 'Imię i nazwisko',
    dataIndex: 'name',
    align: 'center' as const,
  },
  {
    title: 'Nazwa firmy',
    dataIndex: 'company',
    align: 'center' as const,
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    align: 'center' as const,
  },
  {
    title: 'Telefon',
    dataIndex: 'phone',
    align: 'center' as const,
  },
  {
    title: 'Adres',
    dataIndex: 'address',
    align: 'center' as const,
  },
  {
    title: 'Ilość projektów',
    dataIndex: 'projectsCount',
    align: 'center' as const,
  },
];

interface PropsT {
  customers: CustomerT[];
  loading: boolean;
}

const List: React.FC<PropsT> = ({ customers, loading }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: AppStateT) => state.auth.user);
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    if (user) {
      const newList = customers
        .filter((customer: CustomerT) =>
          user.permission !== perms.ADMIN
            ? checkIsCustomerHasDesignerProjects(customer.Projects, user)
            : true
        )
        .sort((a, b) => `${a.surname || ''}`.localeCompare(b.surname || ''))
        .map((customer: CustomerT, i: number) => ({
          key: i,
          name: (
            <TextButton
              text={
                <span>
                  {getPersonalName(customer)}{' '}
                  {customer.CustomerBlackList && (
                    <ExclamationCircleOutlined style={{ color: 'red' }} />
                  )}
                </span>
              }
              onClick={() => handleCustomerClick(customer)}
            />
          ),
          company: customer.company || '-',
          email: <Text copyable>{customer.email}</Text>,
          phone: formatPhoneNumber(customer.phone),
          address: customer.CustomerAddress
            ? formatAddress(customer.CustomerAddress)
            : '-',
          projectsCount: customer.Projects?.length,

          id: customer.id,
        }));

      setList(newList);
    }
  }, [customers, user]);

  const handleCustomerClick = (customer: CustomerT) => {
    dispatch(setCustomer(customer));
    history.push(`/customers/${customer.id}`);
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={list}
        pagination={false}
        loading={loading}
      />
    </div>
  );
};

export default List;

const checkIsCustomerHasDesignerProjects = (
  projects: ProjectT[] | undefined,
  designer: UserT
) => {
  if (!projects || projects.length === 0) return true;
  let result = false;
  for (let i = 0; i < projects.length; i++) {
    const { UserId } = projects[i];
    if (result) break;
    if (UserId === designer.id) result = true;
  }
  return result;
};
