import React, { useEffect, useState } from 'react';
import PageTemplate from 'templates/PageTemplate';
import { message, PageHeader } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { AppStateT } from 'services/store';
import { getTermsByYear, termsSetDate } from 'services/store/actions/terms';
import { setSpinner } from 'services/store/actions/view';
import moment, { Moment } from 'moment';
import { StyledH1 } from 'components/Header';
import { TermT } from 'services/store/types/terms/Terms';
import Filters from './components/Filters';
import CalendarView from './components/Calendar';
import { filterTermsByCustomer, filterTermsByType } from './utils';
import { useQuery } from '@tanstack/react-query';
import { termsApi } from 'services/api/terms.api';
import Spinner from 'components/Spinner';
import { perms } from 'services/utils/const';
import ContentTemplate from 'templates/ContentTemplate/ContentTemplate';

const SchedulePage = () => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(moment());
  const user = useSelector((state: AppStateT) => state.auth.user);
  const filters = useSelector((state: AppStateT) => state.terms.filters);

  const [terms, setTerms] = useState<TermT[]>([]);
  const [filteredTerms, setFilteredTerms] = useState<TermT[]>([]);

  const { data, isLoading } = useQuery({
    queryKey: ['get-schedule', date],
    queryFn: () => termsApi.getTermsByYear(moment(date).year()),
    onSuccess(res) {
      if (user && [perms.DESIGNER].includes(user.permission)) {
        const newTerms = res.data.terms.filter((term) => {
          const project =
            term.montageTerm || term.measureTerm || term.receptionTerm || null;
          if (project && project.UserId === user.id) return term;
        });
        setTerms(newTerms);
      } else {
        setTerms(res.data.terms);
      }
    },
    onError(err) {
      message.error('Błąd!');
    },
    enabled: !!user,
  });

  useEffect(() => {
    if (terms && user) {
      let newTerms: TermT[] = [...terms];

      if (filters.type) newTerms = filterTermsByType(newTerms, filters.type);
      if (filters.customerId)
        newTerms = filterTermsByCustomer(newTerms, filters.customerId);

      setFilteredTerms(newTerms);
    }
  }, [terms, filters, user]);

  const handleDate = (d: Moment) => setDate(d);

  return (
    <PageTemplate>
      {isLoading && <Spinner />}
      <ContentTemplate>
        <PageHeader title={<StyledH1>Terminarz</StyledH1>} />
        <Filters />
        <CalendarView
          items={filteredTerms}
          date={date}
          handleDate={handleDate}
        />
      </ContentTemplate>
    </PageTemplate>
  );
};

export default SchedulePage;
