import React, { ReactElement } from 'react';
import { Divider, Typography } from 'antd';

const { Title } = Typography;

interface PropsT {
  title: string | ReactElement;
  icon?: ReactElement;
  orientation?: 'left' | 'right';
}

const IconDivider: React.FC<PropsT> = ({
  title,
  icon,
  orientation = 'left',
}) => {
  return (
    <Divider orientation={orientation}>
      <Title level={2}>
        <span style={{ color: 'lightskyblue' }}>{icon}</span>
        {title}
      </Title>
    </Divider>
  );
};

export default IconDivider;
