import { DeleteOutlined } from '@ant-design/icons';
import { Button, message, Modal, Space, Table, Typography } from 'antd';
import { FieldArray, Formik } from 'formik';
import { Form, Input, InputNumber } from 'formik-antd';
import React, { useEffect, useState } from 'react';
import {
  CabinetElementT,
  CabinetFrontConfigT,
} from 'services/store/types/cabinets/Cabinets';
import { getElementVeneerLength } from 'services/utils/catalog';
import { initCabinetFront } from 'services/utils/const/initData';
import { FlexRow } from 'templates/FlexRow';
import { frontConfigSchema } from './utils/validate';

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  handleSubmit: (config: CabinetFrontConfigT) => void;
  initValues: CabinetFrontConfigT;
}

const CabinetFrontConfigModal: React.FC<PropsT> = ({
  handleSubmit,
  closeModal,
  visible,
  initValues,
}) => {
  return (
    <Modal
      title="Konfiguracja frontów"
      destroyOnClose={true}
      visible={visible}
      style={{ top: 40 }}
      footer={null}
      onCancel={closeModal}
    >
      <Formik
        validationSchema={frontConfigSchema}
        onSubmit={(values) => {
          if (!values.Elements.length) {
            message.error('Dodaj fronty');
            return;
          }
          const Elements = values.Elements.map((el: CabinetElementT) => ({
            ...el,
            veneerLength: getElementVeneerLength(el),
            surface: el.height * el.width,
          }));

          handleSubmit({ ...values, Elements });
        }}
        initialValues={initValues}
      >
        {({ values, handleSubmit: innerSubmit }) => {
          return (
            <>
              <Form.Item name="name" label="Nazwa">
                <Input name="name" placeholder="Nazwa" />
              </Form.Item>
              <Form.Item name="description" label="Opis">
                <Input name="description" placeholder="Opis..." />
              </Form.Item>
              <Typography.Title level={5}>Cena</Typography.Title>
              <FlexRow justify="space-between">
                <Form.Item
                  name="laminatePrice"
                  label="Laminat"
                  required
                  style={{ marginBottom: 0 }}
                >
                  <InputNumber name="laminatePrice" min={0} />
                </Form.Item>
                <Form.Item
                  name="paintSemiGlossPrice"
                  label="Mat"
                  required
                  style={{ marginBottom: 0 }}
                >
                  <InputNumber name="paintSemiGlossPrice" min={0} />
                </Form.Item>

                <Form.Item
                  name="paintGlossPrice"
                  label="Połysk"
                  required
                  style={{ textAlign: 'center' }}
                >
                  <InputNumber name="paintGlossPrice" min={0} />
                </Form.Item>
              </FlexRow>

              {/* NOTE Elements table */}
              <Typography.Title level={5}>Fronty</Typography.Title>
              <FieldArray name="Elements">
                {({ push, remove }) => {
                  return (
                    <div>
                      <FrontsTable fronts={values.Elements} remove={remove} />
                      <Button
                        size="small"
                        type="primary"
                        style={{ marginBottom: 20 }}
                        onClick={() => push(initCabinetFront)}
                      >
                        Dodaj
                      </Button>
                    </div>
                  );
                }}
              </FieldArray>

              <FlexRow justify="flex-end">
                <Space>
                  <Button key="back" onClick={closeModal}>
                    Anuluj
                  </Button>

                  <Button
                    key="submit"
                    type="primary"
                    onClick={() => innerSubmit()}
                  >
                    Zatwierdź
                  </Button>
                </Space>
              </FlexRow>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CabinetFrontConfigModal;

const columns = [
  {
    title: 'Wysokość',
    dataIndex: 'height',
    align: 'center' as const,
    render: (value: any, record: CabinetElementT, i: number) => {
      return (
        <Form.Item
          name={`Elements[${i}].height`}
          required
          style={{ marginBottom: 0 }}
        >
          <InputNumber name={`Elements[${i}].height`} min={0} />
        </Form.Item>
      );
    },
  },
  {
    title: 'Szerokość',
    dataIndex: 'width',
    align: 'center' as const,
    render: (value: any, record: CabinetElementT, i: number) => {
      return (
        <Form.Item
          name={`Elements[${i}].width`}
          required
          style={{ marginBottom: 0 }}
        >
          <InputNumber name={`Elements[${i}].width`} min={0} />
        </Form.Item>
      );
    },
  },
  {
    title: 'Grubość',
    dataIndex: 'thickness',
    align: 'center' as const,
    render: (value: any, record: CabinetElementT, i: number) => {
      return (
        <Form.Item
          name={`Elements[${i}].thickness`}
          required
          style={{ marginBottom: 0 }}
        >
          <InputNumber name={`Elements[${i}].thickness`} min={0} />
        </Form.Item>
      );
    },
  },
  {
    title: 'Ilośc',
    dataIndex: 'defaultQty',
    align: 'center' as const,
    render: (value: any, record: CabinetElementT, i: number) => {
      return (
        <Form.Item
          name={`Elements[${i}].defaultQty`}
          required
          style={{ marginBottom: 0 }}
        >
          <InputNumber name={`Elements[${i}].defaultQty`} min={0} />
        </Form.Item>
      );
    },
  },
  {
    title: 'Usuń',
    dataIndex: 'remove',
    align: 'center' as const,
    render: (remove: any, record: CabinetElementT, index: number) => {
      return (
        <DeleteOutlined
          style={{ color: 'red', cursor: 'pointer' }}
          onClick={() => remove(index)}
        />
      );
    },
  },
];

interface FrontsT {
  fronts: CabinetElementT[];
  remove: (index: number) => void;
}
const FrontsTable: React.FC<FrontsT> = ({ fronts, remove }) => {
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    const newList = fronts.map((front: CabinetElementT, i: number) => ({
      ...front,
      key: i,
      remove,
    }));
    setList(newList);
  }, [fronts]);

  return (
    <Table
      columns={columns}
      pagination={false}
      dataSource={list}
      size="small"
      style={{ paddingBottom: 5 }}
    />
  );
};
