import {
  EditOutlined,
  ExclamationCircleOutlined,
  HomeOutlined,
  UserDeleteOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import useUser from 'hooks/useUser';
import React from 'react';
import { CustomerT } from 'services/store/types/customers/Customers';
import { TCustomerProfileModal } from '..';

interface IProps {
  showModal: (modal: TCustomerProfileModal) => void;
  customer: CustomerT | undefined;
}

const CustomerProfileSider: React.FC<IProps> = ({ showModal, customer }) => {
  const { isAdmin } = useUser();
  return (
    <>
      <Menu mode="inline">
        <Menu.Item
          onClick={() => showModal('editCustomer')}
          icon={<EditOutlined />}
          disabled={!customer}
        >
          Edytuj dane
        </Menu.Item>
        <Menu.Item
          onClick={() => showModal('editAddress')}
          icon={<HomeOutlined />}
          style={!customer?.CustomerAddress ? { color: 'green' } : {}}
          disabled={!customer}
        >
          Adres zamieszkania
        </Menu.Item>
        {isAdmin() && (
          <Menu.Item
            onClick={() => showModal('blackList')}
            icon={<ExclamationCircleOutlined />}
            disabled={
              !customer ||
              !customer.pesel ||
              (!!customer && !!customer.CustomerBlackList)
            }
            danger
          >
            Dodaj do czarnej listy
          </Menu.Item>
        )}
        <Menu.Item
          onClick={() => showModal('customerRemove')}
          icon={<UserDeleteOutlined />}
          disabled={!customer || !isAdmin()}
          danger
        >
          Usuń klienta
        </Menu.Item>
      </Menu>
    </>
  );
};

export default CustomerProfileSider;
