import React from 'react';
import imgPlaceholder from 'assets/images/placeholder.png';
import { Image } from 'antd';
import { domain } from 'services/utils/const';

interface PropsT {
  path?: string;
  previewPath?: string;
  isExist: any;
  width?: number;
  height?: number;
}

const Img: React.FC<PropsT> = ({
  path,
  previewPath,
  isExist,
  width,
  height,
}) => {
  if (isExist && path && previewPath)
    return (
      <Image
        alt="img"
        width={width || 40}
        height={height || 40}
        src={`${domain}${path}`}
        preview={{ src: `${domain}${previewPath}` }}
      />
    );
  return <Image width={40} height={40} src={imgPlaceholder} />;
};

export default Img;
