import React, { useState, useEffect } from 'react';
import { FileT } from 'services/store/types/projects/Projects';
import { Divider, Button, Image, Typography, Skeleton } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { domain, perms } from 'services/utils/const';
import { useSelector } from 'react-redux';
import { AppStateT } from 'services/store';
import AddImage, { IAddImageProps } from './Modals/AddImage';
import useUser from 'hooks/useUser';

const { Title } = Typography;

const ImagesWrapper = styled.div`
  height: 300px;
  overflow-y: scroll;
  div {
    margin: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`;

const initModals = {
  addImage: false,
};

interface PropsT extends Omit<IAddImageProps, 'visible' | 'closeModal'> {
  files: FileT[] | undefined;
}

// FIXME mozliwość kasowania zdjęć

const Images: React.FC<PropsT> = ({ files, ...props }) => {
  const user = useSelector((state: AppStateT) => state.auth.user);

  const { hasPermission } = useUser();
  const [modal, setModal] = useState(initModals);

  const [images, setImages] = useState<any>({
    montage: [],
    other: [],
    realization: [],
    measure: [],
    inspiration: [],
  });

  useEffect(() => {
    if (files && user) {
      const imagesList = files
        .filter((item: FileT) => {
          if (item.type === 'img') {
            if (hasPermission([perms.ADMIN, perms.DESIGNER])) return item;
            if (
              !item.protect ||
              (item.UserId && user && item.UserId === user.id)
            )
              return item;
          }
        })
        .reduce(
          (i: any, item: FileT) => {
            if (item.imgType)
              return {
                ...i,
                [item.imgType]: [
                  ...i[item.imgType],
                  { key: item.id, src: `${domain}${item.path}` },
                ],
              };
            return i;
          },
          {
            montage: [],
            other: [],
            realization: [],
            measure: [],
            inspiration: [],
          }
        );

      setImages(imagesList);
    }
  }, [files, user]);

  const handleModal = (name: 'addImage') =>
    setModal({ ...modal, [name]: true });
  const closeModal = () => setModal(initModals);

  return (
    <div>
      <Title level={4}>Zdjęcia</Title>

      <ImagesWrapper>
        {images.inspiration.length ? (
          <>
            <Title level={5}>Inspiracje</Title>
            <ImagesList images={images.inspiration} />
          </>
        ) : null}

        <Title level={5}>Pomiar</Title>
        <ImagesList images={images.measure} />

        <Title level={5}>Montaż</Title>
        <ImagesList images={images.montage} />

        <Title level={5}>Inne</Title>
        <ImagesList images={images.other} />

        {!hasPermission([perms.EXTERNAL_COMPANY]) && (
          <>
            <Title level={5}>Realizacja</Title>
            <ImagesList images={images.realization} />
          </>
        )}
      </ImagesWrapper>

      <Divider style={{ opacity: 0.2 }} />
      <Button onClick={() => handleModal('addImage')}>
        <UploadOutlined />
        Dodaj zdjęcie
      </Button>

      {/* MODALS */}
      {modal.addImage && (
        <AddImage visible={modal.addImage} closeModal={closeModal} {...props} />
      )}
    </div>
  );
};

export default Images;

const ImagesList = ({ images }: { images: any }) => (
  <div>
    {images.length ? (
      images.map((item: any) => {
        return <Image key={item.key} src={item.src} width={100} />;
      })
    ) : (
      <Skeleton.Image />
    )}
  </div>
);
