import React, { useEffect, useState } from 'react';
import { Popconfirm, Table, Typography } from 'antd';
import {
  CabinetElementT,
  CabinetFrontConfigT,
} from 'services/store/types/cabinets/Cabinets';
import { cutString } from 'services/utils/string';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const columns = [
  {
    title: (
      <div>
        <div>Nazwa</div>
        <div>
          <small>opis</small>
        </div>
      </div>
    ),
    dataIndex: 'name',
    align: 'center' as const,
    render: (value: string, record: CabinetFrontConfigT) => (
      <div>
        <div>{value || '-'}</div>
        <div>
          <small title={record.description}>
            {cutString(record.description || '-', 30)}
          </small>
        </div>
      </div>
    ),
  },

  {
    title: (
      <div>
        <div>Cena</div>
        <div>
          <small>laminat/mat/połysk</small>
        </div>
      </div>
    ),
    dataIndex: 'price',
    align: 'center' as const,
  },
  {
    title: 'Ilość frontów',
    align: 'center' as const,
    render: (value: any, record: CabinetFrontConfigT) =>
      record.Elements.reduce((acc: number, element: CabinetElementT) => {
        const qty = acc + element.defaultQty;
        return qty;
      }, 0),
  },
  {
    title: 'Akcje',
    dataIndex: 'actions',
    align: 'center' as const,
  },
];

interface PropsT {
  fronts: CabinetFrontConfigT[];
  actions?: {
    remove?: (index: number, id?: string | number) => void;
    update?: (
      index: number,
      values: CabinetFrontConfigT,
      id?: string | number
    ) => void;
  };
}

const CabinetFrontsConfigsList: React.FC<PropsT> = ({
  fronts,
  actions = undefined,
}) => {
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    const newList = fronts
      .sort((a: CabinetFrontConfigT, b: CabinetFrontConfigT) =>
        `${a.name || ''}`.localeCompare(b.name || '')
      )
      .map((front: CabinetFrontConfigT, i: number) => {
        const obj = {
          ...front,
          key: front.id || front.config,
          price: `${front.laminatePrice}/${front.paintSemiGlossPrice}/${front.paintGlossPrice}`,
          actions: actions ? (
            <div>
              {actions.update && (
                <EditOutlined
                  title="Edytuj"
                  style={{ color: 'gray', cursor: 'pointer', margin: 5 }}
                  onClick={() => actions.update && actions.update(i, front)}
                />
              )}
              {actions.remove && (
                <Popconfirm
                  title="Usuń konfigurację"
                  onConfirm={() =>
                    actions.remove && actions.remove(i, front.id)
                  }
                >
                  <DeleteOutlined
                    title="Usuń"
                    style={{ color: 'red', cursor: 'pointer', margin: 5 }}
                  />
                </Popconfirm>
              )}
            </div>
          ) : (
            <Typography.Text disabled>brak</Typography.Text>
          ),

          elements: front.Elements.map((element: CabinetElementT) => ({
            ...element,
            key: element.width * element.height * element.defaultQty,
          })),
        };

        return obj;
      });
    setList(newList);
  }, [fronts]);

  return (
    <div>
      <Table
        dataSource={list}
        columns={columns}
        size="small"
        pagination={false}
        expandedRowRender={FrontsTable}
      />
    </div>
  );
};

export default CabinetFrontsConfigsList;

const frontsColumns = [
  {
    title: 'Wysokość',
    dataIndex: 'height',
    align: 'center' as const,
  },
  {
    title: 'Szerokość',
    dataIndex: 'width',
    align: 'center' as const,
  },
  {
    title: 'Grubość',
    dataIndex: 'thickness',
    align: 'center' as const,
  },
  {
    title: 'Długość obrzeża',
    dataIndex: 'veneerLength',
    align: 'center' as const,
    render: (v: number) => v.toFixed(2),
  },
  {
    title: 'Ilośc',
    dataIndex: 'defaultQty',
    align: 'center' as const,
  },
];

const FrontsTable = (row: any) => {
  return (
    <Table
      columns={frontsColumns}
      pagination={false}
      dataSource={row.elements}
      size="small"
      style={{ paddingBottom: 10 }}
    />
  );
};
