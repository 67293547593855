import Axios from 'axios';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import setAuthToken from 'services/utils/setAuthToken';
import { NewCabinetT } from 'services/utils/const/initData';
import { CabinetT } from 'services/store/types/cabinets/Cabinets';

export const createNewCabinet = async (
  cabinet: NewCabinetT,
  onEnd: (cabinet: CabinetT) => void,
  onErr: () => void
) => {
  setAuthToken();
  try {
    const body = new FormData();
    body.append('cabinet', JSON.stringify(cabinet));
    if (cabinet.img.length) {
      body.append('file', cabinet.img[0], cabinet.img[0].name);
    }

    const res: any = await Axios.post('/api/cabinets', body);

    onEnd(res.data.newCabinet);
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};
