import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { Input, Form, InputNumber } from 'formik-antd';
import { Formik } from 'formik';
import { SupplierT } from 'services/store/types/suppliers/Suppliers';
import { schema } from './utils/validate';

const initValues = {
  name: '',
  email: '',
  phone: '',
  term: 14,
};
interface PropsT {
  visible: boolean;
  closeModal: () => void;
  handleSubmit: (supplier: SupplierT, setLoading: (x: boolean) => void) => void;
  title: string;
  values?: SupplierT;
}

const SupplierModal: React.FC<PropsT> = ({
  title,
  visible,
  closeModal,
  handleSubmit,
  values = initValues,
}) => {
  const [loading, setLoading] = useState(false);

  const handleOk = (supplier: SupplierT) => {
    setLoading(true);
    handleSubmit(supplier, setLoading);
  };

  return (
    <Modal
      afterClose={() => {
        setLoading(false);
      }}
      title={title}
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Anuluj
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          htmlType="submit"
          form="addSupplierForm"
        >
          Zatwierdź
        </Button>,
      ]}
    >
      <Formik
        validationSchema={schema}
        onSubmit={handleOk}
        initialValues={values}
      >
        {(props) => {
          return (
            <Form
              noValidate
              className="form-container"
              id="addSupplierForm"
              onFinish={props.handleSubmit}
            >
              <Form.Item name="name">
                <Input name="name" placeholder="Nazwa" required />
              </Form.Item>

              <Form.Item name="email">
                <Input
                  name="email"
                  placeholder="E-mail"
                  type="email"
                  required
                />
              </Form.Item>
              <Form.Item name="phone">
                <Input name="phone" placeholder="Numer telefonu" required />
              </Form.Item>
              <Form.Item name="term" label="Czas realizacji">
                <InputNumber name="term" />
              </Form.Item>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default SupplierModal;
