import { UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, message, Modal, Select, Tooltip } from 'antd';
import Upload, { RcFile } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import useUser from 'hooks/useUser';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addProjectFile } from 'services/api/files/post';
import { setSpinner } from 'services/store/actions/view';
import { perms } from 'services/utils/const';
import styled from 'styled-components';

const { Option } = Select;

const StyledInput = styled(Input)`
  margin-bottom: 10px;
`;

export interface IAddImageProps {
  visible: boolean;
  closeModal: () => void;
  refetchFiles: () => void;
  projectId: string;
}

const AddImage: React.FC<IAddImageProps> = ({
  visible,
  closeModal,
  projectId,
  refetchFiles,
}) => {
  const dispatch = useDispatch();
  const { hasPermission } = useUser();
  const [files, setFiles] = useState<RcFile[]>([]);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [protect, setProtect] = useState(false);
  const [type, setType] = useState<any>(undefined);

  const handleFile = (f: RcFile, fList: RcFile[]) => {
    setFiles([...files, ...fList]);
    return false;
  };
  const handleRemove = (f: UploadFile) => {
    const filesList = files.filter((file: RcFile) => file.uid !== f.uid);
    setFiles(filesList);
  };

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    await addProjectFile(
      projectId,
      files,
      { name, description, type: 'img', imgType: type, protect },
      (p) => {
        message.success('Dodano plik do projektu');
        refetchFiles();
        dispatch(setSpinner(false));
        closeModal();
      },
      () => {
        dispatch(setSpinner(false));
        message.error('Błąd!');
      }
    );
  };

  return (
    <Modal
      title="Dodaj plik"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      onOk={handleSubmit}
      okButtonProps={{ disabled: !files.length || !name || !type }}
    >
      <div>
        <StyledInput
          value={name}
          placeholder="Nazwa*"
          size="large"
          onChange={(e) => setName(e.target.value)}
        />
        <StyledInput
          value={description}
          placeholder="Opis..."
          onChange={(e) => setDescription(e.target.value)}
        />
        <Select
          value={type}
          onChange={(value) => {
            if (value === 'inspiration') setProtect(true);
            return setType(value);
          }}
          style={{ width: '100%' }}
          size="large"
          placeholder="Typ*"
        >
          <Option value="other">Inny</Option>
          <Option
            value="measure"
            disabled={hasPermission([
              perms.CARPENTER,
              perms.INSTALLER,
              perms.OFFICE,
            ])}
          >
            Pomiar
          </Option>
          <Option value="montage" disabled={hasPermission([perms.OFFICE])}>
            Montaż
          </Option>
          <Option value="realization">Realizacja</Option>
          {hasPermission([perms.ADMIN, perms.DESIGNER]) && (
            <Option value="inspiration">Inspiracje</Option>
          )}
        </Select>
        <div>
          <Tooltip title="Widoczny dla administratorów, projektantów i osoby dodającej">
            <Checkbox
              checked={protect}
              onChange={(e) => setProtect(e.target.checked)}
            >
              Chroniony
            </Checkbox>
          </Tooltip>
        </div>
        <Upload
          fileList={files}
          beforeUpload={handleFile}
          onRemove={handleRemove}
          accept="image/*"
          multiple
        >
          <Button size="large" icon={<UploadOutlined />}>
            Dodaj plik
          </Button>
        </Upload>
      </div>
    </Modal>
  );
};

export default AddImage;
