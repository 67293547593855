import React, { useState } from 'react';
import { Modal, Button, message, Typography, Row, Col } from 'antd';
import { Input, Form } from 'formik-antd';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import {
  createNewCustomer,
  CreateCustomerValuesT,
} from 'services/api/customers/post';
import { addCustomerToList } from 'services/store/actions/customers';
import { isMobile } from 'react-device-detect';
import { isAddressValid } from 'services/utils';
import { customerSchema } from '../utils/validate';

const { Title } = Typography;

const initValues = {
  firstname: '',
  surname: '',
  pesel: '',
  phone: '',
  email: '',
  nip: '',
  company: '',
  CustomerAddress: {
    city: '',
    postcode: '',
    street: '',
    apartment: '',
    description: '',
  },
};

// FIXME OGARNIJ TO GFÓWNO
interface PropsT {
  visible: boolean;
  closeModal: () => void;
  refetchCustomers?: () => Promise<any>;
}

const AddCustomer: React.FC<PropsT> = ({
  visible,
  closeModal,
  refetchCustomers,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: CreateCustomerValuesT, actions: any) => {
    setLoading(true);

    const customerData = { ...values };
    if (!isAddressValid(values.CustomerAddress))
      delete customerData.CustomerAddress;

    return createNewCustomer(
      customerData,
      (newCustomer) => {
        if (refetchCustomers) refetchCustomers();
        dispatch(addCustomerToList(newCustomer));
        setLoading(false);
        message.success('Utworzono nowego klienta');
        closeModal();
      },
      (msg: string) => {
        actions.setErrors({ email: msg });
        setLoading(false);
        message.error('Błąd');
      }
    );
  };
  return (
    <Modal
      title="Nowy klient"
      destroyOnClose={true}
      width={!isMobile ? 800 : '98vw'}
      visible={visible}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Anuluj
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          htmlType="submit"
          form="AddUserForm"
        >
          Zatwierdź
        </Button>,
      ]}
    >
      <Formik
        validationSchema={customerSchema}
        onSubmit={handleSubmit}
        initialValues={initValues}
      >
        {(props) => {
          return (
            <Form
              noValidate
              className="form-container"
              id="AddUserForm"
              onFinish={props.handleSubmit}
            >
              <Row justify="space-between">
                <Col sm={6} md={12}>
                  <Title level={5}>Dane klienta</Title>
                  <Form.Item name="firstname">
                    <Input name="firstname" placeholder="Imię" required />
                  </Form.Item>
                  <Form.Item name="surname">
                    <Input name="surname" placeholder="Nazwisko" required />
                  </Form.Item>
                  <Form.Item name="pesel">
                    <Input name="pesel" placeholder="PESEL" required />
                  </Form.Item>
                  <Title level={5}>Dane kontaktowe</Title>
                  <Form.Item name="email">
                    <Input
                      name="email"
                      placeholder="E-mail"
                      type="email"
                      required
                    />
                  </Form.Item>
                  <Form.Item name="phone">
                    <Input name="phone" placeholder="Numer telefonu" required />
                  </Form.Item>

                  <Title level={5}>Dane Firmy*</Title>
                  <Form.Item name="company">
                    <Input name="company" placeholder="Nazwa" />
                  </Form.Item>
                  <Form.Item name="nip">
                    <Input name="nip" placeholder="NIP" />
                  </Form.Item>
                  <small>* - nieobowiązkowe</small>
                </Col>
                <Col sm={6} md={12}>
                  <Title level={5}>Adres zamieszkania</Title>
                  <Form.Item name="CustomerAddress.city">
                    <Input
                      name="CustomerAddress.city"
                      placeholder="Miejscowość"
                      required
                    />
                  </Form.Item>
                  <Form.Item name="CustomerAddress.postcode">
                    <Input
                      name="CustomerAddress.postcode"
                      placeholder="Kod pocztowy"
                      required
                    />
                  </Form.Item>
                  <Form.Item name="CustomerAddress.street">
                    <Input
                      name="CustomerAddress.street"
                      placeholder="Ulica"
                      required
                    />
                  </Form.Item>
                  <Form.Item name="CustomerAddress.apartment">
                    <Input
                      name="CustomerAddress.apartment"
                      placeholder="Nr domu/lokalu"
                      required
                    />
                  </Form.Item>
                  <Form.Item name="CustomerAddress.description">
                    <Input.TextArea
                      name="CustomerAddress.description"
                      placeholder="Opis..."
                      required
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddCustomer;
