/* eslint-disable @typescript-eslint/default-param-last */
import update from 'immutability-helper';
import {
  AccessoriesActionsT,
  ACCESSORIES_LOAD_SUCCESS,
  ACCESSORIES_LOAD_ERROR,
  ACCESSORIES_ADD_TO_LIST,
  ACCESSORIES_UPDATE_IN_LIST,
  ACCESSORIES_REMOVE_FROM_LIST,
} from '../types/accesories/actions';
import { AccessoriesT, AccessoryT } from '../types/accesories/Accessories';

const initialState: AccessoriesT = {
  accessories: [],
};

const accessoriesReducer = (
  state = initialState,
  action: AccessoriesActionsT
): AccessoriesT => {
  switch (action.type) {
    case ACCESSORIES_LOAD_SUCCESS: {
      return { ...state, accessories: action.accessories };
    }
    case ACCESSORIES_LOAD_ERROR: {
      return initialState;
    }

    case ACCESSORIES_ADD_TO_LIST: {
      return update(state, { accessories: { $push: [action.accessory] } });
    }
    case ACCESSORIES_UPDATE_IN_LIST: {
      const index = state.accessories.findIndex(
        (item: AccessoryT) => item.id === action.accessory.id
      );
      return update(state, {
        accessories: {
          [index]: { $set: action.accessory },
        },
      });
    }
    case ACCESSORIES_REMOVE_FROM_LIST: {
      const index = state.accessories.findIndex(
        (item: AccessoryT) => item.id === action.id
      );
      return update(state, {
        accessories: {
          $splice: [[index, 1]],
        },
      });
    }
    default:
      return state;
  }
};

export { accessoriesReducer };
