import { SettingsT, CabinetTypeT, SettingsListsT } from './Settings';

export const SETTINGS_LOAD_SUCCESS = 'SETTINGS_LOAD_SUCCESS';
export const SETTINGS_LOAD_ERROR = 'SETTINGS_LOAD_ERROR';
export const SETTINGS_ADD_TO_LIST = 'SETTINGS_ADD_TO_LIST';
export const SETTINGS_UPDATE_IN_LIST = 'SETTINGS_UPDATE_IN_LIST';
export const SETTINGS_REMOVE_FROM_LIST = 'SETTINGS_REMOVE_FROM_LIST';

export interface SettingsLoadSuccessT {
  type: typeof SETTINGS_LOAD_SUCCESS;
  settings: SettingsT;
}
export interface SettingsLoadErrorT {
  type: typeof SETTINGS_LOAD_ERROR;
}

export interface SettingsAddToListT {
  type: typeof SETTINGS_ADD_TO_LIST;
  item: CabinetTypeT;
  field: SettingsListsT;
}
export interface SettingsUpdateInListT {
  type: typeof SETTINGS_UPDATE_IN_LIST;
  item: CabinetTypeT;
  field: SettingsListsT;
}
export interface SettingsRemoveFromListT {
  type: typeof SETTINGS_REMOVE_FROM_LIST;
  id: string;
  field: SettingsListsT;
}

export type SettingsActionsT =
  | SettingsRemoveFromListT
  | SettingsLoadSuccessT
  | SettingsLoadErrorT
  | SettingsAddToListT
  | SettingsUpdateInListT;
