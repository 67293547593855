import { DatePicker, Descriptions, message, PageHeader } from 'antd';
import { StyledH1 } from 'components/Header';
import IconDivider from 'components/IconDivider';
import ProjectOrdersList from 'components/Orders/ProjectOrders';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { removeOrder } from 'services/api/orders/remove';
import { AppStateT } from 'services/store';
import { setOrdersFilterField } from 'services/store/actions/orders';
import {
  getSuplierById,
  getSuplierByIdWithOrders,
  setSupplier,
} from 'services/store/actions/suppliers';
import { setSpinner } from 'services/store/actions/view';
import { IdType } from 'services/types';
import { FlexRow } from 'templates/FlexRow';
import PageTemplate from 'templates/PageTemplate';

const SupplierPage = () => {
  const params: { id: string } = useParams();
  const dispatch = useDispatch();

  const supplier = useSelector((state: AppStateT) => state.suppliers.supplier);
  const dates = useSelector(
    (state: AppStateT) => state.orders.filters.ended.dates
  );

  useEffect(() => {
    dispatch(setSpinner(true));
    dispatch(getSuplierByIdWithOrders(params.id, dates));
  }, [dates]);

  useEffect(() => {
    return () => {
      dispatch(setSupplier(null));
    };
  }, []);

  const handleDates = (values: any) => {
    dispatch(setOrdersFilterField('ended', 'dates', values));
  };

  const handleRemove = async (orderId: IdType) => {
    await removeOrder(
      orderId,
      () => {
        message.success('Zamówienie usunięte');
        dispatch(getSuplierByIdWithOrders(params.id, dates));
      },
      () => {}
    );
  };

  const handleUpdate = () => {
    dispatch(setSpinner(true));
    dispatch(getSuplierByIdWithOrders(params.id, dates));
  };

  return (
    <PageTemplate>
      <PageHeader
        title={<StyledH1>{supplier ? supplier.name : 'Dystrybutor'}</StyledH1>}
      />
      <Descriptions column={1} title="Dane dystrybutora">
        <Descriptions.Item label="email">
          {supplier?.email || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="tel.">
          {supplier?.phone || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="czas realizacji">
          {`${supplier?.term} dni` || '-'}
        </Descriptions.Item>
      </Descriptions>

      <IconDivider title="Zamówienia" />
      <FlexRow justify="flex-end">
        <DatePicker.RangePicker
          value={dates}
          onChange={handleDates}
          allowClear={false}
        />
      </FlexRow>
      {supplier && supplier.Orders && (
        <ProjectOrdersList
          orders={supplier.Orders}
          handleUpdate={handleUpdate}
          handleRemove={handleRemove}
        />
      )}
    </PageTemplate>
  );
};

export default SupplierPage;
