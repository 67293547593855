import { Layout } from 'antd';
import React from 'react';

const ContentTemplate: React.FC = ({ children }) => {
  return (
    <Layout>
      <Layout.Content>{children}</Layout.Content>
    </Layout>
  );
};

export default ContentTemplate;
