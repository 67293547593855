import { Image, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import imgPlaceholder from 'assets/images/placeholder.png';
import { CabinetAccessoryT } from 'services/store/types/cabinets/Cabinets';
import { Form, InputNumber } from 'formik-antd';
import { domain } from 'services/utils/const';
import Img from 'components/Image';

const columns: any[] = [
  {
    title: 'Lp.',
    align: 'center' as const,
    render: (t: string, r: any, i: number) => i + 1,
  },
  {
    title: 'Zdjęcie',
    dataIndex: 'img',
    align: 'center' as const,
  },
  {
    title: 'Nazwa',
    dataIndex: 'name',
    align: 'center' as const,
    defaultSortOrder: 'ascend' as const,
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
    align: 'center' as const,
  },
  {
    title: 'Dystrybutor',
    dataIndex: 'supplier',
    align: 'center' as const,
    defaultSortOrder: 'ascend' as const,
    sorter: (a: any, b: any) => a.supplierName.localeCompare(b.supplierName),
  },
  {
    title: 'Cena',
    dataIndex: 'price',
    align: 'center' as const,
  },
  {
    title: 'Domyślna ilosć',
    dataIndex: 'defaultQty',
    align: 'center' as const,
  },
];

interface PropsT {
  accessories: CabinetAccessoryT[];
  noEditable?: boolean;
}

const CabinetAccessoriesList: React.FC<PropsT> = ({
  accessories,
  noEditable,
}) => {
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    const newList = accessories.map((a: CabinetAccessoryT, i: number) => {
      return {
        ...a.Accessory,
        key: a.AccessoryId,
        img: (
          <Img
            path={a.Accessory?.miniaturePath}
            previewPath={a.Accessory?.imgPath}
            isExist={a.Accessory}
          />
        ),
        supplier: a.Accessory?.Supplier.name || '-',
        supplierName: a.Accessory?.Supplier.name || '-',
        name: a.Accessory?.name || '-',
        defaultQty: !noEditable ? (
          <Form.Item
            name={`CabinetAccessories[${i}].defaultQty`}
            required
            style={{ marginBottom: 0 }}
          >
            <InputNumber name={`CabinetAccessories[${i}].defaultQty`} min={1} />
          </Form.Item>
        ) : (
          a.defaultQty
        ),
      };
    });
    setList(newList);
  }, [accessories]);

  return (
    <div>
      <Table
        columns={columns}
        dataSource={list}
        pagination={false}
        size="small"
      />
    </div>
  );
};

export default CabinetAccessoriesList;
