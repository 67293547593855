import axios, { AxiosResponse } from 'axios';
import { CustomerT } from 'services/store/types/customers/Customers';
import { AddressT } from 'services/store/types/settings/Settings';
import setAuthToken from 'services/utils/setAuthToken';

export const customersApi = {
  //NOTE GET
  getAllCustomers: (): Promise<AxiosResponse<{ customers: CustomerT[] }>> => {
    setAuthToken();
    return axios.get(`/api/customers`);
  },
  getCustomerById: (
    id: string
  ): Promise<AxiosResponse<{ customer: CustomerT }>> => {
    setAuthToken();
    return axios.get(`/api/customers/alldata/${id}`);
  },

  // NOTE UPDATE
  updateCustomer: (
    id: string,
    body: Partial<CustomerT>
  ): Promise<AxiosResponse<{ customer: CustomerT }>> => {
    setAuthToken();
    return axios.put(`/api/customers/${id}`, body);
  },
};
