import Axios from 'axios';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import setAuthToken from 'services/utils/setAuthToken';
import { AccessoryT } from 'services/store/types/accesories/Accessories';

export interface UpdateAccessoryValuesT extends AccessoryT {
  img: any[];
}

export const updateAccessory = async (
  id: string,
  product: UpdateAccessoryValuesT,
  onEnd: (accessory: AccessoryT) => void,
  onErr: () => void
) => {
  setAuthToken();
  try {
    const body = new FormData();
    body.append('product', JSON.stringify(product));
    if (product.img.length) {
      body.append('file', product.img[0], product.img[0].fileName);
    }

    const res: any = await Axios.put(`/api/accessories/${id}`, body);

    onEnd(res.data.accessory);
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};
