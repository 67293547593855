/* eslint-disable @typescript-eslint/default-param-last */
import update from 'immutability-helper';
import moment from 'moment';
import {
  ProjectsActionsT,
  PROJECTS_LOAD_SUCCESS,
  PROJECTS_LOAD_ERROR,
  PROJECTS_CLEAR_LIST,
  PROJECTS_CUSTOMERS_LOAD_SUCCESS,
  PROJECTS_EXPAND_ROW,
  PROJECTS_COLLAPSE_ROW,
  PROJECTS_SET_PROJECT,
  PROJECTS_SET_FILTERS_FIELD,
  PROJECTS_SET_PROJECT_PRICING,
  PROJECTS_SET_PROJECT_ORDERS,
  UPDATE_FILES_IN_PROJECT,
} from '../types/projects/actions';
import { ProjectsT } from '../types/projects/Projects';

const initialState: ProjectsT = {
  projects: [],
  //   Project data
  project: null,
  //   Customers-projects list
  active: [],
  ended: [],
  filters: {
    ended: {
      dates: [
        moment().hour(0).minutes(0).subtract(1, 'months'),
        moment().hour(0).minutes(0),
      ],
    },
  },
  expandedKeys: {
    active: [],
    ended: [],
  },
};

const projectsReducer = (
  state = initialState,
  action: ProjectsActionsT
): ProjectsT => {
  switch (action.type) {
    case PROJECTS_LOAD_SUCCESS: {
      return { ...state, projects: action.projects };
    }
    case UPDATE_FILES_IN_PROJECT: {
      if (state.project)
        return { ...state, project: { ...state.project, Files: action.files } };
      return state;
    }
    case PROJECTS_CUSTOMERS_LOAD_SUCCESS: {
      return { ...state, [action.list]: action.customers };
    }
    case PROJECTS_LOAD_ERROR: {
      return { ...state, projects: [], active: [], ended: [] };
    }
    case PROJECTS_CLEAR_LIST: {
      return { ...state, [action.list]: [] };
    }
    case PROJECTS_EXPAND_ROW: {
      return update(state, {
        expandedKeys: { [action.list]: { $push: [action.key] } },
      });
    }
    case PROJECTS_COLLAPSE_ROW: {
      const index = state.expandedKeys[action.list].findIndex(
        (key: string) => key === action.key
      );
      return update(state, {
        expandedKeys: {
          [action.list]: { $splice: [[index, 1]] },
        },
      });
    }
    case PROJECTS_SET_PROJECT: {
      return { ...state, project: action.project };
    }
    case PROJECTS_SET_PROJECT_PRICING: {
      return update(state, { project: { Pricing: { $set: action.pricing } } });
    }
    case PROJECTS_SET_PROJECT_ORDERS: {
      if (state.project)
        return update(state, { project: { Orders: { $set: action.orders } } });
      return state;
    }
    case PROJECTS_SET_FILTERS_FIELD: {
      return update(state, {
        filters: {
          [action.list]: {
            [action.field]: {
              $set: action.value,
            },
          },
        },
      });
    }
    default:
      return state;
  }
};

export { projectsReducer };
