import { Input, message, Modal } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { removeCustomer } from 'services/api/customers/remove';
import { setSpinner } from 'services/store/actions/view';
import { CustomerT } from 'services/store/types/customers/Customers';

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  customer: CustomerT;
}
const DeleteCustomer: React.FC<PropsT> = ({
  visible,
  closeModal,
  customer,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState('');

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    await removeCustomer(
      customer.id,
      () => {
        dispatch(setSpinner(false));
        message.warning('Klient usunięty');
        history.push('/customers');
      },
      () => {
        dispatch(setSpinner(false));
        message.error('Błąd');
      }
    );
  };
  return (
    <Modal
      title="Usuwanie danych klienta"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      onOk={handleSubmit}
      okButtonProps={{ danger: true, disabled: email !== customer.email }}
    >
      <div style={{ textAlign: 'center' }}>
        Aby usunąć dane klienta i wszstkie jego projekty przepisz adres e-mail
        należący do klienta.
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail"
        />
      </div>
    </Modal>
  );
};

export default DeleteCustomer;
