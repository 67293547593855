import useUser from 'hooks/useUser';
import React from 'react';
import { FileT, ProjectT } from 'services/store/types/projects/Projects';
import { PricingFileTypesT } from 'services/types';
import { TProjectModals } from '..';
import AdminSider from './AdminSider';
import CarpenterSider from './CarpenterSider';
import DesignerSider from './DesignerSider';
import ExternalCompanySider from './ExternalCompanySider';
import TechnologistSider from './TechnologistSider';

export interface IProjectSiderProps {
  project: ProjectT | undefined;
  files: FileT[] | undefined;
  showModal: (modal: TProjectModals) => void;
  getPicingFile: (type: PricingFileTypesT) => void;
}

// TODO INSTALLER & OFFICE SIDER
const ProjectSider: React.FC<IProjectSiderProps> = ({
  project,
  showModal,
  getPicingFile,
  files,
}) => {
  const {
    isAdmin,
    isDesigner,
    isTechnologist,
    isExternalCompany,
    isCarpenter,
    isInstaller,
    isOffice,
  } = useUser();
  if (!project) return null;
  if (isAdmin())
    return (
      <AdminSider
        project={project}
        showModal={showModal}
        getPicingFile={getPicingFile}
      />
    );

  if (isDesigner())
    return (
      <DesignerSider
        project={project}
        showModal={showModal}
        getPicingFile={getPicingFile}
        files={files}
      />
    );
  if (isTechnologist())
    return (
      <TechnologistSider
        project={project}
        showModal={showModal}
        getPicingFile={getPicingFile}
        files={files}
      />
    );
  if (isCarpenter())
    return (
      <CarpenterSider
        project={project}
        showModal={showModal}
        getPicingFile={getPicingFile}
        files={files}
      />
    );
  if (isExternalCompany())
    return (
      <ExternalCompanySider
        project={project}
        showModal={showModal}
        getPicingFile={getPicingFile}
        files={files}
      />
    );
  return null;
};

export default ProjectSider;

// TODO NOW: utworzyć jeden sider z warunkami? czy wiele siderów per permission?
