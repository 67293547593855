/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Modal, DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { ProjectT } from 'services/store/types/projects/Projects';
import {
  projectTermsTypes,
  updateProjectTermsById,
} from 'services/store/actions/projects';

const { RangePicker } = DatePicker;

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  project: ProjectT;
}

const SetMontageDay: React.FC<PropsT> = ({
  project: { montageTerm, montageWeek, id },
  visible,
  closeModal,
}) => {
  const dispatch = useDispatch();

  const [dates, setDates] = useState<[Moment | null, Moment | null]>([
    null,
    null,
  ]);

  useEffect(() => {
    if (montageTerm)
      return setDates([
        moment(montageTerm.startDate),
        moment(montageTerm.endDate),
      ]);
    if (montageWeek)
      return setDates([
        moment(montageWeek.date).day(1),
        moment(montageWeek.date).day(2),
      ]);
  }, []);

  const handleChange = (d: any) => setDates(d);

  const disabledDate = (currDate: Moment) => {
    if (currDate && montageWeek)
      return !currDate.isBetween(
        moment(montageWeek.date).startOf('week'),
        moment(montageWeek.date).add(1, 'week').endOf('week')
      );
    return true;
  };

  const onSubmit = async () => {
    if (dates && dates[0] && dates[1]) {
      dispatch(setSpinner(true));
      dispatch(
        updateProjectTermsById(
          projectTermsTypes.MONTAGE_DATE,
          id,
          { montageDate: dates },
          'Ustalono termin montażu',
          () => closeModal()
        )
      );
    }
  };

  return (
    <Modal
      title="Ustal datę montażu"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      onOk={onSubmit}
      okButtonProps={{ disabled: !dates || !dates[0] || !dates[1] }}
    >
      {montageWeek ? (
        <RangePicker
          style={{ width: '100%' }}
          size="large"
          value={dates}
          onChange={handleChange}
          disabledDate={disabledDate}
        />
      ) : (
        <strong style={{ textAlign: 'center' }}>Wyznacz tydzień</strong>
      )}
    </Modal>
  );
};

export default SetMontageDay;
