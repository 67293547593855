import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Popconfirm, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { CabinetElementT } from 'services/store/types/cabinets/Cabinets';
import { cutString } from 'services/utils/string';
import ElementDimension from '../ElementDimension';

const columns = [
  {
    title: (
      <div>
        <div>Nazwa</div>
        <div>
          <small>opis</small>
        </div>
      </div>
    ),
    dataIndex: 'name',
    align: 'center' as const,
    render: (value: string, record: CabinetElementT) => (
      <div>
        <div>
          {value || ''} {record.isHdf && ' /HDF/'}
        </div>
        <div>
          <small title={record.description}>
            {cutString(record.description || '', 25)}
          </small>
        </div>
      </div>
    ),
  },
  {
    title: 'Wysokość',
    dataIndex: 'height',
    align: 'center' as const,
    render: (t: number, r: any) => (
      <ElementDimension edge={r.h1} edge2={r.h2}>
        {t}
      </ElementDimension>
    ),
  },
  {
    title: 'Szerokość',
    dataIndex: 'width',
    align: 'center' as const,
    render: (t: number, r: any) => (
      <ElementDimension edge={r.w1} edge2={r.w2}>
        {t}
      </ElementDimension>
    ),
  },
  {
    title: 'Grubość',
    dataIndex: 'thickness',
    align: 'center' as const,
  },
  {
    title: 'Długość obrzeża',
    dataIndex: 'veneerLength',
    align: 'center' as const,
    render: (v: number) => v.toFixed(2),
  },

  {
    title: 'Ilość domyślna',
    dataIndex: 'defaultQty',
    align: 'center' as const,
  },
  {
    title: 'Akcje',
    dataIndex: 'actions',
    align: 'center' as const,
  },
];

interface PropsT {
  elements: CabinetElementT[];
  actions?: {
    remove?: (index: number, id?: string | number) => void;
    update?: (
      index: number,
      values: CabinetElementT,
      id?: string | number
    ) => void;
  };
}

const CabinetElementsList: React.FC<PropsT> = ({
  elements,
  actions = undefined,
  ...props
}) => {
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    if (elements) {
      const newList = elements.map((element: CabinetElementT, i: number) => ({
        ...element,
        key: element.width * element.height * i,
        actions: actions ? (
          <div>
            {actions.update && (
              <EditOutlined
                title="Edytuj"
                style={{ color: 'gray', cursor: 'pointer', margin: 5 }}
                onClick={() => actions.update && actions.update(i, element)}
              />
            )}
            {actions.remove && (
              <Popconfirm
                title="Usuń element"
                onConfirm={() =>
                  actions.remove && actions.remove(i, element.id)
                }
              >
                <DeleteOutlined
                  title="Usuń"
                  style={{ color: 'red', cursor: 'pointer', margin: 5 }}
                />
              </Popconfirm>
            )}
          </div>
        ) : (
          <Typography.Text disabled>brak</Typography.Text>
        ),
      }));
      setList(newList);
    }
  }, [elements]);

  return (
    <Table
      columns={columns}
      dataSource={list}
      pagination={false}
      size="small"
      {...props}
    />
  );
};

export default CabinetElementsList;
