import React from 'react';
import { UserT } from 'services/store/types/auth/Auth';
import { Tooltip, Select, SelectProps } from 'antd';
import { getPersonalName } from 'services/utils/users';
import { perms } from 'services/utils/const';

const { Option } = Select;

interface PropsT extends SelectProps {
  users: UserT[];
  externalCompanyId?: string;
  handleUser: (id: string) => void;
  style?: any;
}

const DesignersSelect: React.FC<PropsT> = ({
  users,
  externalCompanyId,
  handleUser,
  style,
  ...props
}) => {
  return (
    <Tooltip title="Zmień firmę zewnętrzną" placement="right">
      <Select
        disabled={!users.length}
        size="large"
        placeholder="Firma zewnętrzna"
        value={externalCompanyId}
        onChange={handleUser}
        style={style || { width: 200, marginBottom: 30 }}
        allowClear={true}
        {...props}
      >
        {users.map((item: UserT) => {
          if ([perms.EXTERNAL_COMPANY].includes(item.permission))
            return (
              <Option key={item.id} value={item.id}>
                {item.company} - {getPersonalName(item)}
              </Option>
            );
          return null;
        })}
      </Select>
    </Tooltip>
  );
};

export default DesignersSelect;
