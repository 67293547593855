import React, { useEffect, useState } from 'react';
import PageTemplate from 'templates/PageTemplate';
import { PageHeader, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getSupliers } from 'services/store/actions/suppliers';
import { AppStateT } from 'services/store';
import { setSpinner } from 'services/store/actions/view';
import {
  getAccessories,
  removeAccessoryFromList,
  updateAccessoryInList,
} from 'services/store/actions/accessories';
import { AccessoryT } from 'services/store/types/accesories/Accessories';
import { removeAccessory } from 'services/api/accessories/remove';
import {
  updateAccessory,
  UpdateAccessoryValuesT,
} from 'services/api/accessories/update';
import { StyledH1 } from 'components/Header';
import AccessoryModal from 'components/Base/Accessories/AccessoryModal';
import List from '../../components/Base/Accessories/List';

const AccessoriesPage = () => {
  const dispatch = useDispatch();
  const [toUpdate, setToUpdate] = useState<AccessoryT | null>(null);
  const accessories = useSelector(
    (state: AppStateT) => state.accessories.accessories
  );

  useEffect(() => {
    dispatch(getSupliers());
    if (!accessories.length) {
      dispatch(setSpinner(true));
      dispatch(getAccessories());
    }
  }, []);

  const handleUpdateAccessory = async (
    accessory: UpdateAccessoryValuesT,
    setLoading: (x: boolean) => void
  ) => {
    if (toUpdate && toUpdate.id) {
      await updateAccessory(
        toUpdate.id,
        accessory,
        (updatedAccessory) => {
          dispatch(updateAccessoryInList(updatedAccessory));
          message.success('Produkt zaktualizowany');
          setLoading(false);
          setToUpdate(null);
        },
        () => {
          setLoading(false);
        }
      );
    }
  };
  const handleRemoveAccessory = async (accessory: AccessoryT) => {
    if (accessory.id) {
      const { id } = accessory;
      dispatch(setSpinner(true));
      await removeAccessory(
        id,
        () => {
          dispatch(setSpinner(false));
          dispatch(removeAccessoryFromList(id));
          message.success('Produkt usunięty');
        },
        () => {
          dispatch(setSpinner(false));
        }
      );
    }
  };

  return (
    <PageTemplate>
      <PageHeader title={<StyledH1>Akcesoria</StyledH1>} />
      <List
        accessories={accessories}
        actions={{ remove: handleRemoveAccessory, update: setToUpdate }}
      />

      {/* MDOALS */}
      {toUpdate && (
        <AccessoryModal
          title="Edycja produktu"
          visible={!!toUpdate}
          closeModal={() => setToUpdate(null)}
          handleSubmit={handleUpdateAccessory}
          values={toUpdate}
        />
      )}
    </PageTemplate>
  );
};

export default AccessoriesPage;
