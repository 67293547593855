/* eslint-disable @typescript-eslint/default-param-last */
import update from 'immutability-helper';
import {
  PricingActionsT,
  PRICING_ADD_SEGMENT_CABINET,
  PRICING_SET_PRICE,
  PRICING_SET_PROJECT,
  PRICING_SET_ORDER_SEGMENT_CABINETS,
  PRICING_ADD_PATTERN,
  PRICING_REMOVE_PATTERN,
  PRICING_ADD_SEGMENT,
  PRICING_REMOVE_SEGMENT_CABINET,
  PRICING_REMOVE_SEGMENT,
  PRICING_UPDATE_SEGMENT_CABINET,
  PRICING_ADD_ELEMENTS,
  PRICING_REMOVE_ELEMENTS,
  PRICING_ADD_ACCESSORIES,
  PRICING_REMOVE_ACCESSORY,
  PRICING_EDIT_LISTS,
  PRICING_ELEMENTS_QTY,
  PRICING_ACCESSORIES_QTY,
  PRICING_SET_SEGMENT_TABLE,
  PRICING_CLEAR_DATA,
  PRICING_SET_PRICING_TO_EDIT,
} from '../types/pricing/actions';
import {
  PricingPatternT,
  PricingT,
  SegmentCabinetT,
} from '../types/pricing/Pricing';

const initialState: PricingT = {
  ProjectId: '',
  Project: null,
  price: 0,
  veneerLength: 0,
  frontsSurface: 0,
  elementsSurface: 0,
  PricingPatterns: [],
  Segments: [],
  listEditable: false,
  PricingAccessories: [],
  PricingElements: [],
};

const pricingReducer = (
  state = initialState,
  action: PricingActionsT
): PricingT => {
  switch (action.type) {
    case PRICING_SET_PRICING_TO_EDIT: {
      return {
        ...action.pricing,
        listEditable: state.listEditable,
      };
    }
    case PRICING_CLEAR_DATA: {
      return {
        ...initialState,
      };
    }
    case PRICING_SET_PROJECT: {
      return {
        ...state,
        Project: action.Project,
        ProjectId: action.Project.id,
      };
    }
    case PRICING_SET_PRICE: {
      return { ...state, price: action.price };
    }

    case PRICING_EDIT_LISTS: {
      return update(state, {
        listEditable: { $set: action.edit },
      });
    }
    case PRICING_SET_SEGMENT_TABLE: {
      return update(state, {
        Segments: {
          [action.segmentIndex]: {
            Table: { $set: action.table },
            TableId: { $set: action.table ? action.table.id : undefined },
          },
        },
      });
    }

    // PATTERNS
    case PRICING_ADD_PATTERN: {
      return update(state, {
        PricingPatterns: { $push: [action.item] },
      });
    }
    case PRICING_REMOVE_PATTERN: {
      const index = state.PricingPatterns.findIndex(
        (item: PricingPatternT) =>
          item.type === action.patternType &&
          item.PatternId === action.pattern.PatternId &&
          item.VeneerId === action.pattern.VeneerId
      );
      return update(state, {
        PricingPatterns: {
          $splice: [[index, 1]],
        },
      });
    }

    // ELEMENTS
    case PRICING_ADD_ELEMENTS: {
      return update(state, {
        PricingElements: { $set: action.elements },
      });
    }
    case PRICING_REMOVE_ELEMENTS: {
      return update(state, {
        PricingElements: { $splice: [[action.index, 1]] },
      });
    }
    case PRICING_ELEMENTS_QTY: {
      return update(state, {
        PricingElements: {
          [action.index]: {
            qty: { $set: action.value },
          },
        },
      });
    }

    // ACCESSORIES
    case PRICING_ADD_ACCESSORIES: {
      return update(state, {
        PricingAccessories: { $set: action.accessories },
      });
    }
    case PRICING_REMOVE_ACCESSORY: {
      return update(state, {
        PricingAccessories: { $splice: [[action.index, 1]] },
      });
    }
    case PRICING_ACCESSORIES_QTY: {
      return update(state, {
        PricingAccessories: {
          [action.index]: {
            qty: { $set: action.value },
          },
        },
      });
    }

    // SEGMENTS
    case PRICING_ADD_SEGMENT: {
      return update(state, {
        Segments: { $push: [action.segment] },
      });
    }
    case PRICING_REMOVE_SEGMENT: {
      return update(state, {
        Segments: { $splice: [[action.index, 1]] },
      });
    }

    // SEGMENT CABINETS
    case PRICING_ADD_SEGMENT_CABINET: {
      return update(state, {
        Segments: {
          [action.index]: {
            SegmentCabinets: { $push: [action.cabinet] },
          },
        },
      });
    }
    case PRICING_REMOVE_SEGMENT_CABINET: {
      const newCabinets = state.Segments[
        action.segmentIndex
      ].SegmentCabinets.filter(
        (cabinet: SegmentCabinetT, index: number) =>
          index !== action.cabinetIndex
      ).map((cabinet: SegmentCabinetT, index: number) => {
        const newCabinet = { ...cabinet, order: index + 1 };
        return newCabinet;
      });
      return update(state, {
        Segments: {
          [action.segmentIndex]: {
            SegmentCabinets: { $set: newCabinets },
          },
        },
      });
    }
    case PRICING_UPDATE_SEGMENT_CABINET: {
      return update(state, {
        Segments: {
          [action.segmentIndex]: {
            SegmentCabinets: {
              [action.cabinetIndex]: { $set: action.cabinet },
            },
          },
        },
      });
    }

    // DRAG AND DROP ORDER
    case PRICING_SET_ORDER_SEGMENT_CABINETS: {
      return update(state, {
        Segments: {
          [action.segmentIndex]: {
            SegmentCabinets: { $set: action.cabinets },
          },
        },
      });
    }
    default:
      return state;
  }
};

export { pricingReducer };
