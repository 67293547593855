import React from 'react';
import { CustomerT } from 'services/store/types/customers/Customers';
import PersonalData from 'components/Users/PersonalData';
import AddressData from 'components/Address/Display';
import { Col, Row } from 'antd';

interface PropsT {
  customer: CustomerT;
}

const CustomerData: React.FC<PropsT> = ({ customer }) => {
  if (customer)
    return (
      <Row>
        <Col sm={12} md={8}>
          <PersonalData customer={customer} title="Dane osobowe" column={1} />
        </Col>
        <Col sm={12} md={8}>
          <AddressData address={customer.CustomerAddress} column={1} />
        </Col>
      </Row>
    );
  return null;
};

export default CustomerData;
