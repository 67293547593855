import React from 'react';
import styled from 'styled-components';
import { Descriptions, Statistic, Col, Row } from 'antd';
import { getPersonalName } from 'services/utils/users';
import { useHistory } from 'react-router';
import TextButton from 'components/TextButton';
import { dateToString, rangeDateToString } from 'services/utils/date';
import { ProjectT } from 'services/store/types/projects/Projects';
import AddressData from 'components/Address/Display';
import { capitalizeFirstLetter } from 'services/utils/string';
import { isMobile } from 'react-device-detect';
import withPermission from 'hoc/withPermission';
import { PermissionsT } from 'services/store/types/auth/Auth';
import { perms } from 'services/utils/const';
import moment from 'moment';

const StatisticsWrapper = styled.div`
  display: flex;
  justify-content: ${() => (isMobile ? 'flex-start' : 'flex-end')};
  div {
    margin-right: 10px;
    width: fit-content;
  }
`;

interface PropsT {
  project: ProjectT;
  permission: PermissionsT;
}

const ProjectInfo: React.FC<PropsT> = ({ project, permission }) => {
  const history = useHistory();

  return (
    <Row justify="space-between" wrap>
      {/* PROJECT DATA */}
      <Col sm={12} md={6}>
        <Descriptions size="small" column={1} title="Projekt">
          <Descriptions.Item label="Projektant">
            {[perms.ADMIN, perms.DESIGNER].includes(permission) ? (
              <TextButton
                text={project.User ? getPersonalName(project.User) : 'BRAK'}
                onClick={() =>
                  project.User ? history.push(`/users/${project.UserId}`) : null
                }
              />
            ) : (
              <span>
                {project.User ? getPersonalName(project.User) : 'BRAK'}
              </span>
            )}
          </Descriptions.Item>
          {project.externalCompany && (
            <Descriptions.Item label="Firma zewnętrzna">
              {[perms.ADMIN, perms.DESIGNER].includes(permission) ? (
                <TextButton
                  text={
                    project.externalCompany
                      ? `${project.externalCompany?.company}`
                      : 'BRAK'
                  }
                  onClick={() =>
                    project.User
                      ? history.push(`/users/${project.UserId}`)
                      : null
                  }
                />
              ) : (
                <span>
                  {project.User ? getPersonalName(project.User) : 'BRAK'}
                </span>
              )}
            </Descriptions.Item>
          )}

          <Descriptions.Item label="Numer">
            <strong>{project.number}</strong>
          </Descriptions.Item>
          <Descriptions.Item label="Numer porządkowy">
            <strong>
              {project.ordinalNumber
                ? `${project.ordinalNumber}/${project.projectYear}`
                : '-'}
            </strong>
          </Descriptions.Item>

          <Descriptions.Item label="Uwagi">
            {project.comments || '-'}
          </Descriptions.Item>
        </Descriptions>
      </Col>
      {/* PROJECT DATES */}
      <Col sm={12} md={6}>
        <Descriptions size="small" column={1} title="Daty">
          <Descriptions.Item label="Data rozpoczęcia">
            <strong>
              {project.startAt ? dateToString(project.startAt) : '-'}
            </strong>
          </Descriptions.Item>

          <Descriptions.Item label="Data pomiaru">
            <strong>
              {project.measureTerm
                ? rangeDateToString(
                    project.measureTerm.startDate,
                    project.measureTerm.endDate
                  )
                : '-'}
            </strong>
            <small>
              {' '}
              (tydzień:{' '}
              <strong>
                {project.measureWeek
                  ? moment(project.measureWeek.date).week()
                  : '-'}
              </strong>
              )
            </small>
          </Descriptions.Item>

          {[perms.ADMIN, perms.DESIGNER, perms.OFFICE].includes(permission) && (
            <Descriptions.Item label="Data montażu">
              <strong>
                {project.montageTerm
                  ? rangeDateToString(
                      project.montageTerm.startDate,
                      project.montageTerm.endDate
                    )
                  : '-'}
              </strong>
              <small>
                (tydzień:{' '}
                <strong>
                  {project.montageWeek
                    ? `${moment(project.montageWeek.date).week()}, ${moment(
                        project.montageWeek.date
                      )
                        .add(1, 'week')
                        .week()}`
                    : '-'}
                </strong>
                )
              </small>
            </Descriptions.Item>
          )}

          <Descriptions.Item label="Data zakończenia">
            <strong>
              {project.receptionTerm
                ? dateToString(project.receptionTerm.startDate)
                : '-'}
            </strong>
          </Descriptions.Item>
        </Descriptions>
      </Col>
      <Col sm={12} md={6}>
        <AddressData
          address={project.montageAddress}
          column={1}
          title="Adres montażu"
        />
      </Col>
      <Col sm={12} md={6}>
        {/* <StatisticsWrapper>
          <span style={{ marginBottom: 10 }}>
            Data utworzenia: <strong>{dateToString(project.createdAt)}</strong>
          </span>
        </StatisticsWrapper> */}
        <StatisticsWrapper>
          <Statistic
            title="Status"
            value={capitalizeFirstLetter(project.status)}
          />
          {[perms.ADMIN, perms.DESIGNER, perms.OFFICE].includes(permission) && (
            <Statistic title="Cena" value={`${project.price || 0}zł`} />
          )}
        </StatisticsWrapper>
      </Col>
    </Row>
  );
};

export default withPermission(ProjectInfo);
