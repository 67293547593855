import { PricingT, SegmentT } from 'services/store/types/pricing/Pricing';

export const validatePricing = (pricing: PricingT) => {
  let errors: string[] = [];

  if (!pricing.PricingPatterns.length)
    errors.push('Musisz wybrać conajmniej 1 wzór płyty/frontu');
  if (!pricing.Segments.length) errors.push('Brak segmentów');
  else {
    const segmentCabinetsErrors: string[] = [];

    pricing.Segments.forEach((s: SegmentT, i: number) => {
      if (!s.SegmentCabinets.length)
        segmentCabinetsErrors.push(`Segment nr ${i + 1} nie zawiera korpusów`);
    });
    if (segmentCabinetsErrors.length)
      errors = errors.concat(segmentCabinetsErrors);
  }

  return errors;
};
