import { UserT } from 'services/store/types/auth/Auth';
import { initZero } from 'services/utils/date';
import { getUserInitials } from 'services/utils/users';
import { NewProjectT } from 'services/store/types/newProject/NewProject';

export const generateProjectNumber = (designer: UserT) => {
  const date = new Date();
  const dayNumber = `${initZero(date.getDate())}${initZero(date.getMonth())}`;
  const designerInitials = getUserInitials(designer);
  return `U/${dayNumber}/${date.getFullYear()}/${designerInitials}`.toUpperCase();
};

export const validateNewProject = (newProject: NewProjectT) => {
  const errors = [];
  if (!newProject.User || !newProject.UserId)
    errors.push('Wybierz projektanta');
  if (!newProject.Customer || !newProject.CustomerId)
    errors.push('Wybierz klienta');
  if (!newProject.name) errors.push('Nazwij projekt');

  return errors;
};
