import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getProjectPricingByProjectId } from 'services/store/actions/projects';
import { setSpinner } from 'services/store/actions/view';
import { ProjectT } from 'services/store/types/projects/Projects';
import PricingAccessoriesList from 'components/Pricing/Accessories/List';
import { Empty } from 'antd';

interface PropsT {
  project: ProjectT;
}

const ProjectPricingAccessories: React.FC<PropsT> = ({ project }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!project.Pricing) {
      dispatch(setSpinner(true));
      dispatch(getProjectPricingByProjectId(project.id));
    }
  }, []);

  if (project.Pricing)
    return (
      <div>
        <PricingAccessoriesList
          accessories={project.Pricing.PricingAccessories || []}
        />
      </div>
    );
  return <Empty />;
};

export default ProjectPricingAccessories;
