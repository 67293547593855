import React, { useState } from 'react';
import { Input, message, Modal } from 'antd';
import { ProjectT } from 'services/store/types/projects/Projects';

import { useMutation, useQuery } from '@tanstack/react-query';
import { projectsApi } from 'services/api/projects.api';

interface PropsT {
  project: ProjectT;
  closeModal: () => void;
  visible: boolean;
  refetchProject: () => Promise<any>;
}

const ChangeProjectNumber: React.FC<PropsT> = ({
  project,
  closeModal,
  visible,
  refetchProject,
}) => {
  const [number, setNumber] = useState(project.number || '');

  const getSuggestedNumber = useQuery({
    queryKey: ['get-project-suggested-number', project.id],
    queryFn: () => projectsApi.getProjectSuggestedNumber(project.id),
    onSuccess(res) {
      setNumber(res.data.number);
    },
    onError() {
      message.error('Błąd');
    },
    retry: false,
  });

  const updateNumber = useMutation(
    async () => number && projectsApi.updateNumber(project.id, number),
    {
      onSuccess() {
        message.success('Numer zaktualizowany');
        refetchProject();
        closeModal();
      },
      onError(err: any) {
        console.log(err?.response);
        message.error('Bład');
      },
    }
  );

  return (
    <Modal
      title="Zmiana numeru projektu"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      onOk={() => updateNumber.mutate()}
      okButtonProps={{
        disabled: !number || getSuggestedNumber.isFetching,
        loading: getSuggestedNumber.isLoading || updateNumber.isLoading,
      }}
    >
      <Input value={number} onChange={(e) => setNumber(e.target.value)} />
    </Modal>
  );
};

export default ChangeProjectNumber;
