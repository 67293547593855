import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Dropdown,
  Menu,
  message,
  Popconfirm,
  Table,
  Typography,
  Modal,
} from 'antd';
import Img from 'components/Image';
import { OrderStatusTag } from 'components/Projects/StatusTag';
import TextButton from 'components/TextButton';
import withPermission from 'hoc/withPermission';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getOrderById } from 'services/api/files/get';
import { sendOrder, cancelOrder } from 'services/store/actions/orders';
import { setSpinner } from 'services/store/actions/view';
import { PermissionsT, UserT } from 'services/store/types/auth/Auth';
import { OrderAccessoryT, OrderT } from 'services/store/types/orders/Orders';
import { ProjectT } from 'services/store/types/projects/Projects';
import { SupplierT } from 'services/store/types/suppliers/Suppliers';
import { IdType } from 'services/types';
import { orderStates, perms } from 'services/utils/const';
import { dateToString } from 'services/utils/date';
import { cutString } from 'services/utils/string';
import { getPersonalName } from 'services/utils/users';

const columns = [
  {
    title: 'Lp.',
    align: 'center' as const,
    render: (t: string, r: any, i: number) => i + 1,
  },
  {
    title: (
      <div>
        <div>Nazwa</div>
        <div>
          <small>Opis</small>
        </div>
      </div>
    ),
    dataIndex: 'name',
    align: 'center' as const,
    render: (N: string, R: any) => (
      <div>
        <div>{cutString(N, 30)}</div>
        <div>
          <small>{cutString(R.description, 30)}</small>
        </div>
      </div>
    ),
  },
  {
    title: 'Projekt',
    dataIndex: 'project',
    align: 'center' as const,
    render: (P: any, R: any) => {
      if (R.Project && R.Project.id)
        return (
          <TextButton
            text={R.Project.number || 'Brak numeru'}
            onClick={() => {
              R.redirect(`/projects/${R.Project.id}`);
            }}
          />
        );
      return '-';
    },
  },

  {
    title: 'Dystrybutor',
    dataIndex: 'Supplier',
    align: 'center' as const,
    render: (S: SupplierT, r: any) => {
      return S ? (
        <TextButton
          text={S.name}
          onClick={() => {
            r.redirect(`/suppliers/${S.id}`);
          }}
        />
      ) : (
        '-'
      );
    },
  },

  {
    title: 'Liczba pozycji',
    dataIndex: 'OrderAccessories',
    align: 'center' as const,
    render: (A: OrderAccessoryT[]) => (A ? A.length : 0),
  },
  {
    title: 'Utworzone/wysłane przez',
    dataIndex: 'User',
    align: 'center' as const,
    render: (U: UserT) => U && getPersonalName(U),
  },
  {
    title: 'Data wysłania',
    dataIndex: 'sendedAt',
    align: 'center' as const,
    render: (D?: Date) => (D ? dateToString(D) : '-'),
  },
  {
    title: 'Przewidywana data realizacji',
    dataIndex: 'expectedPickUp',
    align: 'center' as const,
    render: (D?: Date) => (D ? dateToString(D) : '-'),
  },
  {
    title: 'Data zakończenia',
    dataIndex: 'endedAt',
    align: 'center' as const,
    render: (D?: Date) => (D ? dateToString(D) : '-'),
  },
  {
    title: 'Cena',
    dataIndex: 'price',
    align: 'center' as const,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center' as const,
    render: (S: string) => <OrderStatusTag status={S} />,
  },

  {
    title: 'Akcje',
    key: 'action',
    align: 'center' as const,
    render: (a: any, R: any) => (
      <div>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                disabled={R.status !== orderStates.CREATED}
                onClick={() => {
                  Modal.confirm({
                    title: 'Wysyłanie zamówienia',
                    icon: <ExclamationCircleOutlined />,
                    content:
                      'Automatyczna wysyłka wyłączona. Wysłanie spowoduje zmianę statusu zamówienia. Aby doszło do realizacji musisz pobrać akrusz i wysłać go poprzez e-mail.',
                    onOk: () => R.sendOrder(R.id),
                  });
                }}
              >
                Wyślij
              </Menu.Item>
              <Menu.Item
                disabled={
                  R.status !== orderStates.SENDED &&
                  R.status !== orderStates.PART_PICKED
                }
                onClick={() => R.redirect(`/orders/${R.id}/pickup`)}
              >
                Odbierz
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  return R.downloadOrder(R.id, `${R.Supplier.name}`);
                }}
              >
                Pobierz
              </Menu.Item>
              <Menu.Item
                disabled={
                  R.status === orderStates.SENDED ||
                  R.status === orderStates.PART_PICKED ||
                  R.status === orderStates.CANCEL ||
                  R.status === orderStates.ENDED
                }
                onClick={() => R.cancelOrder(R.id)}
              >
                Anuluj
              </Menu.Item>

              <Menu.Item
                danger
                disabled={
                  R.status === orderStates.SENDED ||
                  R.status === orderStates.PART_PICKED ||
                  R.permission !== perms.ADMIN
                }
                onClick={() => {
                  Modal.confirm({
                    title: 'Kasowanie zamówienia',
                    icon: <ExclamationCircleOutlined />,
                    content: 'Usunięcie zamówienia jest nieodwracalne',
                    onOk: () => R.removeOrder(R.id),
                  });
                }}
              >
                Usuń
              </Menu.Item>
            </Menu>
          }
        >
          <Typography.Link>
            Akcje <DownOutlined />
          </Typography.Link>
        </Dropdown>
      </div>
    ),
  },
];

interface PropsT {
  permission: PermissionsT;
  orders: OrderT[];
  expandable?: boolean;
  handleRemove?: (orderId: IdType) => void;
  project?: ProjectT;
  handleUpdate?: (order: OrderT) => void;
}

const ProjectOrdersList: React.FC<PropsT> = ({
  orders,
  expandable = true,
  handleRemove,
  project,
  handleUpdate,
  permission,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [list, setList] = useState<any>([]);

  useEffect(() => {
    const newList = orders.map((order: OrderT) => {
      return {
        permission,
        ...order,
        key: order.id,
        redirect: (path: string) => {
          history.push(path);
        },
        downloadOrder: handleDownloadOrder,
        removeOrder: handleRemoveOrder,
        sendOrder: handleSendOrder,
        cancelOrder: handleCancelOrder,
      };
    });
    setList(newList);
  }, [orders]);

  const handleRemoveOrder = (orderId: IdType) => {
    if (handleRemove) {
      dispatch(setSpinner(true));
      handleRemove(orderId);
      dispatch(setSpinner(false));
    }
  };

  const handleSendOrder = (orderId: IdType) => {
    dispatch(setSpinner(true));
    dispatch(
      sendOrder(orderId, (ord) => {
        if (handleUpdate) handleUpdate(ord);
        message.success('Zamówienie wysłane');
      })
    );
  };

  const handleCancelOrder = (orderId: IdType) => {
    dispatch(setSpinner(true));
    dispatch(
      cancelOrder(orderId, (ord) => {
        if (handleUpdate) handleUpdate(ord);
        message.success('Zamówienie anulowane');
      })
    );
  };

  const handleDownloadOrder = async (orderId: IdType, name: string) => {
    dispatch(setSpinner(true));
    await getOrderById(
      orderId,
      () => {},
      () => {},
      `${project ? `${project.number}_` : ''}${name}`
    );
    dispatch(setSpinner(false));
  };

  return (
    <div>
      <Table
        pagination={false}
        columns={columns}
        dataSource={list}
        expandable={
          expandable
            ? {
                expandedRowRender: (record: any) => {
                  const accessories = record.OrderAccessories.map(
                    (accessory: OrderAccessoryT) => {
                      return {
                        ...accessory,
                        ...accessory.Accessory,
                        key: accessory.id,
                      };
                    }
                  );

                  return OrderAccessories(accessories);
                },
              }
            : {}
        }
      />
    </div>
  );
};

export default withPermission(ProjectOrdersList);

const accessoriesColumns: any[] = [
  {
    title: 'Lp.',
    align: 'center' as const,
    render: (t: string, r: any, i: number) => i + 1,
  },
  {
    title: 'Zdjęcie',
    dataIndex: 'img',
    align: 'center' as const,
    render: (t: string, record: any, i: number) => (
      <Img
        previewPath={record.imgPath}
        path={record.miniaturePath}
        isExist={record.Accessory}
      />
    ),
  },
  {
    title: 'Nazwa',
    dataIndex: 'name',
    align: 'center' as const,
    defaultSortOrder: 'ascend' as const,
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
    align: 'center' as const,
  },
  {
    title: 'Cena',
    dataIndex: 'price',
    align: 'center' as const,
  },
  {
    title: 'Ilość',
    dataIndex: 'qty',
    align: 'center' as const,
  },
];

const OrderAccessories = (row: any) => {
  return (
    <Table
      columns={accessoriesColumns}
      dataSource={row}
      pagination={false}
      size="small"
    />
  );
};
