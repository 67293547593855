import React, { useState, useEffect } from 'react';
import { Modal, Button, Upload } from 'antd';
import { Input, Form, InputNumber, Select } from 'formik-antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateT } from 'services/store';
import { Formik } from 'formik';
import { UploadOutlined } from '@ant-design/icons';
import { SupplierT } from 'services/store/types/suppliers/Suppliers';
import { getSupliers } from 'services/store/actions/suppliers';
import { setSpinner } from 'services/store/actions/view';
import { schema } from './utils/validate';

const { Option } = Select;

const initValues = {
  name: '',
  symbol: '',
  imgPath: '',
  SupplierId: undefined,
  img: [],
  price: 0,
};
interface PropsT {
  visible: boolean;
  closeModal: () => void;
  handleSubmit: (supplier: any, setLoading: (x: boolean) => void) => void;
  title: string;
  values?: any;
}

const AccessoryModal: React.FC<PropsT> = ({
  title,
  visible,
  closeModal,
  handleSubmit,
  values = initValues,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const suppliers = useSelector(
    (state: AppStateT) => state.suppliers.suppliers
  );

  useEffect(() => {
    if (!suppliers.length) {
      dispatch(setSpinner(true));
      dispatch(getSupliers());
    }
  }, []);

  const handleOk = async (product: any) => {
    setLoading(true);
    handleSubmit(product, setLoading);
  };

  return (
    <Modal
      afterClose={() => {
        setLoading(false);
      }}
      title={title}
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Anuluj
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          htmlType="submit"
          form="addSupplierForm"
        >
          Zatwierdź
        </Button>,
      ]}
    >
      <Formik
        validationSchema={schema}
        onSubmit={handleOk}
        initialValues={{ ...values, img: [] }}
      >
        {(props) => {
          return (
            <Form
              noValidate
              className="form-container"
              id="addSupplierForm"
              onFinish={props.handleSubmit}
            >
              <Form.Item name="SupplierId">
                <Select placeholder="Dystrybutor" name="SupplierId">
                  {suppliers.map((supp: SupplierT) => {
                    if (supp.id)
                      return (
                        <Option value={supp.id} key={supp.id}>
                          {supp.name}
                        </Option>
                      );
                    return null;
                  })}
                </Select>
              </Form.Item>

              <Form.Item name="name">
                <Input name="name" placeholder="Nazwa" required />
              </Form.Item>
              <Form.Item name="symbol">
                <Input name="symbol" placeholder="Symbol" required />
              </Form.Item>
              <Form.Item name="price" label="Cena">
                <InputNumber name="price" />
              </Form.Item>
              <Upload
                onRemove={() => props.setFieldValue('img', [])}
                beforeUpload={(file: any) => {
                  props.setFieldValue('img', [file]);
                  return false;
                }}
                fileList={[...props.values.img]}
                multiple={false}
                accept="image/*"
              >
                <Button icon={<UploadOutlined />}>Dodaj zdjęcie</Button>
              </Upload>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AccessoryModal;
