import { RcFile } from 'antd/lib/upload';
import axios, { AxiosResponse } from 'axios';
import { Moment } from 'moment';
import {
  CommentT,
  EquipmentT,
  FileT,
  HistoryItemT,
  PaymentT,
  ProjectStatusT,
  ProjectT,
} from 'services/store/types/projects/Projects';
import { AddressT } from 'services/store/types/settings/Settings';
import { PricingFileTypesT } from 'services/types';
import setAuthToken from 'services/utils/setAuthToken';

export interface IPaymentsToApprove {
  PaymentId: string;
  value: number;
}

export const projectsApi = {
  //NOTE GET
  getProject: (id: string): Promise<AxiosResponse<{ project: ProjectT }>> => {
    setAuthToken();
    return axios.get(`/api/projects/${id}`);
  },
  getProjectComments: (
    id: string
  ): Promise<AxiosResponse<{ comments: CommentT[] }>> => {
    setAuthToken();
    return axios.get(`/api/projects/comments/${id}`);
  },
  getProjectEquipment: (
    id: string
  ): Promise<AxiosResponse<{ equipment: EquipmentT[] }>> => {
    setAuthToken();
    return axios.get(`/api/projects/equipment/${id}`);
  },
  getProjectHistory: (
    id: string
  ): Promise<AxiosResponse<{ history: HistoryItemT[] }>> => {
    setAuthToken();
    return axios.get(`/api/projects/history/${id}`);
  },
  getProjectSuggestedNumber: (
    id: string
  ): Promise<AxiosResponse<{ number: string }>> => {
    setAuthToken();
    return axios.get(`/api/projects/suggested-number/${id}`);
  },
  getNextOrdinalNumber: (
    userId?: string
  ): Promise<AxiosResponse<{ nextOrdinalNumber: string }>> => {
    setAuthToken();
    if (!userId) throw new Error('wrong param');
    return axios.get(`/api/projects/suggested-ordinal-number/${userId}`);
  },
  //   NOTE GET FILES
  getProjectFiles: (id: string): Promise<AxiosResponse<{ files: FileT[] }>> => {
    setAuthToken();
    return axios.get(`/api/files/project/${id}`);
  },
  getProjectPricingFiles: (
    id: string,
    type: PricingFileTypesT
  ): Promise<AxiosResponse<{ files: FileT[] }>> => {
    setAuthToken();
    return axios.get(`/api/files/project/pricing/${id}/${type}`);
  },

  // NOTE UPDATE
  updateMontageAddress: (
    id: string,
    montageAddress: AddressT
  ): Promise<AxiosResponse<{ project: ProjectT }>> => {
    setAuthToken();
    return axios.put(`/api/projects/montageaddress/${id}`, { montageAddress });
  },
  updateStatus: (
    id: string,
    status: ProjectStatusT
  ): Promise<AxiosResponse<{ project: ProjectT }>> => {
    setAuthToken();
    return axios.put(`/api/projects/statuschange/${id}`, { status });
  },
  finishProject: (
    id: string,
    status: ProjectStatusT
  ): Promise<AxiosResponse<{ project: ProjectT }>> => {
    setAuthToken();
    return axios.put(`/api/projects/montageend/${id}`, { status });
  },
  updatePrice: (
    id: string,
    price: number
  ): Promise<AxiosResponse<{ project: ProjectT }>> => {
    setAuthToken();
    return axios.put(`/api/projects/pricechange/${id}`, { price });
  },
  updateName: (
    id: string,
    name: string
  ): Promise<AxiosResponse<{ project: ProjectT }>> => {
    setAuthToken();
    return axios.put(`/api/projects/namechange/${id}`, { name });
  },
  updateNumber: (
    id: string,
    number: string
  ): Promise<AxiosResponse<{ project: ProjectT }>> => {
    setAuthToken();
    return axios.put(`/api/projects/numberchange/${id}`, { number });
  },
  updateOrdinalNumber: (
    id: string,
    ordinalNumber: string | number,
    projectYear: number
  ): Promise<AxiosResponse<{ project: ProjectT }>> => {
    setAuthToken();
    return axios.put(`/api/projects/ordinalnumberchange/${id}`, {
      ordinalNumber,
      projectYear,
    });
  },
  updateDesigner: (
    id: string,
    designer: string
  ): Promise<AxiosResponse<{ project: ProjectT }>> => {
    setAuthToken();
    return axios.put(`/api/projects/designerchange/${id}`, {
      UserId: designer,
    });
  },
  updateCustomer: (
    id: string,
    customer: string
  ): Promise<AxiosResponse<{ project: ProjectT }>> => {
    setAuthToken();
    return axios.put(`/api/projects/customerchange/${id}`, {
      CustomerId: customer,
    });
  },
  updateExternalCompany: (
    id: string,
    externalCompany: string | undefined
  ): Promise<AxiosResponse<{ project: ProjectT }>> => {
    setAuthToken();
    return axios.put(`/api/projects/externalcompany/${id}`, {
      externalCompanyId: externalCompany,
    });
  },
  updatePaymentsParts: (
    id: string,
    Payments: PaymentT[]
  ): Promise<AxiosResponse<{ project: ProjectT }>> => {
    setAuthToken();
    return axios.put(`/api/projects/paymentparts/${id}`, { Payments });
  },
  updatePayments: (
    id: string,
    paymentsToApprove: (IPaymentsToApprove | null)[],
    files: RcFile[]
  ): Promise<AxiosResponse<{ project: ProjectT }>> => {
    setAuthToken();
    const body = new FormData();
    files.forEach((file: RcFile) => body.append('files[]', file, file.name));
    body.append('data', JSON.stringify({ paymentsToApprove }));

    return axios.put(`/api/projects/payment/${id}`, body);
  },
  updateEquipment: (
    id: string,
    equipment: Partial<EquipmentT>[]
  ): Promise<AxiosResponse<{ project: ProjectT }>> => {
    setAuthToken();
    return axios.post(`/api/equipment/${id}`, { equipment });
  },

  //   NOTE UPDATE DATES
  updateCreationDate: (
    id: string,
    date: Moment
  ): Promise<AxiosResponse<{ project: ProjectT }>> => {
    setAuthToken();
    return axios.put(`/api/projects/datechange/${id}`, {
      date,
    });
  },

  //   UPDATE TERMS
  updateMeasureTerm: (
    id: string,
    startDate: Moment,
    endDate: Moment
  ): Promise<AxiosResponse<{ project: ProjectT }>> => {
    setAuthToken();
    return axios.post(`/api/terms/measuredate/${id}`, {
      measureDate: [startDate, endDate],
    });
  },
  updateMontageTerm: (
    id: string,
    startDate: Moment,
    endDate: Moment
  ): Promise<AxiosResponse<{ project: ProjectT }>> => {
    setAuthToken();
    return axios.post(`/api/terms/montagedate/${id}`, {
      montageDate: [startDate, endDate],
    });
  },
  updateMeasureWeek: (
    id: string,
    data: { measureWeek: Date }
  ): Promise<AxiosResponse<{ project: ProjectT }>> => {
    setAuthToken();
    return axios.post(`/api/terms/measureweek/${id}`, data);
  },
  updateMontageWeek: (
    id: string,
    data: { montageWeek: Date }
  ): Promise<AxiosResponse<{ project: ProjectT }>> => {
    setAuthToken();
    return axios.post(`/api/terms/montageweek/${id}`, data);
  },
};
