import Axios from 'axios';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import { CustomerT } from 'services/store/types/customers/Customers';
import setAuthToken from 'services/utils/setAuthToken';
import { AddressT } from 'services/store/types/settings/Settings';

export const createNewCustomer = async (
  values: CreateCustomerValuesT,
  onEnd: (user: CustomerT) => void,
  onErr: (msg: string) => void
) => {
  setAuthToken();
  try {
    const res: any = await Axios.post('/api/customers/', { ...values });

    onEnd(res.data.customer);
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};

export interface CreateCustomerValuesT {
  email: string;
  phone?: string;
  firstname: string;
  surname: string;
  pesel: string;
  nip?: string;
  company?: string;
  CustomerAddress?: AddressT;
}
