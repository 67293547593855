/* eslint-disable @typescript-eslint/default-param-last */
import update from 'immutability-helper';
import { SettingsT, CabinetTypeT } from '../types/settings/Settings';
import {
  SettingsActionsT,
  SETTINGS_LOAD_SUCCESS,
  SETTINGS_LOAD_ERROR,
  SETTINGS_ADD_TO_LIST,
  SETTINGS_UPDATE_IN_LIST,
  SETTINGS_REMOVE_FROM_LIST,
} from '../types/settings/actions';

const initialState: SettingsT = {
  cabinetTypes: [],
  contact: null,
  tab: 'contact',
  other: {
    blowUserId: '',
  },
};

const settingsReducer = (
  state = initialState,
  action: SettingsActionsT
): SettingsT => {
  switch (action.type) {
    case SETTINGS_LOAD_SUCCESS: {
      return { ...state, ...action.settings };
    }
    case SETTINGS_LOAD_ERROR: {
      return { ...state, cabinetTypes: [], contact: null };
    }

    case SETTINGS_ADD_TO_LIST: {
      return update(state, { [action.field]: { $push: [action.item] } });
    }
    case SETTINGS_UPDATE_IN_LIST: {
      const index = state[action.field].findIndex(
        (item: CabinetTypeT) => item.id === action.item.id
      );
      return update(state, {
        [action.field]: {
          [index]: { $set: action.item },
        },
      });
    }
    case SETTINGS_REMOVE_FROM_LIST: {
      const index = state[action.field].findIndex(
        (item: CabinetTypeT) => item.id === action.id
      );
      return update(state, {
        [action.field]: {
          $splice: [[index, 1]],
        },
      });
    }
    default:
      return state;
  }
};

export { settingsReducer };
