import moment, { Moment } from 'moment';
import React from 'react';
import { TermT, WeekT } from 'services/store/types/terms/Terms';
import {
  Alert,
  Badge,
  Button,
  Calendar,
  message,
  Popover,
  Typography,
} from 'antd';
import {
  getUserNameFromTerm,
  getUserNameFromWeek,
} from '../utils/projectTerm.utils';

interface IProps {
  terms?: TermT[];
  weeks?: WeekT[];
  date: Moment;
  handleDate: (d: Moment) => void;
  pickDate: (d: Moment) => void;
  startDate: Moment | undefined;
  endDate: Moment | undefined;
  termType: 'montaż' | 'pomiar';
}

const PickTermCalendarView: React.FC<IProps> = ({
  terms,
  weeks,
  date,
  handleDate,
  pickDate,
  startDate,
  endDate,
  termType,
}) => {
  const dateCellRender = (d: Moment) => {
    const cellData: any = {};
    cellData.terms = terms
      ?.filter((term) => {
        if (
          (d.isSame(term.endDate, 'date') ||
            d.isSame(term.startDate, 'date') ||
            d.isBetween(
              moment(term.startDate).startOf('day'),
              moment(term.endDate).endOf('day')
            )) &&
          term.type === termType
        )
          return term;
      })
      .map((term) => `${term.type} - ${getUserNameFromTerm(term)}`);

    // cellData.weeks = weeks
    //   ?.filter((week) => {
    //     if (d.isSame(moment(week.date), 'week') && week.type === termType)
    //       return week;
    //   })
    //   .map((week) => `rezerwacja - ${getUserNameFromWeek(week)}`);
    if (
      ![0, 6].includes(d.day()) &&
      ((startDate && d.isSame(startDate, 'date')) ||
        (endDate && d.isSame(endDate, 'date')) ||
        (startDate && endDate && d.isBetween(startDate, endDate)))
    )
      cellData.isInPickedRange = true;
    // if (cellData.terms?.length) console.log(cellData.terms);
    return (
      <div>
        {/* {cellData.weeks?.map((item: string) => (
          <div key={item}>
            <Badge
              status="default"
              text={<Typography.Text disabled>{item}</Typography.Text>}
            />
          </div>
        ))} */}
        {cellData.terms?.map((item: string) => (
          <div key={item}>
            <Badge
              status="processing"
              text={<Typography.Text>{item}</Typography.Text>}
            />
          </div>
        ))}
        {cellData.isInPickedRange && <Alert type="success" message="Wybrano" />}
      </div>
    );
  };
  //   console.log(terms);

  return (
    <div>
      <Calendar
        dateCellRender={dateCellRender}
        // monthCellRender={()=>{}}
        onSelect={pickDate}
        value={date}
        onPanelChange={(d: Moment) => {
          return handleDate(d);
        }}
      />
    </div>
  );
};

export default PickTermCalendarView;
