import React from 'react';
import PageTemplate from 'templates/PageTemplate';
import withPermission from 'hoc/withPermission';
import { perms } from 'services/utils/const';
import { useSelector } from 'react-redux';
import { AppStateT } from 'services/store';
import AdminHomePage from './Admin';
import DesignerHomePage from './Designer';
import TechnologistHomePage from './Technologist';
import CarpenterHomePage from './Carpenter';
import InstallerHomePage from './Installer';
import OfficeHomePage from './Office';
import ExternalCompanyHomePage from './ExternalCompany';
import SiderTemplate from 'templates/SiderTemplate/SiderTemplate';
import HomeSider from './sider/HomeSider';
import ContentTemplate from 'templates/ContentTemplate/ContentTemplate';

interface PropsT {
  permission: string;
}

const HomePage: React.FC<PropsT> = ({ permission }) => {
  const user = useSelector((state: AppStateT) => state.auth.user);
  let Page = null;
  if (permission === perms.ADMIN) Page = <AdminHomePage />;
  if (permission === perms.EXTERNAL_COMPANY) Page = <ExternalCompanyHomePage />;
  if (permission === perms.OFFICE) Page = <OfficeHomePage />;
  else if (permission === perms.DESIGNER) Page = <DesignerHomePage />;
  else if (permission === perms.TECHNOLOGIST) Page = <TechnologistHomePage />;
  else if (permission === perms.CARPENTER) Page = <CarpenterHomePage />;
  else if (permission === perms.INSTALLER) Page = <InstallerHomePage />;

  if (user)
    return (
      <PageTemplate>
        <SiderTemplate>
          <HomeSider />
        </SiderTemplate>
        <ContentTemplate>{Page}</ContentTemplate>
      </PageTemplate>
    );
  return null;
};

export default withPermission(HomePage);
