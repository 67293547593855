import { UploadOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Button, Divider, Upload, Modal, message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import useUser from 'hooks/useUser';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getFileFrompath } from 'services/api/files/get';
import { projectsApi } from 'services/api/projects.api';
import { useModal } from 'services/hooks/useModal';
import { setSpinner } from 'services/store/actions/view';
import { PaymentT, ProjectT } from 'services/store/types/projects/Projects';
import EditPayment from './EditPayment';
import EditPaymentParts from './EditPaymentParts';
import PaidItem from './PaidItem';
import PaymentItem from './PaymentItem';

// FIXME REFACTOR
interface PropsT {
  visible: boolean;
  closeModal: () => void;
  project: ProjectT;
  refetchProject: () => Promise<any>;
}

const Payment: React.FC<PropsT> = ({
  visible,
  closeModal,
  project,
  refetchProject,
}) => {
  const dispatch = useDispatch();
  const { isAdmin, isDesigner } = useUser();
  const [editedPayment, setEditedPayment] = useState<PaymentT | null>(null);
  const {
    modal,
    showModal,
    closeModal: closeInnerModal,
  } = useModal({
    paymentParts: false,
  });

  const [toApprove, setToApprove] = useState<boolean[]>([false, false, false]);
  const [values, setValues] = useState<number[]>([]);
  const [files, setFiles] = useState<RcFile[]>([]);

  useEffect(() => {
    const suggestedValues = project.Payments.map((p) => p.suggestedValue);
    setValues(suggestedValues);
  }, [project]);

  useEffect(() => {
    const notPaidIndex = project.Payments.findIndex((p) => !p.isPaid);
    if (notPaidIndex >= 0) {
      const approve = [...toApprove];
      approve[notPaidIndex] = true;
      setToApprove(approve);
    }
  }, [project]);

  const handleValue = (index: number, value: number) => {
    const v = [...values];
    v[index] = value;
    setValues(v);
  };
  const handleFile = (f: RcFile, fList: RcFile[]) => {
    setFiles([...files, ...fList]);
    return false;
  };
  const handleRemove = (f: UploadFile) => {
    const filesList = files.filter((file: RcFile) => file.uid !== f.uid);
    setFiles(filesList);
  };

  const handleMultiApproveItem = (value: boolean, index: number) => {
    const approve = [...toApprove];
    approve[index] = value;
    setToApprove(approve);
  };

  const getPaymentSum = () => {
    if (project.Payments)
      return project.Payments.reduce((acc: number, item: PaymentT) => {
        if (item.isPaid && item.value) return acc + item.value;
        return acc;
      }, 0);
    return 0;
  };

  const getLeftValue = () => {
    const paidValue = getPaymentSum();
    if (project.price) {
      return project.price - paidValue;
    }
    return 0;
  };

  const isSubmitDisabled = () => {
    if (!files.length) return true;
    const hasValues = toApprove.some((p, i) => {
      const value = values[i];
      if ((p && value) || !p) return true;
      return false;
    });
    if (!hasValues) return true;
    return false;
  };

  const updatePayments = useMutation(
    async () => {
      const paymentsToApprove = project.Payments.map((p, i) => {
        if (toApprove[i]) return { PaymentId: p.id, value: values[i] };
        return null;
      });
      return projectsApi.updatePayments(project.id, paymentsToApprove, files);
    },
    {
      onSuccess() {
        message.success('Zatwierdzono płatności');
        refetchProject();
        closeModal();
      },
      onError(err: any) {
        console.log(err?.response);
        message.error('Bład');
      },
    }
  );

  const handleGetFile = async (path: string, name: string) => {
    dispatch(setSpinner(true));
    await getFileFrompath(
      path,
      name,
      () => {
        dispatch(setSpinner(false));
      },
      () => {
        dispatch(setSpinner(false));
      }
    );
  };

  return (
    <Modal
      title="Płatności"
      visible={visible}
      onCancel={closeModal}
      onOk={() => {}}
      footer={[
        <Button
          key={1}
          onClick={() => showModal('paymentParts')}
          disabled={!isAdmin()}
        >
          Edytuj transze
        </Button>,
        <Button
          key={2}
          type="primary"
          onClick={() => updatePayments.mutate()}
          disabled={isSubmitDisabled()}
          loading={updatePayments.isLoading}
        >
          Zatwierdź
        </Button>,
      ]}
      width={700}
      style={{ top: 20 }}
    >
      <div style={{ textAlign: 'center', fontSize: 18 }}>
        Zatwierdzono wpłatę <strong>{getPaymentSum()}</strong> zł. Do pełnej
        kwoty brakuje <strong>{getLeftValue()} </strong>
        zł.
      </div>
      <Divider />
      {project.Payments &&
        project.Payments.map((item: PaymentT, i: number) =>
          !item.isPaid ? (
            <PaymentItem
              key={item.id}
              payment={item}
              placeholder={() => {
                if (project.price) return `${project.price * item.part}`;
                return `${0}`;
              }}
              index={i}
              toApprove={toApprove}
              handleMultiApprove={handleMultiApproveItem}
              value={values[i]}
              handleValue={handleValue}
            />
          ) : (
            <PaidItem
              key={item.id}
              payment={item}
              getFile={handleGetFile}
              editPayment={() => setEditedPayment(item)}
            />
          )
        )}
      <Upload
        fileList={files}
        beforeUpload={handleFile}
        multiple
        onRemove={handleRemove}
        disabled={!isAdmin() && !isDesigner()}
      >
        <Button
          style={{ margin: 1 }}
          icon={<UploadOutlined />}
          disabled={!isAdmin() && !isDesigner()}
        >
          Dodaj plik
        </Button>
      </Upload>
      {/* MODALS */}
      {editedPayment && (
        <EditPayment
          visible={!!editedPayment}
          payment={editedPayment}
          closeModal={() => setEditedPayment(null)}
          project={project}
          refetchProject={refetchProject}
        />
      )}
      {modal.paymentParts && (
        <EditPaymentParts
          Payments={project.Payments}
          project={project}
          visible={modal.paymentParts}
          closeModal={closeInnerModal}
          refetchProject={refetchProject}
        />
      )}
    </Modal>
  );
};

export default Payment;
