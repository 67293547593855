import * as yup from 'yup';

export const frontConfigSchema = yup.object({
  laminatePrice: yup
    .number()
    .typeError('Uzupełnij')
    .moreThan(0, 'Uzupełnij')
    .required('Uzupełnij'),
  paintGlossPrice: yup
    .number()
    .typeError('Uzupełnij')
    .moreThan(0, 'Uzupełnij')
    .required('Uzupełnij'),
  paintSemiGlossPrice: yup
    .number()
    .typeError('Uzupełnij')
    .moreThan(0, 'Uzupełnij')
    .required('Uzupełnij'),
  Elements: yup.array().of(
    yup.object().shape({
      width: yup
        .number()
        .typeError('Uzupełnij')
        .moreThan(0, 'Uzupełnij')
        .required('Uzupełnij'),
      height: yup
        .number()
        .typeError('Uzupełnij')
        .moreThan(0, 'Uzupełnij')
        .required('Uzupełnij'),
      thickness: yup
        .number()
        .typeError('Uzupełnij')
        .moreThan(0, 'Uzupełnij')
        .required('Uzupełnij'),
      defaultQty: yup
        .number()
        .typeError('Uzupełnij')
        .moreThan(0, 'Uzupełnij')
        .required('Uzupełnij'),
    })
  ),
});
