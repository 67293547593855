import Axios from 'axios';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import setAuthToken from 'services/utils/setAuthToken';
import {
  AddressT,
  CabinetTypeT,
  ContactT,
} from 'services/store/types/settings/Settings';

export const updateCabinetType = async (
  id: string,
  name: string,
  onEnd: (cabinetType: CabinetTypeT) => void,
  onErr: () => void
) => {
  setAuthToken();
  try {
    const res: any = await Axios.put(`/api/settings/cabinettype/${id}`, {
      name,
    });

    onEnd(res.data.cabinetType);
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};

export const updateCompanyAddress = async (
  addressId: string | number,
  values: AddressT,
  onEnd: () => void,
  onErr: () => void
) => {
  setAuthToken();
  try {
    await Axios.put(`/api/settings/companyaddress/${addressId}`, {
      addressData: values,
    });

    onEnd();
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};

export const updateCompanyData = async (
  values: ContactT,
  onEnd: (data: ContactT) => void,
  onErr: () => void
) => {
  setAuthToken();
  try {
    const res: any = await Axios.put('/api/settings/companydata', {
      companyData: values,
    });

    onEnd(res.contact);
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};
