import { message, PageHeader } from 'antd';
import TablesList from 'components/Catalog/Tables/List';
import { StyledH1 } from 'components/Header';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NewTableT } from 'services/api/catalog/post';
import { removeTable } from 'services/api/catalog/remove';
import { updateTable } from 'services/api/catalog/update';
import { AppStateT } from 'services/store';
import {
  catalogRemoveFromList,
  catalogUpdateInList,
  getCatalogPatterns,
} from 'services/store/actions/catalog';
import { setSpinner } from 'services/store/actions/view';
import { TableT } from 'services/store/types/catalog/Catalog';
import TableModal from 'siders/Catalog/components/TableModal';
import PageTemplate from 'templates/PageTemplate';

const CatalogTablesPage = () => {
  const dispatch = useDispatch();
  const tables = useSelector((state: AppStateT) => state.catalog.tables);

  const [toUpdate, setToUpdate] = useState<TableT | null>(null);

  useEffect(() => {
    if (!tables.length) {
      dispatch(setSpinner(true));
      dispatch(getCatalogPatterns('tables'));
    }
  }, []);

  const handleRemove = async (table: TableT) => {
    if (table.id) {
      const { id } = table;
      dispatch(setSpinner(true));
      await removeTable(
        id,
        () => {
          dispatch(setSpinner(false));
          dispatch(catalogRemoveFromList('tables', id));
          message.success('Blat usunięty');
        },
        () => {
          dispatch(setSpinner(false));
        }
      );
    }
  };
  const handleUpdate = async (table: NewTableT) => {
    if (table.id) {
      dispatch(setSpinner(true));
      await updateTable(
        table,
        (updatedTable) => {
          dispatch(setSpinner(false));
          dispatch(catalogUpdateInList('tables', updatedTable));
          message.success('Blat zaktualizowany');
          setToUpdate(null);
        },
        () => {
          dispatch(setSpinner(false));
        }
      );
    }
  };

  return (
    <PageTemplate>
      <PageHeader title={<StyledH1>Blaty</StyledH1>} />
      <TablesList
        tables={tables}
        actions={{
          remove: handleRemove,
          update: (table: TableT) => setToUpdate(table),
        }}
      />
      {/* Modals */}
      {toUpdate && (
        <TableModal
          title="Edycja blatu"
          initData={toUpdate}
          closeModal={() => setToUpdate(null)}
          visible={!!toUpdate}
          customHandleSubmit={handleUpdate}
        />
      )}
    </PageTemplate>
  );
};

export default CatalogTablesPage;
