import { message, PageHeader } from 'antd';
import PatternsList from 'components/Catalog/Patterns/List';
import { StyledH1 } from 'components/Header';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NewPatternT } from 'services/api/catalog/post';
import { removePattern } from 'services/api/catalog/remove';
import { updatePattern } from 'services/api/catalog/update';
import { AppStateT } from 'services/store';
import {
  catalogRemoveFromList,
  catalogUpdateInList,
  getCatalogPatterns,
} from 'services/store/actions/catalog';
import { setSpinner } from 'services/store/actions/view';
import { PatternT } from 'services/store/types/catalog/Catalog';
import PatternModal from 'siders/Catalog/components/PatternModal';
import PageTemplate from 'templates/PageTemplate';

const CatalogBoardsPage = () => {
  const dispatch = useDispatch();
  const boards = useSelector((state: AppStateT) => state.catalog.boards);

  const [toUpdate, setToUpdate] = useState<PatternT | null>(null);

  useEffect(() => {
    if (!boards.length) {
      dispatch(setSpinner(true));
      dispatch(getCatalogPatterns('boards'));
    }
  }, []);

  const handleRemove = async (pattern: PatternT) => {
    if (pattern.id) {
      const { id } = pattern;
      dispatch(setSpinner(true));
      await removePattern(
        id,
        () => {
          dispatch(setSpinner(false));
          dispatch(catalogRemoveFromList('boards', id));
          message.success('Wzór usunięty');
        },
        () => {
          dispatch(setSpinner(false));
        }
      );
    }
  };
  const handleUpdate = async (pattern: NewPatternT) => {
    if (pattern.id) {
      dispatch(setSpinner(true));
      await updatePattern(
        pattern,
        (updatedPattern) => {
          dispatch(setSpinner(false));
          dispatch(catalogUpdateInList(updatedPattern.type, updatedPattern));
          message.success('Wzór zaktualizowany');
          setToUpdate(null);
        },
        () => {
          dispatch(setSpinner(false));
        }
      );
    }
  };

  return (
    <PageTemplate>
      <PageHeader title={<StyledH1>Płyty</StyledH1>} />
      <PatternsList
        patterns={boards}
        actions={{
          update: (pattern: PatternT) => setToUpdate(pattern),
          remove: handleRemove,
        }}
      />

      {/* MODALS */}
      {toUpdate && (
        <PatternModal
          title="Edycja wzoru"
          type="boards"
          initData={toUpdate}
          closeModal={() => setToUpdate(null)}
          visible={!!toUpdate}
          customHandleSubmit={handleUpdate}
        />
      )}
    </PageTemplate>
  );
};

export default CatalogBoardsPage;
