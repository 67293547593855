import { RcFile } from 'antd/lib/upload';
import Axios from 'axios';
import { ProjectT } from 'services/store/types/projects/Projects';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';

export const updateFile = async (
  fileId: string,
  files: RcFile[],
  onEnd: (project: ProjectT) => void,
  onError: () => void
) => {
  try {
    const body = new FormData();
    files.forEach((file: RcFile) => body.append('files[]', file, file.name));

    const res: any = await Axios.put(`/api/files/${fileId}`, body);

    return onEnd(res.data.project);
  } catch (err) {
    return axiosErrorHandle(err, onError);
  }
};
