import { Menu, Modal } from 'antd';
import React from 'react';
import {
  MergeCellsOutlined,
  ColumnWidthOutlined,
  DownloadOutlined,
  ForwardOutlined,
  DollarCircleOutlined,
  GroupOutlined,
  HomeOutlined,
  ClusterOutlined,
  TableOutlined,
  AppstoreOutlined,
  ApiOutlined,
  CreditCardOutlined,
} from '@ant-design/icons';
import { states } from 'services/utils/const';
import SubMenu from 'antd/lib/menu/SubMenu';
import { IProjectSiderProps } from './ProjectSider';
import { useHistory } from 'react-router';
import { isStatusChangeAvailable } from './utils';

interface IProps extends IProjectSiderProps {}

const DesignerSider: React.FC<IProps> = ({
  project,
  showModal,
  getPicingFile,
  files,
}) => {
  const history = useHistory();
  return (
    <>
      <Menu mode="inline">
        <Menu.Item
          key="toTechnologist"
          icon={<ForwardOutlined />}
          onClick={() => {
            const errors = project
              ? isStatusChangeAvailable('designer', project, files)
              : [];
            if (!errors.length) return showModal('changeStatus');
            return Modal.error({
              maskClosable: true,
              title: 'Błąd zmiany statusu projektu',
              content: (
                <ul>
                  {errors.map((err: string) => {
                    return <li key={err}>{err}</li>;
                  })}
                </ul>
              ),
            });
          }}
          disabled={!project || ![states.PROJECT].includes(project.status)}
        >
          Przekaż technologowi
        </Menu.Item>
        <Menu.Item
          key="montageAddress"
          icon={<HomeOutlined />}
          onClick={() => showModal('montageAddress')}
          disabled={!project || ![states.PENDING].includes(project.status)}
        >
          {!project?.montageAddress ? 'Dodaj' : 'Zmień'} adres montażu
        </Menu.Item>
        <Menu.Item
          key="changePrice"
          icon={<DollarCircleOutlined />}
          onClick={() => showModal('changePrice')}
          // disabled={![states.PENDING].includes(project.status)}
        >
          Zmień cenę
        </Menu.Item>
        <Menu.Item
          key="updatePayments"
          icon={<CreditCardOutlined />}
          onClick={() => showModal('payments')}
          // disabled={!project.price || !project.Payments?.length}
        >
          Płatności
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="setMeasureDate"
          icon={<ColumnWidthOutlined />}
          onClick={() =>
            project && history.push(`/projects/schedule/measure/${project.id}`)
          }
          disabled={!project || ![states.PENDING].includes(project.status)}
        >
          Wyznacz datę pomiaru
        </Menu.Item>
        <Menu.Item
          key="setMontageDate"
          icon={<MergeCellsOutlined />}
          onClick={() =>
            project && history.push(`/projects/schedule/montage/${project.id}`)
          }
          disabled={!project || ![states.PENDING].includes(project.status)}
        >
          Wyznacz datę montażu
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="priceProject"
          icon={<ClusterOutlined />}
          onClick={() => project && history.push(`/pricing/${project.id}`)}
        >
          Wyceń projekt
        </Menu.Item>

        <SubMenu
          key="files"
          icon={<TableOutlined />}
          title="Pobierz pliki"
          disabled={!project || !project.isPriced}
        >
          <Menu.Item
            key="elements"
            icon={<AppstoreOutlined />}
            onClick={() => project && getPicingFile('elements')}
          >
            Lista elementów konstrukcyjnych
          </Menu.Item>
          <Menu.Item
            key="fronts"
            icon={<AppstoreOutlined />}
            onClick={() => project && getPicingFile('fronts')}
          >
            Lista frontów
          </Menu.Item>
          <Menu.Item
            key="accessories"
            icon={<ApiOutlined />}
            onClick={() => project && getPicingFile('accessories')}
          >
            Lista akcesoriów
          </Menu.Item>
        </SubMenu>
        <Menu.Divider />
        <Menu.Item
          key="equipment"
          icon={<GroupOutlined />}
          onClick={() => showModal('equipment')}
          disabled={
            !project ||
            ![states.PENDING, states.PROJECT].includes(project.status)
          }
        >
          Wykaz sprzętu
        </Menu.Item>
        <Menu.Item
          key="documents"
          icon={<DownloadOutlined />}
          onClick={() => showModal('files')}
        >
          Pobierz dokumenty
        </Menu.Item>
      </Menu>
    </>
  );
};

export default DesignerSider;
