/* eslint-disable @typescript-eslint/default-param-last */
import update from 'immutability-helper';
import moment from 'moment';
import { TermsT } from '../types/terms/Terms';
import {
  TermsActionsT,
  TERMS_LOAD_SUCCESS,
  TERMS_LOAD_ERROR,
  TERMS_SET_DATE,
  TERMS_SET_FILTER,
} from '../types/terms/actions';
import { getCustomersFromTerms } from './utils/terms';

const initialState: TermsT = {
  date: moment(),
  terms: [],
  customersList: [],
  filters: {
    type: undefined,
    customerId: undefined,
  },
};

const termsReducer = (state = initialState, action: TermsActionsT): TermsT => {
  switch (action.type) {
    case TERMS_SET_FILTER: {
      return update(state, {
        filters: {
          [action.key]: { $set: action.value },
        },
      });
    }
    case TERMS_SET_DATE: {
      return { ...state, date: action.date };
    }
    case TERMS_LOAD_SUCCESS: {
      const customersList = getCustomersFromTerms(action.terms);
      return { ...state, terms: action.terms, customersList };
    }
    case TERMS_LOAD_ERROR: {
      return { ...state, terms: [] };
    }
    default:
      return state;
  }
};

export { termsReducer };
