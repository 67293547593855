import React, { useEffect } from 'react';
import CustomersWithProjectsList from 'components/Projects/CustomersWithProjectsList';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateT } from 'services/store';
import { setSpinner } from 'services/store/actions/view';
import { getCustomersWithEndedProjects } from 'services/store/actions/projects';

const EndedProjects = () => {
  const dispatch = useDispatch();
  const customersProjects = useSelector(
    (state: AppStateT) => state.projects.ended
  );
  const dates = useSelector(
    (state: AppStateT) => state.projects.filters.ended.dates
  );

  useEffect(() => {
    if (!customersProjects.length) dispatch(setSpinner(true));
    dispatch(getCustomersWithEndedProjects(dates[0], dates[1]));
  }, [dates]);

  return (
    <div>
      <CustomersWithProjectsList
        customers={customersProjects}
        listName="ended"
      />
    </div>
  );
};

export default EndedProjects;
