import { Badge, Button, Calendar, Popover } from 'antd';
import withPermission from 'hoc/withPermission';
import moment, { Moment } from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AppStateT } from 'services/store';
import { PermissionsT } from 'services/store/types/auth/Auth';
import { ProjectT } from 'services/store/types/projects/Projects';
import { TermT } from 'services/store/types/terms/Terms';
import { perms } from 'services/utils/const';
import { isDateBetween, isDateEqual, isMonthEqual } from 'services/utils/date';
import { capitalizeFirstLetter } from 'services/utils/string';
import { getPersonalName } from 'services/utils/users';
import styled from 'styled-components';
import { ButtonRow } from 'templates/FlexRow';

const Span = styled.span`
  &:hover {
    color: skyblue;
  }
`;

const TermsTypes: any = {
  pomiar: 'processing',
  montaż: 'success',
  inny: 'default',
};

interface PropsT {
  items: TermT[];
  date: Moment;
  handleDate: (d: Moment) => void;
  permission: PermissionsT;
}

const CalendarView: React.FC<PropsT> = ({
  items,
  date,
  handleDate,
  permission,
}) => {
  const history = useHistory();
  const user = useSelector((state: AppStateT) => state.auth.user);

  const goToProject = (id: string) => history.push(`/projects/${id}`);

  //   DAY
  const getListData = (value: any) => {
    const listData: any = [];

    items.forEach((item: TermT) => {
      const project =
        item.montageTerm || item.measureTerm || item.receptionTerm || null;
      if (
        project &&
        user &&
        (isDateBetween(value, item.startDate, item.endDate) ||
          isDateEqual(value, item.startDate))
      ) {
        const isButtonDisabled =
          permission === perms.DESIGNER ? project.UserId !== user.id : false;

        listData.push({
          project,
          type: item.type,
          content: (
            <DateItem
              project={project}
              redirect={goToProject}
              date={date}
              term={item}
              buttonDisabled={isButtonDisabled}
            />
          ),
          id: item.id,
        });
      }
    });

    return listData;
  };

  const dateCellRender = (value: any) => {
    const listData = getListData(value);
    return (
      <ul className="events" style={{ listStyleType: 'none', padding: 0 }}>
        {listData.map((item: any) => (
          <li key={item.id}>
            <Badge status={TermsTypes[item.type]} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };

  //   MONTH

  const getMonthData = (value: any) => {
    const listData: any = [];

    items.forEach((item: TermT) => {
      if (
        isMonthEqual(value, moment(item.startDate)) ||
        isMonthEqual(value, moment(item.endDate))
      ) {
        if (item.type === 'montaż') {
          const project =
            item.montageTerm || item.measureTerm || item.receptionTerm || null;
          if (project) {
            const projectName = `${project.name.slice(0, 20)}${
              project.name.length > 20 ? '...' : ''
            }`;
            const itemName = `${capitalizeFirstLetter(
              item.type
            )} ${getPersonalName(project.Customer)} - ${projectName}`;

            if (!listData.includes(itemName)) listData.push(itemName);
          }
        }
      }
    });
    return listData;
  };

  const monthCellRender = (value: any) => {
    const data = getMonthData(value);
    return (
      <div className="notes-month">
        <ul>
          {data.map((item: any) => (
            <li key={item} style={{ listStyleType: 'none', padding: 0 }}>
              <Badge status="success" text={item} />
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div>
      <Calendar
        dateCellRender={dateCellRender}
        monthCellRender={monthCellRender}
        value={date}
        onPanelChange={(d) => {
          return handleDate(d);
        }}
      />
    </div>
  );
};

export default withPermission(CalendarView);

interface DateItemPropsT {
  project: ProjectT;
  redirect: (id: string) => void;
  date: Moment;
  term: TermT;
  buttonDisabled: boolean;
}

const DateItem: React.FC<DateItemPropsT> = ({
  project,
  redirect,
  date,
  term,
  buttonDisabled,
}) => {
  const projectName = `${project.name.slice(0, 10)}${
    project.name.length > 10 ? '...' : ''
  }`;

  if (
    isMonthEqual(date, moment(term.startDate)) &&
    isMonthEqual(date, moment(term.endDate))
  )
    return (
      <Popover
        content={
          <div>
            <div>
              <small>{term.name}</small>
            </div>
            <div>
              <small>Projektant: {getPersonalName(project.User)}</small>
            </div>
            <ButtonRow justify="flex-end">
              <Button
                size="small"
                type="primary"
                onClick={() => redirect(project.id)}
                disabled={buttonDisabled}
              >
                Przejdź
              </Button>
            </ButtonRow>
          </div>
        }
        title={project.number || '-'}
      >
        <Span>
          {`${capitalizeFirstLetter(term.type)}`},{' '}
          <small>
            ({project.Customer ? getPersonalName(project.Customer) : '-'}
            {` - ${projectName}`})
          </small>
        </Span>
      </Popover>
    );
  return (
    <span style={{ opacity: 0.2 }}>
      {`${capitalizeFirstLetter(term.type)}`},{' '}
      <small>
        ({project.Customer ? getPersonalName(project.Customer) : '-'}
        {` - ${projectName}`})
      </small>
    </span>
  );
};
