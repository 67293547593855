import {
  Descriptions,
  Empty,
  Image,
  PageHeader,
  Statistic,
  Typography,
  Row,
  Col,
} from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { AppStateT } from 'services/store';
import { StyledH1, StyledH4 } from 'components/Header';
import PageTemplate from 'templates/PageTemplate';
import { setSpinner } from 'services/store/actions/view';
import { getCabinetData } from 'services/store/actions/cabinets';
import { FlexRow } from 'templates/FlexRow';
import IconDivider from 'components/IconDivider';
import CabinetElementsList from 'components/Base/Cabinets/CabinetElementsList';
import CabinetFrontsConfigsList from 'components/Base/Cabinets/CabinetFrontsConfigsList';
import { domain } from 'services/utils/const';
import imgPlaceholder from 'assets/images/placeholder.png';
import CabinetAccessoriesList from 'components/Base/Cabinets/CabinetAccessoriesList';
import {
  getAccessoriesPrice,
  getCabinetAccessoryQty,
} from 'services/utils/pricing';

const CabinetPage = () => {
  const dispatch = useDispatch();
  const params: { id: string } = useParams();
  const cabinet = useSelector((state: AppStateT) => state.cabinets.cabinet);

  useEffect(() => {
    dispatch(setSpinner(true));
    dispatch(getCabinetData(params.id));
  }, []);

  if (cabinet)
    return (
      <PageTemplate>
        <PageHeader
          title={<StyledH1>{cabinet.symbol.toUpperCase()}</StyledH1>}
          subTitle={`/${cabinet.CabinetType.name}/`}
        />
        {/* <Divider /> */}
        {/* ------------------------------ CABINET DATA ------------------------------ */}
        <Row>
          <Col sm={12} md={2}>
            <Image
              src={
                cabinet.imgPath ? `${domain}${cabinet.imgPath}` : imgPlaceholder
              }
              alt="Zdjęcie"
              height={150}
              width={150}
            />
          </Col>
          <Col sm={12} md={3}>
            <Descriptions size="small" column={1} title="Informacje">
              <Descriptions.Item label="Nazwa">
                {cabinet.name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Opis">
                {cabinet.description || '-'}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col sm={12} md={4}>
            <Descriptions size="small" column={1} title="Wymiary">
              <Descriptions.Item label="Wysokość">
                {cabinet.height}
              </Descriptions.Item>
              <Descriptions.Item label="Szerokość">
                {cabinet.width}
              </Descriptions.Item>
              <Descriptions.Item label="Głębokość">
                {cabinet.depth}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col sm={12} md={3}>
            <FlexRow justify="flex-end">
              <Statistic
                title="Cena bez frontów i akcesoriów"
                value={`${cabinet.price || 0}zł`}
              />
            </FlexRow>
          </Col>
        </Row>

        <IconDivider title="Elementy" />
        <Row>
          <Col md={6}>
            <StyledH4>Elementy konstrukcyjne</StyledH4>
            <CabinetElementsList elements={cabinet.CabinetElements} />
          </Col>
          <Col md={6}>
            <StyledH4>Konfiguracje frontów</StyledH4>
            <CabinetFrontsConfigsList fronts={cabinet.CabinetFronts} />
          </Col>
        </Row>

        <IconDivider title="Akcesoria" />
        <CabinetAccessoriesList
          accessories={cabinet.CabinetAccessories}
          noEditable
        />
        <FlexRow justify="flex-end">
          <Statistic
            title="Cena akcesoriów"
            value={getAccessoriesPrice(
              getCabinetAccessoryQty(cabinet.CabinetAccessories)
            )}
          />
        </FlexRow>
      </PageTemplate>
    );
  return <Empty />;
};

export default CabinetPage;
