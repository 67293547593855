import * as yup from 'yup';

export const userSchema = yup.object({
  email: yup
    .string()
    .email('Format adresu email jest nieprawidłowy')
    .required('Email jest wymagany'),
  phone: yup.string().nullable(),
  firstname: yup.string().required('Imię jest wymagane'),
  surname: yup.string().required('Nazwisko jest wymagane'),
});

export const customerSchema = yup.object({
  firstname: yup.string().trim().required('Imię jest wymagane'),
  surname: yup.string().required('Nazwisko jest wymagane'),
  company: yup.string(),
  pesel: yup
    .string()
    .trim()
    .matches(/^\d{11}$/, 'PESEL składa się z 11 cyfr'),
  nip: yup
    .string()
    .when('company', {
      is: (value) => !!value,
      then: yup
        .string()
        .required('NIP jest wymagany')
        .matches(/^\d{10}$/, 'NIP składa się z 10 cyfr'),
      otherwise: yup.string().matches(/^\d{10}$/, 'NIP składa się z 10 cyfr'),
    })
    .nullable(),
  email: yup
    .string()
    .email('Format adresu email jest nieprawidłowy')
    .required('Email jest wymagany'),
  phone: yup.string().nullable(),
  CustomerAddress: yup.object().shape({
    city: yup.string(),
    street: yup.string().when('city', {
      is: (value) => !!value,
      then: yup.string().required('Ulica jest wymagana'),
      otherwise: yup.string(),
    }),
    postcode: yup.string().matches(/^\d{2}-\d{3}$/, 'Nieprawidłowy format'),
    apartment: yup.string(),
  }),
});
