import { message, Modal } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { removeProject } from 'services/api/projects/remove';
import { setSpinner } from 'services/store/actions/view';
import { ProjectT } from 'services/store/types/projects/Projects';

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  project: ProjectT;
}

const DeleteProject: React.FC<PropsT> = ({ visible, closeModal, project }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    await removeProject(
      project.id,
      () => {
        dispatch(setSpinner(false));
        message.warning('Projekt usunięty');
        history.push('/');
      },
      () => {
        dispatch(setSpinner(false));
        message.error('Błąd');
      }
    );
  };
  return (
    <Modal
      title="Usuwanie projektu"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      onOk={handleSubmit}
      okButtonProps={{ danger: true }}
    >
      <div style={{ textAlign: 'center' }}>
        Czy na pewno chcesz usunąć ten projekt?
      </div>
    </Modal>
  );
};

export default DeleteProject;
