import Axios from 'axios';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import setAuthToken from 'services/utils/setAuthToken';
import {
  PatternT,
  TableT,
  VeneerT,
} from 'services/store/types/catalog/Catalog';
import { NewPatternT, NewTableT, NewVeneerT } from './post';

export const updatePattern = async (
  pattern: NewPatternT,
  onEnd: (pattern: PatternT) => void,
  onErr: () => void
) => {
  setAuthToken();
  try {
    const body = new FormData();
    body.append('pattern', JSON.stringify(pattern));
    if (pattern.img.length) {
      body.append('file', pattern.img[0], pattern.img[0].fileName);
    }

    const res: any = await Axios.put(
      `/api/catalog/pattern/${pattern.id}`,
      body
    );

    onEnd(res.data.updatedPattern);
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};

export const updateTable = async (
  table: NewTableT,
  onEnd: (table: TableT) => void,
  onErr: () => void
) => {
  setAuthToken();
  try {
    const body = new FormData();
    body.append('table', JSON.stringify(table));
    if (table.img.length) {
      body.append('file', table.img[0], table.img[0].fileName);
    }

    const res: any = await Axios.put(`/api/catalog/table/${table.id}`, body);

    onEnd(res.data.updatedTable);
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};

export const updateVeneer = async (
  veneer: NewVeneerT,
  onEnd: (veneer: VeneerT) => void,
  onErr: () => void
) => {
  setAuthToken();
  try {
    const body = new FormData();
    body.append('veneer', JSON.stringify(veneer));
    if (veneer.img.length) {
      body.append('file', veneer.img[0], veneer.img[0].fileName);
    }

    const res: any = await Axios.put(`/api/catalog/veneer/${veneer.id}`, body);

    onEnd(res.data.updatedVeneer);
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};
