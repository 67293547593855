import React, { useState } from 'react';
import { Modal, message } from 'antd';
import DesignersSelect from 'components/Users/DesignersSelect';
import { useMutation, useQuery } from '@tanstack/react-query';
import { usersApi } from 'services/api/users.api';
import { projectsApi } from 'services/api/projects.api';

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  refetchProject: () => Promise<any>;
  UserId: string;
  projectId: string;
}

const ChangeDesigner: React.FC<PropsT> = ({
  visible,
  closeModal,
  UserId,
  projectId,
  refetchProject,
}) => {
  const [designer, setDesigner] = useState(UserId);
  const handleSelect = (id: string) => setDesigner(id);

  const getUsers = useQuery({
    queryKey: ['get-users'],
    queryFn: () => usersApi.getAllUsers(),
    onError() {
      message.error('Błąd');
    },
    retry: false,
  });

  const updateDesigner = useMutation(
    async () => designer && projectsApi.updateDesigner(projectId, designer),
    {
      onSuccess() {
        message.success('Projektant zaktualizowany');
        refetchProject();
        closeModal();
      },
      onError(err: any) {
        console.log(err?.response);
        message.error('Bład');
      },
    }
  );

  return (
    <Modal
      title="Zmień projektanta"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      onOk={() => updateDesigner.mutate()}
      okButtonProps={{ disabled: !designer, loading: updateDesigner.isLoading }}
    >
      <DesignersSelect
        users={getUsers?.data?.data?.users || []}
        disabled={!getUsers.data}
        loading={getUsers.isLoading}
        UserId={designer}
        handleUser={handleSelect}
        style={{ width: '100%' }}
      />
    </Modal>
  );
};

export default ChangeDesigner;
