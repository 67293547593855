import { useMutation, useQuery } from '@tanstack/react-query';
import { message, Modal, Select } from 'antd';
import React, { useState } from 'react';
import { customersApi } from 'services/api/customers.api';
import { projectsApi } from 'services/api/projects.api';
import { CustomerT } from 'services/store/types/customers/Customers';
import { getPersonalName } from 'services/utils/users';

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  refetchProject: () => Promise<any>;
  CustomerId: string;
  projectId: string;
}

const ChangeCustomerModal: React.FC<PropsT> = ({
  visible,
  closeModal,
  CustomerId,
  projectId,
  refetchProject,
}) => {
  const [customer, setCustomer] = useState(CustomerId);
  const handleSelect = (id: string) => setCustomer(id);

  const { data, isLoading } = useQuery({
    queryKey: ['get-customers'],
    queryFn: () => customersApi.getAllCustomers(),
    onError() {
      message.error('Błąd');
    },
    retry: false,
  });

  const updateCustomer = useMutation(
    async () => customer && projectsApi.updateCustomer(projectId, customer),
    {
      onSuccess() {
        message.success('Klient zaktualizowany');
        refetchProject();
        closeModal();
      },
      onError(err: any) {
        console.log(err?.response);
        message.error('Bład');
      },
    }
  );

  return (
    <Modal
      title="Zmień klienta"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      onOk={() => updateCustomer.mutate()}
      okButtonProps={{ disabled: !customer, loading: updateCustomer.isLoading }}
    >
      <Select
        disabled={!data?.data.customers.length}
        loading={isLoading}
        size="large"
        placeholder="Klient"
        value={customer}
        onChange={handleSelect}
        style={{ width: '100%' }}
      >
        {data?.data.customers
          .sort((a, b) => `${a.surname || ''}`.localeCompare(b.surname || ''))
          .map((item: CustomerT) => {
            return (
              <Select.Option key={item.id} value={item.id}>
                {getPersonalName(item)}
              </Select.Option>
            );
          })}
      </Select>
    </Modal>
  );
};

export default ChangeCustomerModal;
