import Axios from 'axios';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import setAuthToken from 'services/utils/setAuthToken';
import {
  PatternT,
  TableT,
  VeneerT,
} from 'services/store/types/catalog/Catalog';

export interface NewPatternT extends PatternT {
  img: any[];
}
export interface NewTableT extends TableT {
  img: any[];
}
export interface NewVeneerT extends VeneerT {
  img: any[];
}

export const createNewPattern = async (
  pattern: NewPatternT,
  onEnd: (pattern: PatternT) => void,
  onErr: () => void
) => {
  setAuthToken();
  try {
    const body = new FormData();
    body.append('pattern', JSON.stringify(pattern));
    if (pattern.img.length) {
      body.append('file', pattern.img[0], pattern.img[0].fileName);
    }

    const res: any = await Axios.post('/api/catalog/pattern', body);

    onEnd(res.data.newPattern);
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};

export const createNewTable = async (
  table: NewTableT,
  onEnd: (table: TableT) => void,
  onErr: () => void
) => {
  setAuthToken();
  try {
    const body = new FormData();
    body.append('table', JSON.stringify(table));
    if (table.img.length) {
      body.append('file', table.img[0], table.img[0].fileName);
    }

    const res: any = await Axios.post('/api/catalog/table', body);

    onEnd(res.data.newTable);
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};

export const createNewVeneer = async (
  veneer: NewVeneerT,
  onEnd: (veneer: VeneerT) => void,
  onErr: () => void
) => {
  setAuthToken();
  try {
    const body = new FormData();
    body.append('veneer', JSON.stringify(veneer));
    if (veneer.img.length) {
      body.append('file', veneer.img[0], veneer.img[0].fileName);
    }

    const res: any = await Axios.post('/api/catalog/veneer', body);

    onEnd(res.data.newVeneer);
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};
