import React, { useState, useEffect } from 'react';
import {
  ProjectT,
  CustomerProjectsT,
} from 'services/store/types/projects/Projects';
import { getPersonalName } from 'services/utils/users';
import { Badge, Popover, Table, Typography } from 'antd';
import {
  formatPhoneNumber,
  formatAddress,
  cutString,
} from 'services/utils/string';
import { useHistory } from 'react-router';
import { dateToString, rangeDateToString } from 'services/utils/date';
import {
  ExclamationCircleOutlined,
  IdcardOutlined,
  WarningTwoTone,
} from '@ant-design/icons';
import withPermission from 'hoc/withPermission';
import { PermissionsT } from 'services/store/types/auth/Auth';
import { perms } from 'services/utils/const';
import { CustomerT } from 'services/store/types/customers/Customers';
import moment from 'moment';
import { ProjectStatusTag } from '../StatusTag';
import { checkProjectErrors } from './utils';
import { generateProjectOrdinalNumber } from 'services/utils/project';

const { Text } = Typography;

const WarningsColors: any = {
  warn: 'orange',
  danger: 'red',
};

const columns = [
  {
    title: 'Lp',
    dataIndex: 'Lp',
    align: 'center' as const,
    fixed: 'left' as const,
    width: 80,
  },
  {
    title: 'Klient',
    dataIndex: 'customer',
    align: 'center' as const,
    sorter: (a: any, b: any) =>
      `${a.sortCustomer}`.localeCompare(b.sortCustomer),
    fixed: 'left' as const,
  },
  {
    title: 'Nazwa firmy',
    dataIndex: 'company',
    align: 'center' as const,
    sorter: (a: any, b: any) => `${a.sortCompany}`.localeCompare(b.sortCompany),
  },
  { title: 'E-mail', dataIndex: 'email', align: 'center' as const },
  { title: 'Nr telefonu', dataIndex: 'phone', align: 'center' as const },
  { title: 'Projektant', dataIndex: 'designer', align: 'center' as const },
  {
    title: 'Firma zewnętrzna',
    dataIndex: 'externalCompany',
    align: 'center' as const,
  },
  { title: 'Nazwa projektu', dataIndex: 'name', align: 'center' as const },
  {
    title: 'Numer porządkowy',
    dataIndex: 'ordinalNumber',
    align: 'center' as const,
  },
  {
    title: 'Numer projektu',
    dataIndex: 'number',
    align: 'center' as const,
  },
  { title: 'Adres montażu', dataIndex: 'address', align: 'center' as const },
  {
    title: 'Data utworzenia',
    dataIndex: 'createdAt',
    align: 'center' as const,
    sorter: (a: any, b: any) => a.sortCreatedAt - b.sortCreatedAt,
  },
  { title: 'Data montażu', dataIndex: 'montageAt', align: 'center' as const },
  {
    title: 'Tydzień montażu',
    dataIndex: 'montageWeek',
    align: 'center' as const,
    defaultSortOrder: 'ascend' as const,
    sorter: (a: any, b: any) => {
      if (!a.sortMontageWeek) return 1;
      if (!b.sortMontageWeek) return -1;
      return a.sortMontageWeek - b.sortMontageWeek;
    },

    fixed: 'right' as const,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center' as const,
    sorter: (a: any, b: any) => `${a.sortStatus}`.localeCompare(b.sortStatus),
    fixed: 'right' as const,
  },
];

interface PropsT {
  customers: CustomerProjectsT[];
  permission: PermissionsT;
  isErrors?: boolean;
}

const CustomersWithProjectsList: React.FC<PropsT> = ({
  customers,
  permission,
  isErrors,
}) => {
  const history = useHistory();
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    if (customers) {
      const projects = customers
        .sort((a: any, b: any) =>
          `${a.surname || ''}`.localeCompare(b.surname || '')
        )
        .reduce((acc: ProjectT[], c: CustomerT) => {
          const customerProjects = c.Projects?.map((p) => ({
            ...p,
            Customer: c,
          }));
          if (customerProjects?.length) return [...acc, ...customerProjects];
          return acc;
        }, []);
      const newList = projects.map((p: ProjectT, i: number) => {
        let projectErr: any = {};
        if (isErrors) projectErr = checkProjectErrors(p, permission, []);
        return {
          key: p.id,
          Lp: projectErr.type ? (
            <Popover
              content={projectErr.errors.map((item: any) => {
                const color = WarningsColors[item.type];
                return (
                  <div key={item.text} style={{ color }}>
                    <Badge color={color} />
                    {item.text}
                  </div>
                );
              })}
            >
              <WarningTwoTone
                twoToneColor={WarningsColors[projectErr.type]}
                style={{ fontSize: 20 }}
              />
            </Popover>
          ) : (
            i + 1
          ),
          customer: [perms.ADMIN, perms.DESIGNER].includes(permission) ? (
            <span>
              {p.Customer.nip && (
                <IdcardOutlined style={{ color: 'black' }} title="Firma" />
              )}{' '}
              {p.Customer.surname} {p.Customer.firstname}{' '}
              {p.Customer.CustomerBlackList && (
                <ExclamationCircleOutlined style={{ color: 'red' }} />
              )}
            </span>
          ) : (
            <span>
              {p.Customer.nip && (
                <IdcardOutlined style={{ color: 'black' }} title="Firma" />
              )}{' '}
              {p.Customer.firstname} {p.Customer.surname}
            </span>
          ),
          company: p.Customer.company || '-',
          email: <Text copyable>{p.Customer.email}</Text>,
          phone: formatPhoneNumber(p.Customer.phone),
          projectsCount: p.Customer.Projects?.length,
          name: <span title={p.name}>{cutString(p.name, 30)}</span>,
          address: p.montageAddress ? formatAddress(p.montageAddress) : '-',
          createdAt: dateToString(p.startAt),
          montageAt: p.montageTerm
            ? rangeDateToString(p.montageTerm.startDate, p.montageTerm.endDate)
            : '-',
          montageWeek: p.montageWeek?.date
            ? `${moment(p.montageWeek?.date).week()} - ${
                moment(p.montageWeek?.date).week() + 1
              }`
            : '',
          designer: <span>{p.User ? getPersonalName(p.User) : '-'}</span>,
          externalCompany: (
            <span>{p.externalCompany ? p.externalCompany.company : '-'}</span>
          ),
          status: <ProjectStatusTag status={p.status} />,
          number: p.number,
          ordinalNumber: generateProjectOrdinalNumber(p),
          sortMontageWeek: p.montageWeek?.date
            ? moment(p.montageWeek?.date).week()
            : null,
          sortCustomer: `${p.Customer.surname} ${p.Customer.firstname}`,
          sortCompany: `${p.Customer.company}`,
          sortCreatedAt: p.startAt ? new Date(p.startAt) : null,
          sortStatus: p.status,
        };
      });

      setList(newList);
    }
  }, [customers]);

  return (
    <div>
      <Table
        columns={columns}
        dataSource={list}
        rowClassName={() => 'table-row-primary'}
        pagination={false}
        onRow={(r) => ({ onClick: () => history.push(`/projects/${r.key}`) })}
        scroll={{ x: 1200 }}
      />
    </div>
  );
};

export default withPermission(CustomersWithProjectsList);
