import {
  VIEW_SET_SPINNER,
  ViewSetHomeKeyT,
  VIEW_SET_HOME_KEY,
  SetSiderT,
  SetSpinnerT,
  VIEW_SET_SIDER,
  ViewSetCollapseT,
  VIEW_SET_COLLAPSE,
} from '../types/view/actions';

export const setSider = (isSider: boolean): SetSiderT => ({
  type: VIEW_SET_SIDER,
  isSider,
});
export const setSpinner = (isSpinner: boolean): SetSpinnerT => ({
  type: VIEW_SET_SPINNER,
  isSpinner,
});

export const setTabKey = (
  page: 'ordersKey' | 'homeKey',
  key: string
): ViewSetHomeKeyT => ({
  type: VIEW_SET_HOME_KEY,
  page,
  key,
});

export const setProjectCollapse = (
  key: string | string[]
): ViewSetCollapseT => ({
  type: VIEW_SET_COLLAPSE,
  key,
});
