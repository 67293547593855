import React, { useState } from 'react';
import { Modal, Button, message } from 'antd';
import { Input, Form } from 'formik-antd';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { UserT } from 'services/store/types/auth/Auth';
import { updateUser } from 'services/api/users/update';
import { userLoadSuccess } from 'services/store/actions/auth';
import * as yup from 'yup';

export const passwordChangeSchema = yup.object({
  password: yup.string().required('Hasło jest wymagane'),
  newPassword: yup
    .string()
    .min(6, 'Hasło musi składać się z min. 6 znaków')
    .required('Hasło jest wymagane'),
  newPassword2: yup.string().required('Hasło jest wymagane'),
});

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  user: UserT;
}

const ChangePassword: React.FC<PropsT> = ({ visible, closeModal, user }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (
    values: { password: string; newPassword: string },
    actions: any
  ) => {
    setLoading(true);
    await updateUser(
      user.id,
      { ...values },
      (u) => {
        setLoading(false);
        dispatch(userLoadSuccess(u, u.permission));
        message.success('Hasło zmienione');
        closeModal();
      },
      (err) => {
        actions.setErrors(err);
        setLoading(false);
        message.error('Błąd');
      }
    );
  };
  if (user)
    return (
      <Modal
        title="Zmiana hasła"
        destroyOnClose={true}
        visible={visible}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            Anuluj
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            htmlType="submit"
            form="ChangePasswordForm"
          >
            Zatwierdź
          </Button>,
        ]}
      >
        <div>
          <Formik
            validationSchema={passwordChangeSchema}
            validate={(values) => {
              const errors: any = {};
              if (values.newPassword2 !== values.newPassword)
                errors.newPassword2 = 'Nowe hasła muszą być identyczne';
              return errors;
            }}
            onSubmit={handleSubmit}
            initialValues={{ password: '', newPassword: '', newPassword2: '' }}
          >
            {(props) => {
              return (
                <Form
                  noValidate
                  className="form-container"
                  id="ChangePasswordForm"
                  onFinish={props.handleSubmit}
                >
                  <Form.Item name="password">
                    <Input.Password
                      name="password"
                      placeholder="Aktualne hasło"
                      type="password"
                      required
                    />
                  </Form.Item>
                  <Form.Item name="newPassword">
                    <Input.Password
                      name="newPassword"
                      placeholder="Nowe hasło"
                      type="password"
                      required
                    />
                  </Form.Item>
                  <Form.Item name="newPassword2">
                    <Input.Password
                      name="newPassword2"
                      placeholder="Powtórz nowe hasło"
                      type="password"
                      required
                    />
                  </Form.Item>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    );
  return null;
};

export default ChangePassword;
