import React, { useState } from 'react';
import { Modal, Input } from 'antd';

const { TextArea } = Input;

interface PropsT {
  email: string;
  closeModal: () => void;
  visible: boolean;
}

const EmailMessageModal: React.FC<PropsT> = ({
  email,
  closeModal,
  visible,
}) => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setMessage(e.target.value);
  const handleSubmit = async () => {};
  return (
    <Modal
      afterClose={() => {
        setLoading(false);
        setMessage('');
      }}
      title={`Wiadomość do ${email}`}
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      onOk={handleSubmit}
      okButtonProps={{
        disabled: !message,
        loading,
      }}
    >
      <TextArea
        placeholder="Wiadomość"
        value={message}
        onChange={handleInput}
        rows={5}
      />
    </Modal>
  );
};

export default EmailMessageModal;
