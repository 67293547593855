/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { InputNumber, message, Modal, Switch, Typography } from 'antd';
import { PaymentT, ProjectT } from 'services/store/types/projects/Projects';
import { FlexRow } from 'templates/FlexRow';
import { updateProjectPaymentsParts } from 'services/api/projects/update';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { setProject } from 'services/store/actions/projects';
import { DollarOutlined, PercentageOutlined } from '@ant-design/icons';
import { projectsApi } from 'services/api/projects.api';
import { useMutation } from '@tanstack/react-query';

interface PropsT {
  project: ProjectT;
  Payments: PaymentT[];
  closeModal: () => void;
  refetchProject: () => Promise<any>;
  visible: boolean;
}

const EditPaymentParts: React.FC<PropsT> = ({
  Payments,
  project,
  closeModal,
  visible,
  refetchProject,
}) => {
  const { price } = project;
  const dispatch = useDispatch();
  const [isPercentage, setIsPercentage] = useState(true);
  const [parts, setParts] = useState<PaymentT[]>(Payments);
  const [partsSummary, setPartsSummary] = useState<number>();

  useEffect(() => {
    if (Payments && Payments.length) {
      const newParts = Payments.map((p: PaymentT) => ({
        ...p,
        part: p.part * 100,
      }));
      setParts(newParts);

      const sum = parts.reduce((acc: number, p: PaymentT) => {
        const newAcc = acc + p.suggestedValue;
        return newAcc;
      }, 0);
      setPartsSummary(Math.floor(sum));
    }
  }, [Payments, price]);

  const handleSwitch = () => setIsPercentage((prev) => !prev);

  const handlePart = (
    i: number,
    field: 'part' | 'suggestedValue',
    value: number | string | null | undefined
  ) => {
    if (typeof value === 'number') {
      const newParts = [...parts];
      newParts[i][field] = value;

      if (price) {
        if (field === 'part') {
          newParts[i].suggestedValue = (price * value) / 100;
        } else if (field === 'suggestedValue') {
          newParts[i].part = (value / price) * 100;
        }
      }

      const sum = parts.reduce((acc: number, p: PaymentT) => {
        const newAcc = acc + p.suggestedValue;
        return newAcc;
      }, 0);
      setPartsSummary(Math.floor(sum));

      setParts(newParts);
    }
  };

  const updateDesigner = useMutation(
    async () => {
      const newParts = parts.map((p: PaymentT) => ({
        ...p,
        part: p.part / 100,
      }));
      return projectsApi.updatePaymentsParts(project.id, newParts);
    },
    {
      onSuccess() {
        message.success('Części płatności zaktualizowane');
        refetchProject();
        closeModal();
      },
      onError(err: any) {
        console.log(err?.response);
        message.error('Bład');
      },
    }
  );

  return (
    <Modal
      title="Edycja transz"
      destroyOnClose={true}
      visible={visible}
      onOk={() => updateDesigner.mutate()}
      onCancel={closeModal}
      okButtonProps={{
        disabled: partsSummary !== price,
        loading: updateDesigner.isLoading,
      }}
    >
      <Typography.Title level={5}>
        Wartość projektu: {price || 0} zł
      </Typography.Title>
      <FlexRow justify="space-around">
        <span>Kwota</span>
        <Switch
          checkedChildren={<DollarOutlined />}
          unCheckedChildren={<PercentageOutlined />}
          checked={isPercentage}
          onChange={handleSwitch}
          disabled={!price || price === 0}
        />
        <span>Procent</span>
      </FlexRow>
      <hr />
      <Typography.Title level={5}>Procent</Typography.Title>
      <FlexRow justify="space-between">
        {parts.map((p: PaymentT, i: number) => (
          <InputNumber
            min={0}
            max={100}
            key={`${i}${p}`}
            value={p.part}
            onChange={(v) => {
              handlePart(i, 'part', v);
            }}
            disabled={!isPercentage}
          />
        ))}
      </FlexRow>
      <hr />
      <Typography.Title level={5}>Kwota</Typography.Title>
      <FlexRow justify="space-between">
        {parts.map((part: PaymentT, i: number) => (
          <InputNumber
            min={0}
            key={`${i}${part}`}
            value={part.suggestedValue}
            onChange={(v) => {
              handlePart(i, 'suggestedValue', v);
            }}
            disabled={isPercentage}
          />
        ))}
      </FlexRow>
      <FlexRow justify="space-around">
        <Typography.Title level={5}>
          Podana kwota: <span>{partsSummary} zł</span>
        </Typography.Title>
      </FlexRow>
    </Modal>
  );
};

export default EditPaymentParts;
