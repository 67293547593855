import React from 'react';
import { UserT } from 'services/store/types/auth/Auth';
import { Tooltip, Select, SelectProps } from 'antd';
import { getPersonalName } from 'services/utils/users';
import { perms } from 'services/utils/const';

const { Option } = Select;

interface PropsT extends SelectProps {
  users: UserT[];
  UserId: string;
  handleUser: (id: string) => void;
  style?: any;
}

const DesignersSelect: React.FC<PropsT> = ({
  users,
  UserId,
  handleUser,
  style,
  ...props
}) => {
  return (
    <Tooltip title="Zmień projektanta" placement="right">
      <Select
        disabled={!users.length}
        size="large"
        placeholder="Projektant"
        value={UserId}
        onChange={handleUser}
        style={style || { width: 200, marginBottom: 30 }}
        {...props}
      >
        {users.map((item: UserT) => {
          if ([perms.ADMIN, perms.DESIGNER].includes(item.permission))
            return (
              <Option key={item.id} value={item.id}>
                {getPersonalName(item)}
              </Option>
            );
          return null;
        })}
      </Select>
    </Tooltip>
  );
};

export default DesignersSelect;
