import { UserT } from 'services/store/types/auth/Auth';
import { TermT, WeekT } from 'services/store/types/terms/Terms';

export const getUserNameFromTerm = (term: TermT): string | null => {
  let user = null;
  if (term.measureTerm?.User) user = term.measureTerm.User;
  if (term.montageTerm?.User) user = term.montageTerm.User;
  if (term.receptionTerm?.User) user = term.receptionTerm.User;
  if (term.measureTerm?.externalCompany)
    user = term.measureTerm.externalCompany;
  if (term.montageTerm?.externalCompany)
    user = term.montageTerm.externalCompany;
  if (term.receptionTerm?.externalCompany)
    user = term.receptionTerm.externalCompany;
  if (user) return `${user.surname} ${user.firstname}`;
  return null;
};

export const getUserNameFromWeek = (week: WeekT): string | null => {
  let user = null;
  if (week.measureWeek?.User) user = week.measureWeek.User;
  if (week.montageWeek?.User) user = week.montageWeek.User;
  if (week.receptionWeek?.User) user = week.receptionWeek.User;
  if (week.measureWeek?.externalCompany)
    user = week.measureWeek.externalCompany;
  if (week.montageWeek?.externalCompany)
    user = week.montageWeek.externalCompany;
  if (week.receptionWeek?.externalCompany)
    user = week.receptionWeek.externalCompany;
  if (user) return `${user.surname} ${user.firstname}`;
  return null;
};
