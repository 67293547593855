import React from 'react';
import { Collapse, Typography } from 'antd';
import { EquipmentT, ProjectT } from 'services/store/types/projects/Projects';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateT } from 'services/store';
import { setProjectCollapse } from 'services/store/actions/view';
import Equipment from './Equipment';
import ProjectPricingCabinets from './components/Cabinets';
import ProjectPricingAccessories from './components/Accessories';
import ProjectPricingElements from './components/Elements';
import ProjectOrders from './components/Orders';

const { Panel } = Collapse;

interface PropsT {
  project: ProjectT;
  equipment?: EquipmentT[];
}

const ProjectData: React.FC<PropsT> = ({ project, equipment }) => {
  const dispatch = useDispatch();
  const collapse = useSelector(
    (state: AppStateT) => state.view.projectCollapse
  );

  const handleCollapse = (key: string | string[]) =>
    dispatch(setProjectCollapse(key));

  return (
    <div>
      <Collapse defaultActiveKey={collapse} onChange={handleCollapse}>
        <Panel
          header={<Typography.Title level={4}>Sprzęt AGD</Typography.Title>}
          key="equipment"
        >
          <Equipment projectId={project.id} equipment={equipment} />
        </Panel>
        <Panel
          header={<Typography.Title level={4}>Korpusy</Typography.Title>}
          key="cabinets"
        >
          <ProjectPricingCabinets project={project} />
        </Panel>
        <Panel
          header={<Typography.Title level={4}>Akcesoria</Typography.Title>}
          key="accessories"
        >
          <ProjectPricingAccessories project={project} />
        </Panel>
        <Panel
          header={<Typography.Title level={4}>Elementy</Typography.Title>}
          key="elements"
        >
          <ProjectPricingElements project={project} />
        </Panel>
        <Panel
          header={<Typography.Title level={4}>Zamówienia</Typography.Title>}
          key="orders"
        >
          <ProjectOrders project={project} />
        </Panel>
      </Collapse>
    </div>
  );
};

export default ProjectData;
