import { CollapseKeyT } from './View';

export const VIEW_SET_SPINNER = 'VIEW_SET_SPINNER';
export const VIEW_SET_HOME_KEY = 'VIEW_SET_HOME_KEY';
export const VIEW_SET_SIDER = 'VIEW_SET_SIDER';
export const VIEW_SET_COLLAPSE = 'VIEW_SET_COLLAPSE';

export interface SetSiderT {
  type: typeof VIEW_SET_SIDER;
  isSider: boolean;
}
export interface SetSpinnerT {
  type: typeof VIEW_SET_SPINNER;
  isSpinner: boolean;
}
export interface ViewSetHomeKeyT {
  type: typeof VIEW_SET_HOME_KEY;
  page: 'ordersKey' | 'homeKey';
  key: string;
}
export interface ViewSetCollapseT {
  type: typeof VIEW_SET_COLLAPSE;
  key: string | string[];
}

export type ViewActionsT =
  | SetSpinnerT
  | ViewSetHomeKeyT
  | SetSiderT
  | ViewSetCollapseT;
