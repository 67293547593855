import { Input, message, Modal } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addCustomerToBlackList } from 'services/api/blackList/post';
import { setCustomer } from 'services/store/actions/customers';
import { setSpinner } from 'services/store/actions/view';
import { CustomerT } from 'services/store/types/customers/Customers';
import { FlexRow } from 'templates/FlexRow';

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  customer: CustomerT;
  refetchCustomer: () => Promise<any>;
}
const AddToBlackList: React.FC<PropsT> = ({
  visible,
  closeModal,
  customer,
  refetchCustomer,
}) => {
  const dispatch = useDispatch();
  const [description, setDescription] = useState('');

  const handleSubmit = async () => {
    if (customer.pesel) {
      dispatch(setSpinner(true));

      await addCustomerToBlackList(
        customer.id,
        { pesel: customer.pesel, description },
        (c) => {
          refetchCustomer();
          dispatch(setCustomer(c));
          dispatch(setSpinner(false));
          message.warning('Dodano do czarnej listy');
          closeModal();
        },
        () => {
          dispatch(setSpinner(false));
          message.error('Błąd');
        }
      );
    }
  };
  return (
    <Modal
      title="Dodawanie do czarnej listy"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      onOk={handleSubmit}
      okButtonProps={{ danger: true, disabled: !description }}
    >
      <div style={{ textAlign: 'center' }}>
        <Input.TextArea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Opis przyczyny"
          maxLength={255}
        />
        <FlexRow justify="flex-end">
          <small>{255 - description.length}</small>
        </FlexRow>
      </div>
    </Modal>
  );
};

export default AddToBlackList;
