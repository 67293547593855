import { InputNumber, Table } from 'antd';
import Img from 'components/Image';
import React, { useEffect, useState } from 'react';
import { AccessoryT } from 'services/store/types/accesories/Accessories';
import { OrderAccessoryT } from 'services/store/types/orders/Orders';

const columns = [
  {
    title: 'Lp.',
    align: 'center' as const,
    render: (t: string, r: any, i: number) => i + 1,
  },
  {
    title: 'Zdjęcie',
    dataIndex: 'img',
    align: 'center' as const,
    render: (t: string, record: any, i: number) => (
      <Img
        previewPath={record.imgPath}
        path={record.miniaturePath}
        isExist={record.Accessory}
      />
    ),
  },
  {
    title: 'Nazwa',
    dataIndex: 'name',
    align: 'center' as const,
    defaultSortOrder: 'ascend' as const,
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
    align: 'center' as const,
  },
  {
    title: 'Zamówiona ilość',
    dataIndex: 'qty',
    align: 'center' as const,
  },
  {
    title: 'Odebrana ilość',
    dataIndex: 'pickedQty',
    align: 'center' as const,
    render: <
      T extends {
        qty: number;
        pickedQty: number;
        index: number;
        isPicked: boolean;
        handlePickedQty: (
          index: number,
          value: number | string | undefined | null
        ) => void;
      }
    >(
      V: number,
      R: T,
      i: number
    ) => {
      return (
        <InputNumber
          value={V}
          onChange={(v) => R.handlePickedQty(R.index, v)}
          disabled={R.isPicked}
          max={R.qty}
          min={0}
        />
      );
    },
  },
];

interface PropsT {
  accessories: OrderAccessoryT[];
  handlePickedQty: (
    index: number,
    value: number | string | undefined | null
  ) => void;
}

const OrderPickUpAccessoriesList: React.FC<PropsT> = ({
  accessories,
  handlePickedQty,
}) => {
  const [list, setList] = useState<any>([]);

  useEffect(() => {
    const newAccessories = accessories.map(
      (acc: OrderAccessoryT, i: number) => ({
        key: acc.id,
        ...acc,
        ...acc.Accessory,
        index: i,
        handlePickedQty,
      })
    );
    setList(newAccessories);
  }, [accessories]);

  return <Table columns={columns} pagination={false} dataSource={list} />;
};

export default OrderPickUpAccessoriesList;
