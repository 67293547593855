/* eslint-disable @typescript-eslint/default-param-last */
import update from 'immutability-helper';
import {
  CatalogActionsT,
  CATALOG_ADD_TO_LIST,
  CATALOG_LOAD_ERROR,
  CATALOG_LOAD_SUCCESS,
  CATALOG_REMOVE_FROM_LIST,
  CATALOG_UPDATE_IN_LIST,
  CATALOG_LOAD_MANY_SUCCESS,
  CATALOG_LOAD_MANY_ERROR,
} from '../types/catalog/actions';
import { CatalogT, PatternT, TableT, VeneerT } from '../types/catalog/Catalog';

const initialState: CatalogT = {
  boards: [],
  fronts: [],
  tables: [],
  veneers: [],
};

const catalogReducer = (
  state = initialState,
  action: CatalogActionsT
): CatalogT => {
  switch (action.type) {
    case CATALOG_LOAD_SUCCESS:
      return { ...state, [action.listName]: action.list };
    case CATALOG_LOAD_MANY_SUCCESS: {
      return { ...state, ...action.lists };
    }
    case CATALOG_LOAD_ERROR:
      return { ...state, [action.listName]: [] };
    case CATALOG_LOAD_MANY_ERROR: {
      return { ...initialState };
    }
    case CATALOG_ADD_TO_LIST:
      return update(state, {
        [action.listName]: { $push: [action.item] },
      });
    case CATALOG_UPDATE_IN_LIST: {
      const index = state[action.listName].findIndex(
        (item: PatternT | TableT | VeneerT) =>
          item.id && action.item.id && item.id === action.item.id
      );
      return update(state, {
        [action.listName]: {
          [index]: { $set: action.item },
        },
      });
    }
    case CATALOG_REMOVE_FROM_LIST: {
      const index = state[action.listName].findIndex(
        (item: PatternT | TableT | VeneerT) => item.id && item.id === action.id
      );
      return update(state, {
        [action.listName]: {
          $splice: [[index, 1]],
        },
      });
    }
    default:
      return state;
  }
};

export { catalogReducer };
