/* eslint-disable @typescript-eslint/default-param-last */
import update from 'immutability-helper';
import { CustomersT } from '../types/customers/Customers';
import {
  CustomersActionsT,
  CUSTOMERS_LOAD_SUCCESS,
  CUSTOMERS_LOAD_ERROR,
  CUSTOMERS_SET_CUSTOMER,
  CUSTOMERS_ADD_CUSTOMER_TO_LIST,
} from '../types/customers/actions';

const initialState: CustomersT = {
  customers: [],
  customer: null,
};

const customersReducer = (
  state = initialState,
  action: CustomersActionsT
): CustomersT => {
  switch (action.type) {
    case CUSTOMERS_LOAD_SUCCESS: {
      return { ...state, customers: action.customers };
    }
    case CUSTOMERS_LOAD_ERROR: {
      return { ...state, customers: [] };
    }
    case CUSTOMERS_SET_CUSTOMER: {
      return { ...state, customer: action.customer };
    }
    case CUSTOMERS_ADD_CUSTOMER_TO_LIST: {
      return update(state, { customers: { $push: [action.customer] } });
    }
    default:
      return state;
  }
};

export { customersReducer };
