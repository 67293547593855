import { Dispatch } from 'redux';
import setAuthToken from 'services/utils/setAuthToken';
import Axios from 'axios';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import {
  AUTH_LOGIN_SUCCESS,
  UserLoginSuccessT,
  UserLoginErrorT,
  AUTH_LOGIN_ERROR,
  UserLogoutT,
  AUTH_LOGOUT_USER,
  UserLoadSuccessT,
  AUTH_USER_LOAD_SUCCESS,
} from '../types/auth/actions';
import { UserT } from '../types/auth/Auth';
import { setSpinner } from './view';

export const loginSuccess = (token: string): UserLoginSuccessT => ({
  type: AUTH_LOGIN_SUCCESS,
  token,
});
export const loginError = (): UserLoginErrorT => ({ type: AUTH_LOGIN_ERROR });
export const logoutUser = (): UserLogoutT => ({ type: AUTH_LOGOUT_USER });

export const userLoadSuccess = (
  user: UserT,
  permission: string
): UserLoadSuccessT => ({
  type: AUTH_USER_LOAD_SUCCESS,
  user,
  permission,
});

/**--------------------
 * THUNK
 ----------------------*/

export const loadUserData =
  (onError?: () => void) => async (dispatch: Dispatch) => {
    setAuthToken();
    try {
      const res = await Axios.get('/api/auth/');
      const { user } = res.data;

      dispatch(userLoadSuccess(user, user.permission));
    } catch (error) {
      dispatch(logoutUser());
      //   axiosErrorHandle(error, onError);
      if (onError) onError();
    }
    dispatch(setSpinner(false));
  };
