/* eslint-disable @typescript-eslint/default-param-last */
import update from 'immutability-helper';
import {
  UsersActionsT,
  USERS_LOAD_SUCCESS,
  USERS_LOAD_ERROR,
  USERS_SET_USER,
  USERS_ADD_USER_TO_LIST,
} from '../types/users/actions';
import { UsersT } from '../types/users/Users';

const initialState: UsersT = {
  users: [],
  user: null,
};

const usersReducer = (state = initialState, action: UsersActionsT): UsersT => {
  switch (action.type) {
    case USERS_LOAD_SUCCESS: {
      return { ...state, users: action.users };
    }
    case USERS_LOAD_ERROR: {
      return { ...state, users: [] };
    }
    case USERS_SET_USER: {
      return { ...state, user: action.user };
    }
    case USERS_ADD_USER_TO_LIST: {
      return update(state, { users: { $push: [action.user] } });
    }
    default:
      return state;
  }
};

export { usersReducer };
