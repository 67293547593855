import React from 'react';
import { Modal, Button, message, Form, Input } from 'antd';
import { EquipmentT, ProjectT } from 'services/store/types/projects/Projects';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import styled from 'styled-components';
import { projectsApi } from 'services/api/projects.api';
import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 5px;
`;

interface PropsT {
  visible: boolean;
  project: ProjectT;
  closeModal: () => void;
  refetchEquipment: () => Promise<any>;
}

const ChangeEquipment: React.FC<PropsT> = ({
  visible,
  project,
  closeModal,
  refetchEquipment,
}) => {
  const {} = useQuery({
    queryKey: ['get-project-equipment', project.id],
    queryFn: () => projectsApi.getProjectEquipment(project.id),
    onSuccess(res) {
      console.log(res.data.equipment);
      formik.setValues({
        equipment: res.data.equipment,
      });
    },
    refetchOnWindowFocus: false,
    refetchInterval: false,
  });

  const updateEquipment = useMutation(
    async (equipment: Partial<EquipmentT>[]) =>
      projectsApi.updateEquipment(project.id, equipment),
    {
      async onSuccess() {
        message.success('Sprzęt AGD zaktualizowany');
        await refetchEquipment();
        closeModal();
      },
      onError(err: any) {
        console.log(err?.response);
        message.error('Bład');
      },
    }
  );

  const formik = useFormik<{ equipment: Partial<EquipmentT>[] }>({
    initialValues: { equipment: [] },
    onSubmit: (values) => updateEquipment.mutate(values.equipment),
    validateOnChange: false,
  });

  return (
    <Modal
      title="Wykaz sprzętu"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Anuluj
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          form="equipmentChange"
        >
          Zatwierdź
        </Button>,
      ]}
    >
      <Form noValidate onFinish={formik.handleSubmit} id="equipmentChange">
        <div>
          <FormikProvider value={formik}>
            <FieldArray
              name="equipment"
              render={(arrayHelpers) => (
                <div>
                  {formik.values.equipment.map((item: any, index) => {
                    return (
                      <div key={index}>
                        <Wrapper>
                          <Input
                            value={item.name}
                            onChange={(e) =>
                              formik.setFieldValue(
                                `equipment.${index}.name`,
                                e.target.value
                              )
                            }
                            placeholder="Nazwa"
                            style={{ width: '90%' }}
                          />
                          <MinusCircleOutlined
                            style={{
                              fontSize: 20,
                              marginTop: 8,
                            }}
                            onClick={() => arrayHelpers.remove(index)}
                          />
                        </Wrapper>
                      </div>
                    );
                  })}
                  <Button
                    type="dashed"
                    onClick={() => arrayHelpers.push({ name: '' })}
                    style={{ width: '100%', marginTop: 10 }}
                  >
                    <PlusOutlined /> Dodaj sprzęt
                  </Button>
                </div>
              )}
            />
          </FormikProvider>
        </div>
      </Form>
    </Modal>
  );
};

export default ChangeEquipment;
