import React from 'react';
import { Modal, Button, message } from 'antd';
import { Input, Form } from 'formik-antd';
import { Formik } from 'formik';
import { ContactT } from 'services/store/types/settings/Settings';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { updateCompanyData } from 'services/api/settings/update';
import { getSettings } from 'services/store/actions/settings';
import * as yup from 'yup';

export const schema = yup.object({
  name: yup.string().required('Nazwa jest wymagana'),
  firstname: yup.string().required('Imię jest wymagane'),
  surname: yup.string().required('Nazwisko jest wymagane'),
  nip: yup
    .string()
    .required('NIP jest wymagany')
    .matches(/^\d{10}$/, 'NIP składa się z 10 cyfr'),
  regon: yup.string().required('REGON jest wymagany'),
  email: yup
    .string()
    .email('Format adresu email jest nieprawidłowy')
    .required('E-mail jest wymagany'),
  phone: yup.string().required('Nr telefony jest wymagany'),
  webpage: yup.string().required('WWW jest wymagane'),
  bankAccount: yup
    .string()
    .required('Nr konta jest wymagany')
    .matches(/^\d{26}$/, 'Nr konta składa się z 26 cyfr'),
  bankName: yup.string().required('Nazwa banku jest wymagana'),
});

const initValues = {
  name: '',
  firstname: '',
  surname: '',
  nip: '',
  regon: '',
  email: '',
  phone: '',
  webpage: '',
  bankAccount: '',
  bankName: '',
};

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  values: ContactT;
  refetchSettings: () => Promise<any>;
}

const ChangeCompanyData: React.FC<PropsT> = ({
  values,
  visible,
  closeModal,
  refetchSettings,
}) => {
  const dispatch = useDispatch();

  const handleSubmit = async (data: ContactT) => {
    dispatch(setSpinner(true));
    await updateCompanyData(
      data,
      () => {
        refetchSettings();
        closeModal();
        dispatch(setSpinner(false));
      },
      () => {
        dispatch(setSpinner(false));
      }
    );
  };

  return (
    <Modal
      title="Dane firmy"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Anuluj
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          form="AddMontageAddress"
        >
          Zatwierdź
        </Button>,
      ]}
    >
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={values || initValues}
      >
        {(props) => {
          return (
            <Form
              noValidate
              className="form-container"
              id="AddMontageAddress"
              onFinish={props.handleSubmit}
            >
              <Form.Item name="name">
                <Input name="name" placeholder="Nazwa" required />
              </Form.Item>
              <Form.Item name="firstname">
                <Input name="firstname" placeholder="Imię" required />
              </Form.Item>
              <Form.Item name="surname">
                <Input name="surname" placeholder="Nazwisko" required />
              </Form.Item>
              <Form.Item name="nip">
                <Input name="nip" placeholder="NIP" required />
              </Form.Item>
              <Form.Item name="regon">
                <Input name="regon" placeholder="REGON" required />
              </Form.Item>
              <Form.Item name="email">
                <Input name="email" placeholder="E-mail" required />
              </Form.Item>
              <Form.Item name="phone">
                <Input name="phone" placeholder="Nr telefonu" required />
              </Form.Item>
              <Form.Item name="webpage">
                <Input name="webpage" placeholder="WWW" required />
              </Form.Item>
              <Form.Item name="bankName">
                <Input name="bankName" placeholder="Nazwa banku" required />
              </Form.Item>
              <Form.Item name="bankAccount">
                <Input name="bankAccount" placeholder="Nr konta" required />
              </Form.Item>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ChangeCompanyData;
