import React, { useEffect, useState } from 'react';
import {
  Button,
  Image,
  message,
  Modal,
  Select,
  Space,
  Typography,
  Row,
  Col,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateT } from 'services/store';
import { setSpinner } from 'services/store/actions/view';
import { getCabinets } from 'services/store/actions/cabinets';
import {
  PricingPatternT,
  SegmentCabinetT,
} from 'services/store/types/pricing/Pricing';
import { initSegmentCabinet } from 'services/utils/const/initData';
import { IdType } from 'services/types';
import {
  CabinetFrontConfigT,
  CabinetT,
} from 'services/store/types/cabinets/Cabinets';
import { domain } from 'services/utils/const';
import { cutString } from 'services/utils/string';
import {
  pricingAddSegmentCabinet,
  pricingUpdateSegmentCabinet,
} from 'services/store/actions/pricing';
import imgPlaceholder from 'assets/images/placeholder.png';
import { getSegmentCabinetPrice } from 'scenes/ProjectPricing/components/utils';
import { useModal } from 'services/hooks/useModal';
import PatternsList from 'scenes/ProjectPricing/components/PricingPatterns/List';
import PickPatternToCabinet from './PickPattern';

interface PropsT {
  closeModal: () => void;
  visible: boolean;
  segmentIndex: number;
  cabinetOrder: number;
  cabinetIndex?: number;
  initValues?: SegmentCabinetT;
}

const SegmentCabinetModal: React.FC<PropsT> = ({
  closeModal,
  visible,
  segmentIndex,
  cabinetIndex = -1,
  cabinetOrder,
  initValues,
}) => {
  const {
    modal,
    showModal,
    closeModal: closeInnerModal,
  } = useModal({
    front: false,
    body: false,
  });
  const dispatch = useDispatch();

  const cabinets = useSelector((state: AppStateT) => state.cabinets.cabinets);
  const patterns = useSelector(
    (state: AppStateT) => state.pricing.PricingPatterns
  );

  const [segmentCabinet, setSegmentCabinet] = useState<SegmentCabinetT>(
    initValues || initSegmentCabinet
  );

  useEffect(() => {
    if (!cabinets.length) {
      dispatch(setSpinner(true));
      dispatch(getCabinets());
    }
  }, [cabinets]);

  const handleValue = (object: any) =>
    setSegmentCabinet({ ...segmentCabinet, ...object });

  const handleAddCabinet = () => {
    if (!segmentCabinet.Cabinet) return message.error('Wybierz korpus');
    if (!segmentCabinet.BodyPattern)
      return message.error('Wybierz wzór korpusu');
    if (segmentCabinet.FrontsConfig && !segmentCabinet.FrontPattern)
      return message.error('Wybierz wzór frontu');
    if (!segmentCabinet.FrontsConfig && segmentCabinet.FrontPattern)
      return message.error('Wybierz konfigurację frontów');

    const price = getSegmentCabinetPrice(segmentCabinet);
    const order = cabinetOrder;
    const cabinetData = {
      ...segmentCabinet,
      price,
      order,
    };
    if (initValues && cabinetIndex > -1) {
      dispatch(
        pricingUpdateSegmentCabinet(segmentIndex, cabinetIndex, cabinetData)
      );
    } else {
      dispatch(pricingAddSegmentCabinet(segmentIndex, cabinetData));
    }
    return closeModal();
  };

  return (
    <Modal
      title="Dodawanie korpusu"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      width={880}
      okText="Dodaj"
      onOk={handleAddCabinet}
      style={{ top: 20 }}
      bodyStyle={{ maxHeight: '80vh', overflowY: 'auto' }}
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <div>
          {/* CABINET */}
          <Typography.Title level={5}>Korpus</Typography.Title>
          <Row>
            <Col xs={1}>Zdjęcie</Col>
            <Col xs={2}>Wys/Szer/Głęb</Col>
            <Col xs={3}>Nazwa</Col>
            <Col xs={4}>Symbol</Col>
            <Col xs={2}>Cena</Col>
          </Row>
          <Select
            value={segmentCabinet.CabinetId}
            onChange={(CabinetId: IdType) => {
              const Cabinet = cabinets.find(
                (c: CabinetT) => c.id === CabinetId
              );
              const object = {
                ...initSegmentCabinet,
                CabinetId,
                Cabinet,
              };
              handleValue(object);
            }}
            style={{ width: '100%' }}
            size="large"
            placeholder="Wybierz szafkę"
          >
            {cabinets
              .sort((a: CabinetT, b: CabinetT) =>
                `${a.CabinetType.name}`.localeCompare(b.CabinetType.name)
              )
              .map((cabinet: CabinetT) => {
                if (cabinet.id)
                  return (
                    <Select.Option key={cabinet.id} value={cabinet.id}>
                      <Row>
                        <Col xs={1}>
                          <Image
                            src={
                              cabinet.miniaturePath
                                ? `${domain}${cabinet.miniaturePath}`
                                : imgPlaceholder
                            }
                            preview={false}
                          />
                        </Col>
                        <Col xs={2}>
                          {cabinet.height}/{cabinet.width}/{cabinet.depth}
                        </Col>
                        <Col xs={3}>{cutString(cabinet.name, 40)}</Col>
                        <Col xs={4}>{cabinet.symbol}</Col>
                        <Col xs={2}>{cabinet.price}zł</Col>
                      </Row>
                    </Select.Option>
                  );
                return null;
              })}
          </Select>
        </div>
        <div>
          {/* FRONT CONFIG */}
          <Typography.Title level={5}>Konfiguracja forntów</Typography.Title>
          <Row>
            <Col xs={3}>Nr</Col>
            <Col xs={3}>Nazwa</Col>
            <Col xs={4}>Opis</Col>
            <Col xs={2}>Lamin/Mat/Poł</Col>
          </Row>
          <Select
            value={segmentCabinet.FrontsConfigId}
            onChange={(FrontsConfigId: IdType) => {
              if (segmentCabinet.Cabinet) {
                const FrontsConfig = segmentCabinet.Cabinet.CabinetFronts.find(
                  (c: CabinetFrontConfigT) => c.id === FrontsConfigId
                );
                const object = { FrontsConfigId, FrontsConfig };
                handleValue(object);
              }
            }}
            disabled={
              !segmentCabinet.Cabinet ||
              !segmentCabinet.Cabinet.CabinetFronts.length
            }
            style={{ width: '100%' }}
            size="large"
          >
            {segmentCabinet.Cabinet &&
              segmentCabinet.Cabinet.CabinetFronts.map(
                (config: CabinetFrontConfigT) => {
                  if (config.id)
                    return (
                      <Select.Option value={config.id} key={config.id}>
                        <Row>
                          <Col xs={3}>{config.config}</Col>
                          <Col xs={3}>{cutString(config.name, 20)}</Col>
                          <Col xs={4} title={config.description}>
                            {cutString(config.description, 20)}
                          </Col>
                          <Col xs={2}>
                            {config.laminatePrice}/{config.paintSemiGlossPrice}/
                            {config.paintGlossPrice}
                          </Col>
                        </Row>
                      </Select.Option>
                    );
                  return null;
                }
              )}
          </Select>
        </div>
        <div>
          {/* Body PATTERN */}
          <Typography.Title level={5}>Wzór korpusu</Typography.Title>
          <Button
            type="primary"
            onClick={() => showModal('body')}
            disabled={!segmentCabinet.Cabinet}
          >
            Wybierz wzór korpusu
          </Button>

          <PatternsList
            patterns={
              segmentCabinet.BodyPattern ? [segmentCabinet.BodyPattern] : []
            }
          />
        </div>
        <div>
          {/* Front PATTERN */}
          <Typography.Title level={5}>Wzór frontów</Typography.Title>
          <Button
            type="primary"
            onClick={() => showModal('front')}
            disabled={!segmentCabinet.FrontsConfig}
          >
            Wybierz wzór frontu
          </Button>

          <PatternsList
            patterns={
              segmentCabinet.FrontPattern ? [segmentCabinet.FrontPattern] : []
            }
          />
        </div>
      </Space>

      {/* MODAL */}
      {modal.front && (
        <PickPatternToCabinet
          visible={modal.front}
          type="front"
          closeModal={closeInnerModal}
          patterns={patterns}
          pickPattern={(p) => handleValue({ FrontPattern: p })}
        />
      )}
      {modal.body && (
        <PickPatternToCabinet
          visible={modal.body}
          type="body"
          closeModal={closeInnerModal}
          patterns={patterns}
          pickPattern={(p) => handleValue({ BodyPattern: p })}
        />
      )}
    </Modal>
  );
};

export default SegmentCabinetModal;
