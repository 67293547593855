import { PatternT, PatternTypeT, TableT, VeneerT } from './Catalog';

export const CATALOG_LOAD_SUCCESS = 'CATALOG_LOAD_SUCCESS';
export const CATALOG_LOAD_MANY_SUCCESS = 'CATALOG_LOAD_MANY_SUCCESS';
export const CATALOG_LOAD_ERROR = 'CATALOG_LOAD_ERROR';
export const CATALOG_LOAD_MANY_ERROR = 'CATALOG_LOAD_MANY_ERROR';
export const CATALOG_ADD_TO_LIST = 'CATALOG_ADD_TO_LIST';
export const CATALOG_REMOVE_FROM_LIST = 'CATALOG_REMOVE_FROM_LIST';
export const CATALOG_UPDATE_IN_LIST = 'CATALOG_UPDATE_IN_LIST';

export interface CatalogLoadSuccessT {
  type: typeof CATALOG_LOAD_SUCCESS;
  listName: PatternTypeT;
  list: PatternT[] | TableT[] | VeneerT[];
}
export interface CatalogLoadManySuccessT {
  type: typeof CATALOG_LOAD_MANY_SUCCESS;
  lists: {
    fronts?: PatternT[];
    boards?: PatternT[];
    tables?: TableT[];
    veneers?: VeneerT[];
  };
}
export interface CatalogLoadErrorT {
  type: typeof CATALOG_LOAD_ERROR;
  listName: PatternTypeT;
}
export interface CatalogLoadManyErrorT {
  type: typeof CATALOG_LOAD_MANY_ERROR;
}
export interface CatalogAddToListT {
  type: typeof CATALOG_ADD_TO_LIST;
  listName: PatternTypeT;
  item: PatternT | TableT | VeneerT;
}
export interface CatalogRemoveFromListT {
  type: typeof CATALOG_REMOVE_FROM_LIST;
  listName: PatternTypeT;
  id: string | number;
}
export interface CatalogUpdateInListT {
  type: typeof CATALOG_UPDATE_IN_LIST;
  listName: PatternTypeT;
  item: PatternT | TableT | VeneerT;
}

export type CatalogActionsT =
  | CatalogLoadManySuccessT
  | CatalogLoadManyErrorT
  | CatalogLoadSuccessT
  | CatalogLoadErrorT
  | CatalogAddToListT
  | CatalogRemoveFromListT
  | CatalogUpdateInListT;
