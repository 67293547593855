import React from 'react';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { AppStateT } from 'services/store';
import { Layout } from 'antd';

const StyledWrapper = styled.div`
  padding: 0 0px 20px 0px;
  @media (max-width: 768px) {
    padding: 20px 10px 0 10px;
  }
`;

const PageTemplate: React.FC = ({ children }) => {
  const isAuth = useSelector((state: AppStateT) => state.auth.isAuth);
  const { token } = localStorage;
  if (!token) return <Redirect to="/login" />;
  return <Layout>{children}</Layout>;
};

export default PageTemplate;
