import { Select } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateT } from 'services/store';
import { termsSetFilter } from 'services/store/actions/terms';
import { CustomerItemT } from 'services/store/types/terms/Terms';
import { FlexRow } from 'templates/FlexRow';

const { Option } = Select;

const Filters = () => {
  const dispatch = useDispatch();
  const customersList = useSelector(
    (state: AppStateT) => state.terms.customersList
  );
  const filters = useSelector((state: AppStateT) => state.terms.filters);

  const handleFilter = (
    key: 'customerId' | 'type',
    value: string | number | undefined
  ) => dispatch(termsSetFilter(key, value));

  return (
    <FlexRow justify="flex-start">
      <Select
        allowClear={true}
        disabled={!customersList.length}
        placeholder="Klient"
        style={{ width: 200, marginRight: 10 }}
        value={filters.customerId}
        onChange={(value) => handleFilter('customerId', value)}
      >
        {customersList.map((item: CustomerItemT) => {
          return (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          );
        })}
      </Select>
      <Select
        allowClear={true}
        placeholder="Typ"
        style={{ width: 200 }}
        value={filters.type}
        onChange={(value) => handleFilter('type', value)}
      >
        <Option value="pomiar">Pomiar</Option>
        <Option value="montaż">Montaż</Option>
        <Option value="dostawa" disabled>
          Dostawa
        </Option>
        <Option value="inny">Inny</Option>
      </Select>
    </FlexRow>
  );
};

export default Filters;
