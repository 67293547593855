import { Menu } from 'antd';
import React from 'react';
import {
  MergeCellsOutlined,
  ColumnWidthOutlined,
  DownloadOutlined,
  ForwardOutlined,
  DollarCircleOutlined,
  UserOutlined,
  CalendarOutlined,
  GroupOutlined,
  CreditCardOutlined,
  DeleteOutlined,
  HomeOutlined,
  ClusterOutlined,
  CheckCircleOutlined,
  FileProtectOutlined,
  TableOutlined,
  AppstoreOutlined,
  ApiOutlined,
  AuditOutlined,
  ShoppingCartOutlined,
  FieldNumberOutlined,
  NodeExpandOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { states } from 'services/utils/const';
import SubMenu from 'antd/lib/menu/SubMenu';
import { IProjectSiderProps } from './ProjectSider';
import { useHistory } from 'react-router';

interface IProps extends Omit<IProjectSiderProps, 'files'> {}

const AdminSider: React.FC<IProps> = ({
  project,
  showModal,
  getPicingFile,
}) => {
  const history = useHistory();
  return (
    <>
      <Menu mode="inline">
        <Menu.Item
          key="status"
          icon={<ForwardOutlined />}
          onClick={() => showModal('changeStatus')}
          // disabled={
          //   !project.montageAddress ||
          //   [states.CANCEL, states.ENDED].includes(project.status)
          // }
        >
          Zmień status
        </Menu.Item>
        <Menu.Item
          key="finishProduction"
          icon={<CheckCircleOutlined />}
          onClick={() => showModal('endProduction')}
          disabled={
            !project ||
            project.status !== 'produkcja' ||
            project.productionEndConfirm
          }
        >
          Zakończ produkcję
        </Menu.Item>
        <Menu.Item
          key="finishProject"
          icon={<FileProtectOutlined />}
          onClick={() => showModal('endMontage')}
          disabled={!project || project.status !== 'montaż'}
        >
          Zakończ projekt
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="changeCustomer"
          icon={<UserOutlined />}
          onClick={() => showModal('changeCustomer')}
          disabled={
            !project || [states.CANCEL, states.ENDED].includes(project.status)
          }
        >
          Zmień klienta
        </Menu.Item>
        <Menu.Item
          key="changePrice"
          icon={<DollarCircleOutlined />}
          onClick={() => showModal('changePrice')}
          // disabled={!project||[states.CANCEL, states.ENDED].includes(project.status)}
        >
          Zmień cenę
        </Menu.Item>
        <Menu.Item
          key="changeName"
          icon={<EditOutlined />}
          onClick={() => showModal('changeName')}
        >
          Zmień nazwę
        </Menu.Item>
        <Menu.Item
          key="changeNumber"
          icon={<FieldNumberOutlined />}
          onClick={() => showModal('changeNumber')}
          disabled={!project || ![states.PENDING].includes(project.status)}
        >
          Zmień numer
        </Menu.Item>
        <Menu.Item
          key="changeOrdinalNumber"
          icon={<FieldNumberOutlined />}
          onClick={() => showModal('changeOrdinalNumber')}
        >
          Zmień numer porządkowy
        </Menu.Item>
        <Menu.Item
          key="changeCreationDate"
          icon={<CalendarOutlined />}
          onClick={() => showModal('changeCreationDate')}
          disabled={!project || ![states.PENDING].includes(project.status)}
        >
          Zmień datę utworzenia
        </Menu.Item>
        <Menu.Item
          key="changeMontageAddress"
          icon={<HomeOutlined />}
          onClick={() => showModal('montageAddress')}
          disabled={
            !project || [states.CANCEL, states.ENDED].includes(project.status)
          }
        >
          {!project?.montageAddress ? 'Dodaj' : 'Zmień'} adres montażu
        </Menu.Item>
        <Menu.Item
          key="changeDesigner"
          icon={<UserOutlined />}
          onClick={() => showModal('changeDesigner')}
          disabled={
            !project || [states.CANCEL, states.ENDED].includes(project.status)
          }
        >
          Zmień projektanta
        </Menu.Item>
        <Menu.Item
          key="changeExternalCompany"
          icon={<NodeExpandOutlined />}
          onClick={() => showModal('changeExternalCompany')}
          disabled={
            !project || [states.CANCEL, states.ENDED].includes(project.status)
          }
        >
          Przekaż firmie zewnętrznej
        </Menu.Item>
        <Menu.Item
          key="updatePayments"
          icon={<CreditCardOutlined />}
          onClick={() => showModal('payments')}
          // disabled={!project.price || !project.Payments?.length}
        >
          Płatności
        </Menu.Item>
        <Menu.Divider />
        <SubMenu
          key="measure"
          icon={<ColumnWidthOutlined />}
          title="Termin pomiaru"
          disabled={
            !project ||
            [states.CANCEL, states.ENDED].includes(project.status) ||
            !project.montageAddress
          }
        >
          <Menu.Item
            key="setMeasureWeek"
            icon={<CalendarOutlined />}
            onClick={() => showModal('measureWeek')}
            //   disabled
          >
            Wyznacz tydzień
          </Menu.Item>
          <Menu.Item
            key="setMeasureDay"
            icon={<CalendarOutlined />}
            onClick={() =>
              project &&
              history.push(`/projects/schedule/measure/${project.id}`)
            }
          >
            Wyznacz dzień
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="montage"
          icon={<MergeCellsOutlined />}
          title="Termin montażu"
          disabled={
            !project ||
            [states.CANCEL, states.ENDED].includes(project.status) ||
            !project.montageAddress
          }
        >
          <Menu.Item
            key="setMontageWeek"
            icon={<CalendarOutlined />}
            onClick={() => showModal('montageWeek')}
          >
            Wyznacz tydzień
          </Menu.Item>
          <Menu.Item
            key="setMontageDay"
            icon={<CalendarOutlined />}
            onClick={() =>
              project &&
              history.push(`/projects/schedule/montage/${project.id}`)
            }
            //   disabled={!project.measureTerm}
          >
            Wyznacz dzień
          </Menu.Item>
        </SubMenu>
        <Menu.Divider />
        <Menu.Item
          key="priceProject"
          icon={<ClusterOutlined />}
          onClick={() => project && history.push(`/pricing/${project.id}`)}
          disabled={
            !project || [states.CANCEL, states.ENDED].includes(project.status)
          }
        >
          Wyceń projekt
        </Menu.Item>
        <Menu.Item
          key="accessoriesOrders"
          icon={<ShoppingCartOutlined />}
          onClick={() =>
            project && history.push(`/projects/orders/${project.id}`)
          }
          disabled={
            !project ||
            !project.isPriced ||
            [states.CANCEL, states.ENDED].includes(project.status)
          }
        >
          Zamówienia na akcesoria
        </Menu.Item>

        <SubMenu
          key="files"
          icon={<TableOutlined />}
          title="Pobierz pliki"
          disabled={!project || !project.isPriced}
        >
          <Menu.Item
            key="constructionElements"
            icon={<AppstoreOutlined />}
            onClick={() => getPicingFile('elements')}
          >
            Lista elementów konstrukcyjnych
          </Menu.Item>
          <Menu.Item
            key="frons"
            icon={<AppstoreOutlined />}
            onClick={() => getPicingFile('fronts')}
          >
            Lista frontów
          </Menu.Item>
          <Menu.Item
            key="accessories"
            icon={<ApiOutlined />}
            onClick={() => getPicingFile('accessories')}
          >
            Lista akcesoriów
          </Menu.Item>
          <Menu.Item
            key="orders"
            icon={<AuditOutlined />}
            onClick={() => getPicingFile('orders')}
          >
            Zamówienia
          </Menu.Item>
        </SubMenu>
        <Menu.Divider />
        <Menu.Item
          key="equipment"
          icon={<GroupOutlined />}
          onClick={() => showModal('equipment')}
          disabled={
            !project ||
            [states.CANCEL, states.ENDED].includes(project.status) ||
            !project.montageAddress
          }
        >
          Wykaz sprzętu
        </Menu.Item>
        <Menu.Item
          key="documents"
          icon={<DownloadOutlined />}
          onClick={() => showModal('files')}
          disabled={
            !project ||
            !project.montageAddress ||
            [states.CANCEL, states.ENDED].includes(project.status)
          }
        >
          Pobierz dokumenty
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="remove"
          style={{ color: 'red' }}
          icon={<DeleteOutlined />}
          onClick={() => showModal('deleteProject')}
          disabled={!project}
        >
          Usuń
        </Menu.Item>
      </Menu>
    </>
  );
};

export default AdminSider;
