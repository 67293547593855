import { PlusSquareOutlined } from '@ant-design/icons';
import { Button, Empty, Popconfirm, Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useModal } from 'services/hooks/useModal';
import {
  SegmentCabinetT,
  SegmentT,
} from 'services/store/types/pricing/Pricing';
import styled from 'styled-components';
import { FlexRow } from 'templates/FlexRow';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import {
  pricingRemoveSegment,
  pricingSetSegmentCabinetsOrder,
  pricingSetSegmentTable,
} from 'services/store/actions/pricing';
import TablesList from 'components/Catalog/Tables/List';
import AddCabinetToPricing from './SegmentCabinetModal';
import SegmentCabinet from './SegmentCabinet';
import AddTableToSegment from './AddTable';

const Wrapper = styled.div`
  padding: 10px;
  width: 100%;
  overflow-x: auto;
  min-height: 200px;
  border-radius: 3px;
  border: 1px solid lightgray;
  margin-bottom: 10px;
`;

const initModal = {
  addCabinet: false,
  addTable: false,
};

interface PropsT {
  segment: SegmentT;
  index: number;
  disabled?: boolean;
  previewOnly: boolean;
}

const Segment: React.FC<PropsT> = ({
  segment,
  index,
  disabled,
  previewOnly,
}) => {
  const dispatch = useDispatch();
  const { modal, showModal, closeModal } = useModal(initModal);

  useEffect(() => {
    // showModal('addCabinet');
  }, []);

  const handleDragEnd = (result: any) => {
    if (previewOnly) return;
    const { destination, source } = result;
    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    const start: any = segment.SegmentCabinets;
    const finish: any = segment.SegmentCabinets;
    const draggedItem = start[source.index];

    if (start === finish) {
      let newList: SegmentCabinetT[] = Array.from(start);
      newList.splice(source.index, 1);
      newList.splice(destination.index, 0, draggedItem);

      newList = newList.map((cabinet: any, i: number) => {
        const newCabinet = { ...cabinet };
        newCabinet.order = i + 1;
        return newCabinet;
      });
      dispatch(pricingSetSegmentCabinetsOrder(index, newList));
    }
  };

  const handleRemoveSegment = () => dispatch(pricingRemoveSegment(index));
  const handleRemoveTable = () =>
    dispatch(pricingSetSegmentTable(index, undefined));

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable
        droppableId={`segment${index}`}
        direction="horizontal"
        isDropDisabled={disabled || previewOnly}
      >
        {(provided: any, snapshot: any) => (
          <Wrapper
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {!previewOnly && (
              <FlexRow justify="space-between">
                <Space>
                  <Button
                    disabled={!segment.SegmentCabinets.length || disabled}
                    onClick={() => showModal('addTable')}
                  >
                    Dodaj blat
                  </Button>
                  <Button
                    disabled={disabled}
                    type="primary"
                    onClick={() => showModal('addCabinet')}
                  >
                    <PlusSquareOutlined /> Dodaj korpus
                  </Button>
                </Space>
                <Popconfirm
                  disabled={disabled}
                  title="Usuń segment"
                  onConfirm={handleRemoveSegment}
                >
                  <Button disabled={disabled} danger>
                    Usuń segment
                  </Button>
                </Popconfirm>
              </FlexRow>
            )}
            <FlexRow justify="flex-start">
              {segment && segment.SegmentCabinets.length ? (
                segment.SegmentCabinets.map(
                  (cabinet: SegmentCabinetT, i: number) => {
                    return (
                      <SegmentCabinet
                        key={cabinet.order}
                        cabinet={cabinet}
                        cabinetIndex={i}
                        segmentIndex={index}
                        disabled={disabled}
                        previewOnly={previewOnly}
                      />
                    );
                  }
                )
              ) : (
                <Empty style={{ margin: '0 auto' }} />
              )}
            </FlexRow>

            {/* Table */}
            {segment.Table ? (
              <div>
                <Typography.Title level={4} style={{ marginBottom: 2 }}>
                  Blat
                </Typography.Title>
                <TablesList
                  tables={[segment.Table]}
                  actions={{
                    remove: previewOnly ? handleRemoveTable : undefined,
                  }}
                  pagination={false}
                />
              </div>
            ) : (
              <Typography.Text disabled>Brak blatu</Typography.Text>
            )}
            {/* MDOALS */}
            {/* NOTE Add Cabinet Modal */}
            {modal.addCabinet && (
              <AddCabinetToPricing
                closeModal={closeModal}
                visible={modal.addCabinet}
                segmentIndex={index}
                cabinetOrder={segment.SegmentCabinets.length + 1}
              />
            )}
            {/* NOTE ADD Table */}
            {modal.addTable && (
              <AddTableToSegment
                closeModal={closeModal}
                visible={modal.addTable}
                segmentIndex={index}
              />
            )}
          </Wrapper>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Segment;
