import React, { useState } from 'react';
import { Modal, Button, message } from 'antd';
import { Input, Select, Form } from 'formik-antd';
import { Formik } from 'formik';
import { userPermissions, permsPL } from 'services/utils/const';
import { capitalizeFirstLetter } from 'services/utils/string';
import { createNewUser, CreateUserValuesT } from 'services/api/users/post';
import { useDispatch } from 'react-redux';
import { addUserToList } from 'services/store/actions/users';
import { perms } from 'services/utils/const';
import * as yup from 'yup';

export const schema = yup.object({
  email: yup
    .string()
    .email('Format adresu email jest nieprawidłowy')
    .required('Email jest wymagany'),
  password: yup
    .string()
    .min(6, 'Hasło musi składać się z min. 6 znaków')
    .required('Hasło jest wymagane'),
  password2: yup.string().required('Hasło jest wymagane'),
  permission: yup.string().required('Uprawnienia są wymagane'),
  firstname: yup
    .string()
    .matches(/^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/, 'Wyłącznie litery')
    .required('Imię jest wymagane'),
  company: yup.string().when('permission', {
    is: (permission) => permission === perms.EXTERNAL_COMPANY,
    then: yup.string().required('Nazwa firmy jest wymagana'),
  }),
  surname: yup
    .string()
    .required('Nazwisko jest wymagane')
    .matches(/^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ\-]+$/, 'Wyłącznie litery'),
  phone: yup.string(),
});

const { Option } = Select;

const initValues = {
  email: '',
  firstname: '',
  surname: '',
  company: '',
  phone: undefined,
  password: '',
  password2: '',
  permission: '',
};

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  refetchUsers: () => Promise<any>;
}

// TODO REFACTOR - react query
const AddUserModal: React.FC<PropsT> = ({
  visible,
  closeModal,
  refetchUsers,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: CreateUserValuesT, actions: any) => {
    setLoading(true);
    await createNewUser(
      values,
      (user) => {
        refetchUsers();
        setLoading(false);
        message.success('Utworzono nowego użytkownika');
        closeModal();
      },
      (msg: string) => {
        actions.setErrors({ email: msg });
        setLoading(false);
        message.error('Błąd');
      }
    );
  };
  return (
    <Modal
      title="Nowy użytkownik"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Anuluj
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          htmlType="submit"
          form="AddUserForm"
        >
          Zatwierdź
        </Button>,
      ]}
    >
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        validate={(values) => {
          const errors: any = {};
          if (values.password2 !== values.password)
            errors.password2 = 'Hasła muszą być identyczne';
          return errors;
        }}
        initialValues={initValues}
      >
        {(props) => {
          return (
            <Form
              noValidate
              className="form-container"
              id="AddUserForm"
              onFinish={props.handleSubmit}
            >
              <Form.Item name="email">
                <Input
                  name="email"
                  placeholder="E-mail"
                  type="email"
                  required
                  autoCapitalize="off"
                />
              </Form.Item>
              <Form.Item name="company">
                <Input name="company" placeholder="Nazwa firmy" />
              </Form.Item>
              <Form.Item name="firstname">
                <Input name="firstname" placeholder="Imię" required />
              </Form.Item>
              <Form.Item name="surname">
                <Input name="surname" placeholder="Nazwisko" required />
              </Form.Item>
              <Form.Item name="phone">
                <Input name="phone" placeholder="Numer telefonu" />
              </Form.Item>
              <Form.Item name="password">
                <Input.Password
                  name="password"
                  placeholder="Hasło"
                  required
                  autoCapitalize="off"
                />
              </Form.Item>
              <Form.Item name="password2">
                <Input.Password
                  name="password2"
                  placeholder="Powtórz hasło"
                  required
                  autoCapitalize="off"
                />
              </Form.Item>

              <Form.Item name="permission">
                <Select name="permission" placeholder="Uprawnienia">
                  {userPermissions.map((perm) => (
                    <Option value={perm} key={perm}>
                      {capitalizeFirstLetter(permsPL[perm])}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddUserModal;
