import {
  CabinetAccessoryT,
  CabinetElementT,
} from 'services/store/types/cabinets/Cabinets';
import {
  PricingAccessoryT,
  PricingElementT,
  PricingPatternT,
  PricingT,
  SegmentCabinetT,
  SegmentT,
} from 'services/store/types/pricing/Pricing';
import { IdType } from 'services/types';

export const getFrontPrice = (cabinet: SegmentCabinetT) => {
  const { FrontPattern, FrontsConfig } = cabinet;
  if (FrontsConfig && FrontPattern) {
    if (FrontPattern.Paint) {
      if (FrontPattern.Paint.type === 'półmat')
        return FrontsConfig.paintGlossPrice;
      return FrontsConfig.paintSemiGlossPrice;
    }
    return FrontsConfig.laminatePrice;
  }
  return 0;
};

export const getSegmentCabinetPrice = (cabinet: SegmentCabinetT) => {
  const price = (cabinet.Cabinet?.price || 0) + getFrontPrice(cabinet);
  if (price) return price;
  return 0;
};

export const checkIsPricingPatternsAreUserdInElements = (
  pricing: PricingT,
  patternToRemove: PricingPatternT
) => {
  const { PricingElements } = pricing;

  let result = false;

  for (let i = 0; i < PricingElements.length; i++) {
    if (result) break;
    const { PricingPattern } = PricingElements[i];

    if (
      patternToRemove.type === PricingPattern?.type &&
      patternToRemove.PatternId === PricingPattern?.PatternId &&
      patternToRemove.VeneerId === PricingPattern?.VeneerId &&
      patternToRemove.Paint?.name === PricingPattern?.Paint?.name
    ) {
      result = true;
      break;
    }
  }
  return result;
};

export const getElementsFromSegments = (
  Segments: SegmentT[]
): PricingElementT[] => {
  const elements: PricingElementT[] = [];

  Segments.forEach((segment: SegmentT, segmentIndex: number) => {
    const { SegmentCabinets } = segment;

    SegmentCabinets.forEach((cabinet: SegmentCabinetT) => {
      const {
        FrontsConfig,
        FrontPattern,
        BodyPattern,
        Cabinet,
        order,
      } = cabinet;

      //   NOTE Fronts elements
      if (
        FrontsConfig &&
        FrontPattern &&
        FrontsConfig.Elements.length &&
        Cabinet
      ) {
        FrontsConfig?.Elements.forEach((Element: CabinetElementT) => {
          if (Element.id)
            elements.push({
              type: 'front',
              ElementId: Element.id,
              Element,
              CabinetId: Cabinet.id,
              Cabinet,
              PricingPattern: FrontPattern,
              segmentNumber: segmentIndex + 1,
              order,
              qty: Element.defaultQty,
            });
        });
      }

      //   NOTE Body elements
      if (Cabinet && Cabinet.CabinetElements.length && BodyPattern) {
        Cabinet.CabinetElements.forEach((Element: CabinetElementT) => {
          if (Element.id)
            elements.push({
              type: 'body',
              ElementId: Element.id,
              Element,
              CabinetId: Cabinet.id,
              Cabinet,
              PricingPattern: BodyPattern,
              segmentNumber: segmentIndex + 1,
              order,
              qty: Element.defaultQty,
            });
        });
      }
    });
  });

  return elements;
};

export const getAccessoriesFromSegments = (
  Segments: SegmentT[]
): PricingAccessoryT[] => {
  const accessories: PricingAccessoryT[] = [];

  Segments.forEach((segment: SegmentT) => {
    const { SegmentCabinets } = segment;

    SegmentCabinets.forEach((cabinet: SegmentCabinetT) => {
      const { Cabinet } = cabinet;

      //   NOTE Fronts elements
      if (Cabinet && Cabinet.CabinetAccessories.length) {
        Cabinet.CabinetAccessories.forEach((Accessory: CabinetAccessoryT) => {
          accessories.push({
            AccessoryId: Accessory.AccessoryId,
            Accessory: Accessory.Accessory,
            CabinetId: Cabinet.id,
            Cabinet,
            qty: Accessory.defaultQty,
            isAdditional: false,
          });
        });
      }
    });
  });

  return accessories;
};

export const optymizeAccessoriesList = (accessoires: PricingAccessoryT[]) => {
  const newAccessories = accessoires.reduce(
    (acc: PricingAccessoryT[], accessory: PricingAccessoryT) => {
      const elementExist = acc.find((item: PricingAccessoryT) =>
        isAccessoryEqual(item, accessory)
      );

      if (!elementExist) return [...acc, accessory];

      const newAcc = acc.map((item: PricingAccessoryT) => {
        if (isAccessoryEqual(item, accessory))
          return {
            ...item,
            qty: item.qty + accessory.qty,
            Cabinet: undefined,
            CabinetId: undefined,
          };

        return item;
      });

      return newAcc;
    },
    []
  );

  return newAccessories;
};

export const optymizeElementsList = (elements: PricingElementT[]) => {
  const newElements = elements.reduce(
    (acc: PricingElementT[], element: PricingElementT) => {
      const elementExist = acc.find((item: PricingElementT) =>
        isPricingElementsEqual(item, element)
      );

      if (!elementExist) return [...acc, element];

      const newAcc = acc.map((item: PricingElementT) => {
        if (isPricingElementsEqual(item, element))
          return {
            ...item,
            qty: item.qty + element.qty,
            Cabinet: undefined,
            CabinetId: undefined,
            segmentNumber: '-',
            order: '-',
          };

        return item;
      });

      return newAcc;
    },
    []
  );

  return newElements;
};

const isPricingElementsEqual = (e1: PricingElementT, e2: PricingElementT) => {
  if (e1.PricingPatternId !== e2.PricingPatternId) return false;
  if (e1.Element.height !== e2.Element.height) return false;
  if (e1.Element.width !== e2.Element.width) return false;
  if (e1.Element.thickness !== e2.Element.thickness) return false;
  if (e1.Element.w1 !== e2.Element.w1) return false;
  if (e1.Element.w2 !== e2.Element.w2) return false;
  if (e1.Element.h1 !== e2.Element.h1) return false;
  if (e1.Element.h2 !== e2.Element.h2) return false;
  if (e1.Element.isHdf && e2.Element.isHdf) return true;
  if (e1.PricingPattern && e2.PricingPattern) {
    if (e1.PricingPattern.VeneerId !== e2.PricingPattern.VeneerId) return false;
    if (e1.PricingPattern.PatternId !== e2.PricingPattern.PatternId)
      return false;
  }
  if (e1.Element.type !== 'body') {
    if (e1.PricingPattern && e2.PricingPattern) {
      if (e1.PricingPattern.Paint && e2.PricingPattern.Paint) {
        if (e1.PricingPattern.Paint.name && e2.PricingPattern.Paint.name) {
          if (e1.PricingPattern.Paint.type && e2.PricingPattern.Paint.type)
            return true;
          return false;
        }
        return false;
      }
      return false;
    }
    return false;
  }
  return true;
};

const isAccessoryEqual = (a1: PricingAccessoryT, a2: PricingAccessoryT) => {
  if (a1.AccessoryId !== a2.AccessoryId) return false;
  return true;
};

export const getCabinetsPrice = (segments: SegmentT[]) => {
  let cabinetsPrice = 0;

  segments.forEach((segment: SegmentT) => {
    const segmentCabinetsPrice = segment.SegmentCabinets.reduce(
      (price: number, cabinet: SegmentCabinetT) => {
        const newPrice = price + cabinet.price;
        return newPrice;
      },
      0
    );
    let TablePrice = 0;
    if (segment.Table) TablePrice = segment.Table.price;
    cabinetsPrice = cabinetsPrice + segmentCabinetsPrice + TablePrice;
  });

  return cabinetsPrice;
};

export const getAccessoriesPrice = (accessories: PricingAccessoryT[]) => {
  const accessoriesPrice = accessories.reduce(
    (price: number, accessory: PricingAccessoryT) => {
      const accessoryPrice =
        accessory.qty * (accessory.Accessory ? accessory.Accessory.price : 0);
      const newPrice = price + accessoryPrice;
      return newPrice;
    },
    0
  );

  return accessoriesPrice;
};

// export interface PricingSurfacesResultT {
//   boards: PatternSurfaceT[];
//   fronts: PatternSurfaceT[];
//   veneers: PatternSurfaceT[];
//   boardsSurface: number;
//   frontsSurface: number;
//   hdfSurface: number;
//   veneerLength: number;
// }
// export interface PatternSurfaceT {
//   img: string;
//   symbol: string;
//   type: string;
//   value: number;
// }
// export const getSurfaces = (elements: PricingElementT[]) => {
//   const result: PricingSurfacesResultT = {
//     boards: [],
//     fronts: [],
//     veneers: [],
//     boardsSurface: 0,
//     frontsSurface: 0,
//     hdfSurface: 0,
//     veneerLength: 0,
//   };

//   const itemIndex = (arr: 'boards' | 'fronts' | 'veneers', symbol?: string) => {
//     if (!symbol) return -1;
//     return result[arr].findIndex((i: PatternSurfaceT) => i.symbol === symbol);
//   };

//   elements.forEach((element: PricingElementT) => {
//     const { qty } = element;
//     const elementSurface = element.Element.width * element.Element.height * qty;
//     let veneerLength = 0;
//     if (element.Element.w1) veneerLength += element.Element.width;
//     if (element.Element.w2) veneerLength += element.Element.width;
//     if (element.Element.h1) veneerLength += element.Element.height;
//     if (element.Element.h2) veneerLength += element.Element.height;
//     veneerLength *= qty;

//     if (element.Element.isHdf) {
//       result.hdfSurface += elementSurface;
//       return;
//     }
//     if (element.type === 'body') {
//       result.boardsSurface += elementSurface;
//       result.veneerLength += veneerLength;

//       const index = itemIndex(
//         'boards',
//         element.PricingPattern?.Pattern?.symbol
//       );
//       if (index > -1) result.boards[index].value += elementSurface;
//       else {
//         result.boards.push({
//           symbol: element.PricingPattern?.Pattern?.symbol,
//           type: 'Płyta',
//           img: element.PricingPattern?.Pattern?.miniaturePath,
//           value: elementSurface,
//         });
//       }
//     }
//     if (element.type === 'front') {
//       if (element.PricingPattern?.Paint?.name) {
//       } else {
//       }
//     }
//   });
// };
