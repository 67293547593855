import React from 'react';
import { PermissionsT, UserT } from 'services/store/types/auth/Auth';
import { CustomerT } from 'services/store/types/customers/Customers';
import { Descriptions, Skeleton, Typography } from 'antd';
import withPermission from 'hoc/withPermission';
import { perms } from 'services/utils/const';

const { Text } = Typography;

interface PropsT {
  title?: string;
  user?: UserT;
  customer?: CustomerT;
  loading?: boolean;
  column?: number;
  permission: PermissionsT;
}

const PersonalData: React.FC<PropsT> = ({
  title = '',
  user,
  customer,
  loading,
  column = 6,
  permission,
}) => {
  const data = user || customer;

  const isPerm = [perms.ADMIN, perms.DESIGNER].includes(permission);

  if (data)
    return (
      <Descriptions title={title} size="small" column={column}>
        {customer && customer.company && (
          <Descriptions.Item label="Nazwa firmy">
            <strong>{customer.company}</strong>
          </Descriptions.Item>
        )}
        {customer && customer.nip && isPerm && (
          <Descriptions.Item label="NIP">
            <strong>{customer.nip}</strong>
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Imię">
          <strong>{data.firstname}</strong>
        </Descriptions.Item>
        <Descriptions.Item label="Nazwisko">
          <strong>{data.surname}</strong>
        </Descriptions.Item>
        <Descriptions.Item label="E-mail">
          <strong>
            <Text copyable>{data.email}</Text>
          </strong>
        </Descriptions.Item>
        <Descriptions.Item label="Telefon">
          <strong>{data.phone}</strong>
        </Descriptions.Item>
        {customer && isPerm && (
          <Descriptions.Item label="PESEL">
            {customer.pesel ? (
              <strong>{customer.pesel}</strong>
            ) : (
              <strong style={{ color: 'red' }}>BRAK</strong>
            )}
          </Descriptions.Item>
        )}
      </Descriptions>
    );
  return (
    <Skeleton paragraph={{ rows: 2 }} title={{ width: 200 }} active={loading} />
  );
};

export default withPermission(PersonalData);
