import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { AppActionsT } from './types/appActionsTypes';

import { authReducer } from './reducers/auth';
import { viewReducer } from './reducers/view';
import { usersReducer } from './reducers/users';
import { customersReducer } from './reducers/customers';
import { settingsReducer } from './reducers/settings';
import { suppliersReducer } from './reducers/suppliers';
import { accessoriesReducer } from './reducers/accessories';
import { newProjectReducer } from './reducers/newProject';
import { projectsReducer } from './reducers/projects';
import { termsReducer } from './reducers/terms';
import { cabinetsReducer } from './reducers/cabinets';
import { catalogReducer } from './reducers/catalog';
import { pricingReducer } from './reducers/pricing';
import { ordersReducer } from './reducers/orders';

const rootReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  customers: customersReducer,
  projects: projectsReducer,
  orders: ordersReducer,
  pricing: pricingReducer,
  cabinets: cabinetsReducer,
  catalog: catalogReducer,
  suppliers: suppliersReducer,
  accessories: accessoriesReducer,
  newProject: newProjectReducer,
  terms: termsReducer,
  settings: settingsReducer,
  view: viewReducer,
});
export type AppStateT = ReturnType<typeof rootReducer>;

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(thunk as ThunkMiddleware<AppStateT, AppActionsT>)
  )
);

export default store;
