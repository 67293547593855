import { CaretRightOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { DatePicker, message, PageHeader, Tabs } from 'antd';
import { StyledH1 } from 'components/Header';
import ProjectOrdersList from 'components/Orders/ProjectOrders';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeOrder } from 'services/api/orders/remove';
import { AppStateT } from 'services/store';
import {
  getActiveOrders,
  getEndedOrders,
  setOrdersFilterField,
} from 'services/store/actions/orders';
import { setSpinner, setTabKey } from 'services/store/actions/view';
import { IdType } from 'services/types';
import PageTemplate from 'templates/PageTemplate';

const { TabPane } = Tabs;

const OrdersPage = () => {
  const dispatch = useDispatch();
  const key = useSelector((state: AppStateT) => state.view.ordersKey);
  const dates = useSelector(
    (state: AppStateT) => state.orders.filters.ended.dates
  );
  const orders = useSelector((state: AppStateT) => state.orders);

  useEffect(() => {
    if (key === 'active') {
      if (!orders.active.length) dispatch(setSpinner(true));
      dispatch(getActiveOrders());
    } else if (key === 'ended') {
      if (!orders.ended.length) dispatch(setSpinner(true));
      dispatch(getEndedOrders(...dates));
    }
  }, [key, dates]);

  const handleDates = (values: any) => {
    dispatch(setOrdersFilterField('ended', 'dates', values));
  };
  const handleKey = (k: string) => dispatch(setTabKey('ordersKey', k));

  const getOrders = () => {
    if (key === 'active') {
      dispatch(setSpinner(true));
      dispatch(getActiveOrders());
    } else if (key === 'ended') {
      dispatch(setSpinner(true));
      dispatch(getEndedOrders(...dates));
    }
  };

  const handleRemove = async (orderId: IdType) => {
    await removeOrder(
      orderId,
      () => {
        message.success('Zamówienie usunięte');
        getOrders();
      },
      () => {}
    );
  };
  return (
    <PageTemplate>
      <PageHeader
        title={
          <StyledH1>
            {key === 'active' ? 'Aktywne ' : 'Zakończone '} zamówienia
          </StyledH1>
        }
        extra={
          key === 'ended' && [
            <DatePicker.RangePicker
              key={1}
              value={dates}
              onChange={handleDates}
              allowClear={false}
            />,
          ]
        }
      />

      <Tabs defaultActiveKey={key} onChange={handleKey}>
        <TabPane
          tab={
            <>
              <CaretRightOutlined />
              Aktywne
            </>
          }
          key="active"
        >
          {key === 'active' && (
            <ProjectOrdersList
              orders={orders.active}
              handleUpdate={getOrders}
              handleRemove={handleRemove}
            />
          )}
        </TabPane>
        <TabPane
          tab={
            <>
              <CheckCircleOutlined /> Zakończone
            </>
          }
          key="ended"
        >
          {key === 'ended' && (
            <ProjectOrdersList
              orders={orders.ended}
              handleUpdate={getOrders}
              handleRemove={handleRemove}
            />
          )}
        </TabPane>
      </Tabs>
    </PageTemplate>
  );
};

export default OrdersPage;
