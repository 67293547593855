/* eslint-disable @typescript-eslint/default-param-last */
import update from 'immutability-helper';
import {
  CabinetsActionsT,
  CABINETS_LOAD_SUCCESS,
  CABINETS_LOAD_ERROR,
  CABINETS_ADD_TO_LIST,
  CABINETS_UPDATE_IN_LIST,
  CABINETS_REMOVE_FROM_LIST,
  CABINETS_SET_CABINET,
} from '../types/cabinets/actions';
import { CabinetsT, CabinetT } from '../types/cabinets/Cabinets';

const initialState: CabinetsT = {
  cabinets: [],
  cabinet: null,
};

const cabinetsReducer = (
  state = initialState,
  action: CabinetsActionsT
): CabinetsT => {
  switch (action.type) {
    case CABINETS_LOAD_SUCCESS: {
      return { ...state, cabinets: action.cabinets };
    }
    case CABINETS_LOAD_ERROR: {
      return initialState;
    }
    case CABINETS_SET_CABINET: {
      return { ...state, cabinet: action.cabinet };
    }
    case CABINETS_ADD_TO_LIST: {
      return update(state, { cabinets: { $push: [action.cabinet] } });
    }
    case CABINETS_UPDATE_IN_LIST: {
      const index = state.cabinets.findIndex(
        (item: CabinetT) => item.id === action.cabinet.id
      );
      return update(state, {
        cabinets: {
          [index]: { $set: action.cabinet },
        },
      });
    }
    case CABINETS_REMOVE_FROM_LIST: {
      const index = state.cabinets.findIndex(
        (item: CabinetT) => item.id === action.id
      );
      return update(state, {
        cabinets: {
          $splice: [[index, 1]],
        },
      });
    }
    default:
      return state;
  }
};

export { cabinetsReducer };
