import { Button, Image, Popconfirm, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { TableT } from 'services/store/types/catalog/Catalog';
import { domain, perms } from 'services/utils/const';
import imgPlaceholder from 'assets/images/placeholder.png';
import TextButton from 'components/TextButton';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { TableProps } from 'antd/lib/table';
import { PermissionsT } from 'services/store/types/auth/Auth';
import withPermission from 'hoc/withPermission';

const columns: any[] = [
  {
    title: 'Lp.',
    align: 'center' as const,
    render: (t: string, r: any, i: number) => i + 1,
  },
  {
    title: 'Zdjęcie',
    dataIndex: 'img',
    align: 'center' as const,
  },
  {
    title: 'Nazwa',
    dataIndex: 'name',
    align: 'center' as const,
    defaultSortOrder: 'ascend' as const,
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
    align: 'center' as const,
  },
  {
    title: 'Dystrybutor',
    dataIndex: 'supplier',
    align: 'center' as const,
    defaultSortOrder: 'ascend' as const,
    sorter: (a: any, b: any) => a.supplierName.localeCompare(b.supplierName),
  },
  {
    title: 'Szer/Gł/Gr',
    dataIndex: 'dimensions',
    align: 'center' as const,
  },
  {
    title: 'Cena',
    dataIndex: 'price',
    align: 'center' as const,
  },
  {
    title: 'Akcje',
    dataIndex: 'actions',
    align: 'center' as const,
  },
];

interface PropsT extends TableProps<any> {
  tables: TableT[];
  actions?: {
    remove?: (table: TableT) => void;
    update?: (table: TableT) => void;
    pick?: (table: TableT) => void;
  };
  permission: PermissionsT;
}

const TablesList: React.FC<PropsT> = ({
  permission,
  tables,
  actions,
  ...props
}) => {
  const history = useHistory();
  const [list, setList] = useState<any[]>([]);

  const handleRedirect = (path: string) => history.push(path);
  useEffect(() => {
    const newList = tables.map((table: TableT, i: number) => {
      return {
        ...table,
        key: table.id,
        img: (
          <Image
            src={
              table.miniaturePath
                ? `${domain}${table.miniaturePath}`
                : imgPlaceholder
            }
            alt="img"
            style={{ cursor: 'pointer' }}
            preview={{
              src: `${domain}${table.imgPath}`,
            }}
          />
        ),
        supplier: table.Supplier ? (
          <TextButton
            text={table.Supplier.name}
            onClick={() =>
              table.Supplier
                ? handleRedirect(`/suppliers/${table.Supplier.id}`)
                : null
            }
          />
        ) : (
          '-'
        ),
        dimensions: `${table.width}/${table.depth}/${table.thickness}`,
        supplierName: table.Supplier ? table.Supplier.name : '-',
        actions:
          actions && permission === perms.ADMIN ? (
            <div>
              {actions.pick && (
                <Button
                  type="primary"
                  size="small"
                  onClick={() => actions.pick && actions.pick(table)}
                >
                  Wybierz
                </Button>
              )}
              {actions.update && (
                <EditOutlined
                  title="Edytuj"
                  style={{ color: 'gray', cursor: 'pointer', margin: 5 }}
                  onClick={() => actions.update && actions.update(table)}
                />
              )}
              {actions.remove && (
                <Popconfirm
                  title="Usuń element"
                  onConfirm={() => actions.remove && actions.remove(table)}
                >
                  <DeleteOutlined
                    title="Usuń"
                    style={{ color: 'red', cursor: 'pointer', margin: 5 }}
                  />
                </Popconfirm>
              )}
            </div>
          ) : (
            <Typography.Text disabled>brak</Typography.Text>
          ),
      };
    });

    setList(newList);
  }, [tables]);

  return (
    <>
      <Table
        columns={columns}
        pagination={{ position: ['topRight'] }}
        dataSource={list}
        size="small"
        {...props}
      />
    </>
  );
};

export default withPermission(TablesList);
