import React from 'react';
import { Menu } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import { TUsersListModal } from '..';

interface IProps {
  showModal: (key: TUsersListModal) => void;
}

const UsersListSider: React.FC<IProps> = ({ showModal }) => {
  return (
    <>
      <Menu mode="inline">
        <Menu.Item
          icon={<UserAddOutlined style={{ color: 'green' }} />}
          onClick={() => showModal('newUser')}
        >
          Dodaj użytkownika
        </Menu.Item>
      </Menu>
    </>
  );
};

export default UsersListSider;
