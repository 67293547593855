import Axios from 'axios';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import setAuthToken from 'services/utils/setAuthToken';
import { SupplierT } from 'services/store/types/suppliers/Suppliers';
import { IdType } from 'services/types';

export const updateSupplier = async (
  id: IdType,
  values: SupplierT,
  onEnd: (supplier: SupplierT) => void,
  onErr: () => void
) => {
  setAuthToken();
  try {
    const res: any = await Axios.put(`/api/suppliers/${id}`, {
      values,
    });

    onEnd(res.data.supplier);
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};
