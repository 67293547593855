import React, { useState } from 'react';
import { Modal, Select, message } from 'antd';
import { userPermissions, permsPL } from 'services/utils/const';
import { capitalizeFirstLetter } from 'services/utils/string';
import { updateUser } from 'services/api/users/update';
import { PermissionsT, UserT } from 'services/store/types/auth/Auth';
import { useDispatch } from 'react-redux';
import { setUser } from 'services/store/actions/users';

const { Option } = Select;

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  user: UserT;
  refetchUser: () => Promise<any>;
}

const ChangePermissionModal: React.FC<PropsT> = ({
  visible,
  closeModal,
  user,
  refetchUser,
}) => {
  const dispatch = useDispatch();
  const [permission, setPermission] = useState(user?.permission);
  const [loading, setLoading] = useState(false);

  const handlePermission = (value: PermissionsT) => setPermission(value);
  const handleSubmit = async () => {
    setLoading(true);
    await updateUser(
      user.id,
      { permission },
      (updatedUser: UserT) => {
        setLoading(false);
        refetchUser();
        message.success('Zaktualizowano uprawnienia');
        closeModal();
      },
      () => {
        setLoading(false);
        message.error('Błąd');
      }
    );
  };

  return (
    <Modal
      title="Zmiana uprawnień"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      onOk={handleSubmit}
      confirmLoading={loading}
    >
      <Select
        value={permission}
        onChange={handlePermission}
        placeholder="Uprawnienia"
        style={{ width: '100%' }}
      >
        {userPermissions.map((perm) => (
          <Option value={perm} key={perm}>
            {capitalizeFirstLetter(permsPL[perm])}
          </Option>
        ))}
      </Select>
    </Modal>
  );
};

export default ChangePermissionModal;
