import { CabinetElementT } from 'services/store/types/cabinets/Cabinets';

export const getElementVeneerLength = (element: CabinetElementT): number => {
  let result = 0;
  if (element.isHdf) return result;

  if (element.w1) result += element.width;
  if (element.w2) result += element.width;
  if (element.h1) result += element.height;
  if (element.h2) result += element.height;

  result *= element.defaultQty;

  if (result > 0) return result / 1000;
  return result;
};
