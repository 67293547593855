import { Dispatch } from 'redux';
import setAuthToken from 'services/utils/setAuthToken';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import Axios from 'axios';
import { UserT } from '../types/auth/Auth';
import {
  UsersLoadSuccessT,
  USERS_LOAD_SUCCESS,
  UsersLoadErrorT,
  USERS_LOAD_ERROR,
  USERS_SET_USER,
  SetUserT,
  AddUserToListT,
  USERS_ADD_USER_TO_LIST,
} from '../types/users/actions';
import { setSpinner } from './view';

export const usersLoadSuccess = (users: UserT[] | []): UsersLoadSuccessT => ({
  type: USERS_LOAD_SUCCESS,
  users,
});
export const usersLoadError = (): UsersLoadErrorT => ({
  type: USERS_LOAD_ERROR,
});
export const setUser = (user: UserT | null): SetUserT => ({
  type: USERS_SET_USER,
  user,
});
export const addUserToList = (user: UserT): AddUserToListT => ({
  type: USERS_ADD_USER_TO_LIST,
  user,
});

/**--------------------
 * THUNK
 ----------------------*/

export const getAllUsers = (onEnd?: () => void, onError?: () => void) => async (
  dispatch: Dispatch
) => {
  setAuthToken();
  try {
    const res = await Axios.get('/api/users/');
    const { users } = res.data;

    dispatch(usersLoadSuccess(users));
    if (onEnd) onEnd();
  } catch (error) {
    dispatch(usersLoadError());
    axiosErrorHandle(error, onError);
  }

  dispatch(setSpinner(false));
};

export const getUserById = (
  id: string,
  onEnd?: () => void,
  onError?: () => void
) => async (dispatch: Dispatch) => {
  setAuthToken();
  try {
    const res = await Axios.get(`/api/users/user/${id}`);
    const { user } = res.data;

    dispatch(setUser(user));
    if (onEnd) onEnd();
  } catch (error) {
    dispatch(setUser(null));
    axiosErrorHandle(error, onError);
  }
  dispatch(setSpinner(false));
};
