import * as yup from 'yup';

export const schema = yup.object({
  name: yup.string().required('Nazwa jest wymagana'),
  symbol: yup.string().required('Symbol jest wymagany'),
  SupplierId: yup.number().required('Dystrybutor jest wymagany'),
  price: yup
    .number()
    .moreThan(0, 'Cena jest wymagana')
    .required('Cena jest wymagana'),
});
