import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageTemplate from 'templates/PageTemplate';
import { PageHeader, message } from 'antd';
import { AppStateT } from 'services/store';
import { getAllCustomers } from 'services/store/actions/customers';
import { StyledH1 } from 'components/Header';
import List from './components/List';
import ContentTemplate from 'templates/ContentTemplate/ContentTemplate';
import SiderTemplate from 'templates/SiderTemplate/SiderTemplate';
import { useQuery } from '@tanstack/react-query';
import { customersApi } from 'services/api/customers.api';
import { useModal } from 'services/hooks/useModal';
import CustomersListSider from './sider/CustomersListSider';
import AddCustomer from 'components/Users/AddCustomerModal';

const initModal = {
  newCustomer: false,
};

export type TCustomersListModal = keyof typeof initModal;

const CustomersListPage = () => {
  const { modal, showModal, closeModal } = useModal(initModal);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['get-all-customers'],
    queryFn: () => customersApi.getAllCustomers(),
    onError() {
      message.error('Błąd');
    },
  });

  return (
    <PageTemplate>
      <SiderTemplate>
        <CustomersListSider showModal={showModal} />
      </SiderTemplate>
      <ContentTemplate>
        <PageHeader title={<StyledH1>Lista klientów</StyledH1>} />
        <List customers={data?.data.customers || []} loading={isLoading} />
      </ContentTemplate>

      {/* MODALS */}
      <AddCustomer
        visible={modal.newCustomer}
        closeModal={closeModal}
        refetchCustomers={refetch}
      />
    </PageTemplate>
  );
};

export default CustomersListPage;
