import { Col, Row } from 'antd';
import AddressData from 'components/Address/Display';
import React from 'react';
import { ContactT } from 'services/store/types/settings/Settings';
import CompanyData from './components/CompanyData';

interface PropsT {
  contact: ContactT;
}

const Contact: React.FC<PropsT> = ({ contact }) => {
  return (
    <Row>
      <Col md={24} lg={6}>
        <CompanyData contact={contact} />
      </Col>
      <Col md={24} lg={6}>
        <AddressData
          address={contact.fvAddress}
          column={1}
          title="Adres firmy"
        />
      </Col>
      <Col md={24} lg={6}>
        <AddressData
          address={contact.localAddress}
          column={1}
          title="Adres lokalu"
        />
      </Col>
    </Row>
  );
};

export default Contact;
