import React, { useState } from 'react';
import { Modal, Button, Typography } from 'antd';
import { Input, Form } from 'formik-antd';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { setCustomer } from 'services/store/actions/customers';
import { CustomerT } from 'services/store/types/customers/Customers';
import { updateCustomerData } from 'services/api/customers/update';
import * as yup from 'yup';

export const schema = yup.object({
  firstname: yup.string().required('Imię jest wymagane'),
  surname: yup.string().required('Nazwisko jest wymagane').nullable(),
  company: yup.string(),
  pesel: yup
    .string()
    .matches(/^\d{11}$/, 'PESEL składa się z 11 cyfr')
    .nullable(),
  nip: yup
    .string()
    .matches(/^\d{10}$/, 'NIP składa się z 10 cyfr')
    .nullable(),
  email: yup
    .string()
    .email('Format adresu email jest nieprawidłowy')
    .required('Email jest wymagany'),
  phone: yup.string().nullable(),
});

const { Title } = Typography;

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  customer: CustomerT;
  refetchCustomer: () => Promise<any>;
}

const EditCustomer: React.FC<PropsT> = ({
  refetchCustomer,
  visible,
  closeModal,
  customer,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: CustomerT) => {
    setLoading(true);

    await updateCustomerData(
      values,
      (updatedCustomer) => {
        refetchCustomer();
        dispatch(setCustomer(updatedCustomer));
        setLoading(false);
        closeModal();
      },
      () => {
        setLoading(false);
      }
    );
  };
  return (
    <Modal
      title="Edycja danych klienta"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Anuluj
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          htmlType="submit"
          form="EditCustomerForm"
        >
          Zatwierdź
        </Button>,
      ]}
    >
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={customer}
      >
        {(props) => {
          return (
            <Form
              noValidate
              className="form-container"
              id="EditCustomerForm"
              onFinish={props.handleSubmit}
            >
              <Title level={5}>Dane klienta</Title>
              <Form.Item name="firstname">
                <Input name="firstname" placeholder="Imię" required />
              </Form.Item>
              <Form.Item name="surname">
                <Input name="surname" placeholder="Nazwisko" required />
              </Form.Item>
              <Form.Item name="pesel">
                <Input name="pesel" placeholder="PESEL" required />
              </Form.Item>
              <Title level={5}>Dane kontaktowe</Title>
              <Form.Item name="email">
                <Input
                  name="email"
                  placeholder="E-mail"
                  type="email"
                  required
                />
              </Form.Item>
              <Form.Item name="phone">
                <Input name="phone" placeholder="Numer telefonu" required />
              </Form.Item>

              <Title level={5}>Dane Firmy*</Title>
              <Form.Item name="company">
                <Input name="company" placeholder="Nazwa" />
              </Form.Item>
              <Form.Item name="nip">
                <Input name="nip" placeholder="NIP" />
              </Form.Item>
              <small>* - nieobowiązkowe</small>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default EditCustomer;
