/* eslint-disable @typescript-eslint/default-param-last */
import update from 'immutability-helper';
import { SuppliersT, SupplierT } from '../types/suppliers/Suppliers';
import {
  SuppliersActionsT,
  SUPPLIERS_LOAD_SUCCESS,
  SUPPLIERS_LOAD_ERROR,
  SUPPLIERS_SET_SUPPLIER,
  SUPPLIERS_ADD_TO_LIST,
  SUPPLIERS_UPDATE_IN_LIST,
  SUPPLIERS_REMOVE_FROM_LIST,
} from '../types/suppliers/actions';

const initialState: SuppliersT = {
  suppliers: [],
  supplier: null,
};

const suppliersReducer = (
  state = initialState,
  action: SuppliersActionsT
): SuppliersT => {
  switch (action.type) {
    case SUPPLIERS_LOAD_SUCCESS: {
      return { ...state, suppliers: action.suppliers };
    }
    case SUPPLIERS_LOAD_ERROR: {
      return initialState;
    }
    case SUPPLIERS_SET_SUPPLIER: {
      return { ...state, supplier: action.supplier };
    }
    case SUPPLIERS_ADD_TO_LIST: {
      return update(state, { suppliers: { $push: [action.supplier] } });
    }
    case SUPPLIERS_UPDATE_IN_LIST: {
      const index = state.suppliers.findIndex(
        (item: SupplierT) => item.id === action.supplier.id
      );
      return update(state, {
        suppliers: {
          [index]: { $set: action.supplier },
        },
      });
    }
    case SUPPLIERS_REMOVE_FROM_LIST: {
      const index = state.suppliers.findIndex(
        (item: SupplierT) => item.id === action.id
      );
      return update(state, {
        suppliers: {
          $splice: [[index, 1]],
        },
      });
    }
    default:
      return state;
  }
};

export { suppliersReducer };
