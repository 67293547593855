/* eslint-disable consistent-return */
import { TermT, TermTypesT } from 'services/store/types/terms/Terms';

export const filterTermsByType = (terms: TermT[], type: TermTypesT) =>
  terms.filter((item: TermT) => item.type === type);
export const filterTermsByCustomer = (
  terms: TermT[],
  customerId: string | number
) =>
  terms.filter((item: TermT) => {
    const project =
      item.measureTerm || item.montageTerm || item.receptionTerm || null;

    if (project) {
      const { Customer } = project;
      if (Customer.id === customerId) return item;
    }
  });
