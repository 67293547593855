import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomersWithActiveProjects } from 'services/store/actions/projects';
import CustomersWithProjectsList from 'components/Projects/CustomersWithProjectsList';
import { AppStateT } from 'services/store';
import { setSpinner } from 'services/store/actions/view';

const ActiveProjects = () => {
  const dispatch = useDispatch();
  const customersProjects = useSelector(
    (state: AppStateT) => state.projects.active
  );

  useEffect(() => {
    if (!customersProjects.length) dispatch(setSpinner(true));
    dispatch(getCustomersWithActiveProjects());
  }, []);

  return (
    <div>
      <CustomersWithProjectsList
        customers={customersProjects}
        listName="active"
        isErrors
      />
    </div>
  );
};

export default ActiveProjects;
