import { Empty } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { AppStateT } from 'services/store';
import {
  getAllProjectDataById,
  getProjectOrders,
  getProjectPricingByProjectId,
  setProjectOrders,
} from 'services/store/actions/projects';
import { setSpinner } from 'services/store/actions/view';
import PageTemplate from 'templates/PageTemplate';
import NewOrders from './components/NewOrders';
import Orders from './components/Orders';

const NewOrdersPage = () => {
  const params: { id: string } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const project = useSelector((state: AppStateT) => state.projects.project);

  useEffect(() => {
    if (!project) {
      dispatch(setSpinner(true));
      dispatch(
        getAllProjectDataById(params.id, () => {
          dispatch(getProjectPricingByProjectId(params.id));
          dispatch(getProjectOrders(params.id));
        })
      );
    } else {
      dispatch(setSpinner(true));
      dispatch(getProjectPricingByProjectId(params.id));
      dispatch(getProjectOrders(params.id));
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setProjectOrders([]));
    };
  }, []);

  if (project?.Orders?.length)
    return (
      <PageTemplate>
        <Orders project={project} />
      </PageTemplate>
    );

  if (
    project &&
    project.Orders &&
    !project.Orders.length &&
    project.Pricing &&
    project.Pricing.PricingAccessories
  )
    return (
      <PageTemplate>
        <NewOrders accessories={project.Pricing.PricingAccessories} />
      </PageTemplate>
    );

  return (
    <PageTemplate>
      <Empty />
    </PageTemplate>
  );
};

export default NewOrdersPage;
