import React, { useState } from 'react';
import { ProjectT } from 'services/store/types/projects/Projects';
import { Input, message, Modal } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { projectsApi } from 'services/api/projects.api';

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  refetchProject: () => Promise<any>;
  project: ProjectT;
}

const ChangeName: React.FC<PropsT> = ({
  visible,
  closeModal,
  project,
  refetchProject,
}) => {
  const [name, setName] = useState<string | undefined>(project.name);

  const handleName = (e: any) => setName(e.target.value);

  const updateName = useMutation(
    async () => name && projectsApi.updateName(project.id, name),
    {
      onSuccess() {
        message.success('Nazwa zaktualizowana');
        refetchProject();
        closeModal();
      },
      onError(err: any) {
        console.log(err?.response);
        message.error('Bład');
      },
    }
  );

  return (
    <Modal
      title="Zmiana nazwy"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      onOk={() => updateName.mutate()}
      okButtonProps={{ disabled: !name, loading: updateName.isLoading }}
    >
      <Input
        size="large"
        placeholder="Cena"
        value={name}
        onChange={handleName}
        style={{ width: '100%' }}
      />
    </Modal>
  );
};

export default ChangeName;
