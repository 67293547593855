import { Menu } from 'antd';
import React from 'react';
import {
  DownloadOutlined,
  ForwardOutlined,
  TableOutlined,
  AppstoreOutlined,
  ApiOutlined,
  AuditOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import SubMenu from 'antd/lib/menu/SubMenu';
import { IProjectSiderProps } from './ProjectSider';
import { useHistory } from 'react-router';

interface IProps extends IProjectSiderProps {}

const CarpenterSider: React.FC<IProps> = ({
  project,
  showModal,
  getPicingFile,
}) => {
  const history = useHistory();
  return (
    <>
      <Menu mode="inline">
        <Menu.Item
          icon={<ForwardOutlined />}
          onClick={() => {
            return showModal('changeStatus');
          }}
          disabled={
            !project || project.status !== 'produkcja' || project.productionEnd
          }
        >
          Zakończ produkcję
        </Menu.Item>
        <hr />
        <Menu.Item
          icon={<ShoppingCartOutlined />}
          onClick={() =>
            project && history.push(`/projects/orders/${project.id}`)
          }
          disabled={!project || !project.isOrders}
        >
          Odbierz zamówienia
        </Menu.Item>
        <SubMenu
          icon={<TableOutlined />}
          title="Pobierz pliki"
          disabled={!project || !project.isPriced}
        >
          <Menu.Item
            icon={<AppstoreOutlined />}
            onClick={() => getPicingFile('elements')}
          >
            Lista elementów konstrukcyjnych
          </Menu.Item>
          <Menu.Item
            icon={<AppstoreOutlined />}
            onClick={() => getPicingFile('fronts')}
          >
            Lista frontów
          </Menu.Item>
          <Menu.Item
            icon={<ApiOutlined />}
            onClick={() => getPicingFile('accessories')}
          >
            Lista akcesoriów
          </Menu.Item>
          <Menu.Item
            icon={<AuditOutlined />}
            onClick={() => getPicingFile('orders')}
          >
            Zamówienia
          </Menu.Item>
        </SubMenu>
        <hr />
        <Menu.Item
          icon={<DownloadOutlined />}
          onClick={() => showModal('files')}
        >
          Pobierz dokumenty
        </Menu.Item>
      </Menu>
    </>
  );
};

export default CarpenterSider;
