import { Checkbox, Divider, InputNumber } from 'antd';
import withPermission from 'hoc/withPermission';
import React from 'react';
import { PermissionsT } from 'services/store/types/auth/Auth';
import { PaymentT } from 'services/store/types/projects/Projects';
import { perms } from 'services/utils/const';
import { FlexRow } from 'templates/FlexRow';

interface ItemPropsT {
  payment: PaymentT;
  placeholder: () => string;
  permission: PermissionsT;
  toApprove: boolean[];
  handleMultiApprove: (value: boolean, index: number) => void;
  index: number;
  value: number;
  handleValue: (index: number, value: number) => void;
}

const PaymentItem: React.FC<ItemPropsT> = ({
  payment,
  permission,
  placeholder,
  toApprove,
  handleMultiApprove,
  index,
  handleValue,
  value,
}) => {
  const inputDisabled = () => {
    if (permission !== perms.ADMIN) return true;
    if (!toApprove[index]) return true;
    return false;
  };

  return (
    <>
      <FlexRow justify="space-between">
        <div>
          <Checkbox
            checked={toApprove[index]}
            onChange={(e) => handleMultiApprove(e.target.checked, index)}
          />
          <strong> {payment.name}</strong>
          <sub>({(payment.part * 100).toString().split('.')[0]}%)</sub>:{' '}
          <InputNumber
            value={value}
            onChange={(v: number | string | undefined | null) =>
              handleValue(index, typeof v === 'number' ? v : 0)
            }
            disabled={inputDisabled()}
            placeholder={placeholder()}
          />
        </div>
      </FlexRow>
      <Divider />
    </>
  );
};

export default withPermission(PaymentItem);
