import { Button, Modal, Space, Typography } from 'antd';
import { Formik } from 'formik';
import { Form, Input, InputNumber, Checkbox } from 'formik-antd';
import React from 'react';
import { CabinetElementT } from 'services/store/types/cabinets/Cabinets';
import { getElementVeneerLength } from 'services/utils/catalog';
import { FlexRow } from 'templates/FlexRow';
import { elementSchema } from './utils/validate';

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  handleSubmit: (element: CabinetElementT) => void;
  initValues: CabinetElementT;
}

const CabinetElementModal: React.FC<PropsT> = ({
  handleSubmit,
  closeModal,
  visible,
  initValues,
}) => {
  return (
    <Modal
      title="Element konstrukcyjny"
      destroyOnClose={true}
      visible={visible}
      style={{ top: 40 }}
      footer={null}
      onCancel={closeModal}
    >
      <Formik
        validationSchema={elementSchema}
        onSubmit={(values) =>
          handleSubmit({
            ...values,
            veneerLength: getElementVeneerLength(values),
            surface: values.width * values.height,
          })
        }
        initialValues={initValues}
      >
        {(props) => {
          return (
            <>
              <Form.Item name="name" label="Nazwa">
                <Input name="name" placeholder="Nazwa" />
              </Form.Item>
              <Form.Item name="description" label="Opis">
                <Input name="description" placeholder="Opis..." />
              </Form.Item>
              <FlexRow justify="space-between">
                <div style={{ textAlign: 'center' }}>
                  <Form.Item
                    name="height"
                    label="Wys."
                    required
                    style={{ marginBottom: 0 }}
                  >
                    <InputNumber name="height" min={0} />
                  </Form.Item>

                  <Form.Item name="h1" style={{ marginBottom: 0 }}>
                    <Checkbox name="h1" />
                  </Form.Item>
                  <Form.Item name="h2">
                    <Checkbox name="h2" />
                  </Form.Item>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Form.Item
                    name="width"
                    label="Szer."
                    required
                    style={{ marginBottom: 0 }}
                  >
                    <InputNumber name="width" min={0} />
                  </Form.Item>
                  <Form.Item name="w1" style={{ marginBottom: 0 }}>
                    <Checkbox name="w1" />
                  </Form.Item>
                  <Form.Item name="w2">
                    <Checkbox name="w2" />
                  </Form.Item>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Form.Item
                    name="thickness"
                    label="Gr."
                    required
                    style={{ marginBottom: 0 }}
                  >
                    <InputNumber name="thickness" min={0} />
                  </Form.Item>

                  <Form.Item name="isHdf" style={{ marginBottom: 0 }}>
                    HDF:{' '}
                    <Checkbox
                      name="isHdf"
                      onChange={(e) => {
                        if (e.target.checked) {
                          props.setValues({
                            ...props.values,
                            isHdf: true,
                            thickness: 3,
                          });
                        } else {
                          props.setFieldValue('isHdf', false);
                        }
                      }}
                    />
                  </Form.Item>
                </div>
              </FlexRow>
              <Form.Item name="defaultQty" label="Domyślna ilość" required>
                <InputNumber name="defaultQty" min={1} />
              </Form.Item>

              <div style={{ textAlign: 'right' }}>
                Długość okleiny:{' '}
                {getElementVeneerLength(props.values).toFixed(2)} m.b.
              </div>
              <FlexRow justify="flex-end">
                <Space>
                  <Button key="back" onClick={closeModal}>
                    Anuluj
                  </Button>

                  <Button
                    key="submit"
                    type="primary"
                    onClick={() => props.handleSubmit()}
                  >
                    Zatwierdź
                  </Button>
                </Space>
              </FlexRow>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CabinetElementModal;
