import React, { useEffect, useState } from 'react';
import {
  DatePicker,
  InputNumber,
  message,
  Modal,
  Row,
  Space,
  Typography,
} from 'antd';
import { ProjectT } from 'services/store/types/projects/Projects';

import moment, { Moment } from 'moment';
import { useMutation } from '@tanstack/react-query';
import { projectsApi } from 'services/api/projects.api';

interface PropsT {
  project: ProjectT;
  closeModal: () => void;
  refetchProject: () => Promise<any>;
  visible: boolean;
}

const ChangeProjectOrdinalNumber: React.FC<PropsT> = ({
  project,
  closeModal,
  visible,
  refetchProject,
}) => {
  const [number, setNumber] = useState(project.ordinalNumber || '');
  const [projectYear, setProjectYear] = useState<Moment | null>(moment());

  useEffect(() => {
    if (project.projectYear) {
      const newYear = moment();
      newYear.set('year', project.projectYear);
      setProjectYear(newYear);
    }
  }, [project]);

  const updateOrdinalNumber = useMutation(
    async () =>
      projectYear &&
      projectsApi.updateOrdinalNumber(project.id, number, projectYear.year()),
    {
      onSuccess() {
        message.success('Numer porządkowy zaktualizowany');
        refetchProject();
        closeModal();
      },
      onError(err: any) {
        console.log(err?.response);
        message.error('Bład');
      },
    }
  );

  return (
    <Modal
      title="Zmiana numeru porządkowego"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      onOk={() => updateOrdinalNumber.mutate()}
      okButtonProps={{
        disabled: !number || !projectYear,
        loading: updateOrdinalNumber.isLoading,
      }}
    >
      <Row justify="center">
        <Space direction="vertical">
          <Typography.Title level={5}>Numer porządkowy</Typography.Title>
          <InputNumber
            value={number}
            onChange={(value) => setNumber(value)}
            size="large"
            min={1}
            style={{ width: 200 }}
          />
          <Typography.Title level={5}>Rok projektu</Typography.Title>
          <DatePicker
            size="large"
            picker="year"
            value={projectYear}
            onChange={(d) => setProjectYear(d)}
            disabledDate={(d) => d.year() < moment().year() - 1}
            style={{ width: 200 }}
          />
        </Space>
      </Row>
    </Modal>
  );
};

export default ChangeProjectOrdinalNumber;
