import React, { useState } from 'react';
import { Modal, DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { ProjectT } from 'services/store/types/projects/Projects';
import {
  projectTermsTypes,
  updateProjectTermsById,
} from 'services/store/actions/projects';
import { weeksFromDate } from 'services/utils/date';

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  project: ProjectT;
}

const SetMeasureWeek: React.FC<PropsT> = ({ visible, closeModal, project }) => {
  const { measureWeek, montageWeek } = project;
  const dispatch = useDispatch();
  const [week, setWeek] = useState<Moment | null>(
    measureWeek ? moment(measureWeek.date) : null
  );

  const handleChange = (date: Moment | null) => setWeek(date);

  const disabledDate = (currDate: Moment) => {
    return currDate < moment();
  };

  const onSubmit = async () => {
    if (week) {
      dispatch(setSpinner(true));
      dispatch(
        updateProjectTermsById(
          projectTermsTypes.MEASURE_WEEK,
          project.id,
          { measureWeek: week },
          'Ustalono tydzień pomiaru',
          () => closeModal()
        )
      );
    }
  };
  return (
    <Modal
      title="Tydzień pomiaru"
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      onOk={onSubmit}
      okButtonProps={{ disabled: !week }}
    >
      {montageWeek && weeksFromDate(moment(montageWeek.date)) < 7 && (
        <span style={{ color: 'red' }}>
          Montaż za {weeksFromDate(moment(montageWeek.date))} tyg.
        </span>
      )}
      <DatePicker
        placeholder="Wybierz tydzień"
        style={{ width: '100%' }}
        size="large"
        picker="week"
        value={week || null}
        onChange={handleChange}
        disabledDate={disabledDate}
      />
    </Modal>
  );
};

export default SetMeasureWeek;
