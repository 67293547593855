import React, { useState } from 'react';
import { Image, message, Modal } from 'antd';
import {
  PaintT,
  PatternT,
  VeneerT,
} from 'services/store/types/catalog/Catalog';
import { FlexRow } from 'templates/FlexRow';
import { Form, Radio, Select } from 'formik-antd';
import imgPlaceholder from 'assets/images/placeholder.png';
import { Formik } from 'formik';
import { PricingPatternT } from 'services/store/types/pricing/Pricing';
import { capitalizeFirstLetter } from 'services/utils/string';
import { RadioChangeEvent } from 'antd/lib/radio';
import { domain } from 'services/utils/const';
import RAL from 'assets/data/ColorsRal.json';
import NCS from 'assets/data/ColorsNcs.json';
import ICA from 'assets/data/ColorsIca.json';
import { schema } from '../utils/validate';

interface PropsT {
  fronts: PatternT[];
  boards: PatternT[];
  veneers: VeneerT[];
  closeModal: () => void;
  visible: boolean;
  handleAddPattern: (item: PricingPatternT) => void;
  patternType: 'front' | 'body';
}

const AddFrontPattern: React.FC<PropsT> = ({
  fronts,
  boards,
  veneers,
  closeModal,
  visible,
  handleAddPattern,
  patternType,
}) => {
  const [palette, setPalette] = useState(RAL);
  const [design, setDesign] = useState('laminat');

  const handleAddFrontPattern = (values: PricingPatternT): any => {
    if (values.Pattern) {
      handleAddPattern(values);
      message.success('Dodano wzór');
      return closeModal();
    }
    message.error('Wybierz wzór');
    return values;
  };
  return (
    <Modal
      title={`Dodaj wzór ${patternType === 'body' ? 'korpusu' : 'frontu'}`}
      destroyOnClose={true}
      visible={visible}
      onCancel={closeModal}
      okButtonProps={{ htmlType: 'submit', form: 'frontpattern-form-id' }}
    >
      <Formik
        validationSchema={schema}
        onSubmit={handleAddFrontPattern}
        initialValues={{
          type: patternType,
          PatternId: '',
          Pattern: undefined,
          VeneerId: '',
          Veneer: undefined,
          Paint: undefined,
        }}
      >
        {(props) => {
          return (
            <Form
              noValidate
              id="frontpattern-form-id"
              onFinish={props.handleSubmit}
              labelCol={{ span: 5 }}
            >
              {/* ------------------------------- TYP FRONTU ------------------------------- */}
              <Form.Item name="design" label="Typ frontu">
                <Radio.Group
                  name="design"
                  value={design}
                  onChange={(e: RadioChangeEvent) => {
                    setDesign(e.target.value);
                    props.setFieldValue('PatternId', '');
                    props.setFieldValue('Pattern', undefined);
                    props.setFieldValue('VeneerId', '');
                    props.setFieldValue('Veneer', undefined);
                    if (e.target.value === 'lakier') {
                      props.setFieldValue('Paint', {
                        name: '',
                        hex: '',
                        type: 'półmat',
                        palette: 'ral',
                      });
                    } else {
                      props.setFieldValue('Paint', undefined);
                    }
                  }}
                >
                  <Radio name="design" value="laminat">
                    Laminat
                  </Radio>
                  <Radio
                    name="design"
                    value="lakier"
                    disabled={patternType === 'body'}
                  >
                    Lakier
                  </Radio>
                </Radio.Group>
              </Form.Item>

              {/* ------------------------------- WZÓR LAMINATU ------------------------------- */}
              {design === 'laminat' && (
                <>
                  <Form.Item name="PatternId" label="Płyta">
                    <Select
                      name="PatternId"
                      placeholder="Wzór lamninatu"
                      onChange={(value: string | number) => {
                        props.setFieldValue('PatternId', value);
                        const board = boards.find(
                          (b: PatternT) => b.id === value
                        );
                        props.setFieldValue('Pattern', board);
                      }}
                    >
                      {boards
                        .sort((a: PatternT, b: PatternT) =>
                          `${a.name}`.localeCompare(b.name)
                        )
                        .map((board: PatternT) => {
                          if (board.id)
                            return (
                              <Select.Option value={board.id} key={board.id}>
                                <FlexRow justify="space-between">
                                  <Image
                                    src={
                                      `${domain}${board.miniaturePath}` ||
                                      imgPlaceholder
                                    }
                                    alt="Podgląd"
                                    preview={false}
                                  />
                                  <span>
                                    {capitalizeFirstLetter(board.name)}
                                  </span>
                                  <span>{board.symbol.toUpperCase()}</span>
                                </FlexRow>
                              </Select.Option>
                            );
                          return null;
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item name="VeneerId" label="Obrzeże">
                    <Select
                      name="VeneerId"
                      placeholder="Wzór obrzeża"
                      onChange={(value: string | number) => {
                        props.setFieldValue('VeneerId', value);
                        const veneer = veneers.find(
                          (b: VeneerT) => b.id === value
                        );
                        props.setFieldValue('Veneer', veneer);
                      }}
                    >
                      {veneers
                        .sort((a: VeneerT, b: VeneerT) =>
                          `${a.name}`.localeCompare(b.name)
                        )
                        .map((veneer: VeneerT) => {
                          if (veneer.id)
                            return (
                              <Select.Option value={veneer.id} key={veneer.id}>
                                <FlexRow justify="space-between">
                                  <Image
                                    src={
                                      `${domain}${veneer.miniaturePath}` ||
                                      imgPlaceholder
                                    }
                                    alt="Podgląd"
                                    preview={false}
                                  />
                                  <span>
                                    {capitalizeFirstLetter(veneer.name)}
                                  </span>
                                  <span>{veneer.symbol.toUpperCase()}</span>
                                  <span>
                                    {veneer.thickness}/{veneer.width}
                                    <sub>mm</sub>
                                  </span>
                                </FlexRow>
                              </Select.Option>
                            );
                          return null;
                        })}
                    </Select>
                  </Form.Item>
                </>
              )}

              {/* ------------------------------- WZÓR FRONTU LAKIEROWANEGO ------------------------------- */}
              {design === 'lakier' && (
                <div>
                  <Form.Item name="PatternId" label="Wzór">
                    <Select
                      name="PatternId"
                      placeholder="Wzór frontu"
                      onChange={(value: string | number) => {
                        props.setFieldValue('PatternId', value);
                        const front = fronts.find(
                          (b: PatternT) => b.id === value
                        );
                        props.setFieldValue('Pattern', front);
                      }}
                    >
                      {fronts
                        .sort((a: PatternT, b: PatternT) =>
                          `${a.name}`.localeCompare(b.name)
                        )
                        .map((front: PatternT) => {
                          if (front.id)
                            return (
                              <Select.Option value={front.id} key={front.id}>
                                <FlexRow justify="space-between">
                                  <Image
                                    src={
                                      `${domain}${front.miniaturePath}` ||
                                      imgPlaceholder
                                    }
                                    alt="Podgląd"
                                    preview={false}
                                  />
                                  <span>
                                    {capitalizeFirstLetter(front.name)}
                                  </span>
                                  <span>{front.symbol.toUpperCase()}</span>
                                </FlexRow>
                              </Select.Option>
                            );
                          return null;
                        })}
                    </Select>
                  </Form.Item>

                  {/* ------------------------------- MATOWOŚĆ------------------------------- */}
                  <Form.Item name="Paint.type" label="Matowość">
                    <Radio.Group name="Paint.type">
                      <Radio name="Paint.type" value="mat">
                        Mat
                      </Radio>
                      <Radio name="Paint.type" value="półmat">
                        Półmat
                      </Radio>
                      <Radio name="Paint.type" value="połysk">
                        Połysk
                      </Radio>
                    </Radio.Group>
                  </Form.Item>

                  {/* ------------------------------- PALETA ------------------------------- */}
                  <Form.Item name="Paint.palette" label="Paleta">
                    <Radio.Group
                      name="Paint.palette"
                      onChange={(e: RadioChangeEvent) => {
                        if (e.target.value === 'ral') setPalette(RAL);
                        else if (e.target.value === 'ncs') setPalette(NCS);
                        else if (e.target.value === 'ica') setPalette(ICA);
                      }}
                    >
                      <Radio name="Paint.palette" value="ral">
                        RAL
                      </Radio>
                      <Radio name="Paint.palette" value="ncs">
                        NCS
                      </Radio>
                      <Radio name="Paint.palette" value="ica">
                        ICA
                      </Radio>
                    </Radio.Group>
                  </Form.Item>

                  {/* ------------------------------- KOLOR ------------------------------- */}
                  <Form.Item name="Paint.name" label="Kolor">
                    <Select
                      name="Paint.name"
                      placeholder="Kolor lakieru"
                      showSearch
                      onChange={(value: string) => {
                        const color = palette.find(
                          (c: PaintT) => c.name === value
                        );
                        if (color) props.setFieldValue('Paint.hex', color.hex);
                      }}
                    >
                      {palette
                        .sort((a: PaintT, b: PaintT) =>
                          `${a.name}`.localeCompare(b.name)
                        )
                        .map((paint: PaintT) => {
                          if (paint.name)
                            return (
                              <Select.Option
                                value={paint.name}
                                hex={paint.hex}
                                key={paint.name}
                              >
                                <FlexRow justify="space-between">
                                  <ColorPreview color={paint.hex} />
                                  <span>
                                    {capitalizeFirstLetter(paint.name)}
                                  </span>
                                </FlexRow>
                              </Select.Option>
                            );
                          return null;
                        })}
                    </Select>
                  </Form.Item>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddFrontPattern;

const ColorPreview = ({ color }: { color: string }) => {
  return <div style={{ width: 50, height: 20, backgroundColor: color }} />;
};
