import Axios from 'axios';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import setAuthToken from 'services/utils/setAuthToken';
import { NewProjectT } from 'services/store/types/newProject/NewProject';
import { ProjectT } from 'services/store/types/projects/Projects';
import { PricingT } from 'services/store/types/pricing/Pricing';

export const createNewProjet = async (
  project: NewProjectT,
  onEnd: (project: ProjectT) => void,
  onErr: () => void
) => {
  setAuthToken();
  try {
    const body = new FormData();
    body.append(
      'projectData',
      JSON.stringify({ ...project, projectYear: project.projectYear.year() })
    );
    if (project.images.length) {
      for (let i = 0; i < project.images.length; i++) {
        const file = project.images[i];
        body.append('files[]', file, file.name);
      }
    }

    const res: any = await Axios.post('/api/projects/newproject', body);

    onEnd(res.data.newProject);
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};

export const createNewPricing = async (
  body: { pricing: PricingT; changeProjectPrice: boolean },
  onEnd: (project: ProjectT) => void,
  onErr: () => void
) => {
  setAuthToken();
  try {
    const res: any = await Axios.post(
      `/api/projects/newpricing/${body.pricing.ProjectId}`,
      body
    );

    onEnd(res.data.project);
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};
