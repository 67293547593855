import axios, { AxiosResponse } from 'axios';
import moment, { Moment } from 'moment';
import { Dispatch } from 'redux';
import { IdType } from 'services/types';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import setAuthToken from 'services/utils/setAuthToken';
import {
  OrdersLoadErrorT,
  ORDERS_LOAD_SUCCESS,
  OrdersLoadSuccessT,
  ORDERS_LOAD_ERROR,
  OrdersSetOrderT,
  ORDERS_SET_ORDER,
  OrdersSetFilterFieldT,
  ORDERS_SET_FILTER_FIELD,
} from '../types/orders/actions';
import {
  NewOrderT,
  OrdersFiltersFieldsT,
  OrdersListsT,
  OrderT,
} from '../types/orders/Orders';
import { setProjectOrders } from './projects';
import { setSpinner } from './view';

export const ordersLoadSuccess = (
  list: OrdersListsT,
  orders: OrderT[]
): OrdersLoadSuccessT => ({
  type: ORDERS_LOAD_SUCCESS,
  list,
  orders,
});
export const ordersLoadError = (list: OrdersListsT): OrdersLoadErrorT => ({
  type: ORDERS_LOAD_ERROR,
  list,
});
export const setOrder = (order: OrderT | null): OrdersSetOrderT => ({
  type: ORDERS_SET_ORDER,
  order,
});

export const setOrdersFilterField = (
  list: OrdersListsT,
  field: OrdersFiltersFieldsT,
  value: [Moment, Moment]
): OrdersSetFilterFieldT => ({
  type: ORDERS_SET_FILTER_FIELD,
  list,
  field,
  value,
});

// THUNK
export const getActiveOrders = (
  onEnd?: () => void,
  onError?: () => void
) => async (dispatch: Dispatch) => {
  setAuthToken();
  try {
    const res = await axios.get('/api/orders/active');
    const { orders } = res.data;

    dispatch(ordersLoadSuccess('active', orders || []));
    if (onEnd) onEnd();
  } catch (error) {
    dispatch(ordersLoadError('active'));
    axiosErrorHandle(error, onError);
  }
  dispatch(setSpinner(false));
};

export const getEndedOrders = (
  from: Moment,
  to: Moment,
  onEnd?: () => void,
  onError?: () => void
) => async (dispatch: Dispatch) => {
  setAuthToken();
  try {
    const res = await axios.post('/api/orders/ended', {
      from,
      to: moment(to).add(1, 'day'),
    });
    const { orders } = res.data;

    dispatch(ordersLoadSuccess('ended', orders || []));
    if (onEnd) onEnd();
  } catch (error) {
    dispatch(ordersLoadError('ended'));
    axiosErrorHandle(error, onError);
  }
  dispatch(setSpinner(false));
};

export const createProjectOrders = (
  projectId: IdType,
  orders: NewOrderT[],
  onEnd?: () => void,
  onError?: () => void
) => async (dispatch: Dispatch) => {
  setAuthToken();
  try {
    const body = { orders, projectId };
    const res: AxiosResponse = await axios.post('/api/orders/', body);

    const { newOrders } = res.data;

    dispatch(setProjectOrders(newOrders));
    if (onEnd) onEnd();
  } catch (error) {
    dispatch(setProjectOrders([]));
    axiosErrorHandle(error, onError);
  }
  dispatch(setSpinner(false));
};

export const sendOrder = (
  orderId: IdType,
  onEnd: (order: OrderT) => void,
  onError?: () => void
) => async (dispatch: Dispatch) => {
  setAuthToken();
  try {
    const res: AxiosResponse = await axios.put(`/api/orders/${orderId}/send`);

    const { order } = res.data;

    onEnd(order);
  } catch (error) {
    axiosErrorHandle(error, onError);
  }
  dispatch(setSpinner(false));
};

export const cancelOrder = (
  orderId: IdType,
  onEnd: (order: OrderT) => void,
  onError?: () => void
) => async (dispatch: Dispatch) => {
  setAuthToken();
  try {
    const res: AxiosResponse = await axios.put(`/api/orders/${orderId}/cancel`);

    const { order } = res.data;

    onEnd(order);
  } catch (error) {
    axiosErrorHandle(error, onError);
  }
  dispatch(setSpinner(false));
};
