import { Dispatch } from 'redux';
import setAuthToken from 'services/utils/setAuthToken';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import Axios from 'axios';
import { AccessoryT } from '../types/accesories/Accessories';
import {
  AccessoriesLoadSuccessT,
  ACCESSORIES_LOAD_SUCCESS,
  AccessoriesLoadErrorT,
  ACCESSORIES_LOAD_ERROR,
  AccessoriesAddToListT,
  ACCESSORIES_ADD_TO_LIST,
  AccessoriesUpdateInListT,
  ACCESSORIES_UPDATE_IN_LIST,
  AccessoriesRemoveFromListT,
  ACCESSORIES_REMOVE_FROM_LIST,
} from '../types/accesories/actions';
import { setSpinner } from './view';

export const accessoriesLoadSuccess = (
  accessories: AccessoryT[]
): AccessoriesLoadSuccessT => ({
  type: ACCESSORIES_LOAD_SUCCESS,
  accessories,
});

export const accessoriesLoadError = (): AccessoriesLoadErrorT => ({
  type: ACCESSORIES_LOAD_ERROR,
});

export const addAccessoryToList = (
  accessory: AccessoryT
): AccessoriesAddToListT => ({
  type: ACCESSORIES_ADD_TO_LIST,
  accessory,
});
export const updateAccessoryInList = (
  accessory: AccessoryT
): AccessoriesUpdateInListT => ({
  type: ACCESSORIES_UPDATE_IN_LIST,
  accessory,
});
export const removeAccessoryFromList = (
  id: string
): AccessoriesRemoveFromListT => ({
  type: ACCESSORIES_REMOVE_FROM_LIST,
  id,
});

/**--------------------
 * THUNK
 ----------------------*/
export const getAccessories = (
  onEnd?: () => void,
  onError?: () => void
) => async (dispatch: Dispatch) => {
  setAuthToken();
  try {
    const res = await Axios.get('/api/accessories/');

    dispatch(accessoriesLoadSuccess(res.data.accessories));
    if (onEnd) onEnd();
  } catch (error) {
    dispatch(accessoriesLoadError());
    axiosErrorHandle(error, onError);
  }
  dispatch(setSpinner(false));
};
