import { IdType } from 'services/types';
import { PatternT, TableT } from '../catalog/Catalog';
import { ProjectT } from '../projects/Projects';
import {
  PricingAccessoryT,
  PricingElementT,
  PricingPatternT,
  PricingPatternTypeT,
  PricingT,
  SegmentCabinetT,
  SegmentT,
} from './Pricing';

export const PRICING_ADD_PATTERN = 'PRICING_ADD_PATTERN';
export const PRICING_REMOVE_PATTERN = 'PRICING_REMOVE_PATTERN';
export const PRICING_UPDATE_PATTERN = 'PRICING_UPDATE_PATTERN';
export const PRICING_SET_PRICE = 'PRICING_SET_PRICE';
export const PRICING_SET_PRICING_TO_EDIT = 'PRICING_SET_PRICING_TO_EDIT';
export const PRICING_SET_PROJECT = 'PRICING_SET_PROJECT';
export const PRICING_ADD_SEGMENT = 'PRICING_ADD_SEGMENT';
export const PRICING_REMOVE_SEGMENT = 'PRICING_REMOVE_SEGMENT';
export const PRICING_ADD_SEGMENT_CABINET = 'PRICING_ADD_SEGMENT_CABINET';
export const PRICING_UPDATE_SEGMENT_CABINET = 'PRICING_UPDATE_SEGMENT_CABINET';
export const PRICING_REMOVE_SEGMENT_CABINET = 'PRICING_REMOVE_SEGMENT_CABINET';
export const PRICING_SET_ORDER_SEGMENT_CABINETS =
  'PRICING_SET_ORDER_SEGMENT_CABINETS';
export const PRICING_ADD_ELEMENTS = 'PRICING_ADD_ELEMENTS';
export const PRICING_REMOVE_ELEMENTS = 'PRICING_REMOVE_ELEMENTS';
export const PRICING_ADD_ACCESSORIES = 'PRICING_ADD_ACCESSORIES';
export const PRICING_REMOVE_ACCESSORY = 'PRICING_REMOVE_ACCESSORY';
export const PRICING_EDIT_SEGMENT_NAME = 'PRICING_EDIT_SEGMENT_NAME';
export const PRICING_EDIT_LISTS = 'PRICING_EDIT_LISTS';
export const PRICING_ELEMENTS_QTY = 'PRICING_ELEMENTS_QTY';
export const PRICING_ACCESSORIES_QTY = 'PRICING_ACCESSORIES_QTY';
export const PRICING_SET_SEGMENT_TABLE = 'PRICING_SET_SEGMENT_TABLE';
export const PRICING_CLEAR_DATA = 'PRICING_CLEAR_DATA';

export interface PricingClearDataT {
  type: typeof PRICING_CLEAR_DATA;
}
export interface PricingSetPricingToEditT {
  type: typeof PRICING_SET_PRICING_TO_EDIT;
  pricing: PricingT;
}
export interface PricingSetSegmentTableT {
  type: typeof PRICING_SET_SEGMENT_TABLE;
  segmentIndex: IdType;
  table: TableT | undefined;
}
export interface PricingEditSegmentNameT {
  type: typeof PRICING_EDIT_SEGMENT_NAME;
  segmentIndex: number;
  name: string;
}
export interface PricingEditListsT {
  type: typeof PRICING_EDIT_LISTS;
  edit: boolean;
}

export interface PricingAddPatternT {
  type: typeof PRICING_ADD_PATTERN;
  item: PricingPatternT;
}
export interface PricingRemovePatternT {
  type: typeof PRICING_REMOVE_PATTERN;
  pattern: PricingPatternT;
  patternType: PricingPatternTypeT;
}
export interface PricingUpdatePatternT {
  type: typeof PRICING_UPDATE_PATTERN;
  item: PatternT;
}
export interface PricingSetPriceT {
  type: typeof PRICING_SET_PRICE;
  price: number;
}
export interface PricingSetProjectT {
  type: typeof PRICING_SET_PROJECT;
  Project: ProjectT;
}

export interface PricingAddSegmentT {
  type: typeof PRICING_ADD_SEGMENT;
  segment: SegmentT;
}
export interface PricingRemoveSegmentT {
  type: typeof PRICING_REMOVE_SEGMENT;
  index: number;
}

export interface PricingAddElementsT {
  type: typeof PRICING_ADD_ELEMENTS;
  elements: PricingElementT[];
}
export interface PricingRemoveElementT {
  type: typeof PRICING_REMOVE_ELEMENTS;
  index: number;
}
export interface PricingElementsQtyT {
  type: typeof PRICING_ELEMENTS_QTY;
  index: number;
  value: number;
}

export interface PricingAddAccessoriessT {
  type: typeof PRICING_ADD_ACCESSORIES;
  accessories: PricingAccessoryT[];
}
export interface PricingRemoveAccessoryT {
  type: typeof PRICING_REMOVE_ACCESSORY;
  index: number;
}
export interface PricingAccessoriessQtyT {
  type: typeof PRICING_ACCESSORIES_QTY;
  index: number;
  value: number;
}

export interface PricingAddSegmentCabinetT {
  type: typeof PRICING_ADD_SEGMENT_CABINET;
  index: number;
  cabinet: SegmentCabinetT;
}
export interface PricingRemoveSegmentCabinetT {
  type: typeof PRICING_REMOVE_SEGMENT_CABINET;
  segmentIndex: number;
  cabinetIndex: number;
}
export interface PricingUpdateSegmentCabinetT {
  type: typeof PRICING_UPDATE_SEGMENT_CABINET;
  segmentIndex: number;
  cabinetIndex: number;
  cabinet: SegmentCabinetT;
}

export interface PricingSetSegmentCabinetsT {
  type: typeof PRICING_SET_ORDER_SEGMENT_CABINETS;
  segmentIndex: number;
  cabinets: SegmentCabinetT[];
}

export type PricingActionsT =
  | PricingSetPricingToEditT
  | PricingClearDataT
  | PricingSetSegmentTableT
  | PricingElementsQtyT
  | PricingAccessoriessQtyT
  | PricingEditSegmentNameT
  | PricingEditListsT
  | PricingAddElementsT
  | PricingRemoveElementT
  | PricingAddAccessoriessT
  | PricingRemoveAccessoryT
  | PricingUpdateSegmentCabinetT
  | PricingRemoveSegmentT
  | PricingAddSegmentT
  | PricingSetSegmentCabinetsT
  | PricingRemoveSegmentCabinetT
  | PricingAddSegmentCabinetT
  | PricingAddPatternT
  | PricingRemovePatternT
  | PricingUpdatePatternT
  | PricingSetPriceT
  | PricingSetProjectT;
