import { Descriptions } from 'antd';
import TextButton from 'components/TextButton';
import React from 'react';
import { useHistory } from 'react-router';
import { ProjectT } from 'services/store/types/projects/Projects';
import { dateToString } from 'services/utils/date';
import { getPersonalName } from 'services/utils/users';

interface PropsT {
  project: ProjectT;
}

const PricingProjectInfo: React.FC<PropsT> = ({ project }) => {
  const history = useHistory();
  return (
    <Descriptions column={4} size="small">
      <Descriptions.Item label="Klient">
        <TextButton
          text={project.Customer ? getPersonalName(project.Customer) : 'BRAK'}
          onClick={() =>
            project.Customer
              ? history.push(`/customers/${project.CustomerId}`)
              : null
          }
        />
      </Descriptions.Item>
      <Descriptions.Item label="Projektant">
        <TextButton
          text={project.User ? getPersonalName(project.User) : 'BRAK'}
          onClick={() =>
            project.User ? history.push(`/users/${project.UserId}`) : null
          }
        />
      </Descriptions.Item>
      <Descriptions.Item label="Data rozpoczęcia">
        <strong>{dateToString(project.startAt)}</strong>
      </Descriptions.Item>
      <Descriptions.Item label="Uwagi">
        {project.comments || '-'}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default PricingProjectInfo;
