import React, { useState, useEffect } from 'react';
import { AccessoryT } from 'services/store/types/accesories/Accessories';
import TextButton from 'components/TextButton';
import { Image, Popconfirm, Table, Typography } from 'antd';
import imgPlaceholder from 'assets/images/placeholder.png';
import { useHistory } from 'react-router';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { TableProps } from 'antd/lib/table';
import { domain, perms } from 'services/utils/const';
import { PermissionsT } from 'services/store/types/auth/Auth';
import withPermission from 'hoc/withPermission';

const columns: any[] = [
  {
    title: 'Lp.',
    align: 'center' as const,
    render: (t: string, r: any, i: number) => i + 1,
  },
  {
    title: 'Zdjęcie',
    dataIndex: 'img',
    align: 'center' as const,
  },
  {
    title: 'Nazwa',
    dataIndex: 'name',
    align: 'center' as const,
    defaultSortOrder: 'ascend' as const,
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
    align: 'center' as const,
  },
  {
    title: 'Dystrybutor',
    dataIndex: 'supplier',
    align: 'center' as const,
    defaultSortOrder: 'ascend' as const,
    sorter: (a: any, b: any) => a.supplierName.localeCompare(b.supplierName),
  },
  {
    title: 'Cena',
    dataIndex: 'price',
    align: 'center' as const,
  },
  {
    title: 'Akcje',
    dataIndex: 'actions',
    align: 'center' as const,
  },
];

interface PropsT extends TableProps<any> {
  accessories: AccessoryT[];
  actions?: {
    remove?: (accessory: AccessoryT) => void;
    update?: (accessory: AccessoryT) => void;
  };
  permission: PermissionsT;
}

const AccessoriesList: React.FC<PropsT> = ({
  accessories,
  permission,
  actions = undefined,
  ...props
}) => {
  const history = useHistory();
  const [list, setList] = useState<any[]>([]);

  const handleRedirect = (path: string) => history.push(path);
  useEffect(() => {
    const newList = accessories.map((accessory: AccessoryT, i: number) => {
      return {
        ...accessory,
        key: accessory.id,
        img: (
          <Image
            src={
              accessory.miniaturePath
                ? `${domain}${accessory.miniaturePath}`
                : imgPlaceholder
            }
            alt="img"
            style={{ cursor: 'pointer' }}
            width={40}
            height={40}
            preview={{
              src: `${domain}${accessory.imgPath}`,
            }}
          />
        ),
        supplier: (
          <TextButton
            text={accessory.Supplier.name}
            onClick={() =>
              handleRedirect(`/suppliers/${accessory.Supplier.id}`)
            }
          />
        ),
        supplierName: accessory.Supplier.name,
        name: accessory.name,
        actions:
          actions && permission === perms.ADMIN ? (
            <div>
              {actions.update && (
                <EditOutlined
                  title="Edytuj"
                  style={{ color: 'gray', cursor: 'pointer', margin: 5 }}
                  onClick={() => actions.update && actions.update(accessory)}
                />
              )}
              {actions.remove && (
                <Popconfirm
                  title="Usuń element"
                  onConfirm={() => actions.remove && actions.remove(accessory)}
                >
                  <DeleteOutlined
                    title="Usuń"
                    style={{ color: 'red', cursor: 'pointer', margin: 5 }}
                  />
                </Popconfirm>
              )}
            </div>
          ) : (
            <Typography.Text disabled>brak</Typography.Text>
          ),
      };
    });

    setList(newList);
  }, [accessories]);

  return (
    <div>
      <Table
        columns={columns}
        dataSource={list}
        pagination={{ position: ['topRight'] }}
        size="small"
        {...props}
      />
    </div>
  );
};

export default withPermission(AccessoriesList);
