import { UserAddOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { TNewProjectModal } from '..';

interface IProps {
  showModal: (modal: TNewProjectModal) => void;
}

const NewProjectSider: React.FC<IProps> = ({ showModal }) => {
  return (
    <>
      <Menu mode="inline">
        <Menu.Item
          icon={<UserAddOutlined style={{ color: 'green' }} />}
          onClick={() => showModal('newCustomer')}
        >
          Dodaj klienta
        </Menu.Item>
      </Menu>
    </>
  );
};

export default NewProjectSider;
