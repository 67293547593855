import React, { useState } from 'react';
import { AddressT } from 'services/store/types/settings/Settings';
import { Button, Tooltip, message } from 'antd';
import { ButtonRow } from 'templates/FlexRow';
import { HomeOutlined, HomeTwoTone } from '@ant-design/icons';
import { CustomerT } from 'services/store/types/customers/Customers';
import { useDispatch } from 'react-redux';
import { setNewProjectField } from 'services/store/actions/newProject';
import AddressData from 'components/Address/Display';
import AddAddress from '../../../../components/Address/AddressModal/AddAddress';

interface PropsT {
  address?: AddressT;
  customer?: CustomerT;
}

const MontageAddress: React.FC<PropsT> = ({ address, customer }) => {
  const dispatch = useDispatch();
  const [addAddress, setAddAddress] = useState(false);

  const handleCustomerAddress = () => {
    dispatch(setNewProjectField('montageAddress', customer?.CustomerAddress));
    message.success('Ustawiłeś domyślny adres montażu');
  };
  const handleAddAddress = () => setAddAddress(true);

  const handleAddress = (a: AddressT) => {
    dispatch(setNewProjectField('montageAddress', a));
    message.success('Dodałeś adres montażu');
  };

  return (
    <div>
      <ButtonRow justify="flex-start">
        <Tooltip
          title={!customer ? 'Musisz wybrać klienta' : undefined}
          color="blue"
        >
          <Button
            disabled={!customer || !customer.CustomerAddress}
            onClick={handleCustomerAddress}
          >
            <HomeTwoTone /> Domyślny adres klienta
          </Button>
        </Tooltip>
        <Button type="primary" onClick={handleAddAddress}>
          <HomeOutlined /> Dodaj inny adres
        </Button>
      </ButtonRow>
      <div style={{ marginTop: 30 }}>
        <AddressData address={address} column={1} />
      </div>

      {/* MODALS */}
      {addAddress && (
        <AddAddress
          visible={addAddress}
          closeModal={() => setAddAddress(false)}
          values={address}
          handleSubmit={handleAddress}
        />
      )}
    </div>
  );
};

export default MontageAddress;
