import {
  Modal,
  Checkbox,
  InputNumber,
  DatePicker,
  Divider,
  Button,
} from 'antd';
import withPermission from 'hoc/withPermission';
import useUser from 'hooks/useUser';
import { Moment } from 'moment';
import React, { useState } from 'react';
import { IFileData } from 'services/api/files.api';
import { PermissionsT } from 'services/store/types/auth/Auth';
import { ProjectT } from 'services/store/types/projects/Projects';
import { FileTypesT } from 'services/types';
import { perms } from 'services/utils/const';
import { FlexRow } from 'templates/FlexRow';

const initChanges = {
  date: false,
  price: false,
};

interface PropsT {
  closeModal: () => void;
  getFile: (fileData: IFileData) => void;
  project: ProjectT;
}

const GetAnnex: React.FC<PropsT> = ({ closeModal, getFile, project }) => {
  const { isAdmin } = useUser();

  const [newPrice, setNewPrice] = useState<any>(undefined);
  const [newMontageWeek, setNewMontageWeek] = useState<Moment | null>(null);
  const [changes, setChanges] = useState(initChanges);

  const handleChanges = (type: string, value: boolean) =>
    setChanges({ ...changes, [type]: value });

  return (
    <Modal
      title="Pobierz aneks do umowy"
      visible={true}
      onCancel={closeModal}
      footer={[
        <Button onClick={closeModal} key={0}>
          Anuluj
        </Button>,
        <Button
          onClick={() =>
            getFile({
              name: 'Aneks do umowy',
              fileType: 'annex',
              ext: 'pdf',
              body: {
                newPrice,
                newMontageWeek,
              },
            })
          }
          key={1}
          type="primary"
          disabled={!newPrice && !newMontageWeek}
        >
          *.pdf
        </Button>,
        isAdmin() && (
          <Button
            onClick={() =>
              getFile({
                name: 'Aneks do umowy',
                fileType: 'annex',
                ext: 'docx',
                body: {
                  newPrice,
                  newMontageWeek,
                },
              })
            }
            key={2}
            type="primary"
            disabled={!newPrice && !newMontageWeek}
          >
            *.docx
          </Button>
        ),
      ]}
    >
      <FlexRow justify="space-between">
        <Checkbox
          checked={changes.price}
          onChange={(e) => handleChanges('price', e.target.checked)}
        >
          Zmiana ceny projektu
        </Checkbox>
        <InputNumber
          style={{ width: 150 }}
          value={newPrice}
          onChange={(value) => setNewPrice(value)}
          disabled={!changes.price}
          placeholder="Nowa cena"
          min={0}
        />
      </FlexRow>
      <Divider />
      <FlexRow justify="space-between">
        <Checkbox
          checked={changes.date}
          onChange={(e) => handleChanges('date', e.target.checked)}
          disabled={!project.montageWeek}
        >
          Zmiana terminu montażu
        </Checkbox>
        <DatePicker
          style={{ width: 150 }}
          placeholder="Wybierz tydzień"
          picker="week"
          value={newMontageWeek || null}
          onChange={(date) => setNewMontageWeek(date)}
          disabled={!changes.date}
        />
      </FlexRow>
    </Modal>
  );
};

export default GetAnnex;
