import moment from 'moment';
import { FileT, ProjectT } from 'services/store/types/projects/Projects';
import { states } from 'services/utils/const';
import {
  daysFromToday,
  daysUntilToday,
  weeksFromDate,
} from 'services/utils/date';
import { ProjectErrorT } from './types';

/**
 * OTHER
 */
// ADDRESS
export const montageAddressError = (
  project: ProjectT
): ProjectErrorT | null => {
  if (!project.montageAddress)
    return { type: 'danger', text: 'Brak adresu montażu' };
  return null;
};

/**
 * TERMS
 */
export const lateDesignerError = (project: ProjectT): ProjectErrorT | null => {
  if (project.measureWeek) {
    const measureWeekMonday = moment(project.measureWeek.date).day(1);

    if (daysFromToday(measureWeekMonday) < 0)
      return {
        type: 'danger',
        text: 'Pomiar jest w tym tygodniu. Należy przekazać projekt technologowi',
      };
    if (daysFromToday(measureWeekMonday) < 4)
      return {
        type: 'danger',
        text: 'Pomiar jest w przyszłym tygodniu. Należy przekazać projekt technologowi',
      };
  }
  return null;
};

export const measureTermError = (project: ProjectT): ProjectErrorT | null => {
  const { measureWeek, measureTerm } = project;

  if (measureWeek && !measureTerm) {
    const measureWeekMonday = moment(measureWeek.date).day(1);

    if (daysFromToday(measureWeekMonday) < 0)
      return { type: 'danger', text: 'Brak terminu pomiaru' };
    if (daysFromToday(measureWeekMonday) < 4)
      return { type: 'warn', text: 'Brak terminu pomiaru' };
  }

  return null;
};

export const measureWeekError = (project: ProjectT): ProjectErrorT | null => {
  const { measureWeek, montageWeek } = project;

  if (montageWeek) {
    if (!measureWeek) {
      if (moment(montageWeek.date).week() - moment().week() < 7)
        return {
          type: 'danger',
          text: `Montaż za ${weeksFromDate(
            moment(montageWeek.date)
          )} tyg. Wyznacz termin pomiaru.`,
        };
      if (moment(montageWeek.date).week() - moment().week() < 8)
        return {
          type: 'warn',
          text: `Montaż za ${weeksFromDate(
            moment(montageWeek.date)
          )} tyg. Wyznacz termin pomiaru.`,
        };
    }
  }
  return null;
};

export const montageTermError = (project: ProjectT): ProjectErrorT | null => {
  const { montageWeek, montageTerm } = project;

  if (montageWeek && !montageTerm) {
    const montageWeekMonday = moment(montageWeek.date).day(1);

    if (daysFromToday(montageWeekMonday) < 0)
      return { type: 'danger', text: 'Brak terminu montażu' };
    if (daysFromToday(montageWeekMonday) < 4)
      return { type: 'warn', text: 'Brak terminu montażu' };
  }

  return null;
};

/**
 * FILES
 */
export const agreementFileError = (project: ProjectT): ProjectErrorT | null => {
  const projestCreated = moment(project.startAt);
  if (daysUntilToday(projestCreated) > 2)
    return { type: 'warn', text: 'Umowa nie została pobrana' };

  return null;
};

export const agreementScanError = (files: FileT[]): ProjectErrorT | null => {
  const agreementFile = files.find((file: FileT) =>
    file.name.includes('Skan umowy')
  );

  if (agreementFile && !agreementFile.path) {
    const agrementDownloadDate = moment(agreementFile.createdAt);

    if (daysUntilToday(agrementDownloadDate) > 2)
      return { type: 'warn', text: 'Dodaj skan umowy' };
  }
  return null;
};

export const measureImagesError = (
  project: ProjectT,
  files: FileT[]
): ProjectErrorT | null => {
  if (project.measureTerm) {
    const measureTerm = moment(project.measureTerm.startDate);

    const measurePictures = files
      .filter((item: FileT) => item.type === 'img')
      .find((item: FileT) => item.imgType === 'measure');

    if (!measurePictures)
      if (daysUntilToday(measureTerm) > 2)
        return { type: 'warn', text: 'Brak zdjęć z pomiaru' };
  }
  return null;
};

export const technologistFileError = (
  project: ProjectT,
  files: FileT[]
): ProjectErrorT | null => {
  if (project.measureTerm) {
    const measureTerm = moment(project.measureTerm.startDate);

    const technologistFile = files
      .filter((item: FileT) => item.type === 'file')
      .find((item: FileT) => item.name.includes('Technolog'));

    if (!technologistFile || !technologistFile.path) {
      if (daysUntilToday(measureTerm) > 7)
        return { type: 'danger', text: 'Brak rysunków technologicznych' };

      if (daysUntilToday(measureTerm) > 6)
        return { type: 'warn', text: 'Brak rysunków technologicznych' };
    }
  }
  return null;
};

/**
 * PAYMENTS
 */
export const firstPaymentPartError = (
  project: ProjectT,
  agreementFile: FileT
): ProjectErrorT | null => {
  const agreementFileDate = moment(agreementFile.createdAt);
  if (!project.Payments.length || !project.Payments[0].isPaid) {
    if (![states.PENDING, states.PROJECT].includes(project.status))
      return {
        type: 'danger',
        text: '1 część płatności nie została zatwierdzona',
      };
    if (daysUntilToday(agreementFileDate) > 2)
      return {
        type: 'danger',
        text: '1 część płatności nie została zatwierdzona',
      };
    if (daysUntilToday(agreementFileDate) > 1)
      return {
        type: 'warn',
        text: '1 część płatności nie została zatwierdzona',
      };
  }
  return null;
};

export const secondPaymentPartError = (
  project: ProjectT
): ProjectErrorT | null => {
  if (
    (!project.Payments?.length || !project.Payments[1]?.isPaid) &&
    project.montageTerm
  ) {
    const montageTermDate = moment(project.montageTerm.startDate);
    if (daysFromToday(montageTermDate) < 3)
      return {
        type: 'danger',
        text: '2 część płatności nie została zatwierdzona',
      };
    if (daysFromToday(montageTermDate) < 4)
      return {
        type: 'warn',
        text: '2 część płatności nie została zatwierdzona',
      };
  }
  return null;
};

export const thirdPaymentPartError = (
  project: ProjectT
): ProjectErrorT | null => {
  if (!project.Payments?.length || !project.Payments[2]?.isPaid)
    if (project.montageEnd)
      return {
        type: 'danger',
        text: '3 część płatności nie została zatwierdzona',
      };
  return null;
};
