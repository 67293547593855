import Axios from 'axios';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import setAuthToken from 'services/utils/setAuthToken';
import { ProjectT } from 'services/store/types/projects/Projects';

export const addProjectComment = async (
  projectId: string,
  body: { comment: string; permissions: string },
  onEnd: (project: ProjectT) => void,
  onErr: () => void
) => {
  setAuthToken();
  try {
    const res: any = await Axios.post(`/api/comments/${projectId}`, body);

    onEnd(res.data.project);
  } catch (error) {
    axiosErrorHandle(error, onErr);
  }
};
