import React, { useState } from 'react';
import { Modal } from 'antd';
import { AccessoryT } from 'services/store/types/accesories/Accessories';
import { IdType } from 'services/types';
import AccessoriesList from '../List';

interface PropsT {
  visible: boolean;
  closeModal: () => void;
  handleAddAccessories: (accessories: AccessoryT[]) => void;
  accessories: AccessoryT[];
  selectedIds: IdType[];
}

const PickAccessoriesModal: React.FC<PropsT> = ({
  handleAddAccessories,
  closeModal,
  visible,
  accessories,
  selectedIds,
}) => {
  const [selectedItems, setSelectedItems] = useState<IdType[]>(selectedIds);

  const handleSelectItem = (id: IdType) => {
    if (!selectedItems.includes(id)) setSelectedItems([...selectedItems, id]);
    else {
      const newItems = selectedItems.filter((itemId: IdType) => itemId !== id);
      setSelectedItems(newItems);
    }
  };

  const handleOk = () => {
    const newList = accessories.filter((a: AccessoryT) =>
      selectedItems.includes(a.id)
    );

    handleAddAccessories(newList);
    closeModal();
  };

  return (
    <Modal
      title="Wybierz akcesoria"
      destroyOnClose={true}
      visible={visible}
      style={{ top: 40 }}
      width="85vw"
      onCancel={closeModal}
      onOk={handleOk}
    >
      <AccessoriesList
        accessories={accessories}
        size="small"
        rowSelection={{
          selectedRowKeys: selectedItems,
          onChange: (arr: any) => setSelectedItems(arr),
        }}
        onRow={(a: AccessoryT) => ({
          onClick: () => handleSelectItem(a.id),
        })}
      />
    </Modal>
  );
};

export default PickAccessoriesModal;
