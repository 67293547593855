import { message, PageHeader, Typography } from 'antd';
import React from 'react';
import { StyledH1 } from 'components/Header';
import ProjectOrdersList from 'components/Orders/ProjectOrders';
import { ProjectT } from 'services/store/types/projects/Projects';
import { IdType } from 'services/types';
import { removeOrder } from 'services/api/orders/remove';
import { getProjectOrders } from 'services/store/actions/projects';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';

interface PropsT {
  project: ProjectT;
}

const Orders: React.FC<PropsT> = ({ project }) => {
  const dispatch = useDispatch();

  const handleRemove = async (orderId: IdType) => {
    await removeOrder(
      orderId,
      () => {
        message.success('Zamówienie usunięte');
        dispatch(getProjectOrders(project.id));
      },
      () => {}
    );
  };

  const handleUpdate = () => {
    dispatch(setSpinner(true));
    dispatch(getProjectOrders(project.id));
  };

  if (project?.Orders)
    return (
      <div>
        <PageHeader
          title={
            <StyledH1>Zamówienia do projektu {project?.number || '-'}</StyledH1>
          }
          subTitle={
            <Typography.Text disabled>
              /Zamówienia w projekcie zostały już wygenerowane. Możesz złożyć
              kolejne ręcznie./
            </Typography.Text>
          }
        />
        <ProjectOrdersList
          orders={project.Orders}
          handleRemove={handleRemove}
          handleUpdate={handleUpdate}
        />
      </div>
    );
  return null;
};

export default Orders;
