import React, { useState, useEffect } from 'react';
import { CommentT, ProjectT } from 'services/store/types/projects/Projects';
import {
  Button,
  List,
  Comment,
  Popconfirm,
  message,
  Typography,
  Divider,
} from 'antd';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import TextButton from 'components/TextButton';
import moment from 'moment';
import { setProject } from 'services/store/actions/projects';
import {
  AlignCenterOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { setSpinner } from 'services/store/actions/view';
import { removeProjectComment } from 'services/api/comments/remove';
import AddComment, { IAddCommentProps } from './Modals/AddComment';
import useUser from 'hooks/useUser';

const { Title } = Typography;

const initModals = {
  addComment: false,
};

interface PropsT extends Omit<IAddCommentProps, 'visible' | 'closeModal'> {
  comments: CommentT[] | undefined;
  isLoading: boolean;
}

const Comments: React.FC<PropsT> = ({ comments, isLoading, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAdmin, hasPermission } = useUser();
  const [modal, setModal] = useState(initModals);
  const [commentsList, setCommentsList] = useState<any[]>([]);

  useEffect(() => {
    if (comments) {
      const list = comments
        .filter((item: CommentT) => {
          if (isAdmin()) return item;
          return hasPermission(item.permissions);
        })
        .map((item: CommentT) => {
          return {
            actions: isAdmin() && [
              <Popconfirm
                key="1"
                title="Na pewno chcesz usunąć tą uwagę?"
                icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
                onConfirm={() => handleRemoveComment(item.id)}
                okText="Tak"
                cancelText="Nie"
              >
                <span key="comment-list-reply-to-0">Usuń</span>
              </Popconfirm>,
            ],
            author: (
              <TextButton
                onClick={() => history.push(`/users/${item.User.id}`)}
                text={`${item.User.firstname} ${item.User.surname}`}
              />
            ),
            content: item.comment,
            datetime: moment(item.createdAt).format('llll'),
          };
        });
      setCommentsList(list);
    }
  }, [comments]);

  const handleModal = (name: 'addComment') =>
    setModal({ ...modal, [name]: true });
  const closeModal = () => setModal(initModals);

  const handleRemoveComment = async (id: string) => {
    dispatch(setSpinner(true));
    await removeProjectComment(
      id,
      (p) => {
        dispatch(setProject(p));
        dispatch(setSpinner(false));
        message.success('Uwaga usunięta');
      },
      () => {
        dispatch(setSpinner(false));
        message.error('Błąd');
      }
    );
  };

  return (
    <div>
      <Title level={4}>Uwagi</Title>
      <CommentList comments={commentsList} isLoading={isLoading} />

      <Divider style={{ opacity: 0.2 }} />
      <Button onClick={() => handleModal('addComment')}>
        <AlignCenterOutlined />
        Dodaj uwagę
      </Button>

      {/* MODALS */}
      {modal.addComment && (
        <AddComment
          {...props}
          visible={modal.addComment}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default Comments;

const CommentList = ({
  comments,
  isLoading,
}: {
  comments: any;
  isLoading: boolean;
}) => {
  return (
    <List
      style={{ height: 300, overflowY: 'scroll' }}
      dataSource={comments}
      itemLayout="horizontal"
      renderItem={(props: any) => <Comment {...props} />}
      loading={isLoading}
    />
  );
};
