import { RcFile } from 'antd/lib/upload';
import {
  CabinetElementT,
  CabinetFrontConfigT,
  CabinetT,
} from 'services/store/types/cabinets/Cabinets';
import {
  PatternT,
  PatternTypeT,
  TableT,
  VeneerT,
} from 'services/store/types/catalog/Catalog';
import {
  SegmentCabinetT,
  SegmentT,
} from 'services/store/types/pricing/Pricing';

export const initCabinetData: NewCabinetT = {
  id: '',
  symbol: '',
  name: '',
  description: '',
  price: 200,
  width: 400,
  height: 720,
  depth: 520,
  imgPath: '',
  miniaturePath: '',
  CabinetTypeId: 0,
  CabinetType: { name: 'string' },
  CabinetAccessories: [],
  CabinetElements: [],
  CabinetFronts: [],
  img: [],
};

export const initCabinetElement: CabinetElementT = {
  type: 'body',
  width: 0,
  height: 0,
  thickness: 18,
  veneerLength: 0,
  surface: 0,
  defaultQty: 1,
  isHdf: false,
  h1: false,
  h2: false,
  w1: false,
  w2: false,
  name: '',
  description: '',
};

export const initCabinetFront: CabinetElementT = {
  type: 'front',
  width: 0,
  height: 0,
  thickness: 18,
  veneerLength: 0,
  surface: 0,
  defaultQty: 1,
  isHdf: false,
  h1: true,
  h2: true,
  w1: true,
  w2: true,
  name: '',
  description: '',
};
export const initCabinetFrontConfig: CabinetFrontConfigT = {
  config: 1,
  name: '',
  description: '',
  laminatePrice: 0,
  paintGlossPrice: 0,
  paintSemiGlossPrice: 0,
  Elements: [{ ...initCabinetFront }],
};

// TYPES
export interface NewCabinetT extends CabinetT {
  img: RcFile[];
}

export const initPatternData = (type: PatternTypeT): PatternT => ({
  type,
  name: '',
  symbol: '',
  SupplierId: '',
});

export const initVeneerData: VeneerT = {
  name: '',
  symbol: '',
  width: 18,
  thickness: 2,
  price: 0,
  imgPath: '',
  miniaturePath: '',
  SupplierId: '',
};
export const initTableData: TableT = {
  name: '',
  symbol: '',
  width: 3050,
  depth: 600,
  thickness: 38,
  imgPath: '',
  miniaturePath: '',
  SupplierId: '',
  price: 420,
};

export const initSegment = (): SegmentT => ({
  id: Math.random(),
  SegmentCabinets: [],
  segmentNumber: 0,
});

export const initSegmentCabinet: SegmentCabinetT = {
  CabinetId: '',
  Cabinet: undefined,
  FrontsConfigId: '',
  FrontsConfig: undefined,
  FrontPatternId: '',
  FrontPattern: undefined,
  BodyPatternId: '',
  BodyPattern: undefined,
  order: 0,
  price: 0,
};
