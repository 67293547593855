import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  
  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  body {
    margin: 0;
    padding: 0;
  }

.ant-divider-horizontal.ant-divider-with-text-left::before,
.ant-divider-horizontal.ant-divider-with-text-left::after,
.ant-divider-horizontal.ant-divider-with-text-right::before,
.ant-divider-horizontal.ant-divider-with-text-right::after,
.ant-divider
{
   border-top: .1px solid lightskyblue;
   .anticon{
       color:red !important;
   }
}
.table-row-primary{
    background-color:#f0faff
}
.table-row-clicable{
    cursor: pointer ;
}
`;

export default GlobalStyle;
