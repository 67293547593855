import React from 'react';
import { Typography, Input, InputNumber, DatePicker } from 'antd';
import { useDispatch } from 'react-redux';
import { setNewProjectField } from 'services/store/actions/newProject';
import moment, { Moment } from 'moment';

interface PropsT {
  name: string;
  price: number | undefined;
  projectYear: Moment;
}

const ProjectData: React.FC<PropsT> = ({ name, price, projectYear }) => {
  const dispatch = useDispatch();

  const handleName = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(setNewProjectField('name', e.target.value));
  const handleNumber = (value: string | number | undefined | null) =>
    dispatch(setNewProjectField('price', value));
  const handleProjectYear = (value: Moment | null) =>
    dispatch(setNewProjectField('projectYear', value));

  return (
    <div>
      <div>
        <Typography.Title level={4}>Rok projektu</Typography.Title>
        <DatePicker
          size="large"
          picker="year"
          value={projectYear}
          onChange={(d) => handleProjectYear(d)}
          disabledDate={(d) => d.year() < moment().year()}
          style={{ width: 400 }}
        />
      </div>
      <div>
        <Typography.Title level={4}>Nazwa</Typography.Title>
        <Input
          size="large"
          placeholder="Nazwa projektu"
          value={name}
          onChange={handleName}
          style={{ width: 400 }}
        />
      </div>
      <div>
        <Typography.Title level={4}>Cena</Typography.Title>
        <InputNumber
          size="large"
          placeholder="Cena"
          value={price}
          onChange={handleNumber}
          style={{ width: 400 }}
        />
      </div>
    </div>
  );
};

export default ProjectData;
