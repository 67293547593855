import { FileT, ProjectT } from 'services/store/types/projects/Projects';
import { IdType } from 'services/types';

export const isProjectIncludeFile = (
  files: FileT[],
  fileName: string
): boolean => {
  const result = files.find((file: FileT) => file.name === fileName);
  return !!result;
};

export const getAccessoriesIds = <T extends { AccessoryId?: IdType }>(
  accessories: T[]
): IdType[] => {
  const ids = accessories.reduce((acc: IdType[], accessory: T) => {
    if (accessory.AccessoryId) return [...acc, accessory.AccessoryId];

    return acc;
  }, []);
  return ids;
};

export const generateProjectOrdinalNumber = (project: ProjectT) => {
  const { ordinalNumber, externalCompany, projectYear } = project;
  if (!ordinalNumber) return '-';
  const companyName = externalCompany
    ? `${externalCompany.company?.slice(0, 3)}/`
    : '';
  return `${companyName}${ordinalNumber}/${projectYear}`;
};
