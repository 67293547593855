/* eslint-disable @typescript-eslint/naming-convention */
import { Dispatch } from 'redux';
import setAuthToken from 'services/utils/setAuthToken';
import axiosErrorHandle from 'services/utils/axiosErrorHandle';
import Axios from 'axios';
import { message } from 'antd';
import moment, { Moment } from 'moment';
import { IdType } from 'services/types';
import { ProjectT, CustomerProjectsT, FileT } from '../types/projects/Projects';
import {
  ProjectsLoadSuccessT,
  PROJECTS_LOAD_SUCCESS,
  ProjectsLoadErrorT,
  PROJECTS_LOAD_ERROR,
  PROJECTS_SET_PROJECT,
  ProjectsSetProjectT,
  ProjectsAddToListT,
  PROJECTS_ADD_TO_LIST,
  ProjectsClearListT,
  PROJECTS_CLEAR_LIST,
  PROJECTS_CUSTOMERS_LOAD_SUCCESS,
  ProjectCustomersLoadSuccessT,
  ProjectsExpandRowT,
  PROJECTS_EXPAND_ROW,
  PROJECTS_COLLAPSE_ROW,
  ProjectsCollapseRowT,
  ProjectsListsT,
  ProjectFiltersFieldsT,
  ProjectSetFiltersFielsT,
  PROJECTS_SET_FILTERS_FIELD,
  ProjectsSetProjectPricingT,
  PROJECTS_SET_PROJECT_PRICING,
  ProjectsSetProjectOrdersT,
  PROJECTS_SET_PROJECT_ORDERS,
  UpdateFileInProjectT,
  UPDATE_FILES_IN_PROJECT,
} from '../types/projects/actions';
import { catalogLoadManySuccess } from './catalog';
import { setSpinner } from './view';
import { PricingT } from '../types/pricing/Pricing';
import { OrderT } from '../types/orders/Orders';
import { PatternTypeT } from '../types/catalog/Catalog';
import { pricingSetPricing } from './pricing';

export const updateFilesInProject = (files: FileT[]): UpdateFileInProjectT => ({
  type: UPDATE_FILES_IN_PROJECT,
  files,
});
export const projectsLoadSuccess = (
  projects: ProjectT[]
): ProjectsLoadSuccessT => ({
  type: PROJECTS_LOAD_SUCCESS,
  projects,
});
export const customersWithProjectsLoadSuccess = (
  list: ProjectsListsT,
  customers: CustomerProjectsT[]
): ProjectCustomersLoadSuccessT => ({
  type: PROJECTS_CUSTOMERS_LOAD_SUCCESS,
  customers,
  list,
});
export const projectsLoadError = (): ProjectsLoadErrorT => ({
  type: PROJECTS_LOAD_ERROR,
});
export const setProject = (project: ProjectT | null): ProjectsSetProjectT => ({
  type: PROJECTS_SET_PROJECT,
  project,
});
export const setProjectPricing = (
  pricing: PricingT | null
): ProjectsSetProjectPricingT => ({
  type: PROJECTS_SET_PROJECT_PRICING,
  pricing,
});
export const setProjectOrders = (
  orders: OrderT[]
): ProjectsSetProjectOrdersT => ({
  type: PROJECTS_SET_PROJECT_ORDERS,
  orders,
});
export const addProjectToList = (project: ProjectT): ProjectsAddToListT => ({
  type: PROJECTS_ADD_TO_LIST,
  project,
});
export const clearProjectsList = (
  list: ProjectsListsT
): ProjectsClearListT => ({
  type: PROJECTS_CLEAR_LIST,
  list,
});
export const expandProjectsRow = (
  list: ProjectsListsT,
  key: string
): ProjectsExpandRowT => ({
  type: PROJECTS_EXPAND_ROW,
  list,
  key,
});
export const collapseProjectsRow = (
  list: ProjectsListsT,
  key: string
): ProjectsCollapseRowT => ({
  type: PROJECTS_COLLAPSE_ROW,
  list,
  key,
});
export const setFiltersField = (
  list: ProjectsListsT,
  field: ProjectFiltersFieldsT,
  value: [Moment, Moment]
): ProjectSetFiltersFielsT => ({
  type: PROJECTS_SET_FILTERS_FIELD,
  list,
  field,
  value,
});

/**--------------------
 * THUNK
 ----------------------*/
/**
 * GET
 */

export const getCustomersWithActiveProjects =
  (onEnd?: () => void, onError?: () => void) => async (dispatch: Dispatch) => {
    setAuthToken();
    try {
      const res = await Axios.get('/api/customers/customers/activeprojects');
      const { customers } = res.data;

      dispatch(customersWithProjectsLoadSuccess('active', customers));
      if (onEnd) onEnd();
    } catch (error) {
      dispatch(projectsLoadError());
      axiosErrorHandle(error, onError);
    }
    dispatch(setSpinner(false));
  };

export const getCustomersWithEndedProjects =
  (from: Moment, to: Moment, onEnd?: () => void, onError?: () => void) =>
  async (dispatch: Dispatch) => {
    setAuthToken();
    try {
      const res = await Axios.post('/api/customers/customers/endedprojects', {
        from,
        to: moment(to).add(1, 'day'),
      });
      const { customers } = res.data;

      dispatch(customersWithProjectsLoadSuccess('ended', customers));
      if (onEnd) onEnd();
    } catch (error) {
      dispatch(projectsLoadError());
      axiosErrorHandle(error, onError);
    }
    dispatch(setSpinner(false));
  };

export const getAllProjectDataById =
  (id: string, onEnd?: (p: ProjectT) => void, onError?: () => void) =>
  async (dispatch: Dispatch) => {
    setAuthToken();
    try {
      const res = await Axios.get(`/api/projects/${id}`);
      const { project } = res.data;
      project.Files = [];
      dispatch(setProject(project));
      if (onEnd) onEnd(project);
    } catch (error) {
      dispatch(setProject(null));
      axiosErrorHandle(error, onError);
    }
    dispatch(setSpinner(false));
  };

export const getProjectPricingByProjectId =
  (id: IdType, onEnd?: (p: PricingT) => void, onError?: () => void) =>
  async (dispatch: Dispatch) => {
    setAuthToken();
    try {
      const res = await Axios.get(`/api/projects/pricing/${id}`);
      const { pricing } = res.data;

      dispatch(setProjectPricing(pricing));
      if (onEnd) onEnd(pricing);
    } catch (error) {
      axiosErrorHandle(error, onError);
    }
    dispatch(setSpinner(false));
  };

export const getProjectOrders =
  (projectId: IdType, onEnd?: () => void, onError?: () => void) =>
  async (dispatch: Dispatch) => {
    setAuthToken();
    try {
      const res = await Axios.get(`/api/projects/orders/${projectId}`);
      const { orders } = res.data;

      dispatch(setProjectOrders(orders));
      if (onEnd) onEnd();
    } catch (error) {
      dispatch(setProjectOrders([]));
      axiosErrorHandle(error, onError);
    }
    dispatch(setSpinner(false));
  };

// NOTE NO THUNK
export const getPricingData =
  (
    projectId: IdType,
    patternTypes: PatternTypeT[],
    onEnd?: (p: PricingT) => void,
    onError?: () => void
  ) =>
  async (dispatch: Dispatch) => {
    setAuthToken();
    try {
      const pricingRes = await Axios.get(
        `/api/projects/pricingtoedit/${projectId}`
      );
      const { pricing } = pricingRes.data;
      dispatch(pricingSetPricing(pricing));

      const patternsRes: any = await Axios.post('/api/catalog/many', {
        types: patternTypes,
      });
      dispatch(catalogLoadManySuccess(patternsRes.data.patterns));

      if (onEnd) onEnd(pricing);
    } catch (error) {
      axiosErrorHandle(error, onError);
    }
    dispatch(setSpinner(false));
  };

/**
 * UPDATE
 */
export const updateProjectById =
  (
    type: updateTypes,
    id: string,
    body: any,
    successMsg?: string,
    onEnd?: () => void,
    onErr?: () => void
  ) =>
  async (dispatch: Dispatch) => {
    setAuthToken();
    try {
      const res: any = await Axios.put(`/api/projects/${type}/${id}`, body);

      message.success(successMsg || 'Sukces');
      dispatch(setProject(res.data.project));
      if (onEnd) onEnd();
    } catch (error) {
      axiosErrorHandle(error, onErr);
    }
    dispatch(setSpinner(false));
  };

export const updateProjectTermsById =
  (
    type: termsTypes,
    id: string,
    body: any,
    successMsg?: string,
    onEnd?: () => void,
    onErr?: () => void
  ) =>
  async (dispatch: Dispatch) => {
    setAuthToken();
    try {
      const res: any = await Axios.post(`/api/terms/${type}/${id}`, body);

      message.success(successMsg || 'Sukces');
      dispatch(setProject(res.data.project));
      if (onEnd) onEnd();
    } catch (error) {
      axiosErrorHandle(error, onErr);
    }
    dispatch(setSpinner(false));
  };

export const projectUpdateTypes = {
  DATE_CHANGE: 'datechange' as const,
  PRICE_CHANGE: 'pricechange' as const,
  NAME_CHANGE: 'namechange' as const,
  NUMBER_CHANGE: 'numberchange' as const,
  ORDINAL_NUMBER_CHANGE: 'ordinalnumberchange' as const,
  DESIGNER_CHANGE: 'designerchange' as const,
  EXTERNAL_COMPANY_CHANGE: 'externalcompany' as const,
  STATUS_CHANGE: 'statuschange' as const,
  PRODUCTION_END: 'productionend' as const,
  MONTAGE_END: 'montageend' as const,
};
export const projectTermsTypes = {
  MEASURE_WEEK: 'measureweek' as const,
  MEASURE_DATE: 'measuredate' as const,
  MONTAGE_WEEK: 'montageweek' as const,
  MONTAGE_DATE: 'montagedate' as const,
};
type updateTypes =
  | 'namechange'
  | 'datechange'
  | 'ordinalnumberchange'
  | 'numberchange'
  | 'montageend'
  | 'productionend'
  | 'externalcompany'
  | 'statuschange'
  | 'pricechange'
  | 'designerchange'
  | 'montageaddress';
type termsTypes = 'measureweek' | 'measuredate' | 'montageweek' | 'montagedate';
